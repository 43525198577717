import { Css, useTestIds } from "@homebound/beam";
import { useMemo } from "react";
import { Price } from "src/components";
import {
  BillReviewReason,
  BillType,
  CreateCeModalQuery,
  InputMaybe,
  SaveChangeEventInput,
  SaveChangeEventLineItemInput,
  SaveCommitmentLineItemInput,
} from "src/generated/graphql-types";
import { changeEventTypeToNameMapper, formatCentsToPrice } from "src/utils";
import { ObjectState } from "src/utils/formState";
import { BillFormInput } from "../BillEditor";

type ChangeEventOverviewProps = {
  formState: ObjectState<BillFormInput>;
  changeEventFormState: ObjectState<SaveChangeEventInput>;
  changeEventData: CreateCeModalQuery;
  overheadLineItems: {
    name: string;
    projectItemId: InputMaybe<string>;
    totalCostInCents: number;
    quantity: number;
  }[];
  changeEventLineItems: SaveChangeEventLineItemInput[];
  lineItems: (SaveCommitmentLineItemInput & { name: string; budgetImpactInCents?: number | null })[];
  isEpoChangeEvent: boolean;
};

export function ChangeEventOverview(props: ChangeEventOverviewProps) {
  const { formState, changeEventFormState, overheadLineItems, changeEventLineItems, lineItems, isEpoChangeEvent } =
    props;
  const testIds = useTestIds({});
  const isOverhead = formState.type.value === BillType.Overhead;
  const reviewBudgetImpact = useMemo(() => {
    return isOverhead
      ? overheadLineItems.sumOrUndefined((li) => li.totalCostInCents)
      : changeEventLineItems.sumOrUndefined((li) => li.totalCostInCents ?? 0);
  }, [changeEventLineItems, isOverhead, overheadLineItems]);

  const celis = useMemo(() => {
    if (formState.type.value === BillType.Overhead) {
      return overheadLineItems;
    } else {
      return lineItems?.map((li) => ({
        name: li.name,
        projectItemId: li.projectItemId,
        totalCostInCents: isEpoChangeEvent
          ? Number(li.budgetImpactInCents) > 0
            ? li.budgetImpactInCents
            : (li.costChangeInCents ?? 0)
          : li.costChangeInCents,
      }));
    }
  }, [formState.type.value, overheadLineItems, lineItems, isEpoChangeEvent]);

  return (
    <div>
      <div
        {...testIds.overview}
        css={Css.fwb.pb1.$}
      >{`A ${changeEventFormState.type.value && changeEventTypeToNameMapper(changeEventFormState.type.value)} change event will be created with the following data:`}</div>
      <div css={Css.df.fdc.$}>
        <div css={Css.fwb.pt2.$} {...testIds.budgetUpdate}>
          Budget update: <Price valueInCents={isEpoChangeEvent ? 0 : reviewBudgetImpact} />
        </div>
      </div>
      {isOverhead ? (
        <div>
          If the line item amounts are under the threshold for each cost code, the Change Event will be automatically
          accepted after confirmation.
        </div>
      ) : (
        <>
          <div css={Css.fwb.pt2.$}>Line items: </div>
          {[BillReviewReason.NeedVpo, BillReviewReason.InsufficientBudget].includes(formState.reviewReason.value!) && (
            <div css={Css.pt1.$}>
              A new commitment will be created for the following project items to increase <br></br> the budget
              associated with the commitment selected.
            </div>
          )}
          <div css={Css.my2.$}>
            {celis.map((li) => (
              <li key={li.projectItemId} {...testIds[`li-${li.projectItemId}`]}>
                <strong>{li.name}:</strong> {formatCentsToPrice(li.totalCostInCents ?? 0)}
              </li>
            ))}
            {isEpoChangeEvent && (
              <li {...testIds.contingencyLine}>
                <strong>Contingency: </strong> {formatCentsToPrice(-(reviewBudgetImpact ?? 0))}
              </li>
            )}
          </div>
        </>
      )}
    </div>
  );
}
