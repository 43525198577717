import { Css } from "@homebound/beam";
import { GeneralTlivChangesFragment } from "src/generated/graphql-types";
import { renderAccordion } from "src/routes/libraries/versioning/VersionHistoryUtils";
import { count } from "src/utils";

type ProductTlivChangesProps = {
  tliVersions: GeneralTlivChangesFragment[];
};

export function ProductTlivChanges({ tliVersions }: ProductTlivChangesProps) {
  const productChanges = tliVersions.filter((tliv) => tliv.identity.isProductMaterial);

  if (productChanges.isEmpty) return null;

  return renderAccordion(
    `${count(productChanges, "Product update")}`,
    <ul css={Css.m0.$}>
      {productChanges.map((tliv) => (
        <li key={tliv.id} css={Css.baseMd.$}>
          {/* Location Name: Item Name: Product Name */}
          {tliv.location.effectiveLocation?.name}: {tliv.identity.item.name}: {tliv.materialVariant?.displayName}
        </li>
      ))}
    </ul>,
  );
}
