import {
  ButtonGroup,
  CollapseToggle,
  Css,
  Filter,
  Filters,
  GridColumn,
  GridDataRow,
  GridTable,
  Icon,
  RowStyles,
  SelectToggle,
  collapseColumn,
  column,
  emptyCell,
} from "@homebound/beam";
import startCase from "lodash/startCase";
import { useMemo, useState } from "react";
import { SearchBox, emptyCellDash } from "src/components";
import { CollapseRowToggle } from "src/components/formFields/CollapseRowToggle";
import {
  DesignPackageFinishScheduleFilter,
  FinishSchedule_MaterialAttributeValueFragment,
  FinishSchedule_TliFragment,
  useFinishScheduleDesignPackageTlisQuery,
} from "src/generated/graphql-types";
import { Remap, foldEnum, sanitizeHtml } from "src/utils";
import {
  PRODUCT_EMPTY_STATE_IMG_URL,
  PRODUCT_FALLBACK_IMG_URL,
} from "../../product-catalog/components/product-images-viewer/ProductImageViewer";
import { OptionChips } from "../designCatalogAtoms";
import { FinishScheduleFilters } from "./FinishSchedulePage";

enum GroupBy {
  costCode = "costCode",
  location = "location",
}

type FinishScheduleFilterDefs = Remap<FinishScheduleFilters, (key: string) => Filter<string[]>>;

type FinishScheduleTableProps = {
  versionId: string;
  filter: FinishScheduleFilters;
  setFilter: (filter: FinishScheduleFilters) => void;
  filterDefs: FinishScheduleFilterDefs;
  costCodes: { id: string; name: string }[] | undefined;
  locations: { id: string; name: string }[] | undefined;
};

export function FinishScheduleTable(props: FinishScheduleTableProps) {
  const { versionId, filter, setFilter, filterDefs, costCodes, locations } = props;
  const [groupBy, setGroupBy] = useState(GroupBy.costCode);
  const [searchFilter, setSearchFilter] = useState<string | undefined>(undefined);

  const query = useFinishScheduleDesignPackageTlisQuery({
    variables: { filter: mapToFilter(filter, searchFilter, versionId) },
  });

  const rows = useMemo(
    () => createRows(query.data?.designPackageFinishSchedule.entities ?? [], costCodes ?? [], locations ?? [], groupBy),
    [query.data, costCodes, locations, groupBy],
  );
  const columns = useMemo(() => createColumns(), []);

  return (
    <div css={Css.bcGray200.$}>
      <div css={Css.py3.bb.bw2.bcGray300.df.jcsb.$}>
        <Filters<FinishScheduleFilters> filter={filter} filterDefs={filterDefs} onChange={setFilter} />
        <div css={Css.wPx(600).df.gap2.$}>
          <SearchBox fullWidth placeholder="Search by cost code, item code, product..." onSearch={setSearchFilter} />
          <ButtonGroup
            buttons={[
              { text: "Cost Code", active: groupBy === GroupBy.costCode, onClick: () => setGroupBy(GroupBy.costCode) },
              { text: "Room", active: groupBy === GroupBy.location, onClick: () => setGroupBy(GroupBy.location) },
            ]}
          />
        </div>
      </div>
      <div css={Css.pbPx(1).bgWhite.$}>
        <GridTable rows={rows} columns={columns} style={{ vAlign: "top" }} rowStyles={rowStyles} />
      </div>
    </div>
  );
}

const rowStyles: RowStyles<Row> = {
  parentGroup: { cellCss: Css.bgGray100.py2.$ },
  // reducing the width of the last column due to the row margin pushing the last column out of table view
  data: { rowCss: Css.m2.boxShadow("0 4px 8px rgba(0, 0, 0, 0.2)").addIn("& > div:nth-of-type(12)", Css.wPx(38).$).$ },
};

type Row = FinishScheduleChildRow | FinishScheduleParentRow | FinishScheduleRow;

type FinishScheduleParentRow = {
  id: string;
  kind: "parentGroup";
  data: string;
};

type FinishScheduleChildRow = {
  id: string;
  kind: "childGroup";
  data: string;
};

type FinishScheduleRow = {
  id: string;
  kind: "data";
  data: FinishSchedule_TliFragment;
  dynamicAttributes: FinishSchedule_MaterialAttributeValueFragment[][];
};

function createRows(
  tlis: FinishSchedule_TliFragment[],
  costCodes: { id: string; name: string }[],
  locations: { id: string; name: string }[],
  groupByKey: GroupBy,
): GridDataRow<Row>[] {
  return foldEnum(groupByKey, {
    costCode: () =>
      groupTlisByCostCode(
        tlis.sortBy((tli) => tli.item.costCode?.name ?? ""),
        costCodes,
        locations,
      ),
    location: () =>
      groupTlisByLocation(
        tlis.sortBy((tli) => tli.location.name ?? ""),
        costCodes,
        locations,
      ),
  });
}

function groupTlisByCostCode(
  tlis: FinishSchedule_TliFragment[],
  costCodes: { id: string; name: string }[],
  locations: { id: string; name: string }[],
): GridDataRow<Row>[] {
  const costCodeGroups = tlis.groupBy((tli) => tli.item.costCode?.id ?? "none");
  return Object.entries(costCodeGroups).map(([costCodeId, costCodeTlis]) => {
    // Only show Product TLIs (which all point to their placeholder)
    const locationGroups = costCodeTlis.filter((tli) => !!tli.placeholder).groupBy((tli) => tli.location.id);
    return {
      id: costCodeId,
      kind: "parentGroup" as const,
      data: costCodes.find((c) => c.id === costCodeId)?.name ?? "",
      children: Object.entries(locationGroups).map(([locationId, locationTlis]) => ({
        id: costCodeId + locationId,
        kind: "childGroup" as const,
        data: locations.find((l) => l.id === locationId)?.name ?? "",
        children: locationTlis.map((tli) => ({
          id: tli.id,
          kind: "data" as const,
          data: tli,
          dynamicAttributes: getDynamicAttributes(tli),
        })),
      })),
    };
  });
}

function groupTlisByLocation(
  tlis: FinishSchedule_TliFragment[],
  costCodes: { id: string; name: string }[],
  locations: { id: string; name: string }[],
) {
  const locationGroups = tlis.groupBy((tli) => tli.location?.id ?? "none");
  return Object.entries(locationGroups).map(([locationId, locationTlis]) => {
    const costCodeGroups = locationTlis
      .filter((tli) => !!tli.placeholder)
      .groupBy((tli) => tli.item.costCode?.id ?? "none");
    return {
      id: locationId,
      kind: "parentGroup" as const,
      data: locations.find((l) => l.id === locationId)?.name ?? "",
      children: Object.entries(costCodeGroups).map(([costCodeId, costCodeTlis]) => ({
        id: costCodeId + locationId,
        kind: "childGroup" as const,
        data: costCodes.find((c) => c.id === costCodeId)?.name ?? "",
        children: costCodeTlis.map((tli) => ({
          id: tli.id,
          kind: "data" as const,
          data: tli,
          dynamicAttributes: getDynamicAttributes(tli),
        })),
      })),
    };
  });
}

export function getDynamicAttributes(tli: FinishSchedule_TliFragment) {
  // Get the current TLI's attribute values
  const attributeValues = tli.materialVariant?.materialAttributeValues ?? [];
  // Get the fixed attributes
  const fixedDimensionNames = Object.entries(fixedDimensionsConfiguration).flatMap(([_, attributes]) => attributes);
  // Try to fill out 4 dynamic attribute spots
  // Filter out the fixed attributes and then destructure the first four attribute values
  const [va1, va2, va3, va4] = attributeValues.filter(
    (av) => !fixedDimensionNames.includes(av.dimension.name.toLowerCase()),
  );
  // Then we split values into a two-dimensional array to match column 4 (val1 & val2) and column 5 (val3 & val4)
  return [
    [va1, va2],
    [va3, va4],
  ];
}

function mapToFilter(filter: FinishScheduleFilters, search: string | undefined, designRpavId: string) {
  const { costCodes, locationInPath, plans, ...options } = filter;
  const optionIds = Object.values(options).flat();
  return {
    designRpavId,
    costCodeIds: costCodes,
    locationInPath,
    planRpavIds: plans,
    ...(optionIds.nonEmpty && { optionIds }),
    ...(search && { search }),
  } satisfies DesignPackageFinishScheduleFilter;
}

function createColumns(): GridColumn<Row>[] {
  return [
    column<Row>({
      parentGroup: (parentGroupName) => ({
        content: parentGroupName,
        colspan: 4,
        alignment: "left",
        css: Css.xlMd.$,
      }),
      childGroup: (childGroupName) => ({
        content: childGroupName,
        colspan: 4,
        alignment: "left",
        css: Css.smMd.$,
      }),
      data: (tli) => (
        <div css={Css.hPx(100).df.aic.jcc.$}>
          <img
            css={Css.maxw100.maxh100.ma.p1.$}
            src={
              !tli.isDisabledBidItem && !tli.bidItem
                ? PRODUCT_EMPTY_STATE_IMG_URL
                : tli.materialVariant?.featuredImage?.asset?.previewUrl || PRODUCT_FALLBACK_IMG_URL
            }
            alt="product"
          />
        </div>
      ),
      w: "120px",
    }),
    column<Row>({
      parentGroup: (c) => emptyCell,
      childGroup: (l) => emptyCell,
      data: (tli) => (
        <div css={Css.df.fdc.gap2.$}>
          <div>
            <div data-testid="productType" css={Css.smMd.df.fww.$}>
              {startCase(tli.slot.name || tli.item.name)}
            </div>
            <div css={Css.gray600.$}>PRODUCT TYPE</div>
          </div>
          <div data-testid="productCode">{tli.materialVariant?.code}</div>
          <div css={Css.df.fdr.aic.gap1.w100.oys.$}>
            {tli.options
              .groupByObject((rpo) => rpo.optionGroup)
              .sortBy(([rpog, _]) => rpog.order)
              .map(([rpog, rpos]) => (
                <OptionChips key={rpog.id} rpos={rpos} />
              ))}
          </div>
        </div>
      ),
      w: "300px",
    }),
    column<Row>({
      parentGroup: (c) => emptyCell,
      childGroup: (l) => emptyCell,
      data: () => (
        <div css={Css.h100.ais.$}>
          <Icon icon="link" inc={4} />
        </div>
      ),
      w: "40px",
    }),
    column<Row>({
      parentGroup: (c) => emptyCell,
      childGroup: (l) => emptyCell,
      data: (tli) => (
        <StackedAttributes
          topAttribute={{
            value: tli.materialVariant?.listing.name ?? emptyCellDash,
            label: "PRODUCT NAME",
          }}
          bottomAttribute={{
            value: tli.materialVariant?.listing.brand?.name ?? emptyCellDash,
            label: "BRAND",
          }}
        />
      ),
    }),
    column<Row>({
      parentGroup: (c) => emptyCell,
      childGroup: (l) => emptyCell,
      data: (tli) => (
        <div css={Css.h100.gap2.$}>
          <div>
            <div data-testid="skuModel" css={Css.smMd.$}>
              {tli.materialVariant?.modelNumber ?? emptyCellDash}
            </div>
            <div css={Css.gray600.$}>SKU/MODEL #</div>
          </div>
        </div>
      ),
    }),
    // 3 "fixed attribute" columns.
    ...Object.entries(fixedDimensionsConfiguration).map(([_, [topDimName, bottomDimName]]) =>
      column<Row>({
        parentGroup: (c) => emptyCell,
        childGroup: (l) => emptyCell,
        data: (tli) => {
          const topMav = tli.materialVariant?.materialAttributeValues.find(
            (mav) => mav.dimension.name.toLowerCase() === topDimName?.toLowerCase(),
          );
          const bottomMav = tli.materialVariant?.materialAttributeValues.find(
            (mav) => mav.dimension.name.toLowerCase() === bottomDimName?.toLowerCase(),
          );
          return (
            <StackedAttributes
              topAttribute={{
                value: topMav?.textValue ?? emptyCellDash,
                label: maybeAddUnitSuffix(topMav) ?? topDimName,
              }}
              bottomAttribute={{
                value: bottomMav?.textValue ?? emptyCellDash,
                label: maybeAddUnitSuffix(bottomMav) ?? bottomDimName,
              }}
            />
          );
        },
      }),
    ),
    // We try to render up to 4 attribute fields in addition to the 6 fixed attributes
    // 'row.dynamicAttributes' is a 2D array of FinishSchedule_MaterialAttributeValueFragment[][]
    // i.e. [[attrValue1, attrValue2], [attrValue3, attrValue4]]
    column<Row>({
      parentGroup: (c) => emptyCell,
      childGroup: (l) => emptyCell,
      data: (_, { row }) => {
        const columnOne = row.dynamicAttributes.first ?? [];
        const [topMav, bottomMav] = columnOne;
        return (
          <StackedAttributes
            topAttribute={{ value: topMav?.textValue ?? emptyCellDash, label: maybeAddUnitSuffix(topMav) ?? "" }}
            bottomAttribute={{
              value: bottomMav?.textValue ?? emptyCellDash,
              label: maybeAddUnitSuffix(bottomMav) ?? "",
            }}
          />
        );
      },
    }),
    column<Row>({
      parentGroup: (c) => emptyCell,
      childGroup: (l) => emptyCell,
      data: (_, { row }) => {
        const columnTwo = row.dynamicAttributes.last ?? [];
        const [topMav, bottomMav] = columnTwo;
        return (
          <StackedAttributes
            topAttribute={{ value: topMav?.textValue ?? emptyCellDash, label: maybeAddUnitSuffix(topMav) ?? "" }}
            bottomAttribute={{
              value: bottomMav?.textValue ?? emptyCellDash,
              label: maybeAddUnitSuffix(bottomMav) ?? "",
            }}
          />
        );
      },
    }),
    column<Row>({
      parentGroup: (c) => emptyCell,
      childGroup: (l) => emptyCell,
      data: (tli) => (
        <div data-testid="designNotes" dangerouslySetInnerHTML={{ __html: sanitizeHtml(tli.specifications ?? "") }} />
      ),
      w: "210px",
    }),
    collapseColumn<Row>({
      parentGroup: (groupName, { row, api }) => ({
        content: () => <CollapseRowToggle label={`Collapse ${groupName}`} rowId={row.id} api={api} />,
        alignment: "right",
      }),
      childGroup: (c, { row }) => <CollapseToggle row={row} />,
      data: (tli, { row }) => <SelectToggle id={row.id} />,
      w: "70px",
    }),
  ];
}

export function maybeAddUnitSuffix(mav: FinishSchedule_MaterialAttributeValueFragment | undefined) {
  const dim = mav?.dimension;
  return dim?.unitOfMeasure ? `${dim.name} (${dim.unitOfMeasure.abbreviation})` : dim?.name;
}

type Attribute = {
  value: string;
  label: string;
  testId?: string;
};

type StackedAttributesProps = {
  topAttribute: Attribute;
  bottomAttribute: Attribute;
};

function AttributeDisplay({ value, label, testId }: Attribute) {
  return (
    <div>
      <div data-testid={testId} css={Css.smMd.$}>
        {value}
      </div>
      <div css={Css.gray600.tt("uppercase").$}>{label}</div>
    </div>
  );
}

export function StackedAttributes({ topAttribute, bottomAttribute }: StackedAttributesProps) {
  return (
    <div css={Css.df.fdc.gap4.$}>
      <AttributeDisplay testId="topAttribute" value={topAttribute.value} label={topAttribute.label} />
      <AttributeDisplay testId="bottomAttribute" value={bottomAttribute.value} label={bottomAttribute.label} />
    </div>
  );
}

export const fixedDimensionsConfiguration = {
  columnOne: ["color", "finish"],
  columnTwo: ["width", "height"],
  columnThree: ["length", "depth"],
};
