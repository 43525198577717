import {
  column,
  Css,
  GridTable,
  IconButton,
  LoadingSkeleton,
  Palette,
  simpleHeader,
  Tag,
  useGridTableApi,
} from "@homebound/beam";
import { Link } from "react-router-dom";
import { FormattedDate, Price } from "src/components";
import { billStatusTagMapper } from "src/components/detailPane/bill/utils";
import {
  Bill,
  BillStatusDetail,
  CommitmentLike,
  CommitmentLineItem,
  useBillEditor_ProjectItemQuery,
} from "src/generated/graphql-types";
import { commitmentStatusToTagTypeMapper } from "src/utils";

type ProjectItemDetailsModalProps = {
  projectItemId: string;
  close: () => void;
};

type CommitmentLineItemRow = Pick<CommitmentLineItem, "id" | "committedInCents" | "billableLimitInCents"> & {
  owner: Pick<CommitmentLike, "id" | "status" | "statusText" | "executionDate" | "displayName" | "shortName"> & {
    blueprintUrl?: { path: string };
  };
};
type BillLineItemRow = Pick<Bill, "id" | "name" | "dueDate"> & {
  id: string;
  status: Partial<BillStatusDetail>;
  paidInCents: number;
  blueprintUrl?: { path: string };
  commitmentLineItem?: {
    id: string;
    billedInCents?: number | null;
  } | null;
  projectItem: {
    id: string;
  };
};

type HeaderRow = { kind: "header" };

type CommitmentsLineRow = {
  kind: "lineItem";
  data: CommitmentLineItemRow;
  initSelected?: boolean;
};

type CommitmentsRow = HeaderRow | CommitmentsLineRow;

type BillsLineRow = {
  kind: "lineItem";
  data: BillLineItemRow;
  initSelected?: boolean;
};

type BillFragment = Pick<Bill, "id" | "name" | "dueDate"> & {
  status: Partial<BillStatusDetail>;
  blueprintUrl?: { path: string };
  lineItems?: {
    id: string;
    paidInCents: number;
    commitmentLineItem?: {
      id: string;
      billedInCents?: number | null;
    } | null;
    projectItem: {
      id: string;
    };
  }[];
};

type BillsRow = HeaderRow | BillsLineRow;

export const CommitmentsTable = ({ clis }: { clis: CommitmentLineItemRow[] }) => {
  const tableApi = useGridTableApi<CommitmentsRow>();

  const cols = [
    column<CommitmentsRow>({
      header: "PO Number",
      lineItem: ({ owner }) => <Link to={{ pathname: owner.blueprintUrl?.path }}>{owner.shortName}</Link>,
    }),
    column<CommitmentsRow>({
      header: "Status",
      lineItem: ({ owner }) => <Tag type={commitmentStatusToTagTypeMapper[owner?.status!]} text={owner?.statusText} />,
    }),
    column<CommitmentsRow>({
      header: "Signed On",
      lineItem: ({ owner }) => <FormattedDate date={owner.executionDate || undefined} mdash />,
    }),
    column<CommitmentsRow>({
      header: "Committed",
      lineItem: ({ committedInCents }) => <Price valueInCents={committedInCents} />,
    }),
    column<CommitmentsRow>({
      header: "Remaining",
      lineItem: ({ billableLimitInCents }) => <Price valueInCents={billableLimitInCents} />,
    }),
  ];

  const cliRows = clis?.map((cli) => ({ kind: "lineItem" as const, id: cli.id, data: cli }));

  return <GridTable api={tableApi} columns={cols} rows={[simpleHeader, ...cliRows].compact()} stickyHeader={false} />;
};

export const BillsTable = ({ bills }: { bills: BillFragment[] }) => {
  const tableApi = useGridTableApi<BillsRow>();

  const cols = [
    column<BillsRow>({
      header: "Bill Number",
      lineItem: ({ blueprintUrl, name }) => <Link to={{ pathname: blueprintUrl?.path }}>{name}</Link>,
    }),
    column<BillsRow>({
      header: "Status",
      lineItem: ({ status }) => {
        const [type, statusText] = billStatusTagMapper({
          status: { code: status.code! },
          isProcessingReversal: false,
        });
        return <Tag type={type} text={statusText} />;
      },
    }),
    column<BillsRow>({
      header: "Due Date",
      lineItem: ({ dueDate }) => <FormattedDate date={dueDate || undefined} mdash />,
    }),
    column<BillsRow>({
      header: "Billed",
      lineItem: ({ commitmentLineItem }) => <Price valueInCents={commitmentLineItem?.billedInCents} />,
    }),
    column<BillsRow>({
      header: "Paid",
      lineItem: ({ paidInCents }) => <Price valueInCents={paidInCents} />,
    }),
  ];

  const bliRows = bills.flatMap((b) =>
    b.lineItems?.map((bli) => ({
      kind: "lineItem" as const,
      id: bli.id,
      data: { name: b.name, dueDate: b.dueDate, status: b.status, ...bli },
    })),
  );

  return <GridTable api={tableApi} columns={cols} rows={[simpleHeader, ...bliRows].compact()} stickyHeader={false} />;
};

export function ProjectItemDetails({ projectItemId, close }: ProjectItemDetailsModalProps) {
  const { data, loading } = useBillEditor_ProjectItemQuery({ variables: { id: projectItemId } });
  const cos = data?.projectItem.commitmentLineItems ?? [];
  const bills = data?.projectItem.bills;

  return (
    <div css={Css.df.p0.w("600px").fdc.color(Palette.Gray800).pt1.$} data-testid="ProjectItemDetailsContent">
      <div css={Css.absolute.top("6px").right("20px").$}>
        <IconButton data-testid="exit" color={Palette.Gray700} icon="x" inc={5} onClick={() => close()} />
      </div>
      {loading ? (
        <>
          <div css={Css.pt1.$} />
          <LoadingSkeleton rows={2} columns={5} />
        </>
      ) : data ? (
        cos?.isEmpty && bills?.isEmpty ? (
          <div css={Css.smBd.$}>No commitments or bills found.</div>
        ) : (
          <>
            <div css={Css.df.aic.gap2.pb1.$}>
              <span css={Css.xs.color(Palette.Gray600).$}>Commitments</span>
              <div css={Css.w100.bb.bcGray400.$} />
            </div>
            <div css={Css.w100.$}>
              <CommitmentsTable clis={cos} />
              <div css={Css.df.aic.gap2.pt1.pb1.$}>
                <span css={Css.xs.color(Palette.Gray600).$}>Bills</span>
                <div css={Css.w100.bb.bcGray400.$} />
              </div>
              <BillsTable bills={bills!} />
            </div>
          </>
        )
      ) : (
        <div css={Css.smBd.color(Palette.Red600).$}>Failed to load project item details, please try again later.</div>
      )}
    </div>
  );
}
