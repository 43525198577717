import { Css } from "@homebound/beam";
import { FormattedDate } from "src/components";
import { UpdatesAvailableProductOfferingFragment } from "src/generated/graphql-types";
import { AggregateMemberVersionChangeType } from "src/routes/libraries/design-catalog/design-package-configurator/components/DesignUpdatesAvailableButton";
import { renderListItem } from "src/routes/libraries/versioning/VersionHistoryUtils";
import { pluralize, sanitizeHtml } from "src/utils";

export function ReadyPlanSourceSection({ update }: { update: UpdatesAvailableProductOfferingFragment }) {
  const totalItemSlotCount =
    update.readyPlanAggregateVersionSource?.tliVersions.count((tliv) => !tliv.identity.isPlaceholderMaterial) ?? 0;
  const { newTlivCount, removedTlivCount, updatedTlivCount } = getTlivChangeCounts(update);
  return (
    <div>
      <div data-testid="planInfo" css={Css.sm.df.fdr.gap1.$}>
        <span css={Css.smMd.$}>{update.readyPlanAggregateVersionSource?.readyPlan.name} </span>
        <span css={Css.gray700.$}>v{update.readyPlanAggregateVersionSource?.version} - </span>
        <span css={Css.gray700.$}>
          <FormattedDate date={update.createdAt} dateFormatStyle="long" />
        </span>
      </div>
      {update.readyPlanAggregateVersionSource?.versionNotes && (
        <div
          data-testid="versionNotes"
          css={Css.gray700.sm.$}
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(update.readyPlanAggregateVersionSource.versionNotes || ""),
          }}
        />
      )}
      <ul css={Css.m0.gray700.$}>
        {update.forPlanPackageUpdate && (
          <>
            {renderListItem(newTlivCount, "Added", "takeoff item")}
            {renderListItem(removedTlivCount, "Removed", "takeoff item")}
            {renderListItem(updatedTlivCount, "Modified", "takeoff item")}
          </>
        )}
        {update.forDesignPackageUpdate && (
          <li>
            {totalItemSlotCount} item-slot {pluralize(totalItemSlotCount, "update")}
          </li>
        )}
      </ul>
    </div>
  );
}

export function DropSourceSection({ update }: { update: UpdatesAvailableProductOfferingFragment }) {
  return (
    <div data-testid="dropInfo" css={Css.sm.df.fdr.gap1.$}>
      <span css={Css.smMd.$}>{update.developmentDropSource?.name} - </span>
      <span css={Css.gray700.$}>
        <FormattedDate date={update.createdAt} dateFormatStyle="long" />
      </span>
    </div>
  );
}

function getTlivChangeCounts(update: UpdatesAvailableProductOfferingFragment) {
  const tlivs =
    update.readyPlanAggregateVersionSource?.tliVersions.filter((tliv) => !tliv.identity.isPlaceholderMaterial) || [];
  const newTlivCount = tlivs.count((tliv) => tliv.changeType === AggregateMemberVersionChangeType.ADDED);
  const removedTlivCount = tlivs.count((tliv) => tliv.changeType === AggregateMemberVersionChangeType.REMOVED);
  const updatedTlivCount = tlivs.count((tliv) => tliv.changeType === AggregateMemberVersionChangeType.UPDATED);

  return { newTlivCount, removedTlivCount, updatedTlivCount };
}
