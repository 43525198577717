// organize-imports-ignore
import "src/globalThis";
import { ApolloProvider } from "@apollo/client";
import { CssReset } from "@homebound/beam";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { App } from "./App";
import { amplifyConfig, createApolloClient, getStage } from "./context";
import { enableAnalytics } from "./utils/analytics";
import { configure } from "mobx";
import { hideIdColumn, showIdColumn } from "src/utils/idColumn";
import "src/utils/fetchMonkeyPatch";
import { createQuietEmotionCache } from "src/utils/emotionCache";
import { CacheProvider } from "@emotion/react";
import { createRoot } from "react-dom/client";
import { configureAuth } from "@homebound/auth-components";
import { PostHogProviderWrapper } from "./utils/PostHogProviderWrapper";

const stage = getStage();
const apolloClient = createApolloClient(stage);
configureAuth(amplifyConfig(stage));
void enableAnalytics(stage);
configure({ enforceActions: "never" });
// Amplify.Logger.LOG_LEVEL = "DEBUG";

const emotionCache = createQuietEmotionCache();

// Allow developers to easily turn on the conditional id columns for debugging
Object.assign(global, { showIdColumn, hideIdColumn });

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <ApolloProvider client={apolloClient}>
    <CacheProvider value={emotionCache}>
      <Router>
        <QueryParamProvider ReactRouterRoute={Route}>
          <CssReset />
          <PostHogProviderWrapper>
            <App />
          </PostHogProviderWrapper>
        </QueryParamProvider>
      </Router>
    </CacheProvider>
  </ApolloProvider>,
);
