import { Css, useSnackbar, useTestIds } from "@homebound/beam";
import { ObjectState, useFormState } from "@homebound/form-state";
import { createPlanDetailsUrl } from "src/RouteUrls";
import { StepLayout } from "src/components/stepper/StepLayout";
import { NextStepButton } from "src/components/stepper/useStepperWizard/NextStepButton";
import {
  IncrementalCollectionOp,
  InputMaybe,
  SaveProgramDataInput,
  usePlanDetailsStepMetadataQuery,
  usePlanDetailsStepQuery,
  useSavePlanDetailsMutation,
} from "src/generated/graphql-types";
import { EditOptionsHeader } from "src/routes/developments/plan-and-options/options/components/EditOptionsHeader";
import { BasementCard, PlanDetailsCard, PlanDetailsCardLine } from "../components/PlanDetailsCard";
import {
  AddOptionsMetadata,
  AddProgramDetailsForm,
  OptionsEnumNames,
  readyPlanProgramDataConfig,
} from "../components/utils";

export function PlanDetailsStep({ developmentId, id }: { developmentId: string; id: string }) {
  const { triggerNotice } = useSnackbar();
  const { data: metadata } = usePlanDetailsStepMetadataQuery();
  const query = usePlanDetailsStepQuery({ variables: { id } });
  const [save] = useSavePlanDetailsMutation();

  const formState = useFormState({
    config: readyPlanProgramDataConfig,
    init: {
      query,
      map: ({ readyPlan }) => ({
        id: readyPlan.id,
        description: readyPlan.description,
        programData: {
          ...readyPlan.programData,
          basementConfig: readyPlan.programData?.basementConfig?.code,
          garageConfiguration: readyPlan.programData?.garageConfiguration?.code,
          primaryBedroom: readyPlan.programData?.primaryBedroom?.code,
        },
        minProgramData: {
          ...readyPlan.minProgramData,
          basementConfig: readyPlan.programData?.basementConfig?.code,
          garageConfiguration: readyPlan.programData?.garageConfiguration?.code,
          primaryBedroom: readyPlan.programData?.primaryBedroom?.code,
        },
        maxProgramData: {
          ...readyPlan.maxProgramData,
          basementConfig: readyPlan.programData?.basementConfig?.code,
          garageConfiguration: readyPlan.programData?.garageConfiguration?.code,
          primaryBedroom: readyPlan.programData?.primaryBedroom?.code,
        },
      }),
    },
  });

  const tid = useTestIds({}, "planDetailsStep");
  const generalSection = generalLinesForm(formState);
  const bedroomsSection = bedroomLinesForm(formState, metadata);
  const bathroomsSection = bathroomLinesForm(formState);
  const garageSection = garageLinesForm(formState, metadata);

  const onSave = async () => {
    if (formState.dirty) {
      const { programData, ...rest } = formState.changedValue;
      const { readyPlanProgramData, ...pdChanges } = programData ?? {};
      await save({
        variables: {
          input: {
            ...rest,
            ...(pdChanges && {
              programDatas: [
                {
                  id: formState.programData.readyPlanProgramData.value?.id,
                  op: IncrementalCollectionOp.Include,
                  programData: pdChanges,
                },
              ],
            }),
          },
        },
      });
      triggerNotice({ message: `Your ReadyPlan has been updated!` });
    }
  };

  return (
    <StepLayout
      header={
        <EditOptionsHeader
          title="Plan Details"
          subtitle="Fill out details for the ReadyPlan below, then click Save."
          tooltip="Choose the plan details that you would like to be applicable for this Readyplan"
        />
      }
      body={
        <div css={Css.py3.$}>
          <div css={Css.mt2.df.fww.ais.gap2.$} {...tid.sections}>
            <PlanDetailsCard title="General" headerTooltips lines={generalSection} disabled={undefined} />
            <PlanDetailsCard title="Bedrooms" icon="bed" lines={bedroomsSection} disabled={undefined} />
            <PlanDetailsCard title="Bathrooms" icon="bath" lines={bathroomsSection} disabled={undefined} />
            <PlanDetailsCard title="Dining" icon="chair" lines={diningLinesForm(formState)} disabled={undefined} />
            <PlanDetailsCard
              title="Entertaining"
              icon="image"
              lines={entertainingLinesForm(formState)}
              disabled={undefined}
            />
            <PlanDetailsCard
              title="Work from Home"
              icon="todo"
              lines={workFromHomeLinesForm(formState)}
              disabled={undefined}
            />
            <PlanDetailsCard title="Garage" icon="car" lines={garageSection} disabled={undefined} />
            <BasementCard
              title="Basement"
              icon="basement"
              formState={formState}
              metadata={metadata}
              disabled={undefined}
            />
          </div>
          <NextStepButton
            label="Save & Continue"
            disabled={false}
            onClick={onSave}
            onCloseReturnUrl={createPlanDetailsUrl(developmentId, id)}
            exitButton={{
              variant: "secondary",
              label: "Save & Exit",
              onClick: onSave,
            }}
          />
        </div>
      }
    />
  );
}

function generalLinesForm(formState: ObjectState<AddProgramDetailsForm>): PlanDetailsCardLine[] {
  return [
    line(formState, "Stories", "stories"),
    { label: "Description", base: formState.description, type: "textArea" as const },
  ];
}

function bedroomLinesForm(
  formState: ObjectState<AddProgramDetailsForm>,
  metadata: AddOptionsMetadata,
): PlanDetailsCardLine[] {
  return [
    line(formState, "Bedrooms", "bedrooms"),
    line(formState, "Closets in Primary Suite", "closetsInPrimarySuite"),
    line(formState, "First Floor Bedrooms", "firstFloorBedrooms"),
    line(formState, "Primary Bedroom Location", "primaryBedroom", "programDataPrimaryBedroomConfig", metadata),
  ];
}

function bathroomLinesForm(formState: ObjectState<AddProgramDetailsForm>): PlanDetailsCardLine[] {
  return [line(formState, "Full Baths", "fullBaths"), line(formState, "Half Baths", "halfBaths")];
}

function diningLinesForm(formState: ObjectState<AddProgramDetailsForm>): PlanDetailsCardLine[] {
  return [line(formState, "Formal", "diningRoom"), line(formState, "Casual", "casualDining")];
}

function entertainingLinesForm(formState: ObjectState<AddProgramDetailsForm>): PlanDetailsCardLine[] {
  return [
    line(formState, "Media Rooms", "mediaRooms"),
    line(formState, "Lofts/Game Rooms/Flex Rooms", "loftGameFlexRooms"),
  ];
}

function workFromHomeLinesForm(formState: ObjectState<AddProgramDetailsForm>): PlanDetailsCardLine[] {
  return [line(formState, "Offices", "offices"), line(formState, "Workspaces", "workspaces")];
}

function garageLinesForm(
  formState: ObjectState<AddProgramDetailsForm>,
  metadata: AddOptionsMetadata,
): PlanDetailsCardLine[] {
  return [
    line(formState, "Garage Load", "garageConfiguration", "programDataGarageConfig", metadata),
    line(formState, "Attached", "garageAttached"),
    line(formState, "Detached", "garageDetached"),
    line(formState, "Carport", "garagePort"),
  ];
}

// Only number fields can work here given we're setting up base/min/max
type ProgramDataNumberField = PickByType<SaveProgramDataInput, InputMaybe<number>>;

type ProgramDataEnumField = PickByType<SaveProgramDataInput, InputMaybe<string>>;

function line(
  formState: ObjectState<AddProgramDetailsForm>,
  label: string,
  field: keyof ProgramDataNumberField | keyof ProgramDataEnumField,
  enumName?: OptionsEnumNames,
  metadata?: AddOptionsMetadata,
): PlanDetailsCardLine {
  return {
    label,
    base: formState.programData[field] as any,
    min: formState.minProgramData[field] as any,
    max: formState.maxProgramData[field] as any,
    enumName,
    metadata,
    minMaxComputed: true,
  };
}

type PickByType<T, Value> = {
  [P in keyof T as T[P] extends Value | undefined ? P : never]: T[P];
};
