import { Css } from "@homebound/beam";
import { ReactNode } from "react";
import { fail } from "src/utils";

type StepLayoutProps = React.PropsWithChildren<{ header: ReactNode; body?: ReactNode }>;

/** To be used as the body of the `StepperContext` when using the `newLayout` property */
export function StepLayout({ header, children, body = children }: StepLayoutProps) {
  if (!body) fail("StepLayout requires children or body prop");
  return (
    <>
      {header}
      <div css={Css.df.fdc.fg1.oa.px3.$}>{body}</div>
    </>
  );
}
