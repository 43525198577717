import { ApolloCache, MutationTuple } from "@apollo/client";
import { FetchResult } from "@apollo/client/link/core";
import { Button, Css, GridTableApi, useGridTableApi, useSnackbar } from "@homebound/beam";
import { UseSnackbarHook } from "@homebound/beam/dist/components/Snackbar/useSnackbar";
import { Dispatch, SetStateAction, createContext, useContext, useRef } from "react";
import { useHistory } from "react-router";
import { createPlanPackageTakeoffUrl } from "src/RouteUrls";
import {
  CopyTakeoffLineItemsInput,
  ItivOrderField,
  Order,
  PlanPackageTakeoffPage_TakeoffDetailFragment,
  PlanPackageTakeoffTable_GroupTotalsFragment,
  PlanPackageTakeoffTable_GroupTotalsFragmentDoc,
  PlanPackageTakeoffTable_ItemsFragment,
  SaveTakeoffLineItemInput,
  TakeoffLineItemFilter,
  TakeoffsStore_CopyTakeoffLineItemsDocument,
  TakeoffsStore_CopyTakeoffLineItemsMutation,
  TakeoffsStore_CopyTakeoffLineItemsMutationVariables,
  TakeoffsStore_SaveTakeoffLineItemsDocument,
  TakeoffsStore_SaveTakeoffLineItemsMutation,
  TakeoffsStore_SaveTakeoffLineItemsMutationVariables,
  useTakeoffsStore_CopyTakeoffLineItemsMutation,
  useTakeoffsStore_SaveTakeoffLineItemsMutation,
} from "src/generated/graphql-types";
import useZodQueryString from "src/hooks/useZodQueryString";
import { TakeoffLineItemRows } from "src/routes/libraries/plan-package/takeoffs/components/TakeoffsTable/PlanPackageTakeoffTable";
import { isItivOrderField } from "src/routes/libraries/plan-package/takeoffs/utils";
import { count, fail, mergeEntities, sortObjectKeys } from "src/utils";
import { toServerFilter } from "src/utils/takeoffLineItem";
import { z } from "zod";
import { StoreApi, createStore, useStore } from "zustand";

export type TakeoffsStoreState = {
  planPackage: PlanPackageTakeoffPage_TakeoffDetailFragment;
  filter: TakeoffLineItemFilter;
  setFilter: Dispatch<SetStateAction<TakeoffLineItemFilter>>;
  setItemTableSearch: (search: string) => void;
  itemTableGroupBy: ItivOrderField;
  setItemTableGroupBy: (groupBy: ItivOrderField) => void;
  itemTableSortBy: ItivOrderField;
  setItemTableSortBy: (sortBy: ItivOrderField) => void;
  itemTableApi: GridTableApi<TakeoffLineItemRows> | undefined;
  saveTli: (item: SaveTakeoffLineItemInput | SaveTakeoffLineItemInput[]) => SaveTliResult;
  copyFromPlan: (input: CopyTakeoffLineItemsInput) => Promise<FetchResult<TakeoffsStore_CopyTakeoffLineItemsMutation>>;
  getSelectedTliIds: () => string[];
  refetchFilters?: () => void;
};

export const TakeoffsManagerContext = createContext<StoreApi<TakeoffsStoreState> | null>(null);

export type TakeoffsManagerProviderProps = React.PropsWithChildren<{
  planPackage: PlanPackageTakeoffPage_TakeoffDetailFragment;
}>;

export function TakeoffsManagerProvider({ planPackage, children }: TakeoffsManagerProviderProps) {
  const storeRef = useRef<StoreApi<TakeoffsStoreState>>();
  const [{ groupBy: initialGroupBy, sortBy: initialSortBy, filter: initialFilter }, setQs] =
    useZodQueryString(takeoffsQuerySchema);
  const itemTableApi = useGridTableApi<TakeoffLineItemRows>();
  const { triggerNotice, closeNotice } = useSnackbar();
  const [saveTlis] = useTakeoffsStore_SaveTakeoffLineItemsMutation();
  const [copyFromPlan] = useTakeoffsStore_CopyTakeoffLineItemsMutation();
  const history = useHistory();

  if (!storeRef.current || storeRef.current.getState().planPackage !== planPackage) {
    itemTableApi.clearSelections();
    storeRef.current = createTakeoffsStore({
      planPackage,
      history,
      initialGroupBy,
      initialSortBy,
      initialFilter,
      setQs,
      itemTableApi,
      saveTlis,
      copyFromPlan,
      triggerNotice,
      closeNotice,
    });
  }

  return <TakeoffsManagerContext.Provider value={storeRef.current}>{children}</TakeoffsManagerContext.Provider>;
}

// Returns the store for the takeoffs manager context for instances where reactivity is not needed
export function useTakeoffsManagerContext() {
  const store = useContext(TakeoffsManagerContext);
  if (!store) throw new Error("Missing TakeoffsManagerProvider");
  return store;
}

export function useTakeoffsStore<T>(selector: (state: TakeoffsStoreState) => T) {
  const store = useContext(TakeoffsManagerContext);
  if (!store) throw new Error("Missing TakeoffsManagerProvider");
  return useStore(store, selector);
}

type CreateTakeoffsStoreProps = {
  planPackage: PlanPackageTakeoffPage_TakeoffDetailFragment;
  history: ReturnType<typeof useHistory>;
  initialGroupBy?: string;
  initialSortBy?: string;
  initialFilter?: string;
  setQs?: Function;
  itemTableApi: GridTableApi<TakeoffLineItemRows>;
  saveTlis: MutationTuple<
    TakeoffsStore_SaveTakeoffLineItemsMutation,
    TakeoffsStore_SaveTakeoffLineItemsMutationVariables
  >[0];
  copyFromPlan: MutationTuple<
    TakeoffsStore_CopyTakeoffLineItemsMutation,
    TakeoffsStore_CopyTakeoffLineItemsMutationVariables
  >[0];
  triggerNotice?: UseSnackbarHook["triggerNotice"];
  closeNotice?: UseSnackbarHook["closeNotice"];
};

// Create a store for the takeoffs manager context
// Exported to simplifying testing
export function createTakeoffsStore(props: CreateTakeoffsStoreProps) {
  const {
    planPackage,
    initialGroupBy,
    initialSortBy,
    initialFilter,
    setQs,
    itemTableApi,
    saveTlis,
    copyFromPlan,
    triggerNotice,
    closeNotice,
    history,
  } = props;
  const store = createStore<TakeoffsStoreState>((set, get) => ({
    // Initial Values
    filter: initialFilter ? JSON.parse(initialFilter) : {},
    itemTableGroupBy: initialGroupBy && isItivOrderField(initialGroupBy) ? initialGroupBy : ItivOrderField.CostCode,
    itemTableSortBy: initialSortBy && isItivOrderField(initialSortBy) ? initialSortBy : ItivOrderField.Item,
    itemTableApi,
    planPackage,

    getSelectedTliIds: () =>
      get()
        .itemTableApi?.getSelectedRows()
        .flatMap((row) => {
          switch (row.kind) {
            case "item":
              return row.data.id;
            case "groupBy":
              return row.data.tliIdsInGroup;
            default:
              return [];
          }
        })
        .unique()
        .compact() ?? [],
    // Actions
    setFilter: (maybeFunction: Function | TakeoffLineItemFilter) =>
      set((state) => {
        const filterValue = typeof maybeFunction === "function" ? maybeFunction(state.filter) : maybeFunction;
        setQs?.({ filter: JSON.stringify(filterValue) });
        return { filter: filterValue };
      }),
    setItemTableGroupBy: (groupBy: ItivOrderField) => {
      setQs?.({ groupBy, sortBy: get().itemTableSortBy });
      set({ itemTableGroupBy: groupBy });
    },
    setItemTableSortBy: (sortBy: ItivOrderField) => {
      setQs?.({ sortBy, groupBy: get().itemTableGroupBy });
      set({ itemTableSortBy: sortBy });
    },
    setItemTableSearch: (search: string) =>
      set((state) => {
        // Remove search and don't set a search value it is empty - avoids unnecessary API requests when going from `undefined` to ""
        const { search: oldSearchValue, ...otherFilters } = state.filter;
        return { filter: { ...otherFilters, ...(search ? { search } : {}) } };
      }),
    saveTli: saveTliImpl,
    copyFromPlan: copyFromPlanImpl,
  }));

  /** Private properties and methods */
  function getCacheQualifier() {
    return JSON.stringify({
      filter: sortObjectKeys(toServerFilter(planPackage.id, store.getState().filter)),
      order: [
        { direction: Order.Asc, field: store.getState().itemTableGroupBy },
        { direction: Order.Asc, field: store.getState().itemTableSortBy },
      ],
    });
  }

  async function copyFromPlanImpl(input: CopyTakeoffLineItemsInput) {
    const result = await copyFromPlan({
      mutation: TakeoffsStore_CopyTakeoffLineItemsDocument,
      variables: { input },
      update: (cache, { data }) => {
        addItemsToCache(cache, data?.copyTakeoffLineItems?.copiedItems ?? []);
      },
    });

    // Navigate to the new draft version
    const rpavId = result.data!.copyTakeoffLineItems.targetReadyPlan.version.id;
    history?.push(createPlanPackageTakeoffUrl(planPackage.id, rpavId));

    return result;
  }

  async function saveTliImpl(input: SaveTakeoffLineItemInput | SaveTakeoffLineItemInput[]): SaveTliResult {
    const requestInput = Array.isArray(input) ? input : [input];
    const result = await saveTlis({
      mutation: TakeoffsStore_SaveTakeoffLineItemsDocument,
      variables: {
        readyPlanId: planPackage.id,
        input: requestInput,
        filter: { readyPlan: [store.getState().planPackage.id], ...store.getState().filter },
        order: [
          { field: store.getState().itemTableGroupBy, direction: Order.Asc },
          { field: store.getState().itemTableSortBy, direction: Order.Asc },
        ],
      },
      update: (cache, { data }) => {
        const {
          deleted = [],
          takeoffLineItems = [],
          autoAddedLaborLines = [],
          groupTotals = [],
          removed = [],
        } = data?.saveTakeoffLineItems ?? {};

        if (deleted.nonEmpty) itemTableApi.deleteRows(deleted);

        // show notice if any tli was added that doesn't match filter
        const ppGroupTotal = groupTotals.find((gt) => gt.groupId === "queryTotal") ?? fail("No group totals found");
        // Keep removed TLIs visible in the UI during editing so we can highlight the changes
        const savedTlis = [...takeoffLineItems, ...removed].map((tli) => tli.id);
        if (!savedTlis.every((savedTliId) => ppGroupTotal.tliIdsInGroup.includes(savedTliId))) {
          triggerNotice?.({
            // putting the action in the message instead of using the action prop to put it more inline
            message: (
              <>
                <div css={Css.dif.$}>
                  Success! Your item was added, but not showing due to filters. To see your item
                </div>
                <Button
                  label="clear filters"
                  variant="tertiary"
                  onClick={() => {
                    store.getState().setFilter({});
                    closeNotice?.("tli-add-filter-notice");
                  }}
                />
              </>
            ),
            icon: "success",
            id: "tli-add-filter-notice",
          });
        }

        addItemsToCache(cache, [...takeoffLineItems, ...autoAddedLaborLines], groupTotals);
      },
    });

    // Navigate to the new draft version
    const rpavId = result.data!.saveTakeoffLineItems.version.id;
    history?.push(createPlanPackageTakeoffUrl(planPackage.id, rpavId));

    const autoAddedLaborLines = result.data?.saveTakeoffLineItems.autoAddedLaborLines ?? [];
    if (autoAddedLaborLines.nonEmpty) {
      triggerNotice?.({
        message: `${count(autoAddedLaborLines, "labor line")} automatically added due to new materials.`,
      });
    }

    return result;
  }

  function addItemsToCache(
    cache: ApolloCache<any>,
    newTlis: PlanPackageTakeoffTable_ItemsFragment[],
    groupTotalsResult?: PlanPackageTakeoffTable_GroupTotalsFragment[],
  ) {
    const qualifier = getCacheQualifier();
    cache.modify({
      fields: {
        [`takeoffLineItemsPage:${qualifier}`]: (
          existingResult: any, // Modifiers<TakeoffLineItemVersionsPageQuery["TakeoffLineItemVersionsPage"]>, //, TODO: Fix this type?
        ) => {
          const { items: existingTlis } = existingResult;

          const newOrUpdatedGroups = groupTotalsResult?.map((gt) => {
            const __ref = cache.identify(gt);
            cache.writeFragment({
              id: __ref,
              fragment: PlanPackageTakeoffTable_GroupTotalsFragmentDoc,
              data: gt,
            });
            return { __ref };
          });

          // Then the saved entities should be added to the tliPage cache
          return {
            ...existingResult,
            items: mergeEntities(
              existingTlis,
              newTlis.map((tli) => ({ __ref: cache.identify(tli) })),
            ),
            groupTotals: newOrUpdatedGroups,
          };
        },
      },
    });
    // Refetch filters to ensure they only include facets that are still relevant
    store.getState().refetchFilters?.();
  }

  return store;
}

const takeoffsQuerySchema = z.object({
  groupBy: z.coerce.string().default(ItivOrderField.CostCode),
  sortBy: z.coerce.string().default(ItivOrderField.Item),
  filter: z.coerce.string().default("{}"),
});

export type SaveTliResult = Promise<FetchResult<TakeoffsStore_SaveTakeoffLineItemsMutation>>;
