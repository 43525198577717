import { BoundNumberField, BoundSelectField, Css, Icon, Palette, Tooltip } from "@homebound/beam";
import { FieldState } from "@homebound/form-state";
import { ReactNode } from "react";
import { ReadyPlanType } from "src/generated/graphql-types";

type CreatePlanPackageCodeProps = {
  /** A useFormState() field such as `formState.code` */
  codeField: FieldState<number | null | undefined>;
  typeField: FieldState<ReadyPlanType | null | undefined>;
  disabled: ReactNode | undefined;
};

export function CreatePlanPackageCode({ codeField, typeField, disabled }: CreatePlanPackageCodeProps) {
  return (
    <div css={Css.df.w100.$}>
      <div data-testid="planCode" css={Css.df.gap1.aic.mbPx(4).w("35%").fs0.$}>
        <div css={Css.gray700.$}>Plan Code*</div>
        <Tooltip placement="right" title="Code used to reference the plan throughout Blueprint">
          <Icon icon="infoCircle" color={Palette.Gray900} inc={2} />
        </Tooltip>
      </div>
      <div css={Css.dg.gtc("120px 23px 120px").aic.jic.$}>
        <BoundSelectField
          field={typeField}
          options={Object.values(ReadyPlanType).map((rptype) => ({ value: rptype, label: rptype }))}
          getOptionLabel={(val) => val.label}
          getOptionValue={(val) => val.value}
          placeholder="Type..."
          compact
          labelStyle="hidden"
          hideErrorMessage
          disabled={disabled}
        />
        -
        <BoundNumberField
          field={codeField}
          compact
          placeholder="Plan #..."
          labelStyle="hidden"
          numIntegerDigits={3}
          useGrouping={false}
          hideErrorMessage
          disabled={disabled}
        />
      </div>
    </div>
  );
}
