import {
  BoundSelectField,
  BoundTextField,
  Button,
  Css,
  FormLines,
  GridColumn,
  GridTable,
  IconButton,
  Palette,
  SimpleHeaderAndData,
  column,
  emptyCell,
  simpleHeader,
  useComputed,
} from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useMemo } from "react";
import { SelectMaybeNewField } from "src/components/selectMaybeNew/SelectMaybeNewField";
import {
  MaterialAssemblyForm_AssemblyComponentFragment,
  MaterialAssemblyForm_ItemFragment,
  MaterialAssemblyForm_MaterialVariantFragment,
  MaterialAttributeDimensionType,
  MaterialCatalogMetadataDocument,
  MaterialType,
  SaveMaterialVariantAssemblyComponentInput,
  useMaterialAssemblyForm_AssemblyComponentsQuery,
  useMaterialAssemblyForm_ItemsQuery,
  useSaveMavMutation,
} from "src/generated/graphql-types";
import { disableBasedOnPotentialOperation } from "src/routes/components/PotentialOperationsUtils";
import { pluralize } from "src/utils";
import { MaterialVariantFormValue } from "../material-super-drawer/MaterialSuperDrawerContext";
import { useLazyMaterialVariants } from "./useLazyMaterialVariants";

type MaterialAssemblyFormProps = {
  variant: ObjectState<MaterialVariantFormValue>;
};

export function MaterialAssemblyForm({ variant }: MaterialAssemblyFormProps) {
  const componentsQuery = useMaterialAssemblyForm_AssemblyComponentsQuery({
    variables: { id: variant.id.value! },
    skip: !variant.id.value,
  });

  const variants = useLazyMaterialVariants({ type: [MaterialType.Placeholder, MaterialType.Construction] });
  const itemsQuery = useMaterialAssemblyForm_ItemsQuery();
  const readOnly = useComputed(() => variant.readOnly, [variant]);

  const components = useMemo(() => componentsQuery.data?.materialVariant.components ?? [], [componentsQuery.data]);

  const componentOptions = useMemo(
    () =>
      [...variants, ...(itemsQuery.data?.items ?? [])]?.sortBy((mvOrItem) =>
        isItemFragment(mvOrItem) ? mvOrItem.name : mvOrItem.displayName,
      ),
    [variants, itemsQuery.data],
  );

  const columns = useMemo(() => createColumns(), []);
  const rows = useMemo(() => createRows(components), [components]);

  if (readOnly) return <GridTable style={{ bordered: true, allWhite: true }} columns={columns} rows={rows} />;

  return (
    <>
      <Observer>
        {() => (
          <FormLines labelStyle="above" width="full">
            <div css={Css.py1.bcGray400.bb.baseMd.$}>
              Assembly{" "}
              {variant.components.rows.nonEmpty && (
                <span css={Css.base.gray600.$}>
                  {variant.components.rows.length} {pluralize(variant.components.rows.length, "item")}
                </span>
              )}
            </div>
            {variant.components.rows?.map((row) => (
              <MaterialAssemblyItemForm
                key={row.id.value ?? Math.random()}
                variant={variant}
                row={row}
                componentOptions={componentOptions}
              />
            ))}
            <Button
              label="Add item"
              variant="text"
              disabled={variant.canEdit.value && disableBasedOnPotentialOperation(variant.canEdit.value)}
              onClick={() => variant.components.add({ qualifier: "" })}
            />
          </FormLines>
        )}
      </Observer>
    </>
  );
}

type MaterialAssemblyItemFormProps = MaterialAssemblyFormProps & {
  row: ObjectState<SaveMaterialVariantAssemblyComponentInput>;
  componentOptions: (MaterialAssemblyForm_MaterialVariantFragment | MaterialAssemblyForm_ItemFragment)[];
};

function MaterialAssemblyItemForm({ variant, row, componentOptions }: MaterialAssemblyItemFormProps) {
  const [saveMavMutation] = useSaveMavMutation();

  const selectedMvOrItem = useComputed(
    () => componentOptions.find((mac) => mac.id === row.componentId.value),
    [componentOptions, row],
  );

  const canEditVariant = useComputed(
    () => variant.canEdit.value && disableBasedOnPotentialOperation(variant.canEdit.value),
    [variant],
  );

  const dimensions = useMemo(
    () =>
      (isItemFragment(selectedMvOrItem)
        ? selectedMvOrItem.materialAttributeDimensions
        : selectedMvOrItem?.listing.type.code === MaterialType.Placeholder
          ? selectedMvOrItem.listing.item.materialAttributeDimensions
          : []
      ).filter((mad) => mad.useInTakeoff),
    [selectedMvOrItem],
  );

  // Create a map of `dimensionId` to `mavId` to drive our per-dim Select
  const currentMavByDimension = useComputed(() => {
    // Now, map over the dimensions to create the dimensionId to mavId mapping
    return dimensions.mapToObject((d) => {
      const thisDimsValues = (d.values ?? []).keyBy((mav) => mav.id);
      return [d.id, row.componentValues.value?.map((mavId) => thisDimsValues[mavId]).compact().first?.id];
    });
  }, [dimensions]);

  function updateMavSelection(dimensionId: string, mavId: string | undefined) {
    // Don't worry about updating currentMavByDimension; it will recompute
    const oldMavIdForDim = currentMavByDimension[dimensionId];
    // Remove old, add new...
    row.componentValues.value = [
      ...(row.componentValues.value ?? []).filter((mavId) => mavId !== oldMavIdForDim),
      ...(mavId ? [mavId] : []),
    ];
  }

  // Saves a new MAV and updates form state to select the new id
  async function saveNewMAV(dimensionId: string, textValue: string) {
    const { data } = await saveMavMutation({
      variables: { input: { dimensionId, textValue } },
      refetchQueries: [MaterialCatalogMetadataDocument],
    });
    updateMavSelection(dimensionId, data?.saveMaterialAttributeValue.materialAttributeValue.id);
  }

  return (
    <div css={Css.df.aife.fww.gap2.pb2.bb.bcGray400.$}>
      <div css={Css.wPx(250).$}>
        <BoundSelectField
          label="Item"
          field={row.componentId}
          disabled={canEditVariant}
          onSelect={(_, mvOrItem) => {
            // If the selected assembly component is an item, set assembly component's item id and clear any previous mavs
            if (isItemFragment(mvOrItem)) {
              row.componentItemId.set(mvOrItem.id);
              row.componentValues.set([]);
            }

            // If the selected assembly component is a placeholder, set assembly component's item id and mavs
            if (isMaterialVariantFragment(mvOrItem) && mvOrItem?.listing.type.code === MaterialType.Placeholder) {
              row.componentItemId.set(mvOrItem.listing.item.id);
              row.componentValues.set(
                mvOrItem.materialAttributeValues.filter((mav) => mav.dimension.useInTakeoff).map((mav) => mav.id),
              );
            }

            row.componentId.set(mvOrItem?.id);
          }}
          options={componentOptions}
          getOptionLabel={(mvOrItem) => (isItemFragment(mvOrItem) ? `New ${mvOrItem.name}` : mvOrItem.displayName)}
          getOptionValue={(mvOrItem) => mvOrItem.id}
        />
      </div>
      <div css={Css.wPx(150).$}>
        <BoundTextField label="Qualifier (Optional)" field={row.qualifier} disabled={canEditVariant} />
      </div>
      {dimensions.nonEmpty &&
        dimensions
          .filter((mad) => mad.useInTakeoff)
          .map((mad) => (
            <div key={mad.id} css={Css.wPx(100).$}>
              <SelectMaybeNewField
                label={mad.name}
                value={currentMavByDimension[mad.id]}
                disabled={canEditVariant}
                onSelect={(newMavIdForDim) => updateMavSelection(mad.id, newMavIdForDim)}
                options={mad.values?.map((mav) => ({ id: mav.id, name: mav.textValue })) ?? []}
                getOptionValue={(o) => o.id}
                getOptionLabel={(o) => o.name}
                onAdd={(v) => saveNewMAV(mad.id, v)}
                helperText={
                  mad.type.code === MaterialAttributeDimensionType.Number ? (
                    <div css={Css.pl1.$}>eg. 10, 10-20, &gt;10</div>
                  ) : null
                }
              />
            </div>
          ))}
      <div css={Css.mbPx(5).$}>
        <IconButton
          icon="trash"
          color={Palette.Gray700}
          disabled={canEditVariant}
          onClick={() => variant.components.remove(row.value)}
        />
      </div>
    </div>
  );
}

type Row = SimpleHeaderAndData<MaterialAssemblyForm_AssemblyComponentFragment>;

function createColumns(): GridColumn<Row>[] {
  return [
    column<Row>({
      header: "Name",
      data: ({ name, component }) => name ?? component.displayName,
    }),
    column<Row>({
      header: "Qty",
      data: () => emptyCell,
    }),
    column<Row>({
      header: "Location",
      data: () => emptyCell,
    }),
  ];
}

function createRows(components: MaterialAssemblyForm_AssemblyComponentFragment[]) {
  return [
    simpleHeader,
    ...components.map((mv) => ({
      id: mv.id,
      kind: "data" as const,
      data: mv,
    })),
  ];
}

function isMaterialVariantFragment(
  fragment: MaterialAssemblyForm_MaterialVariantFragment | MaterialAssemblyForm_ItemFragment | undefined,
): fragment is MaterialAssemblyForm_MaterialVariantFragment {
  return !!fragment && fragment.__typename === "MaterialVariant";
}

function isItemFragment(
  fragment: MaterialAssemblyForm_MaterialVariantFragment | MaterialAssemblyForm_ItemFragment | undefined,
): fragment is MaterialAssemblyForm_ItemFragment {
  return !!fragment && fragment.__typename === "Item";
}
