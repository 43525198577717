import { ButtonMenu } from "@homebound/beam";
import { createDesignPackageVersionHistoryUrl } from "src/RouteUrls";
import { DesignPackageConfiguratorFragment } from "src/generated/graphql-types";

type DesignPackageButtonMenuProps = {
  designPackage: DesignPackageConfiguratorFragment;
};

export function DesignPackageButtonMenu({ designPackage }: DesignPackageButtonMenuProps) {
  return (
    <ButtonMenu
      trigger={{ icon: "verticalDots" }}
      items={[{ label: "Version History", onClick: createDesignPackageVersionHistoryUrl(designPackage.id) }]}
    />
  );
}
