import { Filter, multiFilter, singleFilter, treeFilter, usePersistedFilter } from "@homebound/beam";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import {
  CompareMode_DesignPackageFragment,
  CompareMode_DesignPackageOptionsFragment,
  CompareMode_TliFragment,
  NamedFragment,
  useCompareModeDesignPackageQuery,
  useCompareModeDesignPackageTlisQuery,
} from "src/generated/graphql-types";
import { useLocationsTree } from "src/hooks/useLocationsTree";
import { queryResult } from "src/utils";
import { ColumnView } from "./DesignPackageCompareMode";

type DesignPackageCompareModeContextType = {
  /** [["rpo:1", "rpo:2"], ["rpo:3", "rpo:4"]] */
  columns: string[][];
  addColumn: () => void;
  removeColumn: (columnIndex: number) => void;
  updateFilter: (keyOrIndex: keyof CompareModeFilter | number, value: string[] | ColumnView) => void;
  filter: CompareModeFilter;
  slots: string[];
  tlis: CompareMode_TliFragment[];
  designPackage: CompareMode_DesignPackageFragment;
  locations: NamedFragment[];
  costCodes: NamedFragment[];
  loading: boolean;
};

export const DesignPackageCompareModeContext = React.createContext<DesignPackageCompareModeContextType>({
  columns: [],
  addColumn() {},
  removeColumn() {},
  updateFilter() {},
  filter: undefined!,
  slots: [],
  tlis: [],
  designPackage: undefined!,
  locations: [],
  costCodes: [],
  loading: false,
});

export function DesignPackageCompareModeProvider({ children }: React.PropsWithChildren<unknown>) {
  const { designPackageId } = useParams<{ designPackageId: string }>();
  const { metadataQuery, locations, costCodes, versionId } = useDesignPackageData();
  const options = metadataQuery.data?.designPackage.options ?? [];

  const [filterDefs, setFilterDefs] = useState<CompareModeFilterDef>(
    initializeFilterDefs(designPackageId, options, locations, costCodes),
  );

  // Initialize the filters
  const { filter, setFilter } = usePersistedFilter<CompareModeFilter>({
    filterDefs,
    storageKey: `finish-schedule-filter-${designPackageId}`,
  });

  // Extract columns from filter
  const columns = Object.entries(filter)
    .filter(([key]) => key.startsWith("column"))
    .sortBy(([key]) => Number(/\d+/g.exec(key)?.[0]))
    .map(([, value]) => (value as string[]).filter((v) => v !== ""));

  const { tlis, slots, loading } = useDesignPackageTliData(versionId, columns, filter);

  const updateFilter = useCallback(
    (keyOrIndex: keyof CompareModeFilter | number, value: string[] | ColumnView) => {
      const key = typeof keyOrIndex === "number" ? `column${keyOrIndex}` : keyOrIndex;
      setFilter({ ...filter, [key]: value });
    },
    [filter, setFilter],
  );

  const addColumn = useCallback(() => {
    // add a new column filter definition
    setFilterDefs({
      ...filterDefs,
      [`column${columns.length}`]: multiFilter({
        label: `column${columns.length}`,
        options: metadataQuery.data?.designPackage.options ?? [],
        getOptionLabel: (o) => o.name,
        getOptionValue: (o) => o.id,
      }),
    });
    // add a new column filter
    updateFilter(columns.length, []);
  }, [setFilterDefs, filterDefs, columns.length, metadataQuery.data?.designPackage.options, updateFilter]);

  const removeColumn = useCallback(
    (columnIndex: number) => {
      // If there is more than one column, delete the column filter definition and filter
      if (columns.length > 1) {
        // resort and order the 'column${number}' filter definitions
        setFilterDefs(deleteAndReorderColumns(columnIndex, filterDefs) as CompareModeFilterDef);
        // resort and order the 'column${number}' filters
        setFilter(deleteAndReorderColumns(columnIndex, filter) as CompareModeFilter);
      } else {
        // If there's only one column, reset it instead of actually deleting it
        updateFilter(0, []);
      }
    },
    [columns.length, filter, filterDefs, setFilter, setFilterDefs, updateFilter],
  );

  useEffect(() => {
    /**
     * The filters determine which columns are displayed, and we want to start with an empty column by default.
     * We don't have a way to initialize the filters with default values when using `usePersistedFilter`.
     * This hook sets the default filter to the first "column0" and "view" filter values.
     */
    if (!filter["column0"]) updateFilter(0, []);
    // Ensure filter "view" default is set
    if (!filter.view) updateFilter("view", ColumnView.Single);
  }, [filter, updateFilter]);

  return queryResult(metadataQuery, ({ designPackage }) => (
    <DesignPackageCompareModeContext.Provider
      value={{
        columns,
        addColumn,
        removeColumn,
        updateFilter,
        filter,
        slots,
        tlis,
        designPackage,
        locations,
        costCodes,
        loading,
      }}
    >
      {children}
    </DesignPackageCompareModeContext.Provider>
  ));
}

function useDesignPackageData() {
  const { designPackageId } = useParams<{ designPackageId: string }>();
  const metadataQuery = useCompareModeDesignPackageQuery({
    variables: { id: designPackageId, versionId: "latest" },
  });
  const versionId = metadataQuery.data?.designPackage.version.id;
  const locations = useLocationsTree({ rpavId: versionId });
  // losing resolution on what was fmly a per-IT basis. A more 1:1 cutover would be RPAV.costCodes
  const costCodes = useMemo(
    () => metadataQuery.data?.designPackage?.costCodes ?? [],
    [metadataQuery.data?.designPackage?.costCodes],
  );
  return { metadataQuery, locations, costCodes, versionId };
}

function useDesignPackageTliData(versionId: string | undefined, columns: string[][], filter: any) {
  // Fetch actual TLIs based on currently-configured columns
  const { data, loading } = useCompareModeDesignPackageTlisQuery({
    variables: {
      filter: {
        designRpavId: versionId!,
        optionIds: columns.flat().unique(),
        locationInPath: filter.locationInPath?.nonEmpty ? filter.locationInPath : undefined,
        costCodeIds: filter.costCodes?.nonEmpty ? filter.costCodes : undefined,
      },
    },
    skip: !versionId || columns.flat().compact().isEmpty,
  });

  const tlis = useMemo(() => data?.designPackageFinishSchedule.entities ?? [], [data]);
  const slots = useMemo(() => {
    return tlis
      .map((tli) => tli.placeholder?.id)
      .compact()
      .unique();
  }, [tlis]);

  return { tlis, slots, loading };
}

function initializeFilterDefs(
  designPackageId: string,
  options: CompareMode_DesignPackageOptionsFragment[],
  locations: NamedFragment[],
  costCodes: NamedFragment[],
) {
  const localStorageColumns = sessionStorage.getItem(`finish-schedule-filter-${designPackageId}`);
  const initialDefs = {
    locationInPath: treeFilter({
      label: "Locations",
      filterBy: "root",
      getOptionLabel: (o) => o.name,
      getOptionValue: (o) => o.id,
      options: locations,
    }),
    costCodes: multiFilter({
      label: "Cost Codes",
      options: costCodes,
      getOptionLabel: (o) => o.name,
      getOptionValue: (o) => o.id,
    }),
    view: singleFilter({
      label: "view",
      options: [
        { id: ColumnView.Single, name: "Single" },
        { id: ColumnView.Grid, name: "Grid" },
      ],
      getOptionLabel: (o) => o.name,
      getOptionValue: (o) => o.id,
    }),
  };

  const defaultColumn = {
    column0: multiFilter({
      label: "column0",
      options,
      getOptionLabel: (o) => o.name,
      getOptionValue: (o) => o.id,
    }),
  };

  if (!localStorageColumns) return { ...initialDefs, ...defaultColumn };

  const columnsFromStorage = JSON.parse(localStorageColumns);
  const columns = Object.entries(columnsFromStorage).mapToObject((column, i) => [
    `column${i}`,
    multiFilter({
      label: `column${i}`,
      options,
      getOptionLabel: (o) => o.name,
      getOptionValue: (o) => o.id,
    }),
  ]);

  return { ...initialDefs, ...(Object.keys(columns).nonEmpty ? columns : defaultColumn) };
}

function deleteAndReorderColumns(
  columnIndexToRemove: number,
  data: CompareModeFilter | CompareModeFilterDef,
): Record<ColumnName, string[] | ((key: string) => Filter<string[]>)> {
  // Step 1: Extract "column" data
  const columnData = Object.entries(data)
    .filter(([key]) => key.startsWith("column"))
    .sortBy(([key]) => key);

  // Step 2 & 3: Remove specified column and resort
  const updatedColumnsObject = columnData
    .filter(([key]) => key !== `column${columnIndexToRemove}`)
    .mapToObject(([key, value], index) => [`column${index}`, value]);

  // Step 4: Extract original object without "column" data
  const originalWithoutColumns = Object.entries(data)
    .filter(([key]) => !key.startsWith("column"))
    .toObject();

  // Step 5: Merge back with original object, excluding old "column" data
  return { ...originalWithoutColumns, ...updatedColumnsObject };
}

type ColumnName = `column${number}`;

type CompareModeFilter = {
  locationInPath?: string[];
  costCodes?: string[];
  view?: ColumnView;
  /** Dynamically add columns to the filter using the `column${number}` key */
  [key: ColumnName]: string[];
};

type CompareModeFilterDef = {
  locationInPath: (key: string) => Filter<string[]>;
  costCodes: (key: string) => Filter<string[]>;
  view: (key: string) => Filter<ColumnView>;
  [key: ColumnName]: (key: string) => Filter<string[]>;
};
