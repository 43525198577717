import { EventContentArg, EventInput } from "@fullcalendar/core";
import { Css, Palette, Tooltip } from "@homebound/beam";
import { isAfter } from "date-fns";
import { Icon, IconProps } from "src/components/Icon";
import { MinimalCalendarScheduleTaskFragment, TaskStatus, TradePartnerTaskStatus } from "src/generated/graphql-types";
import { ScheduleCalendarView } from "./ScheduleCalendarViewModal";

export function renderTaskEventContent(arg: EventContentArg) {
  return (
    <div
      css={{
        ...Css.df.aic.p1.hPx(32).if(arg.event.extendedProps.selected).bshBasic.$,
        ":hover": Css.bgColor(arg.event.extendedProps.hoverColor).bshHover.$,
      }}
    >
      <div css={Css.df.jcc.wPx(40).px1.mr1.$}>
        {arg.event.extendedProps.view === ScheduleCalendarView.TradePartnerScheduled ? (
          <Tooltip title={arg.event.extendedProps.tradePartnerStatus.name}>
            <Icon
              icon={arg.event.extendedProps.icon}
              color={arg.event.extendedProps.color}
              inc={arg.event.extendedProps.sizeOverride}
            />
          </Tooltip>
        ) : (
          <Icon
            icon={arg.event.extendedProps.icon}
            color={arg.event.extendedProps.color}
            inc={arg.event.extendedProps.sizeOverride}
          />
        )}
      </div>
      <div css={Css.maxw("calc(100% - 72px)").oh.$}>{arg.event.title}</div>
    </div>
  );
}

export function createTaskCalendarEvents(
  tasks: MinimalCalendarScheduleTaskFragment[],
  {
    searchedTaskId,
    selectedTaskId,
    view = ScheduleCalendarView.Completion,
  }: { searchedTaskId?: string; selectedTaskId?: string; view?: ScheduleCalendarView },
) {
  return tasks.map<EventInput>((t) => {
    const isSearchedOrSelected = searchedTaskId === t.id || selectedTaskId === t.id;
    const taskAttrs = getTaskAttrs(t, view);
    const iconAttrs = getIconAttrs(t, view);
    return {
      title: t.name,
      start: t.interval.startDate,
      end: t.interval.endDate,
      id: t.id,
      allDay: true,
      editable: true,
      backgroundColor: taskAttrs.backgroundColor,
      borderColor: isSearchedOrSelected ? Palette.Gray900 : taskAttrs.backgroundColor,
      textColor: Palette.Gray900,
      extendedProps: {
        view,
        icon: iconAttrs?.icon,
        color: iconAttrs?.color,
        hoverColor: taskAttrs.hoverColor,
        sizeOverride: iconAttrs?.sizeOverride,
        selected: isSearchedOrSelected,
        tradePartnerStatus: t.tradePartnerStatus,
        taskStatus: t.status,
        scheduleStatus: t.schedule.status,
      },
    };
  });
}

function getTaskAttrs(task: MinimalCalendarScheduleTaskFragment, view: ScheduleCalendarView) {
  switch (view) {
    case ScheduleCalendarView.Completion:
      return taskStatusToTaskAttrs(task);
    case ScheduleCalendarView.TradePartnerScheduled:
      return tradePartnerStatusToTaskAttrs[task.tradePartnerStatus.code];
    default:
      return TASK_COLOR_CONFIG.default;
  }
}

function getIconAttrs(task: MinimalCalendarScheduleTaskFragment, view: ScheduleCalendarView) {
  switch (view) {
    case ScheduleCalendarView.Completion:
      return taskStatusToIconConfig(task);
    case ScheduleCalendarView.TradePartnerScheduled:
      return tradePartnerStatusToIconConfig[task.tradePartnerStatus.code];
    default:
      return undefined;
  }
}

function validateTask(task: MinimalCalendarScheduleTaskFragment, validation: "isDelayed" | "isUpcomingDelay") {
  const baselineEndDate = task.baselineInterval2?.endDate;
  if (baselineEndDate && isAfter(task.interval.endDate, baselineEndDate) && task.status !== TaskStatus.Complete) {
    const baselineHasPast = isAfter(new Date(), baselineEndDate);
    return validation === "isDelayed" ? baselineHasPast : !baselineHasPast;
  }
}

const TASK_COLOR_CONFIG: Record<TaskTypes, TaskAttrs> = {
  inProgress: {
    backgroundColor: Palette.Blue100,
    hoverColor: Palette.Blue300,
  },
  complete: {
    backgroundColor: Palette.Green100,
    hoverColor: Palette.Green300,
  },
  delayed: {
    backgroundColor: Palette.Red50,
    hoverColor: Palette.Red300,
  },
  upcomingDelay: {
    backgroundColor: Palette.Yellow100,
    hoverColor: Palette.Yellow400,
  },
  milestone: {
    backgroundColor: Palette.Purple100,
    hoverColor: Palette.Purple400,
  },
  flag: {
    backgroundColor: Palette.Red50,
    hoverColor: Palette.Red50,
  },
  needsConfirmation: {
    backgroundColor: Palette.Yellow100,
    hoverColor: Palette.Yellow200,
  },
  confirmed: {
    backgroundColor: Palette.Gray300,
    hoverColor: Palette.Gray400,
  },
  unavailable: {
    backgroundColor: Palette.Red50,
    hoverColor: Palette.Red100,
  },
  notSent: {
    backgroundColor: Palette.Red50,
    hoverColor: Palette.Red100,
  },
  completedJob: {
    backgroundColor: Palette.Green100,
    hoverColor: Palette.Green300,
  },
  default: {
    backgroundColor: Palette.Gray300,
    hoverColor: Palette.Gray500,
  },
};

export const TASK_ICON_CONFIG: Record<TaskIcons, IconAttrs> = {
  flag: {
    icon: "flag",
    color: Palette.Red600,
    sizeOverride: 2,
  },
  delayed: {
    icon: "status",
    color: Palette.Red600,
    sizeOverride: 1.5,
  },
  upcomingDelay: {
    icon: "status",
    color: Palette.Yellow600,
    sizeOverride: 1.5,
  },
  milestone: {
    icon: "diamond",
    color: Palette.Gray900,
  },
  criticalPath: {
    icon: "criticalPath",
    color: Palette.Gray900,
  },
  needsConfirmation: {
    icon: "errorCircle",
    color: Palette.Yellow600,
    sizeOverride: 2,
  },
  confirmed: {
    icon: "checkCircle",
    color: Palette.Gray900,
    sizeOverride: 2,
  },
  unavailable: {
    icon: "xCircle",
    color: Palette.Red600,
    sizeOverride: 2,
  },
  notSent: {
    icon: "xCircle",
    color: Palette.Red600,
    sizeOverride: 2,
  },
  completedJob: {
    icon: "checkCircle",
    color: Palette.Green600,
    sizeOverride: 2,
  },
};

type TaskTypes =
  | "inProgress"
  | "complete"
  | "delayed"
  | "upcomingDelay"
  | "milestone"
  | "flag"
  | "needsConfirmation"
  | "confirmed"
  | "unavailable"
  | "notSent"
  | "completedJob"
  | "default";

type TaskIcons =
  | "flag"
  | "delayed"
  | "upcomingDelay"
  | "milestone"
  | "criticalPath"
  | "needsConfirmation"
  | "confirmed"
  | "unavailable"
  | "notSent"
  | "completedJob";

type IconAttrs = {
  icon?: IconProps<any>["icon"];
  color: Palette;
  sizeOverride?: number;
};

type TaskAttrs = {
  backgroundColor: Palette;
  hoverColor: Palette;
};

function taskStatusToTaskAttrs(task: MinimalCalendarScheduleTaskFragment): TaskAttrs {
  if (validateTask(task, "isUpcomingDelay")) return TASK_COLOR_CONFIG.upcomingDelay;
  if (task.status === TaskStatus.Delayed || validateTask(task, "isDelayed")) return TASK_COLOR_CONFIG.delayed;
  if (task.status === TaskStatus.InProgress) return TASK_COLOR_CONFIG.inProgress;
  if (task.status === TaskStatus.Complete) return TASK_COLOR_CONFIG.complete;
  if (task.isGlobalMilestoneTask) return TASK_COLOR_CONFIG.milestone;
  if (task.scheduleFlags.length > 0) return TASK_COLOR_CONFIG.flag;
  return TASK_COLOR_CONFIG.default;
}

function taskStatusToIconConfig(task: MinimalCalendarScheduleTaskFragment): IconAttrs | undefined {
  if (task.scheduleFlags.length > 0) return TASK_ICON_CONFIG.flag;
  if (task.isGlobalMilestoneTask) return TASK_ICON_CONFIG.milestone;
  if (task.isCriticalPath) return TASK_ICON_CONFIG.criticalPath;
  if (task.status === TaskStatus.Delayed || validateTask(task, "isDelayed")) return TASK_ICON_CONFIG.delayed;
  if (validateTask(task, "isUpcomingDelay")) return TASK_ICON_CONFIG.upcomingDelay;
}

export const tradePartnerStatusToIconConfig: Record<TradePartnerTaskStatus, IconAttrs> = {
  [TradePartnerTaskStatus.NotSent]: TASK_ICON_CONFIG.notSent,
  [TradePartnerTaskStatus.NeedsConfirmation]: TASK_ICON_CONFIG.needsConfirmation,
  [TradePartnerTaskStatus.Confirmed]: TASK_ICON_CONFIG.confirmed,
  [TradePartnerTaskStatus.NeedsReconfirmation]: TASK_ICON_CONFIG.needsConfirmation,
  [TradePartnerTaskStatus.Unavailable]: TASK_ICON_CONFIG.unavailable,
  [TradePartnerTaskStatus.CompletedJob]: TASK_ICON_CONFIG.completedJob,
};

const tradePartnerStatusToTaskAttrs: Record<TradePartnerTaskStatus, TaskAttrs> = {
  [TradePartnerTaskStatus.NotSent]: TASK_COLOR_CONFIG.notSent,
  [TradePartnerTaskStatus.NeedsConfirmation]: TASK_COLOR_CONFIG.needsConfirmation,
  [TradePartnerTaskStatus.Confirmed]: TASK_COLOR_CONFIG.confirmed,
  [TradePartnerTaskStatus.NeedsReconfirmation]: TASK_COLOR_CONFIG.needsConfirmation,
  [TradePartnerTaskStatus.Unavailable]: TASK_COLOR_CONFIG.unavailable,
  [TradePartnerTaskStatus.CompletedJob]: TASK_COLOR_CONFIG.completedJob,
};
