import { BoundNumberField, Css, Icon, LoadingSkeleton, useTestIds } from "@homebound/beam";
import { useBillEditor_CostCodesQuery } from "src/generated/graphql-types";
import { CreateBillFormState } from "../BillEditor";

export type BillLineItemsTableProps = {
  formState: CreateBillFormState;
  onChange: (val: number | undefined) => void;
};

export function BilledInCentsField(props: BillLineItemsTableProps) {
  const { formState, onChange } = props;
  const tid = useTestIds({});
  const type = formState.type.value!;
  // We should only use `billedInCents` when creating a new bill
  const field = formState.lineItems.rows.first?.amountInCents || formState.billedInCents;

  const { data, loading } = useBillEditor_CostCodesQuery({
    variables: { filter: { billType: [type] } },
    fetchPolicy: "cache-first",
  });
  const costCode = data?.costCodes.first;

  return loading ? (
    <LoadingSkeleton rows={1} columns={1} />
  ) : costCode ? (
    <div>
      <div css={Css.df.$}>
        <div>
          <div css={Css.smBd.gray600.$}>Cost Code</div>
          <div {...tid.costCode} css={Css.smBd.mt1.$}>
            {costCode.displayName}
          </div>
        </div>
        <div css={Css.mla.pr1.$}>
          <BoundNumberField
            field={field}
            label="Bill Amount"
            onChange={(val) => {
              field.set(val);
              onChange(val);
            }}
          />
        </div>
      </div>
    </div>
  ) : (
    <div css={Css.red600.df.fw5.cg1.$}>
      <Icon icon="infoCircle" />
      <div css={Css.mya.$}>No cost code found for the selected bill type, please contact accounting.</div>
    </div>
  );
}
