import { treeFilter } from "@homebound/beam";
import { useMemo } from "react";
import { useLocationsTree } from "src/hooks/useLocationsTree";

type useLocationsTreeFilterProps = {
  rpavId: string | undefined;
};

export function useLocationsTreeFilter({ rpavId }: useLocationsTreeFilterProps) {
  const locationOptions = useLocationsTree({ rpavId });

  const filter = useMemo(
    () =>
      treeFilter({
        label: "Locations",
        filterBy: "root",
        getOptionLabel: (o) => o.name,
        getOptionValue: (o) => o.id,
        options: locationOptions,
      }),
    [locationOptions],
  );

  return filter;
}
