import {
  Css,
  FilterDefs,
  Filters,
  FullBleed,
  RouteTabWithContent,
  SelectField,
  TabContent,
  Tabs,
  useSessionStorage,
  useTestIds,
} from "@homebound/beam";
import { useMemo, useState } from "react";
import { useParams, useRouteMatch } from "react-router";
import { createDevelopmentLotSequenceSheetUrl, createDevelopmentLotSummaryReleaseUrl } from "src/RouteUrls";
import { SearchBox } from "src/components";
import {
  ScopeMode,
  useCurrentInternalUserQuery,
  useLotSequenceSheetTableMetadataQuery,
  useLotSummaryMetaDataQuery,
} from "src/generated/graphql-types";
import { ReleaseToTradesTab } from "src/routes/developments/lot-summary/lot-release/ReleaseToTradesTab";
import { LotSequenceSheet } from "src/routes/developments/lot-summary/sequence-sheet/LotSequenceSheet";
import { TableActions } from "src/routes/layout/TableActions";
import { useStorageFilter } from "src/routes/projects/budget/components/useStorageFilter";
import { DevelopmentParams, developmentPaths } from "src/routes/routesDef";
import { LotSummaryCharts, LotSummaryChartsLegendContainer } from "./LotSummaryCharts";
import { LotSummaryPageHeader } from "./sequence-sheet/components/LotSummaryPageHeader";
import {
  LotSequenceSheetTableFilter,
  LotSummaryGroupBy,
  getFilterDef,
  mapToFilter,
} from "./sequence-sheet/components/utils";

enum LotSummaryTabs {
  LotSequence = "Lot sequence",
  ReleaseToTrades = "Release to trades",
}

export function LotSummaryPage() {
  const tid = useTestIds({}, "developmentAllLotsPage");
  const { developmentId } = useParams<DevelopmentParams>();
  const { data: currentInternalUserData } = useCurrentInternalUserQuery({ fetchPolicy: "cache-first" });
  const internalUserId = currentInternalUserData?.currentInternalUser?.id!;
  const { data } = useLotSummaryMetaDataQuery({
    skip: !internalUserId,
    variables: { developmentId, internalUserId },
  });
  const isReleaseToTradesPath = useRouteMatch(developmentPaths.lotSummaryRelease);

  const { isSequenceSheetFollower, isTeamMember, cohorts } = data?.development ?? {};
  const showReleaseToTrades =
    isReleaseToTradesPath ||
    cohorts?.some((c) =>
      c.projects.some(
        (p) =>
          p.scopeMode.code === ScopeMode.ReleaseToTrades ||
          p.scopeMode.code === ScopeMode.Full ||
          p.scopeMode.code === ScopeMode.BudgetSafe,
      ),
    );

  const { data: metadata } = useLotSequenceSheetTableMetadataQuery({ variables: { developmentId: developmentId } });
  const [searchFilter, setSearchFilter] = useState<string | undefined>();
  const filterDefs: FilterDefs<LotSequenceSheetTableFilter> = useMemo(() => getFilterDef(metadata), [metadata]);
  const { filter, setFilter } = useStorageFilter<LotSequenceSheetTableFilter>({
    storageKey: `lotSequenceSheetTable_${developmentId}`,
    filterDefs,
  });
  const [lotSummaryGroupBy, setLotSummaryGroupBy] = useSessionStorage("lotSummaryGroupBy", "cohort");
  const [summaryProjectIds, setSummaryProjectIds] = useState<string[]>([]);

  const tabs: RouteTabWithContent[] = useMemo(() => {
    const tabs = [
      {
        name: "Lot sequence",
        value: LotSummaryTabs.LotSequence,
        href: createDevelopmentLotSequenceSheetUrl(developmentId),
        path: [developmentPaths.lotSummary, developmentPaths.lotSequenceSheet],
        render: () => (
          <LotSequenceSheet
            queryFilter={mapToFilter(filter, metadata)}
            searchFilter={searchFilter}
            groupBy={lotSummaryGroupBy as LotSummaryGroupBy}
            setSummaryProjectIds={setSummaryProjectIds}
          />
        ),
      },
    ];
    if (showReleaseToTrades) {
      tabs.push({
        name: "Release to trades",
        value: LotSummaryTabs.ReleaseToTrades,
        href: createDevelopmentLotSummaryReleaseUrl(developmentId),
        path: [developmentPaths.lotSummaryRelease],
        render: () => (
          <ReleaseToTradesTab
            queryFilter={mapToFilter(filter, metadata)}
            searchFilter={searchFilter}
            groupBy={lotSummaryGroupBy as LotSummaryGroupBy}
            setSummaryProjectIds={setSummaryProjectIds}
          />
        ),
      });
    }
    return tabs;
  }, [developmentId, showReleaseToTrades, filter, metadata, searchFilter, lotSummaryGroupBy]);

  const [selectedTab, setSelectedTab] = useState<string>(LotSummaryTabs.LotSequence);

  return (
    <div {...tid}>
      <LotSummaryPageHeader
        isTeamMember={isTeamMember}
        subscribedToUpdates={isSequenceSheetFollower ?? false}
        internalUserId={internalUserId}
        developmentId={developmentId}
        development={data?.development}
        isReleaseToTradesPath={!!isReleaseToTradesPath}
      />
      <FullBleed>
        <div css={Css.bgWhite.mb2.$}>
          <Tabs
            tabs={tabs}
            selected={selectedTab}
            onChange={(v) => setSelectedTab(v)}
            right={
              <div css={Css.pt(2).$}>
                <TableActions>
                  <div css={Css.h100.$}>
                    <SelectField
                      compact
                      sizeToContent
                      label="Group by"
                      labelStyle={"inline"}
                      options={[
                        { id: "cohort", name: "Cohort" },
                        { id: "none", name: "None" },
                      ]}
                      value={lotSummaryGroupBy}
                      onSelect={(gb) => gb && setLotSummaryGroupBy(gb)}
                    />
                  </div>
                  <Filters filterDefs={filterDefs} filter={filter} onChange={setFilter} numberOfInlineFilters={3} />
                  <SearchBox onSearch={setSearchFilter} />
                </TableActions>
              </div>
            }
          />
        </div>
      </FullBleed>
      <div>
        <LotSummaryCharts developmentId={developmentId} summaryProjectIds={summaryProjectIds} />
        <LotSummaryChartsLegendContainer />
      </div>
      <TabContent tabs={tabs} selected={selectedTab} />
    </div>
  );
}
