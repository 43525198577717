import {
  BoundNumberField,
  Button,
  collapseColumn,
  column,
  Css,
  emptyCell,
  GridCellContent,
  GridColumn,
  GridDataRow,
  GridTable,
  GridTableApi,
  Icon,
  IconButton,
  numericColumn,
  OnRowSelect,
  Palette,
  selectColumn,
  SelectField,
  simpleHeader,
  Tag,
  Tooltip,
  useComputed,
} from "@homebound/beam";
import { useMemo } from "react";
import { priceCell, priceTotal } from "src/components";
import {
  BillEditor_ProjectItemFragment,
  BillEditor_TaskFragment,
  BillType,
  CommitmentStatus,
  ProjectAutocomplete_ProjectFragment,
  useBillEditor_ProjectItemsQuery,
} from "src/generated/graphql-types";
import { useToggle } from "src/hooks";
import { createProjectScheduleUrl } from "src/RouteUrls";
import { commitmentStatusToTagTypeMapper, fail, formatCentsToPrice } from "src/utils";
import { DateOnly, formatWithYear } from "src/utils/dates";
import { ListFieldState, ObjectState } from "src/utils/formState";
import { openNewTab } from "src/utils/window";
import { BillFormLineItem, CreateBillFormState } from "../BillEditor";
import { calcLineItemValues, getBillableLimit } from "../utils";
import { calculateBudgetImpact } from "./BillReviewLineItemsTable";

export type BillLineItemsTableProps = {
  tableApi: GridTableApi<Row>;
  formState: CreateBillFormState;
  isEdit: boolean;
  onUpdateLineItemAmount: () => void;
  hasRecentChangeEventRequested?: boolean;
};

export function BillLineItemsTable(props: BillLineItemsTableProps) {
  const { tableApi, formState, isEdit, onUpdateLineItemAmount } = props;
  const field = formState.lineItems;
  const isCredit = !!formState.isTradePartnerCredit.value;
  const type = formState.type.value!;
  const project = formState.project.value;

  const { data } = useBillEditor_ProjectItemsQuery({
    variables: {
      filter: {
        projectStage: formState.value.project?.stages.map((ps) => ps.id),
        excludeFromPurchaseOrders: true,
      },
    },
    skip: type !== BillType.Overhead,
    fetchPolicy: "cache-first",
  });
  const [showAddLineItem, toggleAddLineItem] = useToggle();

  const totalAmount = useComputed(() => {
    const rows = type !== BillType.Standard ? tableApi.getVisibleRows() : tableApi.getSelectedRows();
    return rows
      .filter(({ kind }: GridDataRow<Row>) => kind === "lineItem")
      .sum((row: GridDataRow<Row>) => (row as LineItemRow).data?.amountInCents.value ?? 0);
  }, [tableApi]);

  const filteredProjectItems = useMemo(
    () =>
      data?.projectItems?.filter(
        (pi) =>
          // Exclude already selected project items and filter by project stage
          !field.rows.map((row) => row.projectItemId.value).includes(pi.id) &&
          (!field.rows.first || field.rows.first.value.projectStage?.id === pi.projectStage.id) &&
          pi.item.isContingency !== true,
      ) ?? [],
    [data, field.rows],
  );

  // Update amountInCents to the available unbilled amount when a row is selected
  const handleRowSelect: OnRowSelect<Row> = {
    lineItem: (data, isSelected, { row }) => {
      if (isSelected && !row.data.amountInCents.value) {
        row.data.amountInCents.set(calcLineItemValues(data, isCredit).unBilledInCents);
      }
      onUpdateLineItemAmount();
    },
  };

  return (
    <div>
      {type === BillType.Overhead && (
        <div>
          {field.rows.first && (
            <div css={Css.red700.df.sm.cgPx(3).mb2.$}>
              <div css={Css.mya.$}>
                <Icon icon="infoCircle" inc={2} />
              </div>
              Results are filtered by the selected project stage: {field.rows.first.value.projectStage?.stage.name}
            </div>
          )}
          <div css={Css.df.mb2.$}>
            <div css={Css.smBd.gray600.$}>Line Items</div>
            <div css={Css.mla.pr1.$}>
              <Button label="+ Add Line Item" disabled={showAddLineItem} onClick={toggleAddLineItem} variant="text" />
            </div>
          </div>
        </div>
      )}
      <GridTable
        api={tableApi}
        onRowSelect={handleRowSelect}
        columns={createColumns(
          field,
          isCredit,
          type,
          filteredProjectItems,
          toggleAddLineItem,
          onUpdateLineItemAmount,
          project,
          props.hasRecentChangeEventRequested,
        )}
        rows={createRows(field.rows, type, showAddLineItem, isEdit, totalAmount)}
        style={{ bordered: true, allWhite: true }}
      />
    </div>
  );
}

type HeaderRow = { kind: "header" };
export type LineItemRow = {
  kind: "lineItem";
  data: CreateBillFormState["lineItems"]["rows"][0];
  initSelected?: boolean;
};
type AddLineItemRow = { kind: "addLineItem"; id: string; data: undefined };
type ParentRow = { kind: "parent"; data: { name: string; status?: CommitmentStatus } };
type TotalRow = {
  kind: "total";
  data: { totalAmount: number };
};
export type Row = HeaderRow | LineItemRow | AddLineItemRow | ParentRow | TotalRow;

function createRows(
  lineItems: readonly ObjectState<BillFormLineItem>[],
  type: BillType,
  showAddLineItem: boolean,
  isEdit: boolean,
  totalAmount: number,
): GridDataRow<Row>[] {
  const groupedParentsPOs =
    type === BillType.Standard ? lineItems.groupBy((r) => String(r.owner.accountingNumber.value)) : {};

  const rows = groupedParentsPOs.toKeys().nonEmpty
    ? groupedParentsPOs.toKeys().map((acctNumber) => {
        const childItemRows = groupedParentsPOs[acctNumber];
        const isCo = childItemRows.first?.owner.id.value?.startsWith("cco");

        return {
          kind: "parent" as const,
          id: String(acctNumber),
          data: {
            name: isCo ? `CO# ${acctNumber}` : `PO# ${acctNumber}`,
            status: childItemRows.first?.owner.status?.value,
          },
          children: childItemRows.map((r) => ({
            kind: "lineItem" as const,
            id: r.commitmentLineItemId.value!,
            data: r,
            initSelected: isEdit,
          })),
        };
      })
    : lineItems.map((row) => ({
        kind: "lineItem" as const,
        id:
          row.commitmentLineItemId.value ??
          row.projectItemId.value ??
          fail("commitmentLineItem or projectItem ID is required"),
        data: row,
      }));

  const addRow = { kind: "addLineItem" as const, id: "add", data: undefined };

  return [
    simpleHeader,
    ...rows,
    ...(showAddLineItem ? [addRow] : []),
    { kind: "total" as const, id: "total", data: { totalAmount } },
  ];
}

function createColumns(
  field: ListFieldState<BillFormLineItem>,
  isCredit: boolean,
  type: BillType,
  projectItems: BillEditor_ProjectItemFragment[],
  toggleAddLineItem: (force?: unknown) => void,
  onUpdateLineItemAmount: () => void,
  project: ProjectAutocomplete_ProjectFragment,
  hasRecentChangeEventRequested?: boolean,
): GridColumn<Row>[] {
  const label = isCredit ? "Credit" : "Bill";

  const projectItemCol = column<Row>({
    header: () => "Project Item",
    parent: (data) => ({
      content: (
        <div css={Css.xsBd.df.cg1.$}>
          <div>{data.name}</div>
          {data.status && <Tag type={commitmentStatusToTagTypeMapper[data.status]} text={data.status} />}
        </div>
      ),
      colspan: 2,
    }),
    lineItem: ({ displayName }) => displayName.value,
    addLineItem: emptyCell,
    total: () => ({ alignment: "right", content: "Total:" }),
  });
  const taskCol = column<Row>({
    header: () => "Task",
    parent: emptyCell,
    lineItem: ({ task }) => (task.value ? allocationCell(task.value, project) : undefined),
    addLineItem: emptyCell,
    total: emptyCell,
  });
  const committedCol = numericColumn<Row>({
    header: () => "Committed",
    parent: emptyCell,
    lineItem: ({ costChangeInCents }) => priceCell({ id: "cost", valueInCents: costChangeInCents.value }),
    total: () => emptyCell,
    addLineItem: emptyCell,
  });
  const billableLimitCol = numericColumn<Row>({
    header: () => "Billable Limit",
    parent: emptyCell,
    lineItem: (data) =>
      priceCell({
        id: "cost",
        valueInCents: getBillableLimit(data.value),
      }),
    total: () => emptyCell,
    addLineItem: emptyCell,
  });
  const billAmountCol = numericColumn<Row>({
    header: () => `${label} Amount`,
    parent: emptyCell,
    lineItem: ({ amountInCents }, api) => {
      return (
        <BoundNumberField
          data-testid={`${api.row.id}_${label}Amount`}
          field={amountInCents}
          onChange={(val) => {
            amountInCents.set(val);
            onUpdateLineItemAmount();
          }}
          type="cents"
        />
      );
    },
    addLineItem: emptyCell,
    total: (row) => priceTotal({ valueInCents: row.totalAmount, id: "amountTotal" }),
  });
  const unbilledCol = numericColumn<Row>({
    header: () => "Unbilled",
    parent: emptyCell,
    lineItem: (data) =>
      priceCell({
        id: "unbilled",
        valueInCents: calcLineItemValues(data, isCredit).unBilledInCents,
      }),
    addLineItem: emptyCell,
    total: () => emptyCell,
  });

  const standardCols = hasRecentChangeEventRequested
    ? [
        collapseColumn<Row>({ lineItem: emptyCell }),
        selectColumn<Row>({ w: "32px", total: emptyCell }),
        projectItemCol,
        taskCol,
        committedCol,
        billAmountCol,
        unbilledCol,
      ]
    : [
        collapseColumn<Row>({ lineItem: emptyCell }),
        selectColumn<Row>({ w: "32px", total: emptyCell }),
        projectItemCol,
        taskCol,
        committedCol,
        billableLimitCol,
        billAmountCol,
        unbilledCol,
      ];

  return [
    ...(type === BillType.Standard
      ? standardCols
      : [
          column<Row>({
            header: () => "Project Item",
            parent: ({ name }) => ({ content: <div css={Css.xsBd.$}>{name}</div> }),
            lineItem: ({ displayName }) => displayName.value,
            addLineItem: () => (
              <SelectField
                value={undefined}
                label="add project item"
                labelStyle="hidden"
                getOptionLabel={(o) => `${o.displayName} - ${formatCentsToPrice(o.uncommittedBudgetAmountInCents)}`}
                getOptionValue={(o) => o.id}
                onSelect={(_, pi) => {
                  if (pi) {
                    field.add({
                      projectItemId: pi.id,
                      amountInCents: pi.uncommittedBudgetAmountInCents,
                      ...pi,
                      bills: pi.bills,
                    });
                    toggleAddLineItem();
                    onUpdateLineItemAmount();
                  }
                }}
                options={projectItems}
              />
            ),
            total: () => ({ alignment: "left", content: "Total:" }),
          }),
          numericColumn<Row>({
            header: () => "Available Budget",
            parent: emptyCell,
            lineItem: (row) => priceCell({ valueInCents: row.uncommittedBudgetAmountInCents.value }),
            addLineItem: emptyCell,
            total: emptyCell,
          }),
          billAmountCol,
          numericColumn<Row>({
            header: () => "Impact",
            parent: emptyCell,
            lineItem: (row) =>
              priceCell({
                valueInCents: calculateBudgetImpact(
                  row.amountInCents.value ?? 0,
                  row.uncommittedBudgetAmountInCents.value ?? 0,
                ),
              }),
            addLineItem: emptyCell,
            total: emptyCell,
          }),
          column<Row>({
            header: emptyCell,
            parent: emptyCell,
            lineItem: (row) => (
              <IconButton
                icon="trash"
                color={Palette.Gray600}
                onClick={() => {
                  field.remove(row.value);
                  toggleAddLineItem();
                  onUpdateLineItemAmount();
                }}
              />
            ),
            addLineItem: emptyCell,
            total: emptyCell,
            w: "50px",
          }),
        ]),
  ];
}

function allocationCell(task: BillEditor_TaskFragment, project: ProjectAutocomplete_ProjectFragment): GridCellContent {
  const taskUrl = project.stages && createProjectScheduleUrl(project.id, project.stages.last?.stage.code, task.id);

  return {
    content: () => (
      <div
        data-testid={`task-cell-${task.id}`}
        onClick={() => openNewTab(taskUrl)}
        css={Css.cursorPointer.onHover.tdu.blue600.$}
      >
        <Tooltip
          title={
            <div>
              <div>Status: {task.status.name}</div>
              <div>
                Start date:{" "}
                {task.startDate
                  ? formatWithYear(
                      typeof task.startDate === "string" ? new DateOnly(new Date(task.startDate)) : task.startDate,
                    )
                  : "-"}
              </div>
              {task.completedAt && (
                <div>
                  Completed at:{" "}
                  {formatWithYear(
                    typeof task.completedAt === "string" ? new DateOnly(new Date(task.completedAt)) : task.completedAt,
                  )}
                </div>
              )}
            </div>
          }
          placement="top"
        >
          <div css={Css.blue600.$}>{task.name}</div>
        </Tooltip>
      </div>
    ),
    value: task.id,
  };
}
