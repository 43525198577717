import { Css, Tooltip } from "@homebound/beam";
import { emptyCellDash } from "src/components";

type TruncatedNameListCellProps = {
  nameList: (string | React.ReactNode)[];
};

export function TruncatedNameListCell({ nameList }: TruncatedNameListCellProps) {
  const [opt1, ...others] = nameList;

  // If we only have the one, then we can just display it - no Tooltip needed
  if (others.isEmpty) return <span css={Css.truncate.$}>{opt1 ?? emptyCellDash}</span>;

  // lets us join react nodes with commas, instead of converting them to strings
  // so we can display styled things like a red (Archived) label
  const joinNodes = (nodeArray: React.ReactNode[]) => nodeArray.reduce((acc, curr) => [acc, ", ", curr]);

  // Otherwise, we have at least 2 names in the list.
  // Display two inline, a count of the remainder, and a Tooltip with the full list.
  return (
    <div css={Css.df.aic.mw0.addIn(":active", Css.add("pointerEvents", "none").$).$}>
      <Tooltip title={joinNodes(nameList)}>
        <span css={Css.truncate.$}>{joinNodes([opt1, others[0]])}</span>
        {others.length > 1 && <span>+{others.length - 1}</span>}
      </Tooltip>
    </div>
  );
}
