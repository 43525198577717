import {
  Banner,
  Button,
  Css,
  Icon,
  IconButton,
  LoadingSkeleton,
  Palette,
  ScrollShadows,
  Tooltip,
  useRightPane,
  useSnackbar,
} from "@homebound/beam";
import { useFormState } from "@homebound/form-state";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { createTaskDetailsPageUrl } from "src/RouteUrls";
import { CommentFeed, HistoryFeed, formatDate } from "src/components";
import {
  DynamicSchedulesSidePaneWithConstraints_PlanTaskFragment,
  SavePlanTaskInput,
  TradePartnerAvailabilityRequestStatus,
  useDraftPlanScheduleQuery,
  useDynamicScheduleSidePaneQuery,
  usePlanTaskDetailsDocumentsQuery,
} from "src/generated/graphql-types";
import { useProjectContext } from "src/routes/projects/context/ProjectContext";
import { useDraftScheduleStore } from "src/routes/projects/dynamic-schedules/draft-mode/scheduleDraftStore";
import { Bills } from "src/routes/projects/dynamic-schedules/task-details/Bills";
import { CostAllocations } from "src/routes/projects/dynamic-schedules/task-details/CostAllocations";
import { PurchaseOrders } from "src/routes/projects/dynamic-schedules/task-details/PurchaseOrders";
import {
  DelayFlagSection,
  DependenciesSection,
  MaterialsSection,
} from "src/routes/projects/dynamic-schedules/task-details/SchedulingCard";
import { TaskDetailCard, TaskDetailCardType } from "src/routes/projects/dynamic-schedules/task-details/TaskDetailCard";
import { VerificationChecklist } from "src/routes/projects/dynamic-schedules/task-details/VerificationChecklist";
import { TaskBillModalProvider } from "src/routes/projects/schedule-v2/contexts/TaskBillModalContext";
import { TaskDocuments } from "src/routes/projects/schedule-v2/detail-pane/TaskDocuments";
import { pluralize, queryResult } from "src/utils";

export function DynamicScheduleSidePane({ draftTaskId, onClose }: { draftTaskId: string; onClose: () => void }) {
  const { closeRightPane } = useRightPane();
  const { id: projectId } = useProjectContext();
  const history = useHistory();
  const draftTaskChanges = useDraftScheduleStore((state) => state.draftTaskChanges);
  const userAddedScheduleFlags = useDraftScheduleStore((state) => state.userAddedScheduleFlags);
  const isNewTask = draftTaskId.includes("WIP");
  const isDeletedTask = useMemo(
    () => draftTaskChanges.some((change) => change.id === draftTaskId && change?.delete),
    [draftTaskId, draftTaskChanges],
  );

  const taskHasChanged = useMemo(
    () =>
      draftTaskChanges.some((change) => change.id === draftTaskId) ||
      userAddedScheduleFlags.some((flag) => flag.taskId === draftTaskId),
    [draftTaskChanges, userAddedScheduleFlags, draftTaskId],
  );

  // calling the draftTask endpoint directly to be able to have the start/end/duration update in real time whe changes are made to the draft table
  const { data } = useDraftPlanScheduleQuery({
    variables: { projectId: projectId ?? "", input: { scheduleParentId: projectId ?? "", draftTaskChanges } },
  });

  const draftTask = useMemo(
    () => data?.draftPlanSchedule?.planTasks.find((task) => task.id === draftTaskId),
    [data, draftTaskId],
  );

  const query = useDynamicScheduleSidePaneQuery({
    variables: { taskId: draftTaskId },
    // Use no-cache to ensure we're preventing any draft changes from being overwritten by a cache hit
    fetchPolicy: "no-cache",
    skip: isNewTask || isDeletedTask,
  });

  // If a user is clicky clicky and opens the pane on a task marked for deletion immediately close it.
  useEffect(() => {
    isDeletedTask && closeRightPane();
  }, [closeRightPane, isDeletedTask]);

  return (
    <div css={Css.df.fdc.oa.h100.px3.pb5.bgWhite.z0.gap1.$}>
      <div css={Css.df.jcsb.aic.sticky.pt3.top0.bgWhite.z1.$}>
        <Tooltip
          title={
            taskHasChanged &&
            "This task has changes within the table that have yet to be published. Task detail information in this panel cannot be guaranteed until pending changes to the schedule have been published."
          }
          placement="top"
          disabled={!taskHasChanged}
        >
          <div css={Css.df.aic.gap1.xl2Bd.if(taskHasChanged).orange500.$}>
            {draftTask?.name}
            {taskHasChanged && (
              <div>
                <Icon icon="error" color={Palette.Orange500} />
              </div>
            )}
          </div>
        </Tooltip>
        <div css={Css.df.gap1.$}>
          <IconButton
            tooltip={isNewTask ? "Task details available after publishing" : "View full page details"}
            icon="expand"
            color={isNewTask ? Palette.Gray400 : Palette.Gray900}
            disabled={isNewTask}
            // Note: IconButton should support just a string for onclick navigation since it's built on Button, but it
            // doesn't work
            onClick={() => history.push(createTaskDetailsPageUrl(projectId!, draftTaskId))}
          />
          <IconButton
            icon="x"
            color={Palette.Gray900}
            onClick={() => {
              onClose();
              closeRightPane();
            }}
          />
        </div>
      </div>
      {draftTask?.tradePartner?.name && <div css={Css.base.gray700.$}>{draftTask.tradePartner?.name}</div>}
      <div css={Css.base.gray700.$}>
        {draftTask ? (
          <>
            {formatDate(draftTask.startDate)} - {formatDate(draftTask.endDate)} (
            {draftTask.knownDurationInDays ?? draftTask.durationInDays}
            {pluralize(draftTask.knownDurationInDays ?? draftTask.durationInDays, " day")})
          </>
        ) : (
          <LoadingSkeleton rows={1} columns={1} />
        )}
      </div>
      {/* Note: using a ternary because query result doesn't have any kind of `ifQuerySkipped` handler */}
      {isNewTask ? (
        <div css={Css.br8.bsDashed.bcGray200.gray700.bw("3px").py2.df.jcc.my2.$}>
          Task details available after publishing
        </div>
      ) : (
        queryResult(query, {
          data: (data) => <DynamicScheduleSidePaneView planTask={data.planTask} />,
          loading: () => (
            <div css={Css.df.fdc.gap7.pt2.$}>
              <LoadingSkeleton columns={1} rows={10} />
            </div>
          ),
        })
      )}
      {}
    </div>
  );
}

function DynamicScheduleSidePaneView({
  planTask,
}: {
  planTask: DynamicSchedulesSidePaneWithConstraints_PlanTaskFragment;
}) {
  return (
    <TaskBillModalProvider>
      <div css={Css.df.fdc.gap7.pt2.$}>
        <TradePartnerAvailabilityRequestRescheduleSection planTask={planTask} />
        <SidePaneCommentSection planTask={planTask} />
        <PurchaseOrders projectItems={planTask.projectItems} inSidePane />
        <Bills task={planTask} inSidePane />
        <SidePaneDocumentsSection planTask={planTask} />
        <MaterialsSection task={planTask} inSidePane />
        <DelayFlagSection task={planTask} inSidePane />
        <CostAllocations projectItems={planTask.projectItems} inSidePane />
        <VerificationChecklist planTask={planTask} />
        <DependenciesSection task={planTask} inSidePane />
        <SidePaneHistoryCard planTask={planTask} />
      </div>
    </TaskBillModalProvider>
  );
}

// We don't really need form state here, but it's easier to just bite that bullet and pray we can refactor it later to have a
// version not weighed down by old code
function SidePaneDocumentsSection({
  planTask,
}: {
  planTask: DynamicSchedulesSidePaneWithConstraints_PlanTaskFragment;
}) {
  const { data } = usePlanTaskDetailsDocumentsQuery({ variables: { parentId: planTask.schedule.parent.id } });
  const formState = useFormState<SavePlanTaskInput, DynamicSchedulesSidePaneWithConstraints_PlanTaskFragment>({
    config: {
      id: { type: "value" },
      documents: { type: "value" },
    },
    init: {
      input: planTask,
      map: (task) => ({
        id: task.id,
        documents: task.documents.map((doc) => doc.id),
      }),
    },
  });
  return (
    <TaskDocuments
      parentId={planTask.schedule.parent.id}
      documentTypes={data?.documentTypes ?? []}
      documents={data?.documents ?? []}
      formState={formState}
      task={planTask}
      onPlanTask
      taskStatus={planTask.status.code}
    />
  );
}

function SidePaneHistoryCard({ planTask }: { planTask: DynamicSchedulesSidePaneWithConstraints_PlanTaskFragment }) {
  return (
    <TaskDetailCard noPadding cardType={TaskDetailCardType.History} maxHeight={450}>
      <h2 css={Css.lgBd.$}>History</h2>
      <ScrollShadows xss={Css.h100.oys.$}>
        <HistoryFeed historyItems={planTask?.history ?? []} renderAsCard={false} />
      </ScrollShadows>
    </TaskDetailCard>
  );
}

function SidePaneCommentSection({ planTask }: { planTask: DynamicSchedulesSidePaneWithConstraints_PlanTaskFragment }) {
  return (
    <TaskDetailCard cardType={TaskDetailCardType.Comments} noPadding maxHeight={650}>
      <CommentFeed
        inlineCommentTitle={<h2 css={Css.lgBd.$}>Comments</h2>}
        showCommentTitle={false}
        showFollowers={false}
        commentable={planTask}
        maxHeight={490}
      />
    </TaskDetailCard>
  );
}

function TradePartnerAvailabilityRequestRescheduleSection({
  planTask,
}: {
  planTask: DynamicSchedulesSidePaneWithConstraints_PlanTaskFragment;
}) {
  const { triggerNotice } = useSnackbar();
  const [showRescheduledBanner, setShowRescheduledBanner] = useState(false);
  const draftTaskChanges = useDraftScheduleStore((state) => state.draftTaskChanges);
  const setDraftTaskChanges = useDraftScheduleStore((state) => state.addDraftTaskChanges);
  // find the latest request on the task
  const latestRequest = planTask.tradePartnerAvailabilityRequests?.last;
  const tradePartnerName = planTask.tradePartner?.name;
  const rescheduleDate = latestRequest?.rescheduleDates?.last;

  useEffect(() => {
    if (
      latestRequest?.status.code === TradePartnerAvailabilityRequestStatus.RescheduleNeeded &&
      latestRequest?.rescheduleDates?.nonEmpty
    )
      setShowRescheduledBanner(true);
  }, [latestRequest]);

  const onRescheduleAction = useCallback(() => {
    setDraftTaskChanges([
      {
        id: planTask.id,
        earliestStartDate: rescheduleDate,
        isManuallyScheduled: true,
      },
    ]);
    triggerNotice({
      message: `You accepted the trade's new date of ${formatDate(rescheduleDate?.date, "monthShort")}.`,
    });
  }, [setDraftTaskChanges, planTask, triggerNotice]);
  // if we don't have a request, then don't show this section
  if (planTask.tradePartnerAvailabilityRequests?.isEmpty) return null;

  return (
    <>
      {showRescheduledBanner && (
        <Banner
          type="info"
          showIcon={false}
          message={
            <div css={Css.df.fdc.gap2.$}>
              <div>
                {`${tradePartnerName} requested to reschedule this task with a new date of ${formatDate(rescheduleDate?.date, "monthShort")}. Would you like to accept this date and notify ${tradePartnerName}?`}
              </div>
              {draftTaskChanges.isEmpty ? (
                <Button label="Confirm New Date" onClick={onRescheduleAction} variant="text" />
              ) : (
                <>
                  <div css={Css.smMd.$}>Confirmed!</div>
                  <span css={Css.df.gap1.aic.$}>
                    <Icon icon="errorCircle" color={Palette.Red600} />
                    <div css={Css.red700.smMd.$}>To save this change, click Publish above.</div>
                  </span>
                </>
              )}
            </div>
          }
          onClose={() => setShowRescheduledBanner(false)}
        />
      )}
    </>
  );
}
