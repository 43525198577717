import { Css } from "@homebound/beam";
import { useParams } from "react-router";
import { createDesignPackageUrl, createDesignPackagesUrl } from "src/RouteUrls";
import { VersionDetailsCard_VersionFragment, useDesignPackageVersionHistoryQuery } from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { fail, queryResult } from "src/utils";
import { VersionDetailsCard } from "../../versioning/VersionDetailsCard";
import { ItemSlotUpdates } from "./components/ItemSlotUpdates";
import { ProductUpdates } from "./components/ProductUpdates";

type DesignPackageVersionHistoryUrlParams = { designPackageId: string };

export function DesignPackageVersionHistoryPage() {
  const { designPackageId } = useParams<DesignPackageVersionHistoryUrlParams>();
  const query = useDesignPackageVersionHistoryQuery({ variables: { filter: { readyPlanId: [designPackageId] } } });

  return queryResult(query, ({ readyPlanAggregateVersions }) => (
    <DesignPackageVersionHistoryView versions={readyPlanAggregateVersions} />
  ));
}

type DesignPackageVersionHistoryViewProps = {
  versions: VersionDetailsCard_VersionFragment[];
};

function DesignPackageVersionHistoryView(props: DesignPackageVersionHistoryViewProps) {
  const { versions } = props;
  const { designPackageId } = useParams<DesignPackageVersionHistoryUrlParams>();
  // We reverse the array so that the most recent version is shown first.
  const rpavs = [...versions].reverse();
  const latestVersion = rpavs.first ?? fail("Expected at least one version");

  return (
    <>
      <PageHeader
        title="Version History"
        breadcrumb={[
          { href: createDesignPackagesUrl(), label: "Design Packages" },
          { href: createDesignPackageUrl(designPackageId, latestVersion.id), label: latestVersion.readyPlan.name },
        ]}
      />
      <div css={Css.df.fdc.gap2.$}>
        {rpavs.map((rpav) => (
          <VersionDetailsCard
            key={rpav.id}
            version={rpav}
            changeDetails={<DesignPackageVersionChanges version={rpav} />}
            versionUrl={createDesignPackageUrl(rpav.readyPlan.id, rpav.id)}
          />
        ))}
      </div>
    </>
  );
}

type DesignPackageVersionChangesProps = {
  version: VersionDetailsCard_VersionFragment;
};

function DesignPackageVersionChanges({ version }: DesignPackageVersionChangesProps) {
  return (
    <>
      <ItemSlotUpdates tliVersions={version.tliVersions} />
      <ProductUpdates tliVersions={version.tliVersions} />
    </>
  );
}
