import { Button, Css, Icon, ModalBody, ModalFooter, ModalHeader, Tag, Tooltip, useModal } from "@homebound/beam";
import {
  UpdatesAvailableProductOfferingFragment,
  useAcceptProductOfferingUpdatesMutation,
} from "src/generated/graphql-types";
import { pluralize } from "src/utils";
import { DropSourceSection, ReadyPlanSourceSection } from "./UpdatesSourceSection";

type UpdatesAvailableButtonProps = {
  productOfferingId: string;
  updates: UpdatesAvailableProductOfferingFragment[];
  copyInProgress?: boolean;
  showText?: boolean;
};

export function UpdatesAvailableButton({
  copyInProgress,
  updates,
  productOfferingId,
  showText = true,
}: UpdatesAvailableButtonProps) {
  const { openModal } = useModal();

  if (copyInProgress || updates.isEmpty) {
    return null;
  }

  return (
    <button
      data-testid="updatesAvailableButton"
      onClick={() =>
        openModal({
          content: <UpdatesAvailableModal updates={updates} productOfferingId={productOfferingId} />,
        })
      }
    >
      <Tooltip
        title={updates
          .map((u) =>
            u.readyPlanAggregateVersionSource
              ? `${u.readyPlanAggregateVersionSource?.readyPlan.name} - v${u.readyPlanAggregateVersionSource?.version}`
              : `${u.developmentDropSource?.name}`,
          )
          .join(", ")}
        placement="bottom"
      >
        <Tag
          type="caution"
          text={
            showText ? <div css={Css.ttn.smSb.$}>Updates available</div> : <Icon icon="arrowFromBottom" inc={1.5} />
          }
          icon={showText ? "arrowFromBottom" : undefined}
          preventTooltip
        />
      </Tooltip>
    </button>
  );
}

type UpdatesAvailableModalProps = UpdatesAvailableButtonProps;

export function UpdatesAvailableModal({ updates, productOfferingId }: UpdatesAvailableModalProps) {
  const [acceptProductOfferingUpdates] = useAcceptProductOfferingUpdatesMutation();
  const { closeModal } = useModal();

  async function onAcceptUpdate() {
    await acceptProductOfferingUpdates({
      variables: { input: { productOfferingId, productOfferingUpdateIds: updates.map((u) => u.id) } },
      refetchQueries: ["ProductOfferingPage"],
    });
    closeModal();
  }
  return (
    <>
      <ModalHeader>Updates Available</ModalHeader>
      <ModalBody>
        <div css={Css.mb3.$}>
          Would you like to pull {pluralize(updates.length, "this update", "these updates")} into a new draft for you to
          review and edit?
        </div>
        <div css={Css.df.fdc.gap2.$}>
          {updates.map((u) =>
            u.readyPlanAggregateVersionSource ? (
              <ReadyPlanSourceSection key={u.id} update={u} />
            ) : (
              <DropSourceSection key={u.id} update={u} />
            ),
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button label="Cancel" onClick={closeModal} variant="secondary" />
        <Button label={`Accept ${pluralize(updates.length, "Update")}`} onClick={onAcceptUpdate} />
      </ModalFooter>
    </>
  );
}
