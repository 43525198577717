import { column, Css, emptyCell, GridColumn, GridTable, simpleHeader } from "@homebound/beam";
import { emptyCellDash, Price, priceCell } from "src/components";
import {
  CommitmentOrChangeOrderPdf_CommitmentFragment,
  CommitmentOrChangeOrderPdf_LineItemFragment,
  CommitmentOrChangeOrderPdf_TradeLineItemFragment,
} from "src/generated/graphql-types";
import { formatList, sanitizeHtml } from "src/utils";
import { Section } from "../../components/Section";

type CommitmentTableProps = {
  commitment: CommitmentOrChangeOrderPdf_CommitmentFragment;
  enableProductConfigPlan: boolean;
};

export function CommitmentTable({ commitment, enableProductConfigPlan }: CommitmentTableProps) {
  const effectiveLineItems = getEffectiveLineItems(commitment.lineItems);
  const totalCostInCents = effectiveLineItems.sum((cli) => cli.costChangeInCents ?? 0);
  // Keep structute of row/columns if there are no prorated lines
  const hasProratedLines =
    enableProductConfigPlan && effectiveLineItems.some((cli) => cli.primaryBidContractLineItem?.prorations.nonEmpty);
  const rows = hasProratedLines
    ? createProductConfigRows(effectiveLineItems)
    : createPdfLineItemRows(effectiveLineItems, commitment.project.readyPlanConfig?.specOption?.readyPlanOption.id);

  return (
    <Section title="Line Items">
      <div css={Css.mb2.$}>
        <GridTable
          as="table"
          columns={createColumns(hasProratedLines, enableProductConfigPlan)}
          rows={[simpleHeader, ...rows]}
        />
      </div>
      <div css={Css.df.fdr.p1.pr3.$}>
        <div css={Css.f1.df.fwb.jcfe.$}>
          Total:&nbsp;
          <Price id="totalCost" valueInCents={totalCostInCents} />
        </div>
      </div>
    </Section>
  );
}

export type HeaderRow = { kind: "header" };
export type OptionGroupRow = { kind: "group"; data: string };
export type LineItemRow = { kind: "data"; data: CommitmentOrChangeOrderPdf_LineItemFragment };
export type SpecificationsRow = { kind: "specifications"; data: string };
type TradeLineItemRow = { kind: "tradeLineItem"; data: CommitmentOrChangeOrderPdf_TradeLineItemFragment[] | undefined };
type CommitmentRow = LineItemRow | HeaderRow | OptionGroupRow | SpecificationsRow | TradeLineItemRow;

function createColumns(hasProratedLines: boolean, enableProductConfigPlan: boolean): GridColumn<CommitmentRow>[] {
  return [
    ...(enableProductConfigPlan
      ? [
          column<CommitmentRow>({
            header: "Item",
            group: (groupName) =>
              !hasProratedLines ? { content: groupName, colspan: 9, css: Css.smMd.px2.$ } : emptyCell,
            tradeLineItem: (data) => {
              return hasProratedLines
                ? {
                    content:
                      // Show either the options or item name from the trade line items
                      formatList(data?.flatMap((tli) => tli.options.map((o) => o.name)) ?? []) ||
                      formatList(data?.map((tli) => tli.bidItem?.items.first?.name).compact() ?? []) ||
                      "Base",
                    colspan: 5,
                    css: Css.smMd.px2.$,
                  }
                : emptyCell;
            },

            data: (cli) => cli.projectItem.item.name,
            specifications: emptyCell,
          }),
          column<CommitmentRow>({
            header: "Name",
            group: emptyCell,
            tradeLineItem: emptyCell,
            data: (cli) => cli.projectItem.name,
            specifications: (specification) => ({
              content: (
                <p data-testid="specification">
                  Note: <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(specification || "") }} />
                </p>
              ),
              colspan: 8,
              css: Css.sm.$,
            }),
          }),
          column<CommitmentRow>({
            header: "Location",
            group: emptyCell,
            tradeLineItem: emptyCell,
            data: (cli) => cli.projectItem.itemTemplateItem?.location.displayLocationPath || "-",
            specifications: emptyCell,
          }),
        ]
      : [
          column<CommitmentRow>({
            header: "Item Code",
            group: (groupName) => ({ content: groupName, colspan: 9, css: Css.smMd.px2.$ }),
            tradeLineItem: () => emptyCell,
            data: (cli) => cli.projectItem.item.fullCode,
            specifications: emptyCell,
          }),
          column<CommitmentRow>({
            header: "Item Description",
            group: emptyCell,
            tradeLineItem: emptyCell,
            data: (cli) => cli.projectItem.name,
            specifications: (specification) => ({
              content: (
                <p data-testid="specification">
                  Note: <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(specification || "") }} />
                </p>
              ),
              colspan: 8,
              css: Css.sm.$,
            }),
          }),
          column<CommitmentRow>({
            header: "Bid Item",
            group: emptyCell,
            tradeLineItem: emptyCell,
            data: ({ bidItem }) => (bidItem ? bidItem.displayName : emptyCellDash),
            specifications: emptyCell,
          }),
          column<CommitmentRow>({
            header: "Location",
            group: emptyCell,
            tradeLineItem: emptyCell,
            data: (cli) => cli.projectItem.location?.name || "-",
            specifications: emptyCell,
          }),
        ]),
    column<CommitmentRow>({
      header: "Cost Type",
      group: emptyCell,
      tradeLineItem: emptyCell,
      data: (cli) => cli.projectItem.costTypeDetail.tradePartnerName,
      specifications: emptyCell,
    }),
    column<CommitmentRow>({
      header: "Qty",
      group: emptyCell,
      tradeLineItem: emptyCell,
      data: (cli) => (cli.projectItem.unitOfMeasure.useQuantity ? cli.quantity : ""),
      specifications: emptyCell,
    }),
    column<CommitmentRow>({
      header: "Units",
      group: emptyCell,
      tradeLineItem: emptyCell,
      data: (cli) => cli.projectItem.unitOfMeasure.abbreviation,
      specifications: emptyCell,
    }),
    column<CommitmentRow>({
      header: "Unit Price",
      group: emptyCell,
      tradeLineItem: emptyCell,
      data: (cli) => {
        if (cli.projectItem.unitOfMeasure.useQuantity && !cli.primaryBidContractLineItem?.prorations.nonEmpty) {
          const { quantity, costChangeInCents } = cli;
          return priceCell({ valueInCents: (costChangeInCents || 0) / (quantity || 1) });
        } else {
          return emptyCellDash;
        }
      },
      specifications: emptyCell,
    }),
    column<CommitmentRow>({
      header: "Subtotal",
      // Show subtotal for individual trade line items
      // Labor tasks associated with multiple trade line items will have their costs accounted for in each individual trade line
      tradeLineItem: (data) => (data?.length === 1 ? priceCell({ valueInCents: data[0].totalCostInCents }) : emptyCell),
      group: emptyCell,
      data: (cli) =>
        cli.primaryBidContractLineItem?.prorations.nonEmpty
          ? emptyCell
          : priceCell({ valueInCents: cli.costChangeInCents }),
      specifications: emptyCell,
    }),
  ];
}

function createProductConfigRows(lineItems: CommitmentOrChangeOrderPdf_LineItemFragment[]) {
  // Group by trade line item
  // Labor tasks associated with multiple trade line items will be shown as a separate line item
  const lineItemsByTrade = lineItems.groupBy(
    (cli) => cli.primaryBidContractLineItem?.prorations.map((li) => li.tradeLineItem.id).join("") ?? "",
  );
  return Object.entries(lineItemsByTrade).map(([tliId, lineItems]) => ({
    kind: "tradeLineItem" as const,
    id: tliId,
    data: lineItems.first?.primaryBidContractLineItem?.prorations.map((proration) => proration.tradeLineItem),
    children: lineItems.flatMap((cli) => [
      // Default row for cli
      { id: cli.id, data: cli, kind: "data" as const },
      // If specifications exist, add a row for them
      ...(cli.specifications
        ? [{ id: `${cli.id}-specifications`, data: cli.specifications, kind: "specifications" as const }]
        : []),
    ]),
  }));
}

function getEffectiveLineItems(lineItems: CommitmentOrChangeOrderPdf_LineItemFragment[]) {
  // If the CLI has modifies, return the core + modifies as separate line items
  return lineItems
    .flatMap((cli) =>
      cli.modifyingBidContractLineItems.isEmpty
        ? cli
        : [cli.primaryBidContractLineItem, ...cli.modifyingBidContractLineItems].compact().map((bcli) => ({
            ...cli,
            id: bcli.id,
            primaryBidContractLineItem: bcli, // So we can properly group prorated lines if needed
            costChangeInCents: bcli?.totalCostInCents,
          })),
    )
    .sortBy((cli) => `${cli.projectItem.item.fullCode} ${cli.projectItem.name}`);
}

function filterLineItemOptions(
  lineItem: CommitmentOrChangeOrderPdf_LineItemFragment,
  specOptionId: string | undefined,
) {
  // Use the option configuration from the specific line items
  const lineOptions = lineItem.primaryBidContractLineItem?.itemTemplateItem?.options ?? [];
  return {
    ...lineItem,
    projectItem: {
      ...lineItem.projectItem,
      // Filter out the spec level and elevation options since they are displayed in the PO details.
      configuredOptions: lineOptions.filter(
        (option) => option.id !== specOptionId && !option.optionGroup.group.type.isElevation,
      ),
    },
  };
}

function getConfiguredOptionsKey(cli: CommitmentOrChangeOrderPdf_LineItemFragment) {
  return (
    // join the configured option ids to create a unique key for each group
    cli.projectItem.configuredOptions
      .sortBy((o) => o.order)
      .map((o) => o.id)
      .join("-") ?? ""
  );
}

function getGroupName(lineItems: CommitmentOrChangeOrderPdf_LineItemFragment[], specOptionId: string | undefined) {
  const options = lineItems.first?.projectItem.configuredOptions.map((option) => option.displayName) ?? [];
  return options.nonEmpty ? options.join(", ") : "Base";
}

export function createPdfLineItemRows(
  lineItems: CommitmentOrChangeOrderPdf_LineItemFragment[],
  specOptionId: string | undefined,
) {
  return (
    lineItems
      .map((li) => filterLineItemOptions(li, specOptionId))
      .groupByObject((cli) => getConfiguredOptionsKey(cli))
      // Sort each group by the sum of all the optionGroup's order
      .sortBy(
        ([key, lineItems]) => lineItems?.first?.projectItem.configuredOptions.sum((o) => o.optionGroup.order) ?? 0,
      )
      .map(([key, lineItems]) => {
        return {
          id: key,
          kind: "group" as const,
          // All lineItems in the group have the same configured options, so use the first lineItem to get the group name
          data: getGroupName(lineItems, specOptionId),
          children: lineItems.flatMap((cli) => {
            return [
              // default row for cli
              { id: cli.id, data: cli, kind: "data" as const },
              // If specifications exist, add a row for them
              ...(cli.specifications
                ? [{ id: `${cli.id}-specifications`, data: cli.specifications, kind: "specifications" as const }]
                : []),
            ];
          }),
        };
      })
  );
}
