import { Step } from "@homebound/beam";
import kebabCase from "lodash/kebabCase";
import { useMemo, useState } from "react";
import { useParams } from "react-router";
import { StepperProvider, useStepperContext } from "src/components/stepper";
import { WizardStep } from "src/components/stepper/useStepperWizard/useStepperWizard";
import { DevelopmentParams, PlanPackageDetailsParams } from "src/routes/routesDef";
import { fail } from "src/utils";
import { PlanDetailsStep } from "./1.PlanDetailsStep";
import { LocationStep } from "./2.1.LocationStep";
import { ElevationsStep } from "./2.ElevationsStep";
import { ChooseOptionsStep } from "./3.ChooseOptionsStep";
import { ConfigureOptionsStep } from "./4.1.ConfigureOptionsStep";
import { ProgramDataStep } from "./5.ProgramDataStep";
import { OptionDetailsStep } from "./6.OptionDetailsStep";

export function CreateOrUpdatePlanPackageWizard() {
  const params = useParams<DevelopmentParams & PlanPackageDetailsParams>();
  const [stepDirty, setStepDirty] = useState(false);
  const steps = useMemo(() => getSteps(params, setStepDirty), [params]);
  const initialSteps = useMemo<Step[]>(() => {
    return steps.map((step) => ({ ...step, state: "incomplete", value: kebabCase(step.label) }));
  }, [steps]);

  return (
    <StepperProvider steps={initialSteps} shouldConfirmStepChange={stepDirty} newLayout>
      <StepPicker steps={steps} />
    </StepperProvider>
  );
}

function getSteps(
  { planPackageId, planPackageVersionId }: DevelopmentParams & PlanPackageDetailsParams,
  setStepDirty: React.Dispatch<React.SetStateAction<boolean>>,
): WizardStep[] {
  const stepDirtyHandler = (dirty: boolean) => setStepDirty(dirty);
  return [
    {
      label: "Plan Details",
      render: () => (
        <PlanDetailsStep id={planPackageId} versionId={planPackageVersionId} setStepDirty={stepDirtyHandler} />
      ),
    },
    {
      label: "Elevations",
      render: () => (
        <ElevationsStep id={planPackageId} versionId={planPackageVersionId} setStepDirty={stepDirtyHandler} />
      ),
      disabled: !planPackageId,
    },
    {
      label: "Location",
      render: () => (
        <LocationStep id={planPackageId} versionId={planPackageVersionId} setStepDirty={stepDirtyHandler} />
      ),
      disabled: !planPackageId,
    },
    {
      label: "Choose Options",
      render: () => (
        <ChooseOptionsStep id={planPackageId} versionId={planPackageVersionId} setStepDirty={stepDirtyHandler} />
      ),
      disabled: !planPackageId,
    },
    {
      label: "Configure Options",
      render: () => (
        <ConfigureOptionsStep id={planPackageId} versionId={planPackageVersionId} setStepDirty={stepDirtyHandler} />
      ),
      disabled: !planPackageId,
    },
    {
      label: "Program Data",
      render: () => (
        <ProgramDataStep id={planPackageId} versionId={planPackageVersionId} setStepDirty={stepDirtyHandler} />
      ),
      disabled: !planPackageId,
    },
    {
      label: "Option Details",
      render: () => (
        <OptionDetailsStep id={planPackageId} versionId={planPackageVersionId} setStepDirty={stepDirtyHandler} />
      ),
      disabled: !planPackageId,
    },
  ];
}

function StepPicker({ steps }: { steps: WizardStep[] }) {
  const { currentStep } = useStepperContext();

  const { render: Component } =
    steps.find((s) => s.label === currentStep.label) ?? fail(`No Wizard step found for ${currentStep.label}`);
  return Component();
}
