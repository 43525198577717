import {
  ButtonMenu,
  Css,
  FullBleed,
  Palette,
  RouteTabWithContent,
  Tabs,
  Tooltip,
  useBreakpoint,
  useTestIds,
} from "@homebound/beam";
import { MutableRefObject, useCallback } from "react";
import { useLocation } from "react-router";
import { Link, matchPath, useParams } from "react-router-dom";
import { StatusIndicator } from "src/components/StatusIndicator";
import { ImageUploader, PendingAsset } from "src/components/uploader/ImageUploader";
import { useUpdateProjectCoverImageMutation } from "src/generated/graphql-types";
import { useProjectContext } from "src/routes/projects/context/ProjectContext";
import { ProjectParams, projectPaths } from "src/routes/routesDef";
import { createDevelopmentUrl, createProjectSettingsUrl } from "src/RouteUrls";
import { stageCodeToNameMapper } from "src/utils";
import { createHopProjectUrl } from "src/utils/hopLinks";
import { projectStatusDetails } from "src/utils/mappers";
import { BooleanParam, useQueryParams } from "use-query-params";

type DashboardHeaderProps = {
  tabs: RouteTabWithContent[];
  headerActionsRef: MutableRefObject<HTMLDivElement | null>;
};
export function DashboardHeader(props: DashboardHeaderProps) {
  const { tabs, headerActionsRef } = props;
  const { projectId } = useParams<ProjectParams>();
  const { buildAddress, market, development, status, latestActiveStage, coverImage } = useProjectContext();
  const tid = useTestIds({});
  const location = useLocation();
  const [{ edit }, setQueryParams] = useQueryParams({ edit: BooleanParam });
  const [updateProjectCoverImage] = useUpdateProjectCoverImageMutation();
  const { sm } = useBreakpoint();

  const handleUpdateImage = useCallback(
    async ({ contentType, fileName, s3Key }: PendingAsset) => {
      await updateProjectCoverImage({
        variables: { input: { id: projectId, coverImage: { contentType, fileName, s3Key } } },
      });
    },
    [updateProjectCoverImage, projectId],
  );

  // Defining some widths for smartly calculating position offsets.
  const imgWidth = 315;
  const imgHeight = 181;
  const gap = 32;

  return (
    <FullBleed omitPadding>
      <div css={Css.bgWhite.df.gapPx(gap).pr5.relative.$}>
        {!sm && (
          <div css={Css.fs0.$}>
            <ImageUploader
              imageUrl={coverImage?.downloadUrl}
              thumbnailUrl={coverImage?.previewUrl ?? undefined}
              onChange={handleUpdateImage}
              xss={Css.objectCover.wPx(imgWidth).hPx(imgHeight).$}
            />
          </div>
        )}

        <div css={Css.df.aic.jcsb.gap2.fg1.py4.if("mdAndDown").fdc.jcc.aifs.if("sm").px2.$}>
          <div css={Css.df.aic.gap2.fg1.if("mdAndDown").fg0.$}>
            <span css={Css.xl3Bd.lineClamp2.if("mdAndDown").lineClamp1.$} {...tid.address}>
              {buildAddress.street1}
            </span>
            {!edit && (
              <ButtonMenu
                trigger={{ icon: "cog", color: Palette.Gray700 }}
                items={[
                  {
                    label: "Edit",
                    onClick: () => setQueryParams({ edit: true }, "replaceIn"),
                    disabled: !matchPath(location.pathname, { path: projectPaths.dashboard, exact: true })
                      ? "Switch to the Dashboard tab to edit the widgets"
                      : false,
                  },
                  { label: "Homeowner Platform", onClick: createHopProjectUrl(projectId) },
                  { label: "Project Settings", onClick: createProjectSettingsUrl(projectId) },
                ]}
              />
            )}
          </div>

          {edit ? (
            <div {...tid.actions} ref={headerActionsRef} />
          ) : (
            <div
              css={
                Css.df.gapPx(20).smMd.gray800.addIn("> *", Css.df.aic.$).addIn("* + *", Css.bl.bw2.bcGray400.plPx(20).$)
                  .$
              }
            >
              <span {...tid.market}>
                <Tooltip title="Market" placement="top">
                  {market.name}
                </Tooltip>
              </span>

              {development && (
                <Link to={createDevelopmentUrl(development.id)} {...tid.development}>
                  <Tooltip title="Development" placement="top">
                    {development.name}
                  </Tooltip>
                </Link>
              )}

              <span {...tid.status}>
                <Tooltip title="Project Status" placement="top">
                  <span css={Css.df.aic.gap1.$}>
                    <StatusIndicator status={status.code} /> {projectStatusDetails[status.code]}
                  </span>
                </Tooltip>
              </span>

              <span {...tid.stage}>
                <Tooltip title="Latest Active Stage" placement="top">
                  {stageCodeToNameMapper[latestActiveStage]}
                </Tooltip>
              </span>
            </div>
          )}
        </div>

        {!edit && (
          <div css={Css.absolute.bottom0.leftPx(imgWidth + gap).if("sm").left2.$}>
            <Tabs tabs={tabs} data-testid="dashboardTabs" />
          </div>
        )}
      </div>
    </FullBleed>
  );
}
