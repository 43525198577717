import {
  column,
  Css,
  emptyCell,
  GridColumn,
  GridDataRow,
  GridTable,
  numericColumn,
  simpleHeader,
} from "@homebound/beam";
import { Percentage, priceCell } from "src/components";
import { StatusIndicator } from "src/components/StatusIndicator";
import {
  CommitmentOrChangeOrderPdf_CommitmentFragment,
  CommitmentOrChangeOrderPdf_DrawFragment,
  CommitmentOrChangeOrderPdf_TaskFragment,
} from "src/generated/graphql-types";
import { Section } from "../../components/Section";

type PaymentScheduleTableProps = {
  commitment: CommitmentOrChangeOrderPdf_CommitmentFragment;
};

export function PaymentScheduleTable({ commitment }: PaymentScheduleTableProps) {
  const { draws, committedInCents, depositInBasisPoints } = commitment;
  return (
    <Section title="Payment Schedule">
      <div css={Css.mb2.$}>
        <GridTable
          as="table"
          columns={createPaymentScheduleColumns(committedInCents)}
          rows={createPaymentScheduleRows(draws, depositInBasisPoints)}
        />
      </div>
    </Section>
  );
}

type HeaderRow = { kind: "header" };
type DrawLineRow = {
  kind: "draw";
  data: Omit<CommitmentOrChangeOrderPdf_DrawFragment, "task"> & {
    isDeposit?: boolean;
    task: CommitmentOrChangeOrderPdf_TaskFragment | undefined;
  };
};
type TotalRow = { kind: "total"; data: undefined };
type Row = HeaderRow | DrawLineRow | TotalRow;

export function createPaymentScheduleColumns(committedInCents: number, showStatus?: boolean): GridColumn<Row>[] {
  return [
    column<Row>({
      header: () => "Type",
      draw: ({ isDeposit }) => (isDeposit ? "Deposit" : "Draw"),
      total: emptyCell,
    }),
    column<Row>({
      header: () => "Associated Task",
      draw: (row) => row.task?.name,
      total: emptyCell,
    }),
    ...(showStatus
      ? [
          column<Row>({
            header: () => "Task status",
            draw: ({ task }) =>
              task ? (
                <div css={Css.df.aic.gap1.$}>
                  <StatusIndicator status={task.status.code} />
                  <div css={Css.hPx(32).df.aic.$}>{task.status.name}</div>
                </div>
              ) : (
                emptyCell
              ),
            total: emptyCell,
          }),
        ]
      : []),
    column<Row>({
      header: () => "Description",
      draw: (row) => row.description,
      total: emptyCell,
    }),
    column<Row>({
      header: () => "Percentage",
      draw: ({ amountInBasisPoints }) => <Percentage percent={amountInBasisPoints} basisPoints />,
      total: emptyCell,
    }),
    numericColumn<Row>({
      header: () => "Amount",
      draw: ({ amountInBasisPoints }) =>
        priceCell({ id: "cost", valueInCents: (committedInCents * amountInBasisPoints) / 10000 }),
      total: () => priceCell({ id: "cost", valueInCents: committedInCents }),
    }),
  ];
}

export function createPaymentScheduleRows(
  draws: CommitmentOrChangeOrderPdf_DrawFragment[],
  depositInBasisPoints: number | null | undefined,
): GridDataRow<Row>[] {
  const depositRow = depositInBasisPoints
    ? [
        {
          kind: "draw" as const,
          id: "deposit",
          data: {
            id: "deposit",
            task: undefined,
            amountInBasisPoints: depositInBasisPoints,
            isDeposit: true,
          },
        },
      ]
    : [];
  const drawRows = draws.map((d) => ({ kind: "draw" as const, id: d.id, data: d }));

  return [simpleHeader, ...depositRow, ...drawRows, { id: "total", kind: "total" as const, data: undefined }];
}
