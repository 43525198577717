import { BoundSelectField, Button, FormLines, ModalBody, ModalFooter, ModalHeader, useModal } from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useCallback } from "react";
import { useHistory, useLocation } from "react-router";
import {
  Maybe,
  ScopeReasonCodesListFragment,
  useSaveItemTemplatesMutation,
  useScopeReasonCodesQuery,
} from "src/generated/graphql-types";
import { noop, queryResult } from "src/utils";
import { RenderMode } from "../review-and-publish/components/utils";
import { TemplateVersioningStep } from "../review-and-publish/enums";

type AddItemTemplatesReasonCodesModalProps = {
  /** If there is a reviewUrl, we will route to the review page after selecting the reason code */
  reviewUrl?: string;
  itemTemplateIds: string[];
  scopeReasonCodes?: ScopeReasonCodesListFragment[];
  onContinue?: () => void | Promise<void>;
};

export function AddItemTemplatesReasonCodesModal(props: AddItemTemplatesReasonCodesModalProps) {
  const query = useScopeReasonCodesQuery();

  return queryResult(query, {
    loading: () => <Loading />,
    data: ({ scopeChangeReasons }) => (
      <AddItemTemplatesReasonCodeDataView {...props} scopeReasonCodes={scopeChangeReasons} />
    ),
  });
}

function AddItemTemplatesReasonCodeDataView(props: AddItemTemplatesReasonCodesModalProps) {
  const { reviewUrl, itemTemplateIds, scopeReasonCodes, onContinue = noop } = props;
  const { closeModal } = useModal();
  const [save] = useSaveItemTemplatesMutation();
  const history = useHistory();
  const { state } = useLocation<{ itemTemplatesIds?: string[]; fromEditAction?: true }>();

  const formState = useFormState({ config: formConfig });

  const continueBtnLabel = reviewUrl ? "Continue" : "Publish";

  const onSubmit = useCallback(async () => {
    await save({
      variables: {
        input: {
          itemTemplates: itemTemplateIds.map((itId) => ({
            id: itId,
            scopeChangeReasonId: formState.reasonCode.value,
          })),
          applyReasonToAllItems: true,
          applyReasonWhenNotSet: true,
        },
      },
    });

    if (reviewUrl) {
      // Only go to the review step when reviewUrl is provided
      history.push({
        pathname: reviewUrl,
        state: {
          ...state,
          itemTemplatesIds: itemTemplateIds,
          mode: RenderMode.edit,
          currentStep: TemplateVersioningStep.REVIEW,
          from: history.location,
        },
      });
    }

    await onContinue();

    closeModal();
  }, [save, itemTemplateIds, history, reviewUrl, formState, state, onContinue, closeModal]);

  return (
    <>
      <ModalHeader>What's the reason for these new template versions?</ModalHeader>
      <ModalBody>
        <FormLines>
          <BoundSelectField
            label="The selected reason code will only apply to templates and items that do not have reason codes already."
            field={formState.reasonCode}
            options={scopeReasonCodes || []}
          />
        </FormLines>
      </ModalBody>
      <ModalFooter>
        <Button label="Cancel" variant="tertiary" onClick={closeModal} />
        <Observer>{() => <Button label={continueBtnLabel} disabled={!formState.valid} onClick={onSubmit} />}</Observer>
      </ModalFooter>
    </>
  );
}

function Loading() {
  return <ModalHeader>Loading Reason Codes...</ModalHeader>;
}

type FormInput = {
  reasonCode: Maybe<string>;
};

const formConfig: ObjectConfig<FormInput> = {
  reasonCode: { type: "value", rules: [required] },
};
