import { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";

/**
 * A custom hook for navigating to a new ReadyPlan aggregate version ID by updating the URL.
 *
 * @returns {Function} A function that takes an `rpavId` and updates the URL path with it.
 */
export function useNavigateToRpav(): (rpavId: string | null | undefined) => void {
  const history = useHistory();
  const location = useLocation();

  const navigateToRpav = useCallback(
    (rpavId: string | null | undefined) => {
      if (!rpavId) return;

      const { pathname, search } = location;

      // Update the path with the new rpavId
      const pathSegments = pathname.split("/");
      const updatedPathSegments = pathSegments.map((segment) => (segment.startsWith("rpav:") ? rpavId : segment));
      const newPathname = updatedPathSegments.join("/");

      history.push(`${newPathname}${search}`);
    },
    [history, location],
  );

  return navigateToRpav;
}
