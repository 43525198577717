import { Css } from "@homebound/beam";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { FormattedDate } from "src/components";
import { Card } from "src/components/Card";
import { AggregateVersionStatus, VersionDetailsCard_VersionFragment } from "src/generated/graphql-types";
import { sanitizeHtml } from "src/utils";

type VersionDetailsProps = {
  version: VersionDetailsCard_VersionFragment;
  changeDetails: ReactNode;
  versionUrl: string;
};

export function VersionDetailsCard(props: VersionDetailsProps) {
  const { version, changeDetails, versionUrl } = props;
  const isDraft = version.status.code === AggregateVersionStatus.Draft;
  const versionLabel = isDraft ? "Draft of " : "";
  const versionNumber = `v${version.version} `;
  const formattedDate = <FormattedDate date={version.createdAt} dateFormatStyle="long" />;

  return (
    <Card key={version.id} xss={Css.wPx(730).ma.p6.$}>
      <div css={Css.df.fdc.gap5.$}>
        <div data-testid="planInfo" css={Css.df.gap1.$}>
          <Link to={versionUrl}>
            <span css={Css.xlBd.$}>
              {versionLabel} {versionNumber}
            </span>
          </Link>
          <span css={Css.gray400.xl2.pl1.lhPx(24).$}>{formattedDate}</span>
        </div>
        {version.versionNotes && (
          <div
            data-testid="versionNotes"
            css={Css.baseMd.$}
            dangerouslySetInnerHTML={{ __html: sanitizeHtml(version.versionNotes || "") }}
          />
        )}
        <div>
          <div css={Css.baseBd.mb1.$}>Changes made</div>
          {changeDetails}
        </div>
      </div>
    </Card>
  );
}
