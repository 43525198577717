import { Css, SelectField } from "@homebound/beam";
import { useMemo } from "react";
import { AggregateVersionStatus, VersionableFragment } from "src/generated/graphql-types";
import { useNavigateToRpav } from "src/hooks/useNavigateToRpav";

type ReadyPlanVersionInfo = {
  aggregateVersions: VersionableFragment[];
};

type ReadyPlanVersionHeaderProps = {
  readyPlan: ReadyPlanVersionInfo;
  versionId: string;
};

export function ReadyPlanVersionHeader({ readyPlan, versionId }: ReadyPlanVersionHeaderProps) {
  const navigateToRpav = useNavigateToRpav();

  const versionOptions = useMemo(
    () =>
      readyPlan.aggregateVersions?.map((v) => ({
        id: v.id,
        name: v.status.code === AggregateVersionStatus.Draft ? `Draft of v${v.version}` : `v${v.version}`,
      })) ?? [],
    [readyPlan.aggregateVersions],
  );

  return (
    <div css={Css.wPx(130).px1.$}>
      <SelectField
        label="version"
        value={versionId}
        options={versionOptions}
        onSelect={navigateToRpav}
        labelStyle="hidden"
        borderless
      />
    </div>
  );
}
