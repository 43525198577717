import { Accordion, Button, Checkbox, Css, useTestIds } from "@homebound/beam";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { createPlanPackageUrl } from "src/RouteUrls";
import {
  BasicFilterOptions,
  FilterLocationSectionData,
  FilterSectionData,
  LocationsFilterSelectTree,
  isLocationFilterSection,
} from "src/components/LocationFilterSelectTree";
import { StatusIndicator } from "src/components/StatusIndicator";
import {
  CostType,
  InputMaybe,
  MaterialType,
  TakeoffLineItemFilter,
  usePlanPackageTakeoffSideBarQuery,
} from "src/generated/graphql-types";
import {
  useTakeoffsManagerContext,
  useTakeoffsStore,
} from "src/routes/libraries/plan-package/takeoffs/TakeoffsManagerContext";
import { pluralize } from "src/utils";
import { PLAN_FALLBACK_IMG } from "./utils";

export function PlanPackageTakeoffSideBar() {
  const planPackage = useTakeoffsStore((state) => state.planPackage);
  const rpavId = useTakeoffsStore((state) => state.planPackage.version.id);
  const { data, loading, refetch } = usePlanPackageTakeoffSideBarQuery({
    variables: { planPackageId: planPackage.id },
  });
  const [allCollapsed, setAllCollapsed] = useState(true);
  const filter = useTakeoffsStore((state) => state.filter);
  const setFilter = useTakeoffsStore((state) => state.setFilter);
  const store = useTakeoffsManagerContext();
  const history = useHistory();
  const tid = useTestIds({}, "PlanPackageTakeoffSideBar");

  const pp = data?.planPackage;
  const { options: allOptions = [], itemLocations = [], costCodes = [] } = pp ?? {};
  const [elevations, options] = useMemo(() => {
    return allOptions
      ?.sortByKey("order")
      .partition(({ type }) => type.isElevation)
      .map((options) =>
        options.map(({ id, displayName, type }, index) => ({
          id,
          name: displayName,
          isBase: type.isElevation && index === 0,
        })),
      );
  }, [allOptions]);

  // Update the store with the refetch functionality so we can trigger it when takeoffLineItems are modified
  useEffect(() => {
    store.setState({ refetchFilters: refetch });
  }, [store, refetch]);

  // Prune out any filters that are no longer valid
  useEffect(() => {
    if (loading) return;

    let updated = false;
    function prune(
      arr: InputMaybe<string>[] | undefined | null,
      validValues: InputMaybe<string>[],
    ): InputMaybe<string>[] | undefined | null {
      if (!arr) return arr;
      const prunedArray = arr.filter((item) => validValues.includes(item));
      if (prunedArray.length !== arr.length) {
        updated = true;
        return prunedArray;
      }
      return arr;
    }

    const currentFilter = store.getState().filter;
    const validLocationIds = itemLocations.map(({ id }) => id);
    const validCostCodeIds = costCodes.map(({ id }) => id);
    const validOptionIds = allOptions.map(({ id }) => id);

    // Use `compact` to remove `null` and `undefined` values from `location` as it is not supported
    const prunedLocations = prune(currentFilter.location, validLocationIds)?.compact();
    const prunedCostCodes = prune(currentFilter.costCode, validCostCodeIds)?.compact();
    const prunedOptions = prune(currentFilter.options, validOptionIds);

    // We don't want to call `set` unless we have to, since it will trigger a re-render
    if (updated) {
      setFilter({
        ...currentFilter,
        location: prunedLocations?.length ? prunedLocations : undefined,
        costCode: prunedCostCodes?.length ? prunedCostCodes : undefined,
        options: prunedOptions?.length ? prunedOptions : undefined,
      });
    }
  }, [allOptions, itemLocations, store, loading, setFilter, costCodes]);

  const filters: (FilterSectionData | FilterLocationSectionData)[] = useMemo(() => {
    function sortByNameProp(a: { name: string }, b: { name: string }) {
      const lowerA = a.name.toLowerCase(),
        lowerB = b.name.toLowerCase();
      if (lowerA < lowerB) return -1;
      if (lowerA > lowerB) return 1;
      return 0;
    }

    return [
      { label: "Elevations", options: elevations.sort(sortByNameProp), delta: true, filterField: "options" as const },
      { label: "Options", options: options.sort(sortByNameProp), delta: true, filterField: "options" as const },
      { label: "Cost Codes", options: [...costCodes].sort(sortByNameProp), filterField: "costCode" as const },
      {
        label: "Item Type",
        options: [
          // NOTE: This filter group is a little strange, since the UX is grouping together essentially different concepts
          { id: MaterialType.Placeholder, name: "Item Slots", filterField: "materialType" },
          { id: CostType.Labor, name: "Labor (Tasks)" },
          { id: CostType.Materials, name: "Materials" },
        ],
        filterField: "costType" as const,
      },
      { label: "Location" as const, options: [...itemLocations].sort(sortByNameProp) },
    ];
  }, [elevations, options, costCodes, itemLocations]);

  const coverPhoto = useMemo(
    () => planPackage.assets.find((ppa) => ppa.active && ppa.isCoverPhoto)?.asset.previewUrl,
    [planPackage],
  );

  return (
    <div css={Css.bgWhite.$}>
      <div css={Css.p3.df.fdc.gap2.$}>
        <Button
          variant="text"
          onClick={createPlanPackageUrl(planPackage.id, planPackage.version.id)}
          label="Back"
          icon="arrowBack"
        />
        <div css={Css.bgGray100.p2.$}>
          <img src={coverPhoto ?? PLAN_FALLBACK_IMG} loading="lazy" alt="Plan package cover" {...tid.coverPhoto} />
        </div>
        <div css={Css.baseBd.fg1.$} {...tid.planName}>
          {planPackage.name}
        </div>
        <div css={Css.dg.gtc("50% 50%").rg1.cg2.aic.$}>
          <div css={Css.df.aic.$}>
            <Button
              variant="text"
              onClick={createPlanPackageUrl(planPackage.id, planPackage.version.id)}
              label={planPackage.displayCode}
              {...tid.planCode}
            />
          </div>
          <div css={Css.df.aic.gap1.$}>
            <StatusIndicator status={planPackage.status.code} type="ReadyPlan" />
            <div css={Css.hPx(32).df.aic.$} {...tid.planStatus}>
              {planPackage.status.name}
            </div>
          </div>
          <div css={Css.xs.$} {...tid.programData}>
            {planPackage.programData.bedrooms ?? 0} bed, {planPackage.programData.fullBaths ?? 0} bath
            <br />
            {planPackage.programData.sellableSqft} sqft
          </div>
          <div css={Css.xs.$} {...tid.optionsCount}>
            {elevations.length} {pluralize(elevations.length, "Elevation")}
            <br />
            {options.length} {pluralize(options.length, "Option")}
          </div>
        </div>
      </div>

      <div css={Css.p2.pt1.df.fdc.gap2.$} {...tid.basePlanSection}>
        <div css={Css.df.jcsb.$}>
          <div css={Css.xsSb.$}>Base Plan</div>
          <div css={Css.xs.$}>
            <Button
              variant="text"
              label={allCollapsed ? "Expand All" : "Collapse All"}
              onClick={() => setAllCollapsed((collapsed) => !collapsed)}
              {...tid.expandToggle}
            />
          </div>
        </div>
        <div css={Css.df.fdc.gap1.$}>
          <label css={Css.gray700.df.aic.gap1.$}>
            <Checkbox
              label=""
              checkboxOnly
              withLabelElement={false}
              onChange={updateFilterWithOption(null, "options", setFilter)}
              selected={(filter["options"] as unknown[])?.includes(null)}
              {...tid[`null_checkbox`]}
            />
            <span css={Css.tiny.$} {...tid[`null_label`]}>
              {planPackage.name}
            </span>
          </label>
        </div>
      </div>
      <div {...tid.filterSection}>
        {filters.map((section, i) => {
          const { label } = section;
          return isLocationFilterSection(section) ? (
            <LocationsFilterSelectTree
              key={label}
              label="Location"
              options={section.options}
              collapsed={allCollapsed}
              topBorder={i === 0}
              filter={filter}
              setFilter={setFilter}
            />
          ) : (
            <TakeoffFilterSection
              key={label}
              label={label}
              options={section.options}
              delta={section.delta}
              filterField={section.filterField}
              collapsed={allCollapsed}
              topBorder={i === 0}
            />
          );
        })}
      </div>
    </div>
  );
}

export type TakeoffFilterSectionProps<T> = {
  label: string;
  delta?: boolean;
  options?: T[];
  filterField: keyof TakeoffLineItemFilter;
  collapsed?: boolean;
  topBorder?: boolean;
};

export function TakeoffFilterSection(props: TakeoffFilterSectionProps<BasicFilterOptions>) {
  const { label, delta, options = [], filterField, collapsed, topBorder = false } = props;
  const filter = useTakeoffsStore((state) => state.filter);
  const setFilter = useTakeoffsStore((state) => state.setFilter);
  const selected = options
    .filter(({ id, filterField: optionFilterField }) =>
      (filter[optionFilterField ?? filterField] as unknown[])?.includes(id),
    )
    .map(({ id }) => id);
  const tid = useTestIds(props, "TakeoffFilterSection");

  return (
    <Accordion
      title={
        <div css={Css.df.jcsb.$} {...tid[`${label.replaceAll(" ", "")}_label`]}>
          <div css={Css.xsSb.$}>
            {label}
            {delta ? " △" : ""}
          </div>
          <div css={Css.xs.gray700.$}>
            {selected.length}/{options.length}
          </div>
        </div>
      }
      bottomBorder
      topBorder={topBorder}
      defaultExpanded={!collapsed}
      {...tid[label.replaceAll(" ", "")]}
      xss={{ paddingBottom: "8px" }}
    >
      <div css={Css.df.fdc.gap1.$}>
        {options.map(({ id, name, filterField: optionFilterField, isBase }) => (
          <label key={id} css={Css.df.aic.gap1.if(selected.includes(id)).color("black").else.gray700.$}>
            <Checkbox
              label=""
              checkboxOnly
              withLabelElement={false}
              onChange={updateFilterWithOption(id, optionFilterField ?? filterField, setFilter)}
              selected={selected.includes(id)}
              {...tid[`${id}_checkbox`]}
            />
            <span css={Css.tiny.df.gap1.$} {...tid[`${id}_label`]}>
              {name}
              {isBase ? (
                <span>
                  <span css={Css.bgGray200.color("black").px1.py("2px").borderRadius("100px").$}>Base</span>
                </span>
              ) : (
                <></>
              )}
            </span>
          </label>
        ))}
      </div>
    </Accordion>
  );
}

function updateFilterWithOption(
  id: string | null,
  filterField: keyof TakeoffLineItemFilter,
  setFilter: React.Dispatch<React.SetStateAction<TakeoffLineItemFilter>>,
) {
  return (selected: boolean) => {
    setFilter((filter) => {
      const current = (filter[filterField] ?? []) as unknown[];
      const newFilter = {
        ...filter,
        [filterField]: selected ? [...current, id] : current.filter((v) => v !== id),
      };

      if ((newFilter[filterField] as unknown[])?.isEmpty) {
        delete newFilter[filterField];
      }

      return newFilter;
    });
  };
}
