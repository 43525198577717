import { Avatar, Css } from "@homebound/beam";
import { sentenceCase } from "change-case";
import { format } from "date-fns";
import { useHistory, useParams } from "react-router-dom";
import { createDevelopmentJobLogNoteDrawerUrl, createProjectJobLogNoteDrawerUrl } from "src/RouteUrls";
import { CommentCountBubble, formatDate } from "src/components";
import { JobLogDetailFragment, JobLogNoteDetailFragment } from "src/generated/graphql-types";
import { nonEmpty } from "src/utils";
import { JobLogImages } from "./JobLogImages";
import { JobLogNoteTags } from "./JobLogNoteTags";

type JobLogNoteProps = {
  note: JobLogNoteDetailFragment;
  jobLog: JobLogDetailFragment;
};

export function JobLogNote(props: JobLogNoteProps) {
  const { note, jobLog } = props;
  const { id, content, createdAt, internalUser, type, title } = note;
  const createdAtAsDate = new Date(createdAt);
  const formattedDate = format(createdAtAsDate, "MMM d");
  const formattedTime = formatDate(createdAtAsDate, "none", "short");
  const detailsPhrase = `${internalUser.name}, ${formattedDate} at ${formattedTime}`;
  const { developmentId } = useParams<{ developmentId: string }>();
  const { push } = useHistory();
  const isProject = !developmentId;
  function onNoteClick() {
    if (isProject) {
      push(createProjectJobLogNoteDrawerUrl(jobLog.project.id, jobLog.id, id));
    } else {
      push(createDevelopmentJobLogNoteDrawerUrl(developmentId, jobLog.id, id));
    }
  }

  return (
    <div
      data-testid="jobLogNote"
      css={
        Css.bgWhite.bshBasic
          .borderRadius("8px")
          .bw1.bss.bcGray300.w100.p3.cursorPointer.add("transition", "visibility 100ms").$
      }
      key={id}
      onClick={onNoteClick}
    >
      <div css={Css.df.fdc.$}>
        <div css={Css.gray900.mb2.pl4.base.$}>
          {" "}
          <b>{title ?? sentenceCase(type ?? "")}</b>
        </div>
        <JobLogNoteTags note={note} />
        <div css={Css.gray900.xs.pl4.mb1.$} dangerouslySetInnerHTML={{ __html: formatJobLogNote(content) }}></div>
        <div css={Css.dn.if(nonEmpty(note.jobLogImages!)).mb1.db.pl4.$}>
          <JobLogImages
            compact
            parentId={note.id}
            description={note.content}
            images={note.jobLogImages!}
            display={"horizontal"}
          />
        </div>
        <div css={Css.df.fdr.jcsb.mt1.$}>
          <div css={Css.df.$}>
            <Avatar src={internalUser.avatar} name={internalUser.name} size="sm" />
            <div css={Css.df.fdc.jcc.mr1.$}>
              <span css={Css.gray700.tiny.pl1.mtPx(4).mbPx(4).$}>{detailsPhrase}</span>
            </div>
          </div>
          <CommentCountBubble streams={note.streams} />
        </div>
      </div>
    </div>
  );
}

// Helper function to format the job log note content (since it's stored as a mix of markdown + HTML).
// This is a temporary fix until mobile squad can take a look
function formatJobLogNote(content: string) {
  return content
    .replace(/&amp;quot;/g, '"') // Double quote
    .replace(/&amp;#39;/g, "'") // Apostrophe
    .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>") // Bold text
    .replace(/-\s(.*?)($|<br>)/g, "<li>$1</li>"); // List items
}
