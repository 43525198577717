import { useApolloClient } from "@apollo/client";
import { Button, Css, ModalBody, ModalFooter, ModalHeader, useModal, useTestIds } from "@homebound/beam";
import { BillPageDocument, LienWaiversPageDocument, useSavePandaDocMutation } from "src/generated/graphql-types";

export type PandaDocConfirmationModalProps = {
  ownerId: string;
  documentName?: string;
  isReUpload?: boolean;
};

export function PandaDocConfirmationModal({
  ownerId,
  isReUpload,
  documentName = "document",
}: PandaDocConfirmationModalProps) {
  const [savePandaDoc] = useSavePandaDocMutation();
  const { closeModal, openModal } = useModal();
  const testIds = useTestIds({}, "dcpdcm");
  const client = useApolloClient();

  return (
    <>
      <ModalHeader>{`PandaDoc ${isReUpload ? "Re-Upload" : "Upload"}`}</ModalHeader>
      <ModalBody>
        <div>
          <p css={Css.baseMd.gray900.mb2.$}>
            {isReUpload
              ? `Are you sure you want to re-upload this ${documentName} to PandaDoc?`
              : `Are you sure you want to upload this ${documentName} to PandaDoc?`}
          </p>
          <p css={Css.sm.gray700.$}>
            {isReUpload
              ? `This action will rewrite over the old ${documentName}. You will no longer be able to access that file.`
              : `To make changes afterwards, you will need to re-upload this ${documentName} to PandaDoc.`}
          </p>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" label="Cancel" onClick={closeModal} />
        <Button
          label={isReUpload ? "Re-Upload" : "Upload"}
          onClick={async () => {
            try {
              await savePandaDoc({ variables: { input: { ownerId } } });
              // close previous modal prior to showing success modal to remove from stack
              if (isReUpload) closeModal();
              // open success modal
              openModal({
                content: <PandaDocSuccessModal documentName={documentName} isReUpload={isReUpload} />,
              });
            } catch (e) {
              console.error(e);
              // close modal if error
              closeModal();
            }
            await client.refetchQueries({ include: [BillPageDocument, LienWaiversPageDocument] });
          }}
          {...testIds.PandaDocUploadTrigger}
        />
      </ModalFooter>
    </>
  );
}

function PandaDocSuccessModal({ documentName, isReUpload }: { documentName: string; isReUpload?: boolean }) {
  const { closeModal } = useModal();

  return (
    <>
      <ModalHeader>{`PandaDoc ${isReUpload ? "Re-Upload" : "Upload"}`}</ModalHeader>
      <ModalBody>
        <div>
          <p css={Css.baseMd.gray900.mb2.$}>{documentName} uploading!</p>
          <p css={Css.sm.gray700.$}>Check back in a few minutes to view this {documentName} in PandaDoc.</p>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button label="Close" onClick={closeModal} variant="tertiary" />
      </ModalFooter>
    </>
  );
}
