import {
  ButtonMenu,
  Css,
  FilterDefs,
  Filters,
  GridColumn,
  GridDataRow,
  GridStyle,
  GridTable,
  ModalProps,
  MultiSelectField,
  Palette,
  RowStyles,
  Tag,
  Tooltip,
  cardStyle,
  column,
  multiFilter,
  useComputed,
  useFilter,
  useGridTableApi,
  useModal,
  useSnackbar,
} from "@homebound/beam";
import { ObjectState, useFormState } from "@homebound/form-state";
import { useEffect, useMemo, useState } from "react";
import { createPlanPackageUrl } from "src/RouteUrls";
import { Icon, SearchBox, useNavigationCheck } from "src/components";
import { StepLayout } from "src/components/stepper/StepLayout";
import { NextStepButton } from "src/components/stepper/useStepperWizard/NextStepButton";
import {
  GlobalOptionTypeStatus,
  PlanPackage_AddReadyPlanOptionFragment,
  usePlanPackage_AddOptionsPageQuery,
  usePlanPackage_SaveReadyPlanOptionsMutation,
} from "src/generated/graphql-types";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { disableBasedOnPotentialOperation } from "src/routes/components/PotentialOperationsUtils";
import { TableActions } from "src/routes/layout/TableActions";
import { PlanPackageEditorHeader } from "src/routes/libraries/plan-package/stepper/components/PlanPackageEditorHeader";
import { fail } from "src/utils";
import {
  ConfigureReadyPlanOption,
  ConfigureReadyPlanOptionsForm,
  configureRpoConfig,
  preProcessUpdatedOptions,
} from "./utils";

type ConfigureOptionsStepProps = {
  id: string;
  versionId: string;
  setStepDirty: (dirty: boolean) => void;
};

export function ConfigureOptionsStep({ id, versionId, setStepDirty }: ConfigureOptionsStepProps) {
  const { triggerNotice } = useSnackbar();
  const [saveRpos] = usePlanPackage_SaveReadyPlanOptionsMutation();
  const onSave = async () => {
    // Save Option Configuration
    if (formState.readyPlanOptions.dirty && rpQuery.data?.readyPlan) {
      const changedOptions = preProcessUpdatedOptions(formState);
      await saveRpos({
        variables: { input: { id: formState.readyPlanOptions.value.first?.readyPlanId, options: changedOptions } },
      });
      formState.commitChanges();
    }

    triggerNotice({
      message: `Your Ready Plan Options Configuration has been updated!`,
    });
  };

  const rpQuery = usePlanPackage_AddOptionsPageQuery({ variables: { id, versionId } });
  const disabled = disableBasedOnPotentialOperation(rpQuery.data?.readyPlan.canEdit);
  const optionsTypes = useMemo(
    () =>
      rpQuery.data?.globalOptionTypes.filter(
        (got) => got.globalOptionsCount > 0 && got.status !== GlobalOptionTypeStatus.Archived,
      ) ?? [],
    [rpQuery.data?.globalOptionTypes],
  );

  const [searchFilter, setSearchFilter] = useState<string | undefined>();
  const filterDefs: FilterDefs<OptionTypeFilter> = useMemo(() => {
    const option = multiFilter({
      label: "Type",
      options: optionsTypes,
      getOptionLabel: ({ name }) => name,
      getOptionValue: ({ id }) => id,
    });
    return { option };
  }, [optionsTypes]);

  const { filter, setFilter } = useFilter({ filterDefs });

  const formState = useFormState({
    config: configureRpoConfig,
    init: {
      query: rpQuery,
      map: ({ readyPlan }) => ({
        readyPlanOptions: readyPlan.options.map((o) => ({
          id: o.id,
          name: o.name,
          active: o.active,
          readyPlanId: readyPlan.id,
          optionTypeId: o.type.id,
          globalTypeName: o.type.name,
          globalOptionCode: o.globalOption.code,
          location: o.location,
          optionGroup: o.optionGroup,
          optionPrerequisiteIds: o.optionPrerequisites.map(({ id }) => id),
          optionConflictIds: o.optionConflicts.map(({ id }) => id),
          optionConflictChildIds: o.optionConflictChildren.map(({ id }) => id),
          optionPrereqChildIds: o.optionPrereqChildren.map(({ id }) => id),
          order: o.order,
        })),
      }),
    },
  });

  const isDirty = useComputed(() => formState.dirty, [formState.dirty]);
  useEffect(() => {
    setStepDirty(isDirty);
  }, [setStepDirty, isDirty]);
  const { useRegisterNavigationCheck } = useNavigationCheck();
  useRegisterNavigationCheck(() => !formState.dirty, [formState]);

  return (
    <StepLayout
      header={<PlanPackageEditorHeader title="Configure Options" />}
      body={
        <div css={Css.pt3.pb0.df.fdc.fg1.$}>
          <TableActions>
            <div css={Css.df.gap1.jcfe.$}>
              <Filters<OptionTypeFilter> filter={filter} filterDefs={filterDefs} onChange={setFilter} />
            </div>
            <div css={Css.df.aic.gap2.$}>
              <SearchBox onSearch={setSearchFilter} clearable updateQueryString={false} />
            </div>
          </TableActions>

          <div css={Css.fg1.pb1.$}>
            <ConfigureOptionsTable
              disabled={disabled}
              collapsedGroups={false}
              formState={formState}
              readyPlanOptions={rpQuery?.data?.readyPlan?.options}
              filter={filter}
              searchFilter={searchFilter}
            />
          </div>

          <NextStepButton
            label="Continue"
            disabled={false}
            onClick={onSave}
            onCloseReturnUrl={createPlanPackageUrl(id, versionId)}
            exitButton={{
              variant: "secondary",
              label: "Save & Exit",
              onClick: onSave,
            }}
          />
        </div>
      }
    />
  );
}

function ConfigureOptionsTable(props: ConfigureOptionsTableProps) {
  const { formState, readyPlanOptions = [], filter, searchFilter, collapsedGroups, disabled } = props;
  const rpos = useComputed(() => formState.readyPlanOptions.rows, [formState]);

  // technically this gets group order for every rpo, rather than every group
  // but since we're only using it to trigger a re-render it doesn't really matter
  const groupOrders = useComputed(() => rpos.flatMap((rpo) => rpo.optionGroup.order.value), [rpos]);
  const rpoOrders = useComputed(() => rpos.map((rpo) => rpo.order.value), [rpos]);
  const { openModal } = useModal();

  const rows: GridDataRow<Row>[] = useComputed(
    () => createRows(rpos, filter),

    [rpos, filter, groupOrders, rpoOrders],
  );

  const api = useGridTableApi<Row>();

  useEffect(() => {
    const [collapsed, expanded] = rows.partition((r) => api.isCollapsedRow(r.id));
    const target = collapsedGroups ? expanded : collapsed;
    target.forEach((r) => api.toggleCollapsedRow(r.id));
  }, [api, collapsedGroups, rows]);

  return (
    <GridTable
      api={api}
      rows={rows}
      columns={createColumns(readyPlanOptions, formState, openModal, disabled)}
      filter={searchFilter}
      style={tableStyles}
      rowStyles={rowStyles}
      as="virtual"
    />
  );
}

export enum Columns {
  Icon,
  Code,
  Name,
  Location,
  Type,
  Conflicts,
  Actions,
}

function createColumns(
  rpos: PlanPackage_AddReadyPlanOptionFragment[],
  formState: ObjectState<ConfigureReadyPlanOptionsForm>,
  openModal: (props: ModalProps) => void,
  disabled: React.ReactNode | boolean,
): GridColumn<Row>[] {
  return [
    column<Row>({
      id: `${Columns.Icon}`,
      header: "",
      option: (data) => ({
        content: <PrereqsButton rpos={rpos} formState={formState} data={data} disabled={disabled} />,
        value: "",
        css: Css.pr0.wPx(40).$,
      }),
      dependency: ({ rpo }) => ({
        content: <PrereqsButton rpos={rpos} formState={formState} data={rpo} disabled={disabled} />,
        value: "",
        css: Css.pr0.wPx(40).$,
      }),
      w: "40px",
      isAction: true,
    }),
    column<Row>({
      id: `${Columns.Code}`,
      header: "Code",
      option: (rpo) => ({
        content: () => (
          <>
            <span css={Css.sm.gray700.$}>{rpo.value.globalOptionCode}</span>
          </>
        ),
        value: rpo.value.globalOptionCode,
      }),
      dependency: ({ rpo }) => ({
        content: () => (
          <>
            <span css={Css.sm.gray700.$}>{rpo.value.globalOptionCode}</span>
          </>
        ),
        value: rpo.value.globalOptionCode,
      }),
      w: "130px",
      isAction: true,
    }),
    column<Row>({
      id: `${Columns.Name}`,
      header: "Name",
      option: (rpo) => ({
        content: () => (
          <span css={Css.sm.color("black").$}>
            {rpo.value.optionGroup?.isMultiOptionGroup && <span css={Css.smSb.$}>{rpo.value.optionGroup.name}: </span>}
            {rpo.value.name}
          </span>
        ),
        value: `${rpo.value.optionGroup?.isMultiOptionGroup ? `${rpo.value.optionGroup.name}: ` : ""}${rpo.value.name}`,
        css: Css.sm.$,
      }),
      dependency: ({ rpo, hasMoreThanOnePrereq }) => ({
        content: () => (
          <>
            {hasMoreThanOnePrereq && (
              <Tooltip title="This is a child of other options">
                <Icon icon="copy" color={Palette.Gray400} pxSize={13} />
              </Tooltip>
            )}
            <span css={Css.sm.color("black").mlPx(4).$}>
              {rpo.value.optionGroup?.isMultiOptionGroup && (
                <span css={Css.smSb.$}>{rpo.value.optionGroup.name}: </span>
              )}
              {rpo.value.name}
            </span>
          </>
        ),
        value: `${rpo.value.optionGroup?.isMultiOptionGroup ? `${rpo.value.optionGroup.name}: ` : ""}${rpo.value.name}`,
        css: Css.sm.$,
      }),
      w: 4,
    }),
    column<Row>({
      id: `${Columns.Location}`,
      header: "Location",
      option: (rpo) => ({
        content: () =>
          rpo.value.location ? (
            <div css={Css.df.gap1.$}>
              <Icon icon="mapPin" pxSize={12} />
              <span css={Css.tinySb.$}>{rpo.value.location?.name}</span>
            </div>
          ) : (
            <></>
          ),
        value: rpo.value.location?.name,
        css: Css.sm.$,
      }),
      dependency: ({ rpo }) => ({
        content: () =>
          rpo.value.location ? (
            <div css={Css.df.gap1.$}>
              <Icon icon="mapPin" pxSize={12} />
              <span css={Css.tinySb.$}>{rpo.value.location?.name}</span>
            </div>
          ) : (
            <></>
          ),
        value: rpo.value.location?.name,
        css: Css.sm.$,
      }),
    }),
    column<Row>({
      id: `${Columns.Type}`,
      header: "Type",
      option: (rpo) => ({
        content: () => rpo.value?.globalTypeName && <Tag text={rpo.value?.globalTypeName} />,
        value: rpo.value.globalTypeName,
      }),
      dependency: ({ rpo }) => ({
        content: () => rpo.value?.globalTypeName && <Tag text={rpo.value?.globalTypeName} />,
        value: rpo.value.globalTypeName,
      }),
    }),
    column<Row>({
      id: `${Columns.Conflicts}`,
      header: "Conflicts",
      option: (rpo) => ({
        content: () => <ConflictsSelectField rpo={rpo} rpos={rpos} formState={formState} disabled={disabled} />,
        value: rpo.optionConflictIds.value?.toString(),
        css: Css.pr0.$,
      }),
      dependency: ({ rpo }) => ({
        content: () => <ConflictsSelectField rpo={rpo} rpos={rpos} formState={formState} disabled={disabled} />,
        value: rpo.optionConflictIds.value?.toString(),
        css: Css.pr0.$,
      }),
    }),
    column<Row>({
      id: `${Columns.Actions}`,
      header: "",
      option: (rpo) => ({
        content: () => (
          <ButtonMenu
            disabled={disabled}
            trigger={{ icon: "verticalDots", size: "sm", inc: 2 } as any}
            items={[
              {
                label: "Remove option",
                onClick: () => cascadeRemove(formState, rpo, openModal),
              },
            ]}
          />
        ),
        value: "",
        css: Css.pr0.$,
      }),
      dependency: ({ rpo, parent }) => ({
        content: () => (
          <ButtonMenu
            disabled={disabled}
            trigger={{ icon: "verticalDots", size: "sm", inc: 2 } as any}
            items={[
              {
                label: "Remove option",
                onClick: () => cascadeRemove(formState, rpo, openModal),
              },
              {
                label: "Remove dependency",
                onClick: () => removeParentRelation(formState, rpo, parent),
              },
            ]}
          />
        ),
        value: "",
        css: Css.pr0.$,
      }),
      w: "40px",
      isAction: true,
    }),
  ];
}

type PrereqsButtonProps = {
  rpos: PlanPackage_AddReadyPlanOptionFragment[];
  formState: ObjectState<ConfigureReadyPlanOptionsForm>;
  data: ObjectState<ConfigureReadyPlanOption>;
  disabled: React.ReactNode | boolean;
};

function cascadeRemove(
  formState: ObjectState<ConfigureReadyPlanOptionsForm>,
  rpo: ObjectState<ConfigureReadyPlanOption>,
  openModal: (props: ModalProps) => void,
) {
  const rpoStateById = formState.readyPlanOptions.rows.groupBy(
    (rpoState) => rpoState.id.value ?? fail("rpo.id should always be set"),
  );
  rpo.active.set(false);
  // clear its prereqs
  removeParentRelation(formState, rpo);

  // check if it has any children
  const dependentRpos =
    rpo.optionPrereqChildIds.value
      ?.map((id) => rpoStateById[id]?.[0])
      // if any of the children have only this as a parent, add them to be removed
      .filter((rpoState) => (rpoState.optionPrerequisiteIds.value?.length ?? 0) === 1)
      .flatMap((rpoState) => getPrereqsRpos(rpoStateById, rpoState)) ?? [];
  // ask the user if they also want to remove the gathered children

  if (dependentRpos.length > 0) {
    // if we cannot delete the rpo this is the only removed relation, we need to remove the reference to the parent and keep their childs
    dependentRpos.forEach((rpoState) => {
      removeParentRelation(formState, rpoState, rpo);
    });
    openModal({
      content: (
        <ConfirmationModal
          confirmationMessage={
            <div css={Css.df.fdc.gap1.$}>
              <span>
                <b css={Css.baseBd.$}>{rpo.name.value}</b> was removed
              </span>
              <span>Do you want to also remove the dependent options?</span>
              <ul>
                {dependentRpos.map((rpoState) => (
                  <li key={rpoState.id.value}>{rpoState.value.name}</li>
                ))}
              </ul>
            </div>
          }
          onConfirmAction={() => {
            // if we do remove all the childs
            dependentRpos.forEach((rpoState) => {
              // delete the dependent option
              rpoState.active.set(false);
              removeParentRelation(formState, rpoState);
              removeChildRelations(formState, rpoState);
            });
          }}
          title="Remove Dependent Options"
          label="Remove Options"
          danger={true}
        />
      ),
    });
  }
}

function PrereqsButton(props: PrereqsButtonProps) {
  const { rpos, formState, data, disabled } = props;
  const items = recursivePrereqCheck(formState, rpos, data);
  return (
    <ButtonMenu
      disabled={disabled}
      trigger={{ icon: "subDirectoryRight", color: Palette.Gray400, size: "sm", inc: 2 } as any}
      items={items.map((rpo) => ({
        label: rpo.displayName,
        onClick: () => {
          data.optionPrereqChildIds.set([...(data.optionPrereqChildIds.value ?? []), rpo.id]);
          const prereqRpoState = formState.readyPlanOptions.rows.find((rpoState) => rpoState.value.id === rpo.id);
          if (prereqRpoState && data.id.value) {
            prereqRpoState?.optionPrerequisiteIds.set([
              ...(prereqRpoState.optionPrerequisiteIds.value ?? []),
              data.id.value,
            ]);
          }
        },
      }))}
    />
  );
}

type RpoSelectFieldProps = {
  rpo: ObjectState<ConfigureReadyPlanOption>;
  rpos: PlanPackage_AddReadyPlanOptionFragment[];
  disabled: React.ReactNode;
};
function ConflictsSelectField(props: RpoSelectFieldProps & { formState: ObjectState<ConfigureReadyPlanOptionsForm> }) {
  const { rpo, rpos, formState, disabled } = props;
  // We're showing both parent and child conflicts together since the relationship is bidirectional.
  // For example, if A conflicts with B, then B also conflicts with A.
  const ids = (rpo.optionConflictIds.value ?? []).concat(rpo.optionConflictChildIds.value ?? []);
  return (
    <div
      css={
        Css.w100.if("optionConflictIds" in rpo.changedValue || "optionConflictChildIds" in rpo.changedValue).bss.bw2.br4
          .bcGreen200.$
      }
    >
      <MultiSelectField
        disabled={disabled}
        label="Option Conflicts"
        labelStyle="hidden"
        values={ids}
        onSelect={(newIds) => updateRpoConflicts(newIds, ids, rpo, formState)}
        options={mapToRpoSelectFieldOptions(rpo, rpos)}
        getOptionValue={(o) => o.id}
        getOptionLabel={(o) => o.name}
        placeholder="Add Conflicts"
        compact
        fullWidth
      />
    </div>
  );
}

function mapToRpoSelectFieldOptions(
  rpo: ObjectState<ConfigureReadyPlanOption>,
  rpos: PlanPackage_AddReadyPlanOptionFragment[],
) {
  return rpos
    .filter((opt) => opt.optionGroup.id !== rpo.optionGroup.value?.id)
    .sortBy((opt) => opt.name)
    .map((opt) => ({
      id: opt.id,
      name: opt.displayName,
    }));
}

// Updates all affected RPO rows when a conflict is added or removed.
// Both newIds and oldIds are a combination of parent and child conflicts (i.e. optionConflictIds and optionConflictChildIds).
function updateRpoConflicts(
  newIds: string[],
  oldIds: string[],
  rpo: ObjectState<ConfigureReadyPlanOption>,
  formState: ObjectState<ConfigureReadyPlanOptionsForm>,
) {
  // Since this function is only called when a single conflict is added or removed,
  // we can safely use the ID array lengths to determine which action happened.
  if (newIds.length > oldIds.length) {
    const idsToAdd = newIds.filter((id) => !oldIds.includes(id));
    rpo.optionConflictIds.set(rpo.optionConflictIds.value?.concat(idsToAdd) ?? idsToAdd);
    formState.readyPlanOptions.rows
      .filter((opt) => opt.id.value && idsToAdd.includes(opt.id.value))
      .forEach((opt) =>
        opt.optionConflictChildIds.set(opt.optionConflictChildIds.value?.concat(rpo.id.value!) ?? [rpo.id.value!]),
      );
  } else if (newIds.length < oldIds.length) {
    const idsToRemove = oldIds.filter((id) => !newIds.includes(id));
    // Since we don't know if the conflict was removed from the parent or child conflicts, we need to set both
    rpo.optionConflictIds.set(rpo.optionConflictIds.value?.filter((id) => !idsToRemove.includes(id)) ?? []);
    rpo.optionConflictChildIds.set(rpo.optionConflictChildIds.value?.filter((id) => !idsToRemove.includes(id)) ?? []);
    formState.readyPlanOptions.rows
      ?.filter((opt) => opt.id.value && idsToRemove.includes(opt.id.value))
      .forEach((opt) => {
        opt.optionConflictIds.set(opt.optionConflictIds?.value?.filter((id) => id !== rpo.id.value!) ?? []);
        opt.optionConflictChildIds.set(opt.optionConflictChildIds?.value?.filter((id) => id !== rpo.id.value!) ?? []);
      });
  }
}

function createRows(
  rpos: readonly ObjectState<ConfigureReadyPlanOption>[],
  filter: OptionTypeFilter,
): GridDataRow<Row>[] {
  const filteredRpos = Array.from(rpos)
    // only active ones
    .filter((rpo) => rpo.active.value)
    // only matching with the filter
    .filter((rpo) =>
      filter.option ? filter.option?.includes(rpo.optionTypeId.value ?? fail("should always be set")) : true,
    );
  const rposById = filteredRpos.groupBy((rpo) => rpo.id.value ?? "");
  // sort by group order
  const rows = filteredRpos
    // we leave at the main level only the ones that don't have any ACTIVE prereqs (it might have the relation but the parent could be archived)
    .filter((rpo) => !rpo.value.optionPrerequisiteIds?.some((id) => rposById[id]?.[0]))
    .sort(
      (rpoA, rpoB) =>
        (rpoA.optionGroup.value?.order ?? Number.POSITIVE_INFINITY) -
        (rpoB.optionGroup.value?.order ?? Number.POSITIVE_INFINITY),
    )
    .map((rpo) => {
      return {
        kind: "option" as const,
        id: `${rpo.id.value}`,
        data: rpo,
        children: getPrereqsRow(rposById, rpo, rpo.value.id ?? "", rpo.value.optionPrereqChildIds),
      };
    });
  return rows;
}

function getPrereqsRow(
  rpos: Record<string, ObjectState<ConfigureReadyPlanOption>[]>,
  parent: ObjectState<ConfigureReadyPlanOption>,
  parentRpoId: string,
  prereqIds?: string[] | null,
): Row[] | undefined {
  return prereqIds
    ?.map((prereqId) => {
      const [prereqRpo] = rpos[prereqId] ?? [];
      if (!prereqRpo) return undefined;
      return {
        kind: "dependency" as const,
        id: `${parentRpoId}_${prereqId}`,
        data: {
          rpo: prereqRpo,
          parent,
          hasMoreThanOnePrereq: (prereqRpo.value.optionPrerequisiteIds?.length ?? 0) > 1,
        },
        children: getPrereqsRow(rpos, prereqRpo, `${parentRpoId}_${prereqId}`, prereqRpo.value.optionPrereqChildIds),
      };
    })
    .compact();
}

type HeaderRow = { kind: "header"; id: string; data: undefined };

type OptionRow = {
  kind: "option";
  id: string;
  data: ObjectState<ConfigureReadyPlanOption>;
};
type DependencyRow = {
  kind: "dependency";
  id: string;
  data: {
    rpo: ObjectState<ConfigureReadyPlanOption>;
    parent: ObjectState<ConfigureReadyPlanOption>;
    hasMoreThanOnePrereq: boolean;
  };
};

type Row = HeaderRow | OptionRow | DependencyRow;

type OptionTypeFilter = { option?: string[]; location?: string[] };
type ConfigureOptionsTableProps = {
  formState: ObjectState<ConfigureReadyPlanOptionsForm>;
  readyPlanOptions?: PlanPackage_AddReadyPlanOptionFragment[];
  searchFilter?: string;
  filter: OptionTypeFilter;
  collapsedGroups: boolean;
  disabled: React.ReactNode | boolean;
};

const tableStyles: GridStyle = {
  ...cardStyle,
  cellCss: {
    ...cardStyle.cellCss,
    ...Css.aic.pPx(12).$,
  },
  presentationSettings: {
    borderless: false,
  },
};

const rowStyles: RowStyles<Row> = {
  option: {
    rowCss: Css.bgWhite.bn.boxShadow("0px 4px 8px 0px #35353514").mt1.borderRadius("8px").hPx(44).$,
    cellCss: Css.bgWhite.$,
  },
  dependency: {
    rowCss: ({ data }) => {
      const { rpo, parent } = data;
      return Css.bgWhite
        .boxShadow("0px 4px 8px 0px #35353514")
        .mt1.borderRadius("8px")
        .hPx(44)
        .bn.bw2.if("optionPrerequisiteIds" in rpo.changedValue && "optionPrereqChildIds" in parent.changedValue).bw2.bss
        .bcGreen200.onHover.bcGreen200.$;
    },
    cellCss: Css.bgWhite.$,
  },
};

export function recursivePrereqCheck(
  formState: ObjectState<ConfigureReadyPlanOptionsForm>,
  rposToFilter: PlanPackage_AddReadyPlanOptionFragment[],
  currentRpo: ObjectState<ConfigureReadyPlanOption>,
) {
  let result = rposToFilter.filter(
    (rpo) =>
      rpo.active &&
      // not already a prereq
      !currentRpo.optionPrereqChildIds.value?.includes(rpo.id) &&
      // not the same option
      rpo.id !== currentRpo.id.value &&
      // not from the same group
      rpo.optionGroup.id !== currentRpo.optionGroup.value?.id &&
      // and is not a cyclic dependency
      !currentRpo.optionPrerequisiteIds.value?.includes(rpo.id) &&
      // the options don't conflict
      !currentRpo.optionConflictIds.value?.includes(rpo.id) &&
      !currentRpo.optionConflictChildIds.value?.includes(rpo.id),
  );
  if (currentRpo.optionPrerequisiteIds.value?.nonEmpty) {
    for (const prereqChild of currentRpo.optionPrerequisiteIds.value) {
      const prereqParentRpo = formState.readyPlanOptions.rows.find((rpo) => rpo.id.value === prereqChild);
      if (prereqParentRpo) {
        result = recursivePrereqCheck(formState, result, prereqParentRpo);
      }
    }
  }

  return result;
}

function getPrereqsRpos(
  rpoStateById: Record<string, ObjectState<ConfigureReadyPlanOption>[]>,
  parentRpo?: ObjectState<ConfigureReadyPlanOption>,
): ObjectState<ConfigureReadyPlanOption>[] {
  const childPrereqs = parentRpo?.optionPrereqChildIds.value?.flatMap((id) =>
    getPrereqsRpos(rpoStateById, rpoStateById[id]?.[0]),
  );
  return [...(parentRpo ? [parentRpo] : []), ...(childPrereqs ?? [])];
}

function removeParentRelation(
  formState: ObjectState<ConfigureReadyPlanOptionsForm>,
  rpo: ObjectState<ConfigureReadyPlanOption>,
  parent?: ObjectState<ConfigureReadyPlanOption>,
) {
  // remove parent ref to this rpo as parent, if no parent provided, we delete all refs
  rpo.optionPrerequisiteIds.set(parent ? rpo.optionPrerequisiteIds.value?.filter((id) => id !== parent.id.value) : []);
  // remove parent ref to this rpo as children, if no parent provided, we delete all refs
  const parentState = formState.readyPlanOptions.rows.find((rpoState) => rpoState.id.value === parent?.id.value);
  if (parentState) {
    parentState.optionPrereqChildIds.set(parentState.optionPrereqChildIds.value?.filter((id) => id !== rpo.id.value));
  } else {
    formState.readyPlanOptions.rows.forEach((rpoState) => {
      if (rpoState.optionPrereqChildIds.value?.includes(rpo.id.value ?? fail("rpo.id should always be set"))) {
        rpoState.optionPrereqChildIds.set(rpoState.optionPrereqChildIds.value?.filter((id) => id !== rpo.id.value));
      }
    });
  }
}

function removeChildRelations(
  formState: ObjectState<ConfigureReadyPlanOptionsForm>,
  rpo: ObjectState<ConfigureReadyPlanOption>,
) {
  // we remove their ref to their children
  rpo.optionPrereqChildIds.set([]);
  // and we remove the children ref to them
  formState.readyPlanOptions.rows.forEach((rpoState) => {
    if (rpoState.optionPrerequisiteIds.value?.includes(rpo.id.value ?? fail("rpo.id should always be set"))) {
      rpoState.optionPrerequisiteIds.set(rpoState.optionPrerequisiteIds.value?.filter((id) => id !== rpo.id.value));
    }
  });
}
