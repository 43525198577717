import { Css, useBreakpoint, useTestIds } from "@homebound/beam";
import { Link } from "react-router-dom";
import { FormattedDate } from "src/components";
import { BillPage_BillFragment } from "src/generated/graphql-types";
import { isDefined } from "src/utils";

export const BillScheduleTasks = ({ bill }: { bill: BillPage_BillFragment }) => {
  const tasksData = bill.lineItems
    .map((bli) => ({ task: bli.scheduleTask, createdBy: bli.bill.createdBy }))
    .filter((data) => isDefined(data.task))
    .uniqueByKey("task");

  const { sm } = useBreakpoint();
  const tids = useTestIds({});

  return (
    <div css={Css.df.fdc.w50.$}>
      <span css={Css.sm.gray700.mb1.$}>Schedule Tasks</span>
      {tasksData.nonEmpty ? (
        tasksData.map((data, index) => (
          <div key={data.task ? data.task.id : `empty-task-${index}`}>
            {data.task && (
              <div css={Css.df.fdc.jcc.w100.mb1.br8.bgGray100.mh("4rem").$}>
                <div css={Css.dg.cg1.rg1.tiny.bgGray100.p2.aic.br8.if(sm).gtc("1fr").else.gtc("repeat(3, auto)").$}>
                  <div css={Css.df.fdr.tal.aic.wbbw.$}>
                    <Link target="_blank" to={data.task.blueprintUrl.path} data-testid="taskItem">
                      {data.task.name}
                    </Link>
                  </div>

                  <div css={Css.df.fdc.wbbw.$} {...tids.taskCompletedOn}>
                    {data.task.completedAt ? (
                      <div>
                        Completed on
                        <FormattedDate date={data.task.completedAt || undefined} dateFormatStyle="short" />
                      </div>
                    ) : (
                      <div>{data.task.status.name}</div>
                    )}
                  </div>
                  <div css={Css.wbbw.df.fdc.$}>
                    <div>Completed by</div>
                    <div {...tids.taskCompletedBy}>{data.createdBy?.name}</div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))
      ) : (
        <span css={Css.tiny.mb1.$}>No tasks associated to bill</span>
      )}
    </div>
  );
};
