import { Route, Switch } from "react-router-dom";
import { NotFound } from "src/routes/NotFound";
import {
  EstimateExportPdf,
  HomeownerChangeOrderPdf,
  ProjectItemScopeOfWorkPdf,
  ScheduleExportPdf,
  ScheduleOfValuesPdf,
} from "src/routes/pdfs";
import { DevelopmentCommitmentPdf } from "src/routes/pdfs/DevelopmentCommitmentPdf";
import { ToDosPdf } from "src/routes/pdfs/ToDosPdf";
import { TradeScopeOfWorkPdf } from "src/routes/pdfs/TradeScopeOfWorkPdf";
import { TradeSpecificAgreement } from "src/routes/pdfs/TradeSpecificAgreement";
import { LotSequenceSheetPdf } from "src/routes/pdfs/lot-sequence-sheet/LotSequenceSheetPdf";
import {
  bidCommitmentChangeOrderPdfPath,
  bidCommitmentPdfPath,
  billPdfPath,
  commitmentChangeOrderPdfPath,
  commitmentPdfPath,
  developmentCommitmentPdfPath,
  developmentContractPdfPath,
  estimateExportPath,
  finishSchedulePdfPath,
  finishScheduleVisualModePdfPath,
  homeownerChangeOrderPath,
  invoicePandaDocPdfPath,
  invoicePdfPath,
  lienWaiverPdfPath,
  lotSequenceSheetPdfPath,
  lotSummaryDetailsPdfPath,
  projectItemScopeOfWorkPath,
  scheduleExportPath,
  scheduleOfValuesPath,
  toDoPdfPath,
  tradeScopeOfWorkPath,
  tradeSpecificAgreementPath,
  tradesPunchlistPdfPath,
} from "src/routes/routesDef";
import { BillPdf } from "./routes/pdfs/BillPdf/BillPdf";
import { CommitmentOrChangeOrderPdf } from "./routes/pdfs/CommitmentOrChangeOrderPdf/CommitmentOrChangeOrderPdf";
import { DevelopmentContractPdf } from "./routes/pdfs/DevelopmentContractPdf/DevelopmentContractPdf";
import { FinishSchedulePdf } from "./routes/pdfs/FinishSchedulePdf";
import { FinishScheduleVisualModePdf } from "./routes/pdfs/FinishSchedulePdf/FinishScheduleVisualModePdf";
import { InvoicePandaDocPdf } from "./routes/pdfs/InvoicePdf/InvoicePandaDocPdf";
import { InvoicePdf } from "./routes/pdfs/InvoicePdf/InvoicePdf";
import { LienWaiverRouter } from "./routes/pdfs/LienWaiverPdf/LienWaiverRouter";
import { TradesPunchlistPdf } from "./routes/pdfs/TradesPuchlistPdf/TradesPunchlistPdf";
import { LotSummaryDetailsPdf } from "./routes/pdfs/lot-summary-details/LotSummaryDetailsPdf";

/**
 * Routes for our "rendering PDFs via React" approach.
 *
 * Note that these are not wrapped in AuthenticatedRoute because when loaded
 * by the "save HTML as PDF" headless browser, we don't have Amplify-managed
 * auth in place, we just use a bump `Authorization` header that the backend
 * understands.
 */
export function PdfRoutes() {
  return (
    <Switch>
      <Route path={scheduleOfValuesPath} exact component={ScheduleOfValuesPdf} />
      <Route path={projectItemScopeOfWorkPath} exact component={ProjectItemScopeOfWorkPdf} />
      <Route path={tradeScopeOfWorkPath} exact component={TradeScopeOfWorkPdf} />
      <Route path={homeownerChangeOrderPath} exact component={HomeownerChangeOrderPdf} />
      <Route path={estimateExportPath} exact component={EstimateExportPdf} />
      <Route path={scheduleExportPath} exact component={ScheduleExportPdf} />
      <Route path={tradeSpecificAgreementPath} component={TradeSpecificAgreement} />
      <Route path={developmentCommitmentPdfPath} component={DevelopmentCommitmentPdf} />
      <Route path={commitmentPdfPath} component={CommitmentOrChangeOrderPdf} />
      <Route path={commitmentChangeOrderPdfPath} component={CommitmentOrChangeOrderPdf} />
      <Route path={bidCommitmentPdfPath} exact component={CommitmentOrChangeOrderPdf} />
      <Route path={bidCommitmentChangeOrderPdfPath} exact component={CommitmentOrChangeOrderPdf} />
      <Route path={toDoPdfPath} exact component={ToDosPdf} />
      <Route path={developmentContractPdfPath} exact component={DevelopmentContractPdf} />
      <Route path={lotSequenceSheetPdfPath} exact component={LotSequenceSheetPdf} />
      <Route path={lotSummaryDetailsPdfPath} exact component={LotSummaryDetailsPdf} />
      <Route path={billPdfPath} exact component={BillPdf} />
      <Route path={invoicePandaDocPdfPath} exact component={InvoicePandaDocPdf} />
      <Route path={invoicePdfPath} exact component={InvoicePdf} />
      <Route path={lienWaiverPdfPath} exact component={LienWaiverRouter} />
      <Route path={finishSchedulePdfPath} exact component={FinishSchedulePdf} />
      <Route path={finishScheduleVisualModePdfPath} exact component={FinishScheduleVisualModePdf} />
      <Route path={tradesPunchlistPdfPath} exact component={TradesPunchlistPdf} />
      <Route component={NotFound} />
    </Switch>
  );
}
