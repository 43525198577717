import { FetchResult } from "@apollo/client";
import {
  Button,
  Chip,
  Css,
  ModalBody,
  ModalFooter,
  ModalHeader,
  SelectField,
  Tooltip,
  useModal,
  useSnackbar,
  useTestIds,
} from "@homebound/beam";
import { useCallback, useMemo, useState } from "react";
import { CsvUploader } from "src/components/CsvUploader";
import {
  CopyTakeoffLineItemsInput,
  SaveTakeoffLineItemInput,
  TakeoffsStore_CopyTakeoffLineItemsMutation,
  usePlanPackagesQuery,
} from "src/generated/graphql-types";
import { fail, itemTemplateStatusToTagType } from "src/utils";
import { SaveTliResult } from "../TakeoffsManagerContext";
import { useTakeoffUploader } from "./useTakeoffUploader";

export type AddItemsFromExistingPlanModalProps = {
  planPackageId: string;
  // We accept fns that know how to call GraphQL + update the cache + redirect to draft
  copyFromPlan: (input: CopyTakeoffLineItemsInput) => Promise<FetchResult<TakeoffsStore_CopyTakeoffLineItemsMutation>>;
  saveTli: (item: SaveTakeoffLineItemInput | SaveTakeoffLineItemInput[]) => SaveTliResult;
};

export function AddItemsFromExistingPlanModal(props: AddItemsFromExistingPlanModalProps) {
  const { planPackageId, copyFromPlan, saveTli } = props;
  const tid = useTestIds(props);
  const { errors, addError, handleOnDrop, handleOnClick, importDataReady } = useTakeoffUploader(saveTli);
  const { closeModal } = useModal();
  const { triggerNotice } = useSnackbar();
  const [sourcePpId, setSourcePpId] = useState<string>();
  const [usingCsv, setUsingCsv] = useState(false);
  const allPlanPackagesQuery = usePlanPackagesQuery({ variables: { filter: {} } });
  const pps = allPlanPackagesQuery.data?.planPackages.entities;
  const sourcePlanPackage = pps?.find((pp) => pp.id === sourcePpId);
  const allPlans = useMemo(
    () => pps?.filter((pp) => pp.id !== planPackageId).sortBy((pp) => pp.displayName) ?? [],
    [pps, planPackageId],
  );

  const maybeTriggerNotice = useCallback(
    (addedCount: number) => {
      if (sourcePlanPackage) {
        triggerNotice({
          message: `${addedCount} items were successfully added from ${sourcePlanPackage.displayName}`,
        });
      }
    },
    [sourcePlanPackage, triggerNotice],
  );

  const onClickHandler = useCallback(async () => {
    if (usingCsv) {
      await handleOnClick();
    } else if (sourcePlanPackage) {
      const result = await copyFromPlan({
        targetReadyPlanId: planPackageId,
        sourceReadyPlanId: sourcePlanPackage.id ?? fail("expected a ready plan"),
      });
      const items = result.data?.copyTakeoffLineItems?.copiedItems ?? [];
      maybeTriggerNotice(items.length);
    }
    closeModal();
  }, [usingCsv, sourcePlanPackage, closeModal, handleOnClick, copyFromPlan, planPackageId, maybeTriggerNotice]);

  const handleOnDropCsv = useCallback(
    async (...params: Parameters<typeof handleOnDrop>) => {
      setUsingCsv(true);
      setSourcePpId(undefined);
      await handleOnDrop(...params);
    },
    [setUsingCsv, handleOnDrop],
  );

  return (
    <>
      <ModalHeader>Import Items</ModalHeader>
      <ModalBody>
        <div css={Css.mt2.mb1.$}>
          <>
            <div css={Css.sm.gray800.$}>
              Import items from a <span css={Css.blue600.$}>.csv file</span> or from another plan
            </div>
            <label css={{ ...Css.gray700.smMd.mt3.db.$, "& button": Css.bcBlue600.bw2.bgBlue50.$ }}>
              Upload Takeoffs
              <CsvUploader errors={errors} onError={addError} onDrop={handleOnDropCsv} />
            </label>
            <div css={Css.mt2.mb1.$}>
              <SelectField
                {...tid.selectPlanPackage}
                label="Import from another plan"
                value={sourcePpId}
                onSelect={setSourcePpId}
                options={allPlans}
                getOptionLabel={(pp) => pp.displayName}
                getOptionMenuLabel={(pp) => (
                  <div css={Css.df.jcc.aic.$}>
                    <span css={Css.wPx(310).mrPx(20).$}>{pp.displayName}</span>
                    <span>
                      <Tooltip title={pp.status.name} placement="right">
                        <Chip
                          text={pp.version.displayVersion}
                          type={itemTemplateStatusToTagType(pp.status.code)}
                          compact
                        />
                      </Tooltip>
                    </span>
                  </div>
                )}
                getOptionValue={(pp) => pp.id}
                disabled={allPlanPackagesQuery.loading || usingCsv}
                placeholder={
                  usingCsv
                    ? "You are importing from a csv file"
                    : allPlanPackagesQuery.loading
                      ? "Loading plans..."
                      : "Select plan"
                }
              />
            </div>
          </>
        </div>
      </ModalBody>
      <ModalFooter>
        <div css={Css.df.gap1.$}>
          <Button label="Cancel" variant="tertiary" onClick={closeModal} />
          <Button
            label="Add Items"
            variant="primary"
            disabled={usingCsv ? !importDataReady || errors.nonEmpty : !sourcePpId}
            onClick={onClickHandler}
            {...tid.addItemsbutton}
          />
        </div>
      </ModalFooter>
    </>
  );
}
