import { ButtonModal, Css, Tag, useTestIds } from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { createBillPageUrl } from "src/RouteUrls";
import { Price, formatDate, formatToPrice } from "src/components";
import { billStatusTagMapper } from "src/components/detailPane/bill/utils";
import { BillFormLineItem, BillFormLineItemOtherBills } from "../BillEditor";
import { FormLineItem } from "../BillPage";

type BillFilteredByProjectItemId = BillFormLineItemOtherBills & { lineItemsAmount: number };

const OtherBillsList = ({ data }: { data: BillFilteredByProjectItemId[] }) => {
  const tids = useTestIds({});
  return (
    <div css={Css.$}>
      <div css={Css.p1.dg.gtc("repeat(5, auto)").gtr("auto").cg2.rg2.$}>
        <span css={Css.fwb.$}>Invoice Number</span>
        <span css={Css.fwb.$}>Status</span>
        <span css={Css.fwb.$}>Due date</span>
        <span css={Css.fwb.$}>Billed Amount</span>
        <span css={Css.fwb.$}>Paid Amount</span>
        {data.map((o) => {
          const [type, statusText] = billStatusTagMapper({
            status: o.status,
            isProcessingReversal: false,
          });
          const dateFormatted = formatDate(o.dueDate as Date | undefined, "short");
          const paidAmount = formatToPrice({
            valueInCents: o.lineItems.reduce((acc, li) => acc + li.amountInCents, 0),
          });

          const billedAmount = formatToPrice({ valueInCents: paidAmount === "$0" ? 0 : o.billedInCents });
          return (
            <Fragment key={o.id}>
              <Link to={createBillPageUrl({ idOrAdd: o.id })} target="_blank" {...tids.billName}>
                {o.name}
              </Link>
              <div css={Css.df.$}>
                <Tag type={type} text={statusText} />
              </div>
              <span css={Css.w("70px").truncate.$}>{dateFormatted}</span>
              <span>{billedAmount}</span>
              <span>{paidAmount}</span>
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export const OtherBillCell = ({
  row,
  billId,
}: {
  row: ObjectState<BillFormLineItem> | ObjectState<FormLineItem>;
  billId: string;
}) => {
  const tids = useTestIds({});
  const { projectItemId, bills } = row.value;

  const otherBills =
    bills
      ?.filter((b) => b.id !== billId)
      ?.map((b) => {
        const lineItems = b.lineItems.filter((li) => li.projectItem.id === projectItemId);
        return {
          ...b,
          lineItemsAmount: lineItems.sum((li) => li.amountInCents),
          lineItems,
        };
      }) ?? [];

  if (!row.bills?.value || row.bills.value.length === 0) {
    return (
      <div css={Css.ml(0).w100.tar.$}>
        <Price valueInCents={0} />
      </div>
    );
  }

  const otherBillsSum = otherBills?.sum((b) => b.lineItemsAmount) ?? 0;

  return (
    <div css={Css.ml(0).w100.tar.$}>
      <ButtonModal
        {...tids[row.id.value ?? "unknownId"]}
        content={<OtherBillsList data={otherBills} />}
        trigger={{ label: <Price valueInCents={otherBillsSum} /> }}
        variant="text"
        hideEndAdornment
      />
    </div>
  );
};
