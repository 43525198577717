import { Css, RouteTabWithContent, TabsWithContent } from "@homebound/beam";
import { useMemo } from "react";
import { useParams } from "react-router";
import { createPlanPackageTakeoffUrl } from "src/RouteUrls";
import { SideNavProps } from "src/components/layout/SideNav";
import { SideNavLayout } from "src/components/layout/SideNavLayout";
import { usePlanPackageTakeoffPageQuery } from "src/generated/graphql-types";
import { TakeoffsManagerItemsTab } from "src/routes/libraries/plan-package/takeoffs/TakeoffsManagerItemsTab";
import { TakeoffsHeader } from "src/routes/libraries/plan-package/takeoffs/components/TakeoffsHeader";
import { PlanPackageTakeoffParams, planPackageTakeoffsPath } from "src/routes/routesDef";
import { queryResult } from "src/utils";
import { PlanPackageTakeoffSideBar } from "./PlanPackageTakeoffSideBar";
import { TakeoffsManagerProvider, useTakeoffsStore } from "./TakeoffsManagerContext";

export function PlanPackageTakeoffPage() {
  const { planPackageId, rpavId } = useParams<PlanPackageTakeoffParams>();
  const query = usePlanPackageTakeoffPageQuery({ variables: { planPackageId, rpavId } });

  return queryResult(query, (data) => (
    <TakeoffsManagerProvider planPackage={data.planPackage}>
      <TakeoffPageContent />
    </TakeoffsManagerProvider>
  ));
}

function TakeoffPageContent() {
  const planPackage = useTakeoffsStore((state) => state.planPackage);
  const { id: ppId, version } = planPackage;

  const sideNavProps: SideNavProps = {
    sideNavConfig: [],
    top: <PlanPackageTakeoffSideBar />,
    navWidth: 265,
  };

  const tabs: RouteTabWithContent[] = useMemo(
    () => [
      {
        name: "Items",
        path: planPackageTakeoffsPath,
        href: createPlanPackageTakeoffUrl(ppId, version.id),
        render: () => <TakeoffsManagerItemsTab />,
      },
    ],
    [version.id, ppId],
  );

  return (
    <SideNavLayout sideNavProps={sideNavProps} layoutProps={{ contentCss: Css.pb3.mr2.$ }}>
      <TakeoffsHeader planPackage={planPackage} />
      <TabsWithContent tabs={tabs} includeBottomBorder contentXss={Css.pl3.pr2.$} />
    </SideNavLayout>
  );
}
