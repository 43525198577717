import { treeFilter } from "@homebound/beam";
import { useMemo } from "react";
import { useProjectLocationsTree } from "src/hooks/useProjectLocationsTree";

export function useProjectLocationsTreeFilter(projectId: string) {
  const locationOptions = useProjectLocationsTree(projectId);

  const filter = useMemo(
    () =>
      treeFilter({
        label: "Locations",
        filterBy: "root",
        getOptionLabel: (o) => o.name,
        getOptionValue: (o) => o.id,
        options: locationOptions,
      }),
    [locationOptions],
  );

  return filter;
}
