import { Css, useComputed } from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { useMemo } from "react";
import { SelectMaybeNewField } from "src/components/selectMaybeNew/SelectMaybeNewField";
import {
  MaterialAttributeDimensionType,
  MaterialCatalogMetadataDocument,
  MaterialCatalog_MaterialAttributeDimensionFragment,
  MaterialType,
  SaveMaterialVariantInput,
  useSaveMavMutation,
} from "src/generated/graphql-types";
import { disableBasedOnPotentialOperation } from "src/routes/components/PotentialOperationsUtils";
import { AddProductFormValue } from "../../design-catalog/design-package-configurator/components/AddProductPage";
import { MaterialVariantFormValue } from "./material-super-drawer/MaterialSuperDrawerContext";

type MaterialDimensionsFormProps = {
  type: MaterialType | null | undefined;
  variant: ObjectState<MaterialVariantFormValue> | ObjectState<AddProductFormValue>;
  /** This is all available dimensions within the `Item`. */
  dimensions: MaterialCatalog_MaterialAttributeDimensionFragment[];
};

export function MaterialDimensionsForm({ type, variant, dimensions }: MaterialDimensionsFormProps) {
  // Create a map of `dimensionId` to `mavId` to drive our per-dim Select
  const currentMavByDimension = useComputed(() => {
    return dimensions
      .map((d) => {
        const thisDimsValues = (d.values ?? []).keyBy((mav) => mav.id);
        // The dimension will have a lot of values; find the `formState.mavIds` for this dimension
        return [d.id, variant.mavIds.value?.map((mavId) => thisDimsValues[mavId]).compact().first?.id] as const;
      })
      .toObject();
  }, [dimensions, variant]);
  const [saveMavMutation] = useSaveMavMutation();

  const readOnly = useComputed(() => variant.readOnly, [variant]);

  const disabled = useComputed(
    () =>
      !readOnly && isMaterialVariantFormValueState(variant) && disableBasedOnPotentialOperation(variant.canEdit.value!),
    [variant, readOnly],
  );

  function updateMavSelection(dimensionId: string, mavId: string | undefined) {
    // Don't worry about updating currentMavByDimension; it will recompute
    const oldMavIdForDim = currentMavByDimension[dimensionId];
    // Remove old, add new...
    variant.mavIds.value = [
      ...(variant.mavIds.value ?? []).filter((mavId) => mavId !== oldMavIdForDim),
      ...(mavId ? [mavId] : []),
    ];
  }

  // Saves a new MAV and updates form state to select the new id
  async function saveNewMAV(dimensionId: string, textValue: string) {
    const { data } = await saveMavMutation({
      variables: { input: { dimensionId, textValue } },
      refetchQueries: [MaterialCatalogMetadataDocument],
    });
    updateMavSelection(dimensionId, data?.saveMaterialAttributeValue.materialAttributeValue.id);
  }

  // Placeholder Materials dont care about MADs with useInTakeoff: false
  const mads = useMemo(
    () =>
      dimensions
        .filter((d) => type !== MaterialType.Placeholder || d.useInTakeoff)
        .filter((mad) => !readOnly || currentMavByDimension[mad.id])
        .sortByKey("sortOrder"),
    [dimensions, type, readOnly, currentMavByDimension],
  );

  return (
    <div css={Css.df.flexWrap("wrap").gap2.aifs.$}>
      {mads.map(({ id, name, unitOfMeasure, values, type: madType }) => (
        <div key={id} css={Css.wPx(150).$}>
          <SelectMaybeNewField
            key={id}
            label={`${name}${unitOfMeasure?.abbreviation ? ` (${unitOfMeasure.abbreviation.toLowerCase()})` : ""}`}
            value={currentMavByDimension[id]}
            readOnly={readOnly}
            disabled={disabled}
            onSelect={(newMavIdForDim) => updateMavSelection(id, newMavIdForDim)}
            options={values?.map((mav) => ({ id: mav.id, name: mav.textValue })) ?? []}
            getOptionLabel={(o) => o.name}
            getOptionValue={(o) => o.id}
            onAdd={(v) => saveNewMAV(id, v)}
            helperText={
              type === MaterialType.Placeholder && madType.code === MaterialAttributeDimensionType.Number ? (
                <div css={Css.pl1.$}>eg. 10, 10-20, &gt;10</div>
              ) : null
            }
          />
        </div>
      ))}
    </div>
  );
}

function isMaterialVariantFormValueState(
  variant: ObjectState<MaterialVariantFormValue> | ObjectState<AddProductFormValue>,
): variant is ObjectState<MaterialVariantFormValue> {
  return "canEdit" in variant.value;
}

export type MaterialDimensionsFormState = ObjectState<SaveMaterialVariantInput>;
