import { Button, ButtonMenu, Css, Loader, LoadingSkeleton, MenuItem, useModal, useTestIds } from "@homebound/beam";
import { Location } from "history";
import { useCallback, useMemo } from "react";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import {
  createDraftScheduleNewTaskUrl,
  createDraftSchedulePublishUrl,
  createDraftScheduleUrl,
  createMilestoneCatalogFormUrl,
} from "src/RouteUrls";
import { useNavigationCheck } from "src/components";
import { useFeatureFlag } from "src/contexts/FeatureFlags/FeatureFlagContext";
import {
  DraftPlanScheduleQueryHookResult,
  FeatureFlagType,
  useDraftPlanScheduleQuery,
  useSaveDraftPlanScheduleMutation,
} from "src/generated/graphql-types";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { SendBulkTradePartnerAvailabilityRequestEmailModal } from "src/routes/projects/dynamic-schedules/components/SendTradePartnerAvailabilityRequestEmailModal";
import { ScheduleDraftModeHeader } from "src/routes/projects/dynamic-schedules/draft-mode/ScheduleDraftModeHeader";
import { DynamicSchedulesMilestoneTable } from "src/routes/projects/dynamic-schedules/milestone-view/DynamicSchedulesMilestone";
import { ProjectParams, dynamicSchedulesPath } from "src/routes/routesDef";
import { foldEnum, queryResult } from "src/utils";
import { FilterSubHeader, useDynamicSchedulesFilter } from "../components/DynamicSchedulesFilterModal";
import { DynamicScheduleView, ScheduleContainer, useScheduleRouteMatch } from "../utils";
import { AddDraftTasks } from "./AddDraftTasks";
import { DraftScheduleCalendar } from "./DraftScheduleCalendar";
import { DraftScheduleGantt } from "./DraftScheduleGantt";
import { DraftScheduleTable, filterPlanTasksAndMilestones } from "./DraftScheduleTable";
import { PublishDraftScheduleStepper } from "./PublishDraftScheduleStepper";
import { ScheduleDraftModeCalendarDelayFlagModal } from "./ScheduleDraftModeCalendarDelayFlagModal";
import { DraftScheduleStoreProvider, useDraftScheduleStore } from "./scheduleDraftStore";

export function ScheduleDraftMode() {
  return (
    <DraftScheduleStoreProvider>
      <ScheduleDraftModeContent />
    </DraftScheduleStoreProvider>
  );
}

function ScheduleDraftModeContent() {
  const { projectId } = useParams<ProjectParams>();
  const tid = useTestIds({}, "scheduleDraftMode");
  const { useRegisterNavigationCheck } = useNavigationCheck();

  const draftTaskChanges = useDraftScheduleStore((state) => state.draftTaskChanges);
  const userAddedScheduleFlags = useDraftScheduleStore((state) => state.userAddedScheduleFlags);
  const maybeSetInitialValues = useDraftScheduleStore((state) => state.maybeSetInitialValues);

  const query = useDraftPlanScheduleQuery({
    variables: {
      input: {
        scheduleParentId: projectId,
        draftTaskChanges: [
          ...draftTaskChanges,
          ...userAddedScheduleFlags.map(({ taskId, taskName, title, scheduleFlagReasonType, ...others }) => ({
            id: taskId,
            scheduleFlags: [{ ...others }],
          })),
        ],
      },
      projectId,
    },
    onCompleted: (data) => maybeSetInitialValues(data),
  });

  const draftModePath = useMemo(() => createDraftScheduleUrl(projectId), [projectId]);

  const draftTasks = useMemo(
    () => query.data?.draftPlanSchedule.planTasks ?? [],
    [query.data?.draftPlanSchedule.planTasks],
  );

  // Prevent navigation if there are unsaved changes UNLESS the user is navigating into a draft mode route
  useRegisterNavigationCheck(
    (currentLocation: Location, nextLocation?: Location | undefined) => {
      return (
        (draftTaskChanges.isEmpty && userAddedScheduleFlags.isEmpty) ||
        (!nextLocation
          ? true
          : nextLocation.pathname.includes(draftModePath) || nextLocation.pathname === currentLocation.pathname)
      );
    },
    [draftTaskChanges, userAddedScheduleFlags, draftModePath],
  );

  return (
    <div {...tid}>
      <Switch>
        <Route
          path={[
            dynamicSchedulesPath.draftMode,
            dynamicSchedulesPath.draftCalendar,
            dynamicSchedulesPath.draftGantt,
            dynamicSchedulesPath.draftLookahead,
            dynamicSchedulesPath.draftMilestone,
          ]}
          exact
          render={() => <DraftDataViews query={query} scheduleParentId={projectId} />}
        />
        <Route
          path={dynamicSchedulesPath.draftNewTask}
          exact
          render={() => <AddDraftTasks draftTasks={draftTasks} />}
        />
        <Route
          path={dynamicSchedulesPath.draftPublish}
          exact
          render={() => <PublishDraftScheduleStepper scheduleParentId={projectId} draftTasks={draftTasks} />}
        />
      </Switch>
    </div>
  );
}

function DraftDataViews({
  query,
  scheduleParentId,
}: {
  query: DraftPlanScheduleQueryHookResult;
  scheduleParentId: string;
}) {
  const history = useHistory();
  const showTradeComms = useFeatureFlag(FeatureFlagType.DynamicSchedulesTradeComms);
  const [saveDraftSchedule] = useSaveDraftPlanScheduleMutation();

  const draftTaskChanges = useDraftScheduleStore((state) => state.draftTaskChanges);
  const userAddedScheduleFlags = useDraftScheduleStore((state) => state.userAddedScheduleFlags);
  const draftScheduleView = useScheduleRouteMatch();
  const setUserAddedScheduleFlags = useDraftScheduleStore((state) => state.setUserAddedScheduleFlags);
  const reset = useDraftScheduleStore((state) => state.reset);
  const canSkipPublishFlow = useDraftScheduleStore((state) => state.canSkipPublishFlow);

  const { openModal } = useModal();
  const { allFilters, search } = useDynamicSchedulesFilter();

  const setIsPublishedMode = useCallback(
    () => history.push(createDraftSchedulePublishUrl(scheduleParentId)),
    [history, scheduleParentId],
  );
  const setAddTaskMode = useCallback(() => {
    history.push(createDraftScheduleNewTaskUrl(scheduleParentId));
  }, [history, scheduleParentId]);

  const publishScheduleAndResetStore = useCallback(async () => {
    await saveDraftSchedule({
      variables: {
        input: {
          scheduleParentId,
          draftTaskChanges,
        },
      },
    });
    reset();
  }, [draftTaskChanges, reset, saveDraftSchedule, scheduleParentId]);

  const onPublishClick = useCallback(() => {
    const canSkipPublish = canSkipPublishFlow(query.data?.draftPlanSchedule.planTasks ?? []);
    openModal({
      content: (
        <ConfirmationModal
          title="Are you sure you want to Publish?"
          label={canSkipPublish ? "Publish Schedule" : "Enter Publish Workflow"}
          onConfirmAction={canSkipPublish ? publishScheduleAndResetStore : setIsPublishedMode}
          confirmationMessage="Publishing will accept the simulated schedule changes and apply them to the existing schedule. Are you sure you want to continue?"
        />
      ),
    });
  }, [canSkipPublishFlow, openModal, query.data?.draftPlanSchedule.planTasks, setIsPublishedMode]);

  return (
    <>
      <ScheduleDraftModeHeader
        scheduleParentId={scheduleParentId}
        onPublishClick={onPublishClick}
        loadingDraftChanges={query.loading}
        planSchedule={query.data?.draftPlanSchedule.planSchedule}
      />

      <ScheduleContainer>
        {/* TODO: When these are cleared scroll position does not reset to first completed task/milestone as expected */}
        <FilterSubHeader
          scheduleParentId={scheduleParentId}
          leftMenuEl={
            draftScheduleView !== DynamicScheduleView.List &&
            query?.previousData &&
            query.loading && (
              <div css={Css.df.aic.gap1.$}>
                <Loader size="xs" />
                Simulating schedule changes...
              </div>
            )
          }
          rightMenuEl={
            <>
              <ActionsButton
                showTradeComms={showTradeComms}
                hasDraftChanges={!draftTaskChanges.isEmpty || !userAddedScheduleFlags.isEmpty}
                scheduleParentId={scheduleParentId}
              />
              <Button onClick={setAddTaskMode} label="Add Task" variant="secondary" />
              {draftScheduleView === DynamicScheduleView.Milestone && (
                <Button
                  disabled={!draftTaskChanges.isEmpty || !userAddedScheduleFlags.isEmpty}
                  label="Create Milestone"
                  onClick={createMilestoneCatalogFormUrl("add")}
                  variant="secondary"
                />
              )}
              {draftScheduleView === DynamicScheduleView.Calendar && (
                <Button
                  onClick={() =>
                    openModal({
                      content: (
                        <ScheduleDraftModeCalendarDelayFlagModal
                          planTasks={query.data?.draftPlanSchedule.planTasks ?? []}
                          setUserAddedScheduleFlags={setUserAddedScheduleFlags}
                        />
                      ),
                    })
                  }
                  label="Add Delay Flag"
                  variant="secondary"
                />
              )}
            </>
          }
        />
      </ScheduleContainer>
      {queryResult(query, {
        data: ({ draftPlanSchedule, enumDetails }) => {
          const { schedulingExclusionDates = [] } = draftPlanSchedule.planSchedule;
          const { filteredTasks, filteredMilestones } = filterPlanTasksAndMilestones(
            draftPlanSchedule.planTasks,
            allFilters,
            search,
          );

          const hasDraftChanges = !draftTaskChanges.isEmpty || !userAddedScheduleFlags.isEmpty;
          const sharedProps = {
            scheduleParentId,
            loading: query.loading,
            schedulingExclusionDates,
            planTasks: filteredTasks,
            planMilestones: filteredMilestones,
            hasDraftChanges,
          };
          return foldEnum(draftScheduleView, {
            List: () => (
              <DraftScheduleTable
                isLookaheadView={false}
                enumDetails={enumDetails}
                groupBy={allFilters.groupBy}
                {...sharedProps}
              />
            ),
            Lookahead: () => (
              <DraftScheduleTable
                isLookaheadView={true}
                enumDetails={enumDetails}
                groupBy={allFilters.groupBy}
                {...sharedProps}
              />
            ),
            Calendar: () => <DraftScheduleCalendar {...sharedProps} />,
            Gantt: () => <DraftScheduleGantt groupBy={allFilters.groupBy} {...sharedProps} />,
            Milestone: () => (
              <DynamicSchedulesMilestoneTable
                hasDraftChanges={hasDraftChanges}
                planMilestones={filteredMilestones}
                projectId={scheduleParentId}
              />
            ),
          });
        },
        loading: () => (
          <ScheduleContainer>
            <LoadingSkeleton columns={4} rows={10} />
          </ScheduleContainer>
        ),
      })}
    </>
  );
}

function ActionsButton({
  scheduleParentId,
  hasDraftChanges,
  showTradeComms,
}: {
  scheduleParentId: string;
  hasDraftChanges: boolean;
  showTradeComms: boolean;
}) {
  const { openModal } = useModal();

  const actionsMenuItems: MenuItem[] = useMemo(
    () => [
      {
        disabled: hasDraftChanges && "Save schedule changes first before sending bulk emails",
        label: "Bulk Send Scheduling Emails",
        onClick: () =>
          openModal({
            content: <SendBulkTradePartnerAvailabilityRequestEmailModal scheduleParentId={scheduleParentId} />,
            size: "xxl",
          }),
      },
    ],
    [hasDraftChanges, openModal, scheduleParentId],
  );

  if (!showTradeComms) return null;

  return <ButtonMenu items={actionsMenuItems} trigger={{ label: "Actions" }} />;
}
