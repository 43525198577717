import { BoundSelectField, PresentationFieldProps, SelectField } from "@homebound/beam";
import { FieldState } from "@homebound/form-state";
import { ReactNode } from "react";
import {
  Maybe,
  NamedFragment,
  TradePartner,
  TradePartnerFilter,
  useTradePartnersSelectLazyQuery,
  useTradePartnersSelectQuery,
} from "src/generated/graphql-types";

export type TradePartnerSelectFieldProps = PresentationFieldProps & {
  label?: string;
  value: Maybe<Pick<TradePartner, "id" | "name">>;
  filter?: TradePartnerFilter;
  readOnly?: boolean | ReactNode;
  onSelect: (id: string | undefined, selectedTradePartner: NamedFragment | undefined) => void;
  allowUnassigned?: boolean;
};

export function TradePartnerSelectField(props: TradePartnerSelectFieldProps) {
  const { filter, value, onSelect, readOnly, label, allowUnassigned, ...others } = props;
  const [load, { data }] = useTradePartnersSelectLazyQuery({
    variables: { filter: filter ?? {} },
    fetchPolicy: "cache-first",
  });
  const allowUnassignedProps = allowUnassigned ? { unsetLabel: "None", nothingSelectedText: "No Trade Assigned" } : {};

  return (
    <SelectField
      {...others}
      {...allowUnassignedProps}
      label={label ?? "Trade Partner"}
      readOnly={readOnly}
      onSelect={onSelect}
      options={{ current: value ?? undefined, load, options: data?.tradePartners }}
      value={value?.id}
    />
  );
}

type BoundTradePartnerSelectFieldProps = Omit<TradePartnerSelectFieldProps, "onSelect" | "value"> & {
  field: FieldState<string | null | undefined>;
  onSelect?: (id: string | undefined, selectedTradePartner: NamedFragment | undefined) => void;
};

export function BoundTradePartnerSelectField({ field, ...props }: BoundTradePartnerSelectFieldProps) {
  const { filter, label, allowUnassigned, ...others } = props;
  const { data } = useTradePartnersSelectQuery({
    variables: { filter: filter ?? {} },
    fetchPolicy: "cache-first",
  });

  const unsetLabel = allowUnassigned ? "None" : undefined;
  const nothingSelectedText = allowUnassigned ? "No Trade Assigned" : undefined;

  return (
    <BoundSelectField
      {...others}
      nothingSelectedText={nothingSelectedText}
      unsetLabel={unsetLabel}
      field={field}
      label={label ?? "Trade Partner"}
      options={data?.tradePartners ?? []}
    />
  );
}
