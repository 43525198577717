import {
  BoundSelectField,
  BoundTextField,
  Button,
  FormLines,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
} from "@homebound/beam";
import { Observer } from "mobx-react";
import { useHistory } from "react-router";
import { createInvoiceTermUrl } from "src/RouteUrls";
import {
  CreateInvoiceTerms_CustomerFragment,
  LotType,
  SaveInvoiceScheduleTemplateInput,
  useCreateInvoiceTermsMetadataQuery,
} from "src/generated/graphql-types";
import { queryResult } from "src/utils";
import { ObjectConfig, required, useFormState } from "src/utils/formState";

export function CreateInvoiceTermsModal() {
  const query = useCreateInvoiceTermsMetadataQuery({ fetchPolicy: "cache-first" });
  return queryResult(query, (data) => <CreateInvoiceTermsContent customers={data.customers} />);
}

type CreateInvoiceFormProps = {
  customers: CreateInvoiceTerms_CustomerFragment[];
};

function CreateInvoiceTermsContent({ customers }: CreateInvoiceFormProps) {
  const formState = useFormState({
    config: formConfig,
    init: {
      onlyOnce: true,
      input: { lotType: LotType.Bool },
    },
  });
  const history = useHistory();
  const { closeModal } = useModal();

  return (
    <>
      <ModalHeader>Create Invoice Terms</ModalHeader>
      <ModalBody>
        <FormLines>
          <BoundTextField field={formState.name} />
          {/* TODO: Make invoice terms available for other lot types */}
          {/* <BoundSelectField
            field={formState.lotType}
            options={Object.values(LotType).map((type) => ({ id: type, name: type }))}
          /> */}
          <BoundSelectField
            field={formState.customerId}
            options={customers.map((customer) => ({ id: customer.id, name: customer.name }))}
            disabledOptions={customers.filter((customer) => customer.isHti).map((c) => c.id)}
          />
        </FormLines>
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" label="Close" onClick={closeModal} />
        <Observer>
          {() => (
            <Button
              disabled={!formState.valid}
              label={"Create Invoice Terms"}
              onClick={() => {
                closeModal();
                history.push({ pathname: createInvoiceTermUrl(), state: formState.value });
              }}
            />
          )}
        </Observer>
      </ModalFooter>
    </>
  );
}

const formConfig: ObjectConfig<SaveInvoiceScheduleTemplateInput> = {
  name: { type: "value", rules: [required] },
  lotType: { type: "value", rules: [required] },
  customerId: { type: "value" },
};
