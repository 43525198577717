import {
  BoundNumberField,
  BoundSelectField,
  ButtonMenu,
  collapseColumn,
  column,
  Css,
  emptyCell,
  GridColumn,
  GridDataRow,
  GridTable,
  Icon,
  ModalProps,
  Palette,
  SelectField,
  simpleHeader,
  useComputed,
  useModal,
} from "@homebound/beam";
import { FieldState, ObjectState } from "@homebound/form-state";
import { ReactNode, useMemo } from "react";
import {
  AddOptionsGlobalOptionTypeFragment,
  IncrementalCollectionOp,
  InputMaybe,
  NamedFragment,
  ReadyPlanOptionsFilter,
  useSaveReadyPlanMutation,
} from "src/generated/graphql-types";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { isDefined } from "src/utils";
import { EditElevationOptionModal } from "./EditElevationOptionModal";
import { EditOtherOptionModal } from "./EditOtherOptionModal";
import { AddOptionsMetadata, AddReadyPlanOption, AddReadyPlanOptionsForm, ProgramDataModifications } from "./utils";

export enum PlanOptionGroupBy {
  optionType = "Option Type",
  location = "location",
  none = "none",
}

type OptionDetailsTableProps = {
  formState: ObjectState<AddReadyPlanOptionsForm>;
  optionsTypes: AddOptionsGlobalOptionTypeFragment[];
  filter: ReadyPlanOptionsFilter;
  searchFilter: string | undefined;
  metadata: AddOptionsMetadata;
  groupBy?: PlanOptionGroupBy;
  showActions: boolean;
  disabled: ReactNode | undefined;
};

export function OptionDetailsTable({
  formState,
  optionsTypes,
  searchFilter = "",
  filter,
  metadata,
  groupBy,
  showActions,
  disabled,
}: OptionDetailsTableProps) {
  const { openModal } = useModal();
  const [saveRp] = useSaveReadyPlanMutation();
  const rows = useComputed(() => {
    switch (groupBy) {
      case PlanOptionGroupBy.location:
        return createRowsGroupByLocation(formState, optionsTypes, filter);

      case PlanOptionGroupBy.none:
        return createRowsGroupByNone(formState, optionsTypes, filter);

      case PlanOptionGroupBy.optionType:
      default:
        return createRowsGroupByOptionType(formState, optionsTypes, filter);
    }
  }, [filter, formState, groupBy, optionsTypes]);

  const columns: GridColumn<Row>[] = useMemo(
    () => createColumns(metadata, openModal, formState, showActions, saveRp, disabled),
    [formState, metadata, openModal, showActions, saveRp, disabled],
  );

  return (
    <GridTable
      rows={rows}
      columns={columns}
      as="virtual"
      sorting={{ on: "client" }}
      filter={searchFilter}
      fallbackMessage={isDefined(filter.active) ? "There are no archived Options." : "No options yet"}
      rowStyles={{ header: { cellCss: Css.bgGray200.$ } }}
    />
  );
}

type HeaderRow = { kind: "header"; id: string; data: undefined };
type ParentRow = {
  kind: "parent";
  id: string;
  data: string;
};
type ChildRow = {
  kind: "child";
  id: string;
  data: ObjectState<AddReadyPlanOption>;
};
type ModificationRow = {
  kind: "modification";
  id: string;
  data: ObjectState<ProgramDataModifications>;
};
type Row = HeaderRow | ParentRow | ChildRow | ModificationRow;

function createColumns(
  metadata: AddOptionsMetadata,
  openModal: (props: ModalProps) => void,
  formState: ObjectState<AddReadyPlanOptionsForm>,
  showActions: boolean,
  saveMutation: ReturnType<typeof useSaveReadyPlanMutation>[0],
  disabled: ReactNode | undefined,
): GridColumn<Row>[] {
  const columns = [
    collapseColumn<Row>({
      child: (data) => {
        return !data.active.value ? (
          <Icon icon="archive" tooltip="This option has been archived" color={Palette.Orange700} />
        ) : (
          emptyCell
        );
      },
      sticky: "left",
    }),
    column<Row>({
      header: "ID",
      parent: (globalTypeName) => ({ content: globalTypeName, css: Css.baseMd.$ }),
      child: ({ globalOptionCode }) => ({
        content: () => (
          <div css={Css.df.gap1.aic.$}>
            <Icon icon="cube" inc={2} />
            <span>{globalOptionCode.value}</span>
          </div>
        ),
        css: Css.xs.$,
      }),
      modification: ({ globalOptionCode }) => ({
        content: () => (
          <div css={Css.df.gap1.aic.plPx(12).$}>
            <Icon icon="subDirectoryRight" inc={2} />
            <span>{globalOptionCode.value}</span>
          </div>
        ),
        css: Css.xs.$,
      }),
      clientSideSort: false,
      sticky: "left",
      w: "150px",
    }),
    column<Row>({
      header: "Internal name",
      parent: emptyCell,
      child: (data, { row }) => ({
        content: () => data.name.value,
        onClick: () => {
          const rpo = data.fragment.value!;
          if (disabled) return;
          return openModal({
            content: rpo.type.isElevation ? (
              <EditElevationOptionModal readyPlanOption={rpo} fState={row.data} />
            ) : (
              <EditOtherOptionModal readyPlanOption={rpo} fState={row.data} />
            ),
          });
        },
      }),
      modification: (data) => {
        const { availableElevations } = getAvailableElevationsForRpo(
          formState,
          data.value.modifiedOption.id,
          data.value.matchesOption?.id,
        );
        const elevationId = data.matchesOption.value?.id || null;

        return (
          <SelectField
            compact
            label="Deviations"
            value={elevationId}
            options={availableElevations ?? []}
            onSelect={(elevationId, opt) => {
              data.matchesOption.set({ id: elevationId! });
              data.globalOptionCode.set(opt!.code!);
            }}
            disabled={disabled}
          />
        );
      },
      clientSideSort: false,
      sticky: "left",
      mw: "300px",
    }),
    column<Row>({
      header: "Description",
      parent: emptyCell,
      child: ({ globalOptionDescription }) => globalOptionDescription.value,
      modification: emptyCell,
      clientSideSort: false,
      mw: "180px",
    }),
    column<Row>({
      header: "Location",
      parent: emptyCell,
      child: ({ globalOptionLocation }) => globalOptionLocation.value?.name,
      modification: emptyCell,
      clientSideSort: false,
      mw: "135px",
    }),
    column<Row>({
      header: "Plan Width",
      parent: emptyCell,
      child: (data, { row }) => {
        return (
          <NumberWithUnitField
            field={row.data.programData.widthInFeet}
            unit="FT"
            numFractionDigits={3}
            disabled={disabled}
          />
        );
      },
      modification: (data, { row }) => {
        return (
          <NumberWithUnitField
            field={row.data.programData.widthInFeet}
            unit="FT"
            numFractionDigits={3}
            disabled={disabled}
          />
        );
      },
      clientSideSort: false,
      w: "100px",
    }),
    column<Row>({
      header: "Plan Depth",
      parent: emptyCell,
      child: (data, { row }) => {
        return (
          <NumberWithUnitField
            field={row.data.programData.depthInFeet}
            unit="FT"
            numFractionDigits={3}
            disabled={disabled}
          />
        );
      },
      modification: (data, { row }) => {
        return (
          <NumberWithUnitField
            field={row.data.programData.depthInFeet}
            unit="FT"
            numFractionDigits={3}
            disabled={disabled}
          />
        );
      },
      clientSideSort: false,
      w: "100px",
    }),
    column<Row>({
      header: "Min Lot Size",
      parent: emptyCell,
      child: (data, { row }) => {
        return <NumberWithUnitField field={row.data.programData.minLotSizeInSqft} unit="SF" disabled={disabled} />;
      },
      modification: (data, { row }) => {
        return <NumberWithUnitField field={row.data.programData.minLotSizeInSqft} unit="SF" disabled={disabled} />;
      },
      clientSideSort: false,
      w: "100px",
    }),
    column<Row>({
      header: "Min Lot Width",
      parent: emptyCell,
      child: (data, { row }) => {
        return (
          <NumberWithUnitField
            field={row.data.programData.minLotWidthInFeet}
            unit="FT"
            numFractionDigits={3}
            disabled={disabled}
          />
        );
      },
      modification: (data, { row }) => {
        return (
          <NumberWithUnitField
            field={row.data.programData.minLotWidthInFeet}
            unit="FT"
            numFractionDigits={3}
            disabled={disabled}
          />
        );
      },
      clientSideSort: false,
      w: "100px",
    }),
    column<Row>({
      header: "Min Lot Depth",
      parent: emptyCell,
      child: (data) => {
        return (
          <NumberWithUnitField
            field={data.programData.minLotDepthInFeet}
            unit="FT"
            numFractionDigits={3}
            disabled={disabled}
          />
        );
      },
      modification: (data) => {
        return (
          <NumberWithUnitField
            field={data.programData.minLotDepthInFeet}
            unit="FT"
            numFractionDigits={3}
            disabled={disabled}
          />
        );
      },
      clientSideSort: false,
      w: "100px",
    }),
    column<Row>({
      header: "Building Height",
      parent: emptyCell,
      child: (data, { row }) => {
        return (
          <NumberWithUnitField
            field={row.data.programData.buildingHeightInFeet}
            unit="FT"
            numFractionDigits={3}
            disabled={disabled}
          />
        );
      },
      modification: (data, { row }) => {
        return (
          <NumberWithUnitField
            field={row.data.programData.buildingHeightInFeet}
            unit="FT"
            numFractionDigits={3}
            disabled={disabled}
          />
        );
      },
      clientSideSort: false,
      w: "100px",
    }),
    column<Row>({
      header: "Stories",
      parent: emptyCell,
      child: (data, { row }) => {
        return <BoundNumberField field={row.data.programData.stories} displayDirection disabled={disabled} />;
      },
      modification: (data, { row }) => {
        return <BoundNumberField field={row.data.programData.stories} displayDirection disabled={disabled} />;
      },
      clientSideSort: false,
      w: "80px",
    }),
    column<Row>({
      header: "Sellable SF",
      parent: emptyCell,
      child: (data, { row }) => {
        return (
          <NumberWithUnitField
            field={row.data.programData.sellableSqft}
            unit="SF"
            disabled="This field is derived from other fields on the program data, it will update after you save your changes."
          />
        );
      },
      modification: (data, { row }) => {
        return (
          <NumberWithUnitField
            field={row.data.programData.sellableSqft}
            unit="SF"
            disabled="This field is derived from other fields on the program data, it will update after you save your changes."
          />
        );
      },
      clientSideSort: false,
      w: "100px",
    }),
    column<Row>({
      header: "Net SF",
      parent: emptyCell,
      child: (data, { row }) => {
        return <NumberWithUnitField field={row.data.programData.netBuildableSqft} unit="SF" disabled={disabled} />;
      },
      modification: (data, { row }) => {
        return <NumberWithUnitField field={row.data.programData.netBuildableSqft} unit="SF" disabled={disabled} />;
      },
      clientSideSort: false,
      w: "100px",
    }),
    column<Row>({
      header: "Gross Buildable SF",
      parent: emptyCell,
      child: (data, { row }) => {
        return <NumberWithUnitField field={row.data.programData.grossBuildableSqft} unit="SF" disabled={disabled} />;
      },
      modification: (data, { row }) => {
        return <NumberWithUnitField field={row.data.programData.grossBuildableSqft} unit="SF" disabled={disabled} />;
      },
      clientSideSort: false,
      w: "100px",
    }),
    column<Row>({
      header: "Permitable SF",
      parent: emptyCell,
      child: (data, { row }) => {
        return <NumberWithUnitField field={row.data.programData.permittableSqft} unit="SF" disabled={disabled} />;
      },
      modification: (data, { row }) => {
        return <NumberWithUnitField field={row.data.programData.permittableSqft} unit="SF" disabled={disabled} />;
      },
      clientSideSort: false,
      w: "100px",
    }),
    column<Row>({
      header: "Sellable Above Ground SF",
      parent: emptyCell,
      child: (data, { row }) => {
        return (
          <NumberWithUnitField field={row.data.programData.sellableAboveGroundSqft} unit="SF" disabled={disabled} />
        );
      },
      modification: (data, { row }) => {
        return (
          <NumberWithUnitField field={row.data.programData.sellableAboveGroundSqft} unit="SF" disabled={disabled} />
        );
      },
      clientSideSort: false,
      w: "135px",
    }),
    column<Row>({
      header: "Gross Below Ground SF",
      parent: emptyCell,
      child: (data, { row }) => {
        return <NumberWithUnitField field={row.data.programData.grossBelowGroundSqft} unit="SF" disabled={disabled} />;
      },
      modification: (data, { row }) => {
        return <NumberWithUnitField field={row.data.programData.grossBelowGroundSqft} unit="SF" disabled={disabled} />;
      },
      clientSideSort: false,
      w: "135px",
    }),
    column<Row>({
      header: "Sellable Below Ground SF",
      parent: emptyCell,
      child: (data, { row }) => {
        return (
          <NumberWithUnitField field={row.data.programData.sellableBelowGroundSqft} unit="SF" disabled={disabled} />
        );
      },
      modification: (data, { row }) => {
        return (
          <NumberWithUnitField field={row.data.programData.sellableBelowGroundSqft} unit="SF" disabled={disabled} />
        );
      },
      clientSideSort: false,
      w: "135px",
    }),
    column<Row>({
      header: "Unfinished Below Ground SF",
      parent: emptyCell,
      child: (data, { row }) => {
        return (
          <NumberWithUnitField
            field={row.data.programData.unfinishedBelowGroundSqft}
            unit="SF"
            disabled="This field is derived from other fields on the program data, it will update after you save your changes."
          />
        );
      },
      modification: (data, { row }) => {
        return (
          <NumberWithUnitField
            field={row.data.programData.unfinishedBelowGroundSqft}
            unit="SF"
            disabled="This field is derived from other fields on the program data, it will update after you save your changes."
          />
        );
      },
      clientSideSort: false,
      w: "135px",
    }),
    column<Row>({
      header: "Impervious SF",
      parent: emptyCell,
      child: (data, { row }) => {
        return <NumberWithUnitField field={row.data.programData.imperviousSqft} unit="SF" disabled={disabled} />;
      },
      modification: (data, { row }) => {
        return <NumberWithUnitField field={row.data.programData.imperviousSqft} unit="SF" disabled={disabled} />;
      },
      clientSideSort: false,
      w: "100px",
    }),
    column<Row>({
      header: "Bed",
      parent: emptyCell,
      child: (data, { row }) => {
        return <BoundNumberField field={row.data.programData.bedrooms} displayDirection disabled={disabled} />;
      },
      modification: (data, { row }) => {
        return <BoundNumberField field={row.data.programData.bedrooms} displayDirection disabled={disabled} />;
      },
      clientSideSort: false,
      w: "55px",
    }),
    column<Row>({
      header: "First Floor Bedrooms",
      parent: emptyCell,
      child: (data, { row }) => {
        return (
          <BoundNumberField field={row.data.programData.firstFloorBedrooms} displayDirection disabled={disabled} />
        );
      },
      modification: (data, { row }) => {
        return (
          <BoundNumberField field={row.data.programData.firstFloorBedrooms} displayDirection disabled={disabled} />
        );
      },
      clientSideSort: false,
      w: "65px",
    }),
    column<Row>({
      header: "Primary Bed",
      parent: emptyCell,
      child: (data, { row }) => (
        <BoundSelectField
          compact
          label="Basement"
          field={row.data.programData.primaryBedroom}
          options={
            metadata?.enumDetails.programDataPrimaryBedroomConfig?.map((enumValue) => ({
              id: enumValue.code,
              name: enumValue.name,
            })) ?? []
          }
          disabled={disabled}
        />
      ),
      modification: (data, { row }) => (
        <BoundSelectField
          compact
          label="Basement"
          field={row.data.programData.primaryBedroom}
          options={
            metadata?.enumDetails.programDataPrimaryBedroomConfig?.map((enumValue) => ({
              id: enumValue.code,
              name: enumValue.name,
            })) ?? []
          }
          disabled={disabled}
        />
      ),
      clientSideSort: false,
      w: "100px",
    }),
    column<Row>({
      header: "Bath",
      parent: emptyCell,
      child: (data, { row }) => {
        return <BoundNumberField field={row.data.programData.fullBaths} displayDirection disabled={disabled} />;
      },
      modification: (data, { row }) => {
        return <BoundNumberField field={row.data.programData.fullBaths} displayDirection disabled={disabled} />;
      },
      clientSideSort: false,
      w: "55px",
    }),
    column<Row>({
      header: "Half Bath",
      parent: emptyCell,
      child: (data, { row }) => {
        return <BoundNumberField field={row.data.programData.halfBaths} displayDirection disabled={disabled} />;
      },
      modification: (data, { row }) => {
        return <BoundNumberField field={row.data.programData.halfBaths} displayDirection disabled={disabled} />;
      },
      clientSideSort: false,
      w: "65px",
    }),
    column<Row>({
      header: "Formal Dining",
      parent: emptyCell,
      child: (data, { row }) => {
        return <BoundNumberField field={row.data.programData.diningRoom} displayDirection disabled={disabled} />;
      },
      modification: (data, { row }) => {
        return <BoundNumberField field={row.data.programData.diningRoom} displayDirection disabled={disabled} />;
      },
      clientSideSort: false,
      w: "65px",
    }),
    column<Row>({
      header: "Casual Dining",
      parent: emptyCell,
      child: (data, { row }) => {
        return <BoundNumberField field={row.data.programData.casualDining} displayDirection disabled={disabled} />;
      },
      modification: (data, { row }) => {
        return <BoundNumberField field={row.data.programData.casualDining} displayDirection disabled={disabled} />;
      },
      clientSideSort: false,
      w: "65px",
    }),
    column<Row>({
      header: "Media Rooms",
      parent: emptyCell,
      child: (data, { row }) => {
        return <BoundNumberField field={row.data.programData.mediaRooms} displayDirection disabled={disabled} />;
      },
      modification: (data, { row }) => {
        return <BoundNumberField field={row.data.programData.mediaRooms} displayDirection disabled={disabled} />;
      },
      clientSideSort: false,
      w: "65px",
    }),
    column<Row>({
      header: "Lofts/ Game Rooms/ Flex Rooms",
      parent: emptyCell,
      child: (data, { row }) => {
        return <BoundNumberField field={row.data.programData.loftGameFlexRooms} displayDirection disabled={disabled} />;
      },
      modification: (data, { row }) => {
        return <BoundNumberField field={row.data.programData.loftGameFlexRooms} displayDirection disabled={disabled} />;
      },
      clientSideSort: false,
      w: "140px",
    }),
    column<Row>({
      header: "Offices",
      parent: emptyCell,
      child: (data, { row }) => {
        return <BoundNumberField field={row.data.programData.offices} displayDirection disabled={disabled} />;
      },
      modification: (data, { row }) => {
        return <BoundNumberField field={row.data.programData.offices} displayDirection disabled={disabled} />;
      },
      clientSideSort: false,
      w: "65px",
    }),
    column<Row>({
      header: "Workspaces",
      parent: emptyCell,
      child: (data, { row }) => {
        return <BoundNumberField field={row.data.programData.workspaces} displayDirection disabled={disabled} />;
      },
      modification: (data, { row }) => {
        return <BoundNumberField field={row.data.programData.workspaces} displayDirection disabled={disabled} />;
      },
      clientSideSort: false,
      w: "95px",
    }),
    column<Row>({
      header: "Garage Attached",
      parent: emptyCell,
      child: (data, { row }) => {
        return <BoundNumberField field={row.data.programData.garageAttached} displayDirection disabled={disabled} />;
      },
      modification: (data, { row }) => {
        return <BoundNumberField field={row.data.programData.garageAttached} displayDirection disabled={disabled} />;
      },
      clientSideSort: false,
      w: "85px",
    }),
    column<Row>({
      header: "Garage Detached",
      parent: emptyCell,
      child: (data, { row }) => {
        return <BoundNumberField field={row.data.programData.garageDetached} displayDirection disabled={disabled} />;
      },
      modification: (data, { row }) => {
        return <BoundNumberField field={row.data.programData.garageDetached} displayDirection disabled={disabled} />;
      },
      clientSideSort: false,
      w: "85px",
    }),
    column<Row>({
      header: "Carport",
      parent: emptyCell,
      child: (data, { row }) => {
        return <BoundNumberField field={row.data.programData.garagePort} displayDirection disabled={disabled} />;
      },
      modification: (data, { row }) => {
        return <BoundNumberField field={row.data.programData.garagePort} displayDirection disabled={disabled} />;
      },
      clientSideSort: false,
      w: "80px",
    }),
    column<Row>({
      header: "Basement",
      parent: emptyCell,
      child: (data, { row }) => {
        return (
          <BoundSelectField
            compact
            label="Basement"
            field={row.data.programData.basementConfig}
            options={
              metadata?.enumDetails.programDataBasementConfig?.map((enumValue) => ({
                id: enumValue.code,
                name: enumValue.name,
              })) ?? []
            }
            disabled={disabled}
          />
        );
      },
      modification: (data, { row }) => {
        return (
          <BoundSelectField
            compact
            label="Basement"
            field={row.data.programData.basementConfig}
            options={
              metadata?.enumDetails.programDataBasementConfig?.map((enumValue) => ({
                id: enumValue.code,
                name: enumValue.name,
              })) ?? []
            }
            disabled={disabled}
          />
        );
      },
      clientSideSort: false,
      w: "180px",
    }),
  ];
  const actionColumn = column<Row>({
    header: emptyCell,
    parent: emptyCell,
    child: (data, { row }) => {
      if (data.fragment.value?.type.isElevation) return emptyCell;
      const { availableElevations } = getAvailableElevationsForRpo(formState, data.id.value!);
      return (
        <ButtonMenu
          data-testid="actions"
          items={[
            {
              label: "Add a Modifier",
              onClick: async () => {
                data.programDataModifiedBy.add({
                  id: `temp_${row.id}`,
                  modifiedOption: { id: row.data.id.value! },
                  matchesOption: {
                    id: availableElevations.first!.id,
                    globalOption: { code: availableElevations.first!.code! } as any,
                  },
                  programData: {},
                  globalOptionCode: availableElevations.first!.code!,
                });
              },
              destructive: false,
              disabled,
            },
          ]}
          persistentItems={[]}
          trigger={{ icon: "verticalDots" }}
          placement="right"
          disabled={!availableElevations.length}
          tooltip={!availableElevations.length ? "There are no more elevations to assign" : "Add a Modifier"}
        />
      );
    },
    modification: (data) => {
      return (
        <ButtonMenu
          data-testid="actions"
          items={[
            {
              label: "Delete Modifier",
              onClick: async () => {
                openModal({
                  content: (
                    <ConfirmationModal
                      danger
                      title="Confirm deletion"
                      confirmationMessage="Are you sure you want to delete this modification? This action cannot be undone"
                      label="Delete"
                      onConfirmAction={async () => {
                        await saveMutation({
                          variables: {
                            input: {
                              id: formState.readyPlanId.value,
                              programDatas: [
                                { id: data.id.value?.split("_")[1] ?? "", op: IncrementalCollectionOp.Delete },
                              ],
                            },
                          },
                        });
                      }}
                    />
                  ),
                });
              },
              destructive: false,
              disabled,
            },
          ]}
          persistentItems={[]}
          trigger={{ icon: "verticalDots" }}
          placement="right"
        />
      );
    },
    clientSideSort: false,
    sticky: "right",
    w: "40px",
  });
  if (showActions) columns.push(actionColumn);
  return columns;
}

function getAvailableElevationsForRpo(
  formState: ObjectState<AddReadyPlanOptionsForm>,
  rpoId: string,
  matchedRpoId?: string,
) {
  const programDataModifiedBy = formState.readyPlanOptions.rows
    .filter((rpo) => rpo.id.value === rpoId)
    .flatMap((rpo) => rpo.programDataModifiedBy.value);

  const modifiers = programDataModifiedBy.flatMap((rpo) => rpo.matchesOption?.id);
  const availableElevations = formState.readyPlanOptions.rows
    .filter((rpo) => rpo.isElevation.value)
    .filter((rpo) => !modifiers.includes(rpo.id.value!) || rpo.id.value === matchedRpoId)
    .map((rpo) => ({
      id: rpo.id.value!,
      name: `${rpo.globalOptionCode.value} - ${rpo.name.value}`,
      code: rpo.globalOptionCode.value,
    }));
  return {
    availableElevations,
  };
}

function createRowsGroupByOptionType(
  data: ObjectState<AddReadyPlanOptionsForm>,
  optionsTypes: NamedFragment[],
  filter: ReadyPlanOptionsFilter,
): GridDataRow<Row>[] {
  const filteredOptions = filterOptions(data.readyPlanOptions.rows, filter, optionsTypes);
  const groupByOptionType = filteredOptions.groupBy((rpo) => rpo.optionTypeId.value!);
  const optionTypesWithOptions = new Set(Object.keys(groupByOptionType));
  // Map over the optionsTypes since it is ordered elsewhere by GOT.order
  const result = optionsTypes
    // Exclude any option types which have no options
    .filter((ot) => optionTypesWithOptions.has(ot.id))
    .map((ot) => {
      return {
        kind: "parent" as const,
        data: ot.name,
        id: ot.id,
        children: groupByOptionType[ot.id].map((rpo) => {
          const id = rpo.id.value ?? `${rpo.globalOptionCode.value}_${rpo.locationId.value}`;
          return {
            kind: "child" as const,
            id,
            data: rpo,
            children: rpo.programDataModifiedBy.rows.flatMap((pdm) => {
              return {
                kind: "modification" as const,
                id: `${pdm.id.value}`,
                data: pdm,
              };
            }),
          };
        }),
      };
    });
  return [simpleHeader, ...result];
}

function createRowsGroupByLocation(
  data: ObjectState<AddReadyPlanOptionsForm>,
  optionsTypes: NamedFragment[],
  filter: ReadyPlanOptionsFilter,
): GridDataRow<Row>[] {
  const filteredOptions = filterOptions(data.readyPlanOptions.rows, filter, optionsTypes);
  const groupByLocation = filteredOptions.groupBy((rpo) => rpo.locationId.value!);
  const result = Object.entries(groupByLocation).map(([locationId, rpos]) => {
    return {
      kind: "parent" as const,
      data: rpos[0].globalOptionLocation.value?.name!,
      id: locationId,
      children: rpos.flatMap((rpo) => {
        const id = rpo.id.value ?? `${rpo.globalOptionCode.value}_${rpo.locationId.value}`;
        return {
          kind: "child" as const,
          id,
          data: rpo,
          children: rpo.programDataModifiedBy.rows.flatMap((pdm) => {
            return {
              kind: "modification" as const,
              id: `${pdm.id.value}`,
              data: pdm,
            };
          }),
        };
      }),
    };
  });
  return [simpleHeader, ...result];
}

function createRowsGroupByNone(
  data: ObjectState<AddReadyPlanOptionsForm>,
  optionsTypes: NamedFragment[],
  filter: ReadyPlanOptionsFilter,
): GridDataRow<Row>[] {
  const filteredOptions = filterOptions(data.readyPlanOptions.rows, filter, optionsTypes);
  const result = filteredOptions
    ? filteredOptions.map((rpo) => {
        const id = rpo.id.value ?? `${rpo.globalOptionCode.value}_${rpo.locationId.value}`;
        return {
          kind: "child" as const,
          id,
          data: rpo,
        };
      })
    : [];
  return [simpleHeader, ...result];
}

function filterOptions(
  options: readonly ObjectState<AddReadyPlanOption>[],
  filter: ReadyPlanOptionsFilter,
  optionsTypes: NamedFragment[],
) {
  const { active, type, location } = filter;
  const filteredOptions = options.filter((rpo) => optionsTypes.find((ot) => ot.id === rpo.optionTypeId.value));
  // The `active` filter uses a `toggleFilter` with the label "Show archived".
  // When the toggle filter is off (undefined), then treat as _only_ showing "Active".
  // When the toggle filter is on (true), then treat as `active` filter is unset
  return filteredOptions.filter((rpo) =>
    location
      ? location?.includes(rpo.locationId.value)
      : type && rpo.optionTypeId.value
        ? type?.includes(rpo.optionTypeId.value)
        : isDefined(active)
          ? true
          : rpo.active.value,
  );
}

type NumberWithUnitFieldProps = {
  field: FieldState<InputMaybe<number>>;
  numFractionDigits?: number;
  unit: string;
  disabled?: React.ReactNode;
};

function NumberWithUnitField({ field, unit, ...others }: NumberWithUnitFieldProps) {
  return (
    <div css={Css.df.jcsb.gap2.aic.$}>
      <BoundNumberField field={field} displayDirection {...others} />
      <span>{unit}</span>
    </div>
  );
}
