import { BoundMultiSelectField, BoundMultiSelectFieldProps } from "@homebound/beam";
import { useRoomTypeLazyQuery } from "src/generated/graphql-types";
import { HasIdAndName } from "src/utils";

type RoomTypeBoundMultiSelectFieldProps = Omit<
  BoundMultiSelectFieldProps<HasIdAndName, string>,
  "options" | "getOptionLabel" | "getOptionValue"
> & {
  current: HasIdAndName[];
};

export function RoomTypeBoundMultiSelectField(props: RoomTypeBoundMultiSelectFieldProps) {
  const { label = "Room Type", placeholder = "Select a Room Type", current, ...otherProps } = props;
  const [load, { data }] = useRoomTypeLazyQuery({ fetchPolicy: "cache-first" });
  return (
    <BoundMultiSelectField
      label={label}
      placeholder={placeholder}
      {...otherProps}
      options={{ current, load, options: data?.roomTypes ?? [] }}
    />
  );
}
