import { Css, useSnackbar } from "@homebound/beam";
import { DeleteConfirmationModal } from "src/routes/components/DeleteConfirmationModal";
import { TakeoffsStoreState } from "src/routes/libraries/plan-package/takeoffs/TakeoffsManagerContext";
import { count } from "src/utils";
import { StoreApi } from "zustand";

type DeleteItemsConfirmationModalProps = {
  store: StoreApi<TakeoffsStoreState>;
  itemIdsToDelete: string[];
};

export function DeleteItemsConfirmationModal({ store, itemIdsToDelete }: DeleteItemsConfirmationModalProps) {
  const { saveTli } = store.getState();
  const { triggerNotice } = useSnackbar();

  return (
    <DeleteConfirmationModal
      entityType="Item"
      confirmationMessage={
        <div>
          <p css={Css.smMd.mb2.$}>{count(itemIdsToDelete, "item")} selected</p>
          <p>Are you sure you want to delete these items? This action cannot be undone.</p>
        </div>
      }
      onConfirmDelete={async () => {
        const { data } = await saveTli(itemIdsToDelete.map((id) => ({ id, active: false })));
        if (data) {
          const deleteItems = data.saveTakeoffLineItems.deleted.length + data.saveTakeoffLineItems.removed.length;
          triggerNotice({
            message: `${deleteItems === 1 ? "Item has" : `${deleteItems} items have`} been deleted`,
            icon: "success",
          });
        }
      }}
    />
  );
}
