import { NestedOption } from "@homebound/beam";
import { useMemo } from "react";
import { LocationType, NamedFragment, useUseLocationsTreeQuery } from "src/generated/graphql-types";

type useLocationsTreeProps = {
  rpavId: string | undefined;
};

export function useLocationsTree({ rpavId }: useLocationsTreeProps) {
  const { data } = useUseLocationsTreeQuery({
    variables: { rpavId: rpavId! },
    skip: !rpavId,
    fetchPolicy: "cache-and-network",
  });

  const locationOptions = useMemo(() => {
    const rowsWithChildren: NestedOption<NamedFragment>[] = [];

    data?.locations.forEach((location) => {
      const { parents, ...rest } = location;

      // Build path hierarchy of this location
      const rootToLeaf = [rest, ...parents].reverse();

      // Loop through each level in hierarchy by chaining through the children
      let currentLocationInHierarchy = rowsWithChildren;
      rootToLeaf.forEach(({ id, name, type }) => {
        currentLocationInHierarchy = getOrCreateLocationOption(
          currentLocationInHierarchy,
          id,
          name,
          type.code,
        ).children!;
      });
    });
    return rowsWithChildren;
  }, [data]);

  return locationOptions;
}

function getOrCreateLocationOption(
  parent: NestedOption<NamedFragment>[],
  id: string,
  name: string,
  type: LocationType,
): NestedOption<NamedFragment> {
  let node = parent.find((child) => child.id === id);
  if (!node) {
    node = { id, name, defaultCollapsed: type === LocationType.Room, children: [] };
    // Insert the node in sorted order
    const index = parent.findIndex((item) => item.name.localeCompare(node!.name) > 0);
    if (index === -1) {
      parent.push(node);
    } else {
      parent.splice(index, 0, node);
    }
  }
  return node;
}
