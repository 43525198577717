import { Button, ModalBody, ModalFooter, ModalHeader, useModal, useTestIds } from "@homebound/beam";
import { Observer } from "mobx-react";
import { ReactNode, useCallback, useState } from "react";

type BillConfirmationModalProps = {
  contents: ReactNode[] | string[];
  submitButtons: { label: string }[];
  onConfirmAction: () => void;
  title: string;
};

export function BillConfirmationModal({ contents, submitButtons, title, onConfirmAction }: BillConfirmationModalProps) {
  const testIds = useTestIds({}, "billConfirmationModal");
  const [currentContentIndex, setCurrentContentIndex] = useState(0);
  const { closeModal } = useModal();

  const onConfirmClick = useCallback(async () => {
    await onConfirmAction();
    closeModal();
  }, [onConfirmAction, closeModal]);

  return (
    <Observer>
      {() => (
        <div {...testIds}>
          <ModalHeader>{title}</ModalHeader>
          <ModalBody>{contents[currentContentIndex]}</ModalBody>
          <ModalFooter>
            <Button label="Cancel" onClick={() => closeModal()} variant="secondary" />
            <Button
              {...testIds.confirm}
              label={submitButtons[currentContentIndex].label}
              onClick={async () => {
                if (currentContentIndex === contents.length - 1) {
                  await onConfirmClick();
                } else {
                  setCurrentContentIndex((currentContentIndex) => currentContentIndex + 1);
                }
              }}
            />
          </ModalFooter>
        </div>
      )}
    </Observer>
  );
}
