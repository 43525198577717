import {
  Css,
  GridColumn,
  GridDataRow,
  GridTable,
  RowStyles,
  collapseColumn,
  emptyCell,
  simpleHeader,
  useComputed,
} from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { Price } from "src/components";
import { FormInput, FormLineItem, calcLineItemValues } from "../BillPage";
import { getBillableLimit } from "../utils";
import { OtherBillCell } from "./OtherBillCell";

type BillLineItemsTotals = {
  thisBilledInCents: number;
  unBilledInCents: number;
  otherBilledInCents: number;
  costChangeInCents: number;
  uncommittedBudgetAmountInCents: number;
};

type HeaderRow = { kind: "header"; data: undefined };
type TotalsRow = { kind: "total"; data: BillLineItemsTotals };
type ColumnHeaderRow = {
  kind: "columnHeader";
  id: string;
};
type LineItemRow = { kind: "lineItem"; data: ObjectState<FormInput>["lineItems"]["rows"][0] };
type CommitmentRow = { kind: "commitment"; id: string; data: { name: string } };
type StandardRow = HeaderRow | ColumnHeaderRow | LineItemRow | CommitmentRow | TotalsRow;
type NoPoRow = HeaderRow | LineItemRow | TotalsRow;
type NestedRow = StandardRow | NoPoRow;

function buildCommitmentName(id: string, accountingNumber: number) {
  return `${id.includes("cco") ? "CO" : "PO"} #${accountingNumber}`;
}

function createColumns(
  formState: ObjectState<FormInput>,
  isHeadlessBill: boolean,
  totalAmount: number,
): GridColumn<NestedRow>[] {
  const itemColumn: GridColumn<NestedRow> = {
    header: (row) => ({
      content: <div css={Css.sm.color("black").$}>Line Items</div>,
      colspan: 4,
    }),
    columnHeader: (row) => ({
      content: <div css={Css.ml(isHeadlessBill ? 0 : -1.3).xs.$}>Item</div>,
    }),
    commitment: (row) => ({
      content: <div css={Css.smBd.color("black").w100.$}>{row.name}</div>,
      colspan: 4,
    }),
    lineItem: (row) => ({
      content: <div css={Css.ml(-1.3).xs.$}>{row.displayName.value}</div>,
      w: "20%",
    }),
    total: (row) => ({
      content: <div css={Css.ml(0).xsBd.$}>Total</div>,
    }),
  };

  const committedColumn: GridColumn<NestedRow> = {
    header: () => emptyCell,
    columnHeader: (row) => ({
      content: <div css={Css.w100.tar.$}>Commited</div>,
    }),
    commitment: () => emptyCell,
    lineItem: (row) => ({
      content: (
        <div css={Css.ml(0).w100.tar.$}>
          <Price valueInCents={row.costChangeInCents.value} />
        </div>
      ),
    }),
    total: () => emptyCell,
  };

  const billableLimitColumn: GridColumn<NestedRow> = {
    header: () => emptyCell,
    columnHeader: (row) => ({
      content: <div css={Css.w100.tar.$}>Billable Limit</div>,
    }),
    commitment: () => emptyCell,
    lineItem: (row) => ({
      content: (
        <div css={Css.ml(0).w100.tar.$}>
          <Price valueInCents={getBillableLimit(row.value)} />
        </div>
      ),
    }),
    total: () => emptyCell,
  };

  const otherBillsColumn: GridColumn<NestedRow> = {
    header: () => emptyCell,
    columnHeader: (row) => ({
      content: <div css={Css.w100.tar.$}>Other Bills</div>,
    }),
    commitment: () => emptyCell,
    lineItem: (row) => ({
      content: <OtherBillCell billId={formState.id.value!} row={row} />,
    }),
    total: () => emptyCell,
  };

  const billAmountColumn: GridColumn<NestedRow> = {
    header: () => emptyCell,
    columnHeader: (row) => ({
      content: <div css={Css.w100.tar.$}>Bill Amount</div>,
    }),
    commitment: () => emptyCell,
    lineItem: (row) => ({
      content: (
        <div css={Css.ml(0).w100.tar.xs.$}>
          <Price valueInCents={row.amountInCents.value} />
        </div>
      ),
    }),
    total: (row) => (
      <div css={Css.ml(0).w100.tar.xsBd.$}>
        <Price valueInCents={totalAmount} />
      </div>
    ),
  };

  const unbilledColumn: GridColumn<NestedRow> = {
    header: () => emptyCell,
    columnHeader: (row) => ({
      content: <div css={Css.w100.tar.$}>Unbilled</div>,
    }),
    commitment: () => emptyCell,
    lineItem: (row) => ({
      content: (
        <div css={Css.ml(0).w100.tar.xs.$}>
          <Price valueInCents={row.unbilledInCents.value} />
        </div>
      ),
    }),
    total: () => emptyCell,
  };

  return isHeadlessBill
    ? [itemColumn, otherBillsColumn, billAmountColumn, unbilledColumn]
    : [itemColumn, committedColumn, billableLimitColumn, billAmountColumn, unbilledColumn];
}

const createRows = (formState: ObjectState<FormInput>, isHeadlessBill: boolean) => {
  const commitmentsWithLineItemsRelated = new Map<string, ObjectState<FormLineItem>[]>();
  const rows: GridDataRow<NestedRow>[] = [];

  if (isHeadlessBill) {
    rows.push({
      kind: "columnHeader",
      id: "columnHeader",
      children: formState.lineItems.rows.map((li, index) => {
        return { kind: "lineItem", id: li.id.value ?? `${index}`, data: li, draggable: false };
      }),
      data: undefined,
    });
  } else {
    formState.lineItems.rows.forEach((r) => {
      if (commitmentsWithLineItemsRelated.has(buildCommitmentName(r.owner.id.value, r.owner.accountingNumber.value))) {
        commitmentsWithLineItemsRelated
          .get(buildCommitmentName(r.owner.id.value, r.owner.accountingNumber.value))
          ?.push(r);
      } else {
        commitmentsWithLineItemsRelated.set(buildCommitmentName(r.owner.id.value, r.owner.accountingNumber.value), [r]);
      }
    });
    commitmentsWithLineItemsRelated.forEach((v, k) => {
      const lineItems: GridDataRow<NestedRow>[] = v.map((li, index) => {
        return {
          ...{ kind: "lineItem", id: li.id.value ?? `${index}`, data: li, draggable: false },
        };
      });

      rows.push({
        ...{ kind: "commitment", id: k, data: { name: k } },
        children: [{ kind: "columnHeader", id: `${k}_columnHeader`, data: undefined }, ...lineItems],
      });
    });
  }

  return rows;
};

export const BillLineItemsDataTable = ({
  formState,
  isHeadlessBill,
}: {
  formState: ObjectState<FormInput>;
  isHeadlessBill: boolean;
}) => {
  const totalAmount = useComputed(() => {
    return formState.lineItems.rows.sum((li) => li.amountInCents.value || 0);
  }, [formState.lineItems.rows]);
  const columns = createColumns(formState, isHeadlessBill, totalAmount);

  const totalsRowData = useComputed(
    () =>
      formState.lineItems.rows.reduce(
        (acc, li) => {
          const { otherBilledInCents, thisBilledInCents, unBilledInCents } = calcLineItemValues(
            li,
            !!formState.isTradePartnerCredit.value,
          );
          const unBilledAmount = isHeadlessBill
            ? Number(li.revisedApprovedBudgetInCents.value) - Number(li.amountInCents.value)
            : unBilledInCents;
          return {
            otherBilledInCents: isHeadlessBill ? 0 : acc.otherBilledInCents + otherBilledInCents,
            thisBilledInCents: acc.thisBilledInCents + thisBilledInCents,
            unBilledInCents: acc.unBilledInCents + unBilledAmount,
            costChangeInCents: acc.costChangeInCents + (li.costChangeInCents.value || 0),
            uncommittedBudgetAmountInCents:
              acc.uncommittedBudgetAmountInCents + (li.uncommittedBudgetAmountInCents.value || 0),
          };
        },
        {
          thisBilledInCents: 0,
          unBilledInCents: 0,
          otherBilledInCents: 0,
          costChangeInCents: 0,
          uncommittedBudgetAmountInCents: 0,
        },
      ),
    [formState],
  );

  const totalsRow: GridDataRow<TotalsRow> = {
    id: "total",
    kind: "total",
    data: totalsRowData,
  };

  const rowsWithHeader: GridDataRow<NestedRow>[] = [simpleHeader, ...createRows(formState, isHeadlessBill)];

  return (
    <div css={Css.px1.$}>
      <GridTable
        rowStyles={billLineItemsTableRowStyles}
        columns={[collapseColumn<NestedRow>(), ...columns]}
        {...{ rows: [...rowsWithHeader, totalsRow] }}
      />
    </div>
  );
};

const billLineItemsTableRowStyles: RowStyles<NestedRow> = {
  total: { cellCss: Css.bgGray200.$ },
};
