import {
  BoundNumberField,
  ButtonModal,
  column,
  Css,
  emptyCell,
  GridCellContent,
  GridDataRow,
  GridTable,
  GridTableApi,
  IconButton,
  numericColumn,
  Palette,
  simpleHeader,
  Tooltip,
} from "@homebound/beam";
import { priceCell } from "src/components";
import { BillEditor_TaskFragment, ProjectAutocomplete_ProjectFragment, TaskStatus } from "src/generated/graphql-types";
import { createProjectScheduleUrl } from "src/RouteUrls";
import { DateOnly, formatWithYear } from "src/utils/dates";
import { ObjectState } from "src/utils/formState";
import { openNewTab } from "src/utils/window";
import { BillReviewFormLineItem, CreateBillFormState } from "../BillEditor";
import { ProjectItemDetails } from "./ProjectItemDetails";

type HeaderRow = { kind: "header" };
type LineItemRow = {
  kind: "lineItem";
  data: CreateBillFormState["reviewLineItems"]["rows"][0];
  initSelected?: boolean;
};

export type ReviewLineItemRow = HeaderRow | LineItemRow;
export type BillReviewLineItemsTableProps = {
  tableApi: GridTableApi<ReviewLineItemRow>;
  formState: CreateBillFormState;
  onRemoveLineItem: (lineItemId: string) => void;
};

export function calculateBudgetImpact(billAmount: number, uncommitedAmount: number) {
  const amountLessUncommitted = billAmount - uncommitedAmount;
  return billAmount && amountLessUncommitted && amountLessUncommitted > 0 ? amountLessUncommitted : 0;
}

function createColumns(onRemoveLineItem: (lineItemId: string) => void, project: ProjectAutocomplete_ProjectFragment) {
  return [
    column<ReviewLineItemRow>({
      header: emptyCell,
      lineItem: ({ id }) => (
        <IconButton icon="trash" color={Palette.Gray500} onClick={() => onRemoveLineItem(id.value)} />
      ),
      w: "56px",
    }),
    column<ReviewLineItemRow>({
      header: () => "Name",
      lineItem: ({ displayName, id }) => (
        <ButtonModal
          content={(close) => <ProjectItemDetails projectItemId={id.value} close={close} />}
          trigger={{ label: <div css={Css.wspw.tal.$}>{displayName.value}</div>, size: "sm" }}
          variant="text"
          hideEndAdornment
        />
      ),
    }),
    column<ReviewLineItemRow>({
      header: () => "Location",
      lineItem: ({ location }) => <>{location.value?.name}</>,
    }),
    column<ReviewLineItemRow>({
      header: () => "Task",
      lineItem: ({ task }) => (task.value ? allocationCell(task.value, project) : undefined),
    }),
    numericColumn<ReviewLineItemRow>({
      header: () => "Available",
      lineItem: ({ uncommittedBudgetAmountInCents }) =>
        priceCell({
          valueInCents: uncommittedBudgetAmountInCents.value > 0 ? uncommittedBudgetAmountInCents.value : 0,
        }),
    }),
    numericColumn<ReviewLineItemRow>({
      header: () => "QTY",
      lineItem: ({
        quantity,
        billAmountInCents,
        revisedApprovedBudgetInCents,
        uncommittedBudgetAmountInCents,
        budgetImpactInCents,
      }) => (
        <div css={Css.df.w3.$}>
          <BoundNumberField
            field={quantity}
            onChange={(val) => {
              quantity.set(val);
              const budgetImpact = calculateBudgetImpact(
                billAmountInCents.value ?? 0,
                uncommittedBudgetAmountInCents.value,
              );
              if (val && val > 0) {
                budgetImpactInCents.set(val * budgetImpact);
              } else {
                budgetImpactInCents.set(budgetImpact);
              }
            }}
          />
        </div>
      ),
    }),
    numericColumn<ReviewLineItemRow>({
      header: () => "Bill Amount",
      lineItem: ({ billAmountInCents, budgetImpactInCents, uncommittedBudgetAmountInCents }) => (
        <BoundNumberField
          field={billAmountInCents}
          onChange={(val) => {
            billAmountInCents.set(val);
            budgetImpactInCents.set(calculateBudgetImpact(val ?? 0, uncommittedBudgetAmountInCents.value));
          }}
        />
      ),
    }),
    numericColumn<ReviewLineItemRow>({
      header: () => "Impact",
      lineItem: ({ budgetImpactInCents }) => priceCell({ valueInCents: budgetImpactInCents?.value ?? 0 }),
    }),
  ];
}

function createRows(lineItems: readonly ObjectState<BillReviewFormLineItem>[]): GridDataRow<ReviewLineItemRow>[] {
  const rows = lineItems?.map((r) => ({ kind: "lineItem" as const, id: r.id.value, data: r }));
  return [simpleHeader, ...rows].compact();
}

function allocationCell(task: BillEditor_TaskFragment, project: ProjectAutocomplete_ProjectFragment): GridCellContent {
  const taskUrl = project.stages && createProjectScheduleUrl(project.id, project.stages.last?.stage.code, task.id);

  return {
    content: () => (
      <div
        data-testid={`task-cell-${task.id}`}
        onClick={() => openNewTab(taskUrl)}
        css={Css.cursorPointer.onHover.tdu.blue600.$}
      >
        <Tooltip
          title={
            <div>
              <div>Status: {task.status.name}</div>
              <div>Start date: {task.startDate ? formatWithYear(new DateOnly(task.startDate)) : "-"}</div>
              {task.completedAt && <div>Completed at: {formatWithYear(new DateOnly(task.completedAt))}</div>}
            </div>
          }
          placement="top"
        >
          <div css={Css.df.aic.$}>
            <div css={Css.blue600.pl1.$}>{task.name}</div>
          </div>
        </Tooltip>
      </div>
    ),
    value: task.id,
  };
}

export function BillReviewLineItemsTable({ tableApi, formState, onRemoveLineItem }: BillReviewLineItemsTableProps) {
  const { reviewLineItems } = formState;
  const project = formState.project.value;

  return (
    <div>
      <GridTable
        api={tableApi}
        columns={createColumns(onRemoveLineItem, project)}
        rows={createRows(reviewLineItems.rows)}
        style={{ bordered: true, allWhite: true }}
      />
    </div>
  );
}

export const taskStatusColorMap: Record<TaskStatus, string> = {
  [TaskStatus.NotStarted]: Palette.Gray400,
  [TaskStatus.InProgress]: Palette.Blue500,
  [TaskStatus.Complete]: Palette.Green500,
  [TaskStatus.OnHold]: Palette.Yellow500,
  [TaskStatus.Delayed]: Palette.Red500,
};
