import { Global } from "@emotion/react";
import { Css, useTestIds } from "@homebound/beam";
import { LienWaiverCondition, LienWaiverType, PandaDocRole } from "src/generated/graphql-types";
import { Signature, SignatureDate } from "../../components/PandaDocField";
import { LienWaiverProps } from "../LienWaiverRouter";
import {
  amountInCents,
  buildAddress,
  claimantName,
  getClaimantName,
  getTitleField,
  invoiceNumber,
  owner,
  payerName,
  throughDate,
} from "../utils";

const FL_OWNER = "MF-HB Florida PropCo, LLC";

export function FLLienWaiver({ data }: LienWaiverProps) {
  const lienWaiver = data.lienWaiver!;
  const testIds = useTestIds({}, "FLLienWaiver");

  const conditional = lienWaiver.condition.code;
  const type = lienWaiver.type.code;
  const isConditional = conditional === LienWaiverCondition.Conditional;
  const isFinal = lienWaiver.type.code === LienWaiverType.Final;
  return (
    <>
      <Global
        styles={{
          "@page": { size: "legal" },
          ul: Css.ml1.my2.$,
          li: Css.my1.$,
        }}
      />

      <div {...testIds.container} css={Css.p2.$}>
        <div css={Css.mb1.lgSb.tac.$}>
          {conditional} WAIVER AND RELEASE OF LIEN UPON {type} PAYMENT
        </div>

        <div css={Css.mb2.$}>
          <p css={Css.mb2.tac.fwb.$}>Identifying Information</p>
          <div>
            <p>Name of Claimant: {claimantName(lienWaiver, "claimantName")}</p>
            <hr></hr>
            <p>Customer: {payerName(lienWaiver, "customer")}</p>
            <hr></hr>
            <p>Job Location: {buildAddress(lienWaiver)}</p>
            <hr></hr>
            <p>Owner: {owner(lienWaiver, FL_OWNER)}</p>
            <hr></hr>
            <p>Through Date: {throughDate(lienWaiver)}</p>
            <hr></hr>
            <p>Invoice Number: {invoiceNumber(lienWaiver)}</p>
            <hr></hr>
          </div>
        </div>

        <div css={Css.mb2.$}>
          {isConditional && (
            <p data-testid="firstParagraph" css={Css.mb2.$}>
              Upon receipt by the undersigned the sum of {amountInCents(lienWaiver)}, payable to the undersigned, and
              when the check has been properly endorsed and has been paid by the bank on which it is drawn, this waiver
              release document shall become effective and enforceable.
            </p>
          )}

          <p data-testid="secondParagraph">
            {isConditional && !isFinal ? <>Subject to the above, the</> : <>The</>} undersigned lienor, in consideration
            of the
            {isFinal ? <> final payment in the amount </> : <> sum </>}of {amountInCents(lienWaiver)}, hereby waives and
            releases its lien and right to claim a lien for labor, services, or materials furnished
            {!isFinal && <> through the Through Date as identified above</>}
            {isConditional && !isFinal ? <>, </> : <> to </>}
            {payerName(lienWaiver, "customer-paragraph")} on the job of {owner(lienWaiver, FL_OWNER)}, to the following
            {isFinal && <> described</>} property:
          </p>

          <div css={Css.df.jcc.m4.$}>
            <p>{lienWaiver.project.legalDescription}</p>
          </div>

          {!isFinal && (
            <p>
              This waiver and release does not cover any retention or labor, services, or materials furnished after the
              date specified.
            </p>
          )}
        </div>
        {isConditional || isFinal ? (
          <div css={Css.df.$}>
            <div css={Css.fg1.mt2.$}>
              <span>
                <SignatureDate role={PandaDocRole.Signatory} prefix="DATED on "></SignatureDate>
              </span>
            </div>
            <div css={Css.fg1.$}>
              <p>LIENOR:</p>
              <p>
                <Signature role={PandaDocRole.Signatory} prefix="By:" />
              </p>
              <p>{getClaimantName("Name:")}</p>
              <p>{getTitleField("Title:")}</p>
            </div>
          </div>
        ) : (
          <div css={Css.mb2.w(100).$}>
            <div css={Css.tdu.$}>
              <Signature role={PandaDocRole.Signatory} prefix="Lienor's Signature:" />
              <p>{getClaimantName("Lienor's Name:")}</p>
              <p>{getTitleField("Lienor's Title: ")}</p>
              <SignatureDate role={PandaDocRole.Signatory} prefix="Date of Signature:" />
            </div>
          </div>
        )}
      </div>
    </>
  );
}
