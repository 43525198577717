import { Css, ModalBody, ModalHeader, Palette, TextField, TextFieldApi, Tooltip, useModal } from "@homebound/beam";
import { Location } from "history";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { createProjectUrl, createTradePartnerUrl } from "src/RouteUrls";
import { Icon, IconKey } from "src/components/Icon";
import { GlobalSearch_RecordFragment, useGlobalSearchModalQuery } from "src/generated/graphql-types";
import { useDebouncedState } from "src/hooks";

/** Shows our cross-app search modal. */
export function GlobalSearchModal() {
  const [search, debouncedSearch, setSearch] = useDebouncedState("");
  const { closeModal } = useModal();
  const query = useGlobalSearchModalQuery({ variables: { search: debouncedSearch } });
  const location = useLocation();
  const [firstLocation] = useState(location);
  const textFieldApi = useRef<TextFieldApi | undefined>();

  // Close the modal when links are clicked; should probably be upstreamed into beam
  useEffect(() => {
    if (location !== firstLocation) closeModal();
  }, [location, firstLocation, closeModal]);

  // Wait for the modal to open before focusing the search field (autoFocus doesn't work in modals)
  useEffect(() => {
    setTimeout(() => {
      textFieldApi.current?.focus();
    }, 0);
  }, []);

  return (
    <>
      <ModalHeader>
        <TextField
          api={textFieldApi}
          label="Search"
          placeholder="Search"
          labelStyle="hidden"
          value={search}
          onChange={(value) => setSearch(value ?? "")}
          // Let escape close the modal, which isn't the default behavior
          onEscapeBubble
          xss={Css.xl2Sb.$}
        />
        <span css={Css.xsMd.gray600.$}>↓ "Tab" down or type "123 Address", "PO 123", "TP Business"</span>
      </ModalHeader>
      <ModalBody>
        {/* p1 so focus ring has space */}
        <div css={Css.df.fdc.p1.$}>
          <GlobalSearchModalBody records={query.data?.globalSearch.map((gsr) => gsr.record) ?? []} />
        </div>
      </ModalBody>
    </>
  );
}

/** Renders each of the ~30-ish search records for the given search query. */
export function GlobalSearchModalBody(props: { records: GlobalSearch_RecordFragment[] }) {
  const location = useLocation();
  const { records } = props;
  return records
    .map((r) => getRow(r, location))
    .compact()
    .map((data) => {
      return (
        <Link /* each result row */ key={data.id} to={data.primaryUrl} css={Css.h6.lineClamp1.df.jcsb.py3.px1.$}>
          <div css={Css.df.aic.gap1.$}>
            <Icon icon={data.icon} color={Palette.Gray400} />
            <Tooltip title={data.primaryText}>
              <span css={Css.lgMd.gray700.truncate.$}>{data.primaryText}</span>
            </Tooltip>
            <span css={Css.lg.gray600.truncate.$}>{data.detailText}</span>
          </div>
        </Link>
      );
    });
}

/** A generic structure for our search box rows. */
type RowData = {
  id: string;
  icon: IconKey;
  primaryText: string;
  primaryUrl: string;
  detailText: string | undefined;
};

/** Maps each GSR parent's unique data to our generic row data structure. */
function getRow(gsr: GlobalSearch_RecordFragment, location: Location): RowData | undefined {
  const { parent } = gsr;
  if (parent.__typename === "Project") {
    return {
      id: gsr.id,
      icon: "muiHome",
      primaryText: parent.buildAddress.street1,
      primaryUrl: getRedirectUrl(parent.id, location.pathname),
      detailText: parent.market.name,
    };
  } else if (parent.__typename === "TradePartner") {
    const contact = parent.contacts[0];
    return {
      id: gsr.id,
      icon: "muiConstruction",
      primaryText: parent.name,
      primaryUrl: createTradePartnerUrl(parent.id, "commitments"),
      detailText: contact ? [contact.mobilePhone ?? contact.officePhone, contact.name].compact().join(" ") : undefined,
    };
  } else if (parent.__typename === "Commitment") {
    return {
      id: gsr.id,
      icon: "muiReceiptLong",
      primaryText: `PO ${parent.accountingNumber} ${parent.name}`,
      primaryUrl: parent.blueprintUrl.path,
      detailText: parent.project.name,
    };
  } else if (parent.__typename === "CommitmentChangeOrder") {
    const owner = parent.commitment;
    return {
      id: gsr.id,
      icon: "muiReceiptLong",
      primaryText: `PO ${parent.accountingNumber} ${owner.name}`,
      primaryUrl: parent.blueprintUrl.path,
      detailText: owner.project.name,
    };
  } else if (parent.__typename === "Bill") {
    return {
      id: gsr.id,
      icon: "bill",
      primaryText: parent.name,
      primaryUrl: parent.blueprintUrl.path,
      detailText: parent.project.name,
    };
  } else {
    return undefined;
  }
}

export function getRedirectUrl(projectId: string, currentPath: string) {
  // Breaking down this RegEx:
  // `/p:\d*` -> translates to "p:[any digit]". This is the start of our matching
  // `()` -> Then define a group to return in parenthesis
  // With in that group...
  // `\/` -> Find first `/`, which would be immediately after the projectId
  // [^/]* -> Match all characters until finding the following `/` (no need to escape `/` if follows ^ in JS)
  // Example: "/projects/p:123/change-events/ce:321".match(projectPathRegex) would return: ["/p:123/change-events", "change-events", ...]
  const projectPathRegex = new RegExp(/p:\d+(\/[^/]*)/);

  const newProjectPath = createProjectUrl(projectId);
  const pathMatch = currentPath.match(projectPathRegex);
  // If we found a match, meaning the immediate path after the projectId, then use that otherwise just go to the Project Url.
  return `${newProjectPath}${pathMatch && pathMatch.length > 1 ? pathMatch[1] : ""}`;
}
