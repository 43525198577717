import {
  BoundNumberField,
  Css,
  GridColumn,
  GridDataRow,
  GridTable,
  RowStyles,
  collapseColumn,
  column,
  emptyCell,
  numericColumn,
} from "@homebound/beam";
import { FieldState, ObjectState } from "@homebound/form-state";
import {
  BidPackageTakeoffLineItemFragment,
  EditBidContractTradeLineItemFragment,
  InputMaybe,
  NamedFragment,
} from "src/generated/graphql-types";
import { DropCodeCell } from "src/routes/developments/product-offerings/components/DropCodeCell";
import { fail, groupBy, roundToDigits } from "src/utils";
import { getOptionsKey } from "../BidsTab";
import { FormValues, ProrationFormValues } from "../ProrateBidModal";
import { ProrationCostColumn, ProrationUnitCostColumn } from "./ProrationCostColumn";

export function initializePlanAndOptionFormState(
  scopeLineItems: BidPackageTakeoffLineItemFragment[],
  existingTradeLineItems: EditBidContractTradeLineItemFragment[],
): FormValues {
  const groupedTLIs = groupBy(scopeLineItems, (tli) => tli.readyPlan?.id + getOptionsKey(tli.options));

  return {
    bidContractTradeLineItems: Object.entries(groupedTLIs).map(([key, tlis]) => {
      // The existing trade line item would match the product offering and options combination
      const existingTradeLineItem = existingTradeLineItems.find(
        (tli) => tli.productOffering.id + getOptionsKey(tli.options) === key,
      );

      return {
        id: existingTradeLineItem?.id,
        unitCostInCents: undefined,
        totalCostInCents: existingTradeLineItem?.totalCostInCents,

        productOfferingId: tlis[0].readyPlan!.id,
        optionIds: tlis[0].options.sortByKey("id").map((o) => o.id),
        prorations: tlis.map((tli) => {
          // The existing proration line item would match the item template item as it is within the same product offering and options
          const existingProration = existingTradeLineItem?.prorations.find(
            (p) => p.lineItem.itemTemplateItem?.id === tli.publishedVersion?.id,
          );
          return {
            id: existingProration?.id,
            quantity: tli.quantity,
            unitCostInCents: existingProration
              ? roundToDigits(existingProration.costInCents / (tli.quantity || 1), 1)
              : undefined,
            costInCents: existingProration?.costInCents,
            estimatedCostInCents: tli.costEstimate.costInCents,
            userEntered: existingProration?.userEntered || false,

            itemTemplateItemId: tli.publishedVersion?.id,
          };
        }),
      };
    }),
  };
}

type PlanAndOptionTablesProps = {
  formState: ObjectState<FormValues>;
  scopeLineItems: BidPackageTakeoffLineItemFragment[];
  tradePartnerName: string;
};

export function PlanAndOptionTables({ formState, scopeLineItems, tradePartnerName }: PlanAndOptionTablesProps) {
  const productOfferings = scopeLineItems.map((tli) => tli.readyPlan!).unique();
  const columns = createColumns();
  return (
    <div css={Css.df.fdc.gap3.w100.$}>
      {productOfferings.map((po) => {
        const rows = createRows(po, formState, scopeLineItems, tradePartnerName);
        return <GridTable key={po.id} columns={columns} rows={rows} rowStyles={rowStyles} />;
      })}
    </div>
  );
}

const rowStyles: RowStyles<Row> = {
  productOffering: {
    cellCss: Css.py3.$,
  },
  head: {
    cellCss: Css.smMd.$,
  },
};

function createRows(
  productOffering: NamedFragment,
  formState: ObjectState<FormValues>,
  scopeLineItems: BidPackageTakeoffLineItemFragment[],
  tradePartnerName: string,
): GridDataRow<Row>[] {
  const itemTemplateItems = scopeLineItems.filter((tli) => tli.readyPlan!.id === productOffering.id);

  const optionGroupBy = groupBy(itemTemplateItems, (tli) => getOptionsKey(tli.options));
  const optionRows = Object.keys(optionGroupBy).map((key) => {
    const children = optionGroupBy[key];
    const tli = children[0];
    const bidContractTradeLineItem = formState.bidContractTradeLineItems.rows.find(
      (bcli) => bcli.productOfferingId.value === productOffering.id && (bcli.optionIds.value || []).join(",") === key,
    );
    if (!bidContractTradeLineItem) {
      fail("BidContractTradeLineItem not found for product offering and options");
    }
    return {
      kind: "option" as const,
      id: key || "base",
      data: {
        options: tli.options,
        totalCostField: bidContractTradeLineItem.totalCostInCents,
      },
      children: children.map((itemTemplateItem) => {
        const prorationState = bidContractTradeLineItem.prorations.rows.find(
          (p) => p.itemTemplateItemId.value === itemTemplateItem.publishedVersion?.id,
        );
        if (!prorationState) {
          fail("prorationLineItem not found for item template item");
        }
        return {
          kind: "tli" as const,
          id: itemTemplateItem.id,
          data: {
            itemTemplateItem,
            prorationState,
          },
        };
      }),
    };
  });

  return [
    {
      kind: "productOffering" as const,
      id: "productOffering",
      data: {
        productOfferingName: productOffering.name,
        tradePartnerName,
      },
      pin: "first",
    },
    { kind: "head" as const, id: "head", data: undefined, pin: "last" },
    ...optionRows,
  ];
}

type ProductOfferingRow = {
  kind: "productOffering";
  data: {
    productOfferingName: string;
    tradePartnerName: string;
  };
};
type HeaderRow = { kind: "head"; data: undefined };
type OptionRow = { kind: "option"; data: { options: NamedFragment[]; totalCostField: FieldState<InputMaybe<number>> } };
type TLIRow = {
  kind: "tli";
  data: {
    itemTemplateItem: BidPackageTakeoffLineItemFragment;
    prorationState: ObjectState<ProrationFormValues>;
  };
};

type Row = HeaderRow | ProductOfferingRow | OptionRow | TLIRow;

function createColumns(): GridColumn<Row>[] {
  return [
    collapseColumn<Row>({
      productOffering: ({ productOfferingName, tradePartnerName }) => ({
        content: (
          <div css={Css.df.w100.aic.$}>
            <div css={Css.xlSb.$}>
              {productOfferingName} <span css={Css.gray600.$}>{`— ${tradePartnerName} Bid`}</span>
            </div>
          </div>
        ),
        colspan: 9,
      }),
    }),
    column<Row>({
      productOffering: emptyCell,
      head: "Code",
      option: emptyCell,
      tli: ({ itemTemplateItem }) => <DropCodeCell scope={itemTemplateItem} />,
      w: "100px",
    }),
    column<Row>({
      productOffering: emptyCell,
      head: "Name",
      option: ({ options }) => ({ content: options.map((o) => o.name).join(", ") || "Base", css: Css.smBd.$ }),
      tli: ({ itemTemplateItem }) => itemTemplateItem.name,
    }),
    column<Row>({
      productOffering: emptyCell,
      head: "Material Code",
      option: emptyCell,
      tli: ({ itemTemplateItem }) => itemTemplateItem.bidItem?.parentMaterialVariant?.code,
      // w: "120px", are the material codes going to be this long?
    }),
    column<Row>({
      productOffering: emptyCell,
      head: "Qty",
      option: emptyCell,
      tli: ({ itemTemplateItem }) => itemTemplateItem.quantity,
      w: "53px",
    }),
    column<Row>({
      productOffering: emptyCell,
      head: "UoM",
      option: emptyCell,
      tli: ({ itemTemplateItem }) => itemTemplateItem.unitOfMeasure.shortName,
      w: "67px",
    }),
    numericColumn<Row>({
      productOffering: emptyCell,
      head: "Cost",
      option: emptyCell,
      tli: ProrationUnitCostColumn,
      w: "160px",
    }),
    numericColumn<Row>({
      productOffering: emptyCell,
      head: "Total Cost",
      option: ({ totalCostField }) => <BoundNumberField field={totalCostField} />,
      tli: ProrationCostColumn,
      w: "160px",
    }),
  ];
}
