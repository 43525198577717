import { Css } from "@homebound/beam";
import { GeneralTlivChangesFragment } from "src/generated/graphql-types";
import { renderAccordion } from "src/routes/libraries/versioning/VersionHistoryUtils";
import { count } from "src/utils";

type ItemSlotUpdatesProps = {
  tliVersions: GeneralTlivChangesFragment[];
};

export function ItemSlotUpdates(props: ItemSlotUpdatesProps) {
  const { tliVersions } = props;
  const placeholderTlivs = tliVersions.filter((tliv) => !tliv.placeholder?.id);

  if (placeholderTlivs.isEmpty) return null;

  return (
    <>
      {renderAccordion(
        `Updated ${count(placeholderTlivs, "item-slot")}`,
        <ul css={Css.m0.$}>
          {placeholderTlivs.map((tliv) => (
            <li data-testid="itemSlot" key={tliv.id} css={Css.baseMd.$}>
              {tliv.location.effectiveLocation?.name}: {tliv.identity.item.name}
            </li>
          ))}
        </ul>,
      )}
    </>
  );
}
