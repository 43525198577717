import {
  BoundNumberField,
  BoundSelectField,
  collapseColumn,
  column,
  Css,
  emptyCell,
  GridColumn,
  GridDataRow,
  GridRowLookup,
  GridTable,
  GridTableApi,
  numericColumn,
  ScrollableContent,
  selectColumn,
  Tooltip,
  useComputed,
  useTestIds,
} from "@homebound/beam";
import { ObjectState, useFormStates } from "@homebound/form-state";
import { MutableRefObject, useMemo } from "react";
import { emptyCellDash, percentageCell, priceCell } from "src/components";
import { StatusIndicator } from "src/components/StatusIndicator";
import { CostType, SpecsAndSelections_UnitOfMeasureFragment } from "src/generated/graphql-types";
import { SpecsAndSelectionsMode, SpecsAndSelectionsTask } from "src/routes/projects/components/SpecsAndSelectionsTable";
import { createSpecsAndSelectionRows } from "src/routes/projects/components/utils";
import {
  ObservableProjectItem,
  projectItemFormConfig,
  ProjectItemFormInput,
} from "src/routes/projects/models/ObservableProjectItem";
import { ProjectItemStore, SpecsAndSelectionsRow } from "src/routes/projects/models/ProjectItemStore";
import { setupPriceFields } from "src/routes/projects/selections/recalc";
import {
  commentColumn,
  getProjectItemColumn,
  idColumn,
  maybeBidItemColumn,
  quantityColumn,
  unitColumn,
} from "src/routes/projects/selections/sharedColumns";
import { centsToDollars, formatNumberToString, safeEntries } from "src/utils";
import { maybeAddIdColumn } from "src/utils/idColumn";

export type SpecsTableProps = {
  /** Optional if you don't want to link to an item details page. */
  createProjectItemUrl?: (projectItemId: string) => string;
  piStore: ProjectItemStore;
  /** Optional if readOnly: true. */
  unitsOfMeasure?: SpecsAndSelections_UnitOfMeasureFragment[];
  disableSelectable: boolean;
  showPlanSource: boolean;
  textFilter?: string;
  persistCollapse: string;
  mode?: SpecsAndSelectionsMode;
  rowLookup: MutableRefObject<GridRowLookup<GridDataRow<SpecsAndSelectionsRow>> | undefined>;
  scheduleTasks?: SpecsAndSelectionsTask[];
  autoSave: (state: ObjectState<ProjectItemFormInput>) => Promise<void>;
  api?: GridTableApi<SpecsAndSelectionsRow>;
};

export function SpecsTable(props: SpecsTableProps) {
  const {
    piStore,
    unitsOfMeasure,
    disableSelectable,
    showPlanSource,
    mode = "S&S",
    textFilter = "",
    createProjectItemUrl,
    persistCollapse,
    rowLookup,
    scheduleTasks,
    autoSave,
    api,
  } = props;
  const specsTableId = useTestIds({}, "specsTable");

  const { getFormState } = useFormStates<ProjectItemFormInput, ObservableProjectItem>({
    config: projectItemFormConfig,
    getId: (v) => v.id,
    autoSave,
    addRules(formState) {
      // Recalc cost/markup/price
      setupPriceFields(
        {
          unitCostInCents: formState.unitCostInCents,
          totalCostInCents: formState.totalCostInCents,
          totalPriceInCents: formState.totalPriceInCents,
          markupPercentage: formState.markupPercent,
          totalMarkupInCents: formState.markupAmount,
          quantity: formState.quantity,
        },
        { canEditPrice: formState.canEditPrice.value },
      );
    },
  });

  const columns = useMemo(
    () => createColumns(disableSelectable, unitsOfMeasure, createProjectItemUrl, mode, getFormState, scheduleTasks),

    [disableSelectable, unitsOfMeasure, createProjectItemUrl, mode, scheduleTasks, getFormState],
  );
  const rows = useComputed(() => createSpecsAndSelectionRows(piStore, showPlanSource), [piStore, showPlanSource]);

  return (
    <div {...specsTableId}>
      <ScrollableContent virtualized>
        <GridTable
          as="virtual"
          style={{ grouped: true, inlineEditing: true, allWhite: true, rowHeight: "flexible", bordered: true }}
          columns={columns}
          rows={rows}
          filter={textFilter}
          stickyHeader
          persistCollapse={persistCollapse}
          fallbackMessage="No specs or selections found that matched the given filters."
          rowLookup={rowLookup}
          sorting={{ on: "client", initial: ["projectItem", "ASC"] }}
          api={api}
        />
      </ScrollableContent>
    </div>
  );
}

function createColumns(
  disableSelectable: boolean,
  unitsOfMeasure: SpecsAndSelections_UnitOfMeasureFragment[] | undefined,
  createProjectItemUrl: ((projectItemId: string) => string) | undefined,
  mode: SpecsAndSelectionsMode,
  getFormState: (input: ObservableProjectItem) => ObjectState<ProjectItemFormInput>,
  scheduleTasks?: SpecsAndSelectionsTask[],
): GridColumn<SpecsAndSelectionsRow>[] {
  const readOnly = mode === "estimate" || mode === "S&S-PartialReadOnly";
  const costTypeColumn = column<SpecsAndSelectionsRow>({
    header: () => "Cost Type",
    totals: () => emptyCell,
    group: () => emptyCell,
    projectItem: (row) => {
      const costTypeTuple = safeEntries(CostType).find(([_, code]) => code === row.costType);
      return costTypeTuple ? costTypeTuple[0] : row.costType;
    },
    lineItem: emptyCell,
    w: "108px",
  });

  const unitCostColumn = numericColumn<SpecsAndSelectionsRow>({
    header: () => "Unit Cost",
    totals: () => emptyCell,
    group: () => emptyCell,
    projectItem: (row) => ({
      content: () => {
        if (row.fragment.unitOfMeasure.useQuantity === false || row.quantity === 0) {
          return <div data-testid="unitCost">N/A</div>;
        }
        const os = getFormState(row);
        return (
          <BoundNumberField
            type="cents"
            field={os.unitCostInCents}
            readOnly={mode === "estimate" || !row.canEditCost}
          />
        );
      },
      value: row.fragment.unitOfMeasure.useQuantity === false ? 0 : row.unitCostInCents,
    }),
    lineItem: () => emptyCell,
    w: "120px",
  });

  const totalCostColumn = numericColumn<SpecsAndSelectionsRow>({
    header: () => "Total Cost",
    totals: (row) => priceCell({ valueInCents: row.totalCostInCents }),
    group: (row) => priceCell({ valueInCents: row.totalCostInCents }),
    projectItem: (row) => {
      const os = getFormState(row);
      return {
        content: () => (
          <BoundNumberField
            type="cents"
            field={os.totalCostInCents}
            readOnly={mode === "estimate" || !row.canEditCost}
          />
        ),
        value: row.totalCostInCents,
      };
    },
    lineItem: (row) => priceCell({ valueInCents: row.totalCostInCents, displayDirection: true }),
    w: "120px",
  });

  const markupPercentColumn = numericColumn<SpecsAndSelectionsRow>({
    header: () => "Markup %",
    totals: (row) => percentageCell(row.totalMarkupPercent),
    group: (row) => percentageCell(row.totalMarkupPercent),
    projectItem: (row) => {
      const os = getFormState(row);
      return {
        content: () => (
          <BoundNumberField
            type="percent"
            field={os.markupPercent}
            readOnly={readOnly || !row.canEditPrice}
            disabled={row.disableMarkup}
            errorMsg={row.alertMarkup}
          />
        ),
        value: row.markupPercent ?? 0,
      };
    },
    lineItem: () => emptyCell,
    w: "120px",
  });

  const markupAmountColumn = numericColumn<SpecsAndSelectionsRow>({
    header: () => "Markup Amount",
    totals: (row) => priceCell({ valueInCents: row.totalMarkupAmount }),
    group: (row) => priceCell({ valueInCents: row.totalMarkupAmount }),
    projectItem: (row) => {
      const os = getFormState(row);
      return {
        content: () => (
          <BoundNumberField
            type="cents"
            field={os.markupAmount}
            readOnly={readOnly || !row.canEditPrice}
            disabled={row.disableMarkup}
          />
        ),
        value: row.markupAmount,
      };
    },
    lineItem: () => emptyCell,
    w: "140px",
  });

  const totalPriceColumn = numericColumn<SpecsAndSelectionsRow>({
    header: () => "Total Price",
    totals: (row) => priceCell({ valueInCents: row.totalPriceInCents }),
    group: (row) => priceCell({ valueInCents: row.totalPriceInCents }),
    projectItem: (row) => {
      const os = getFormState(row);
      return {
        content: () => (
          <BoundNumberField
            type="cents"
            field={os.totalPriceInCents}
            readOnly={readOnly || !row.canEditPrice}
            disabled={row.disableMarkup}
          />
        ),
        value: row.totalPriceInCents,
      };
    },
    lineItem: () => emptyCell,
    w: "120px",
  });

  const locationColumn = column<SpecsAndSelectionsRow>({
    header: () => "Location",
    totals: () => emptyCell,
    group: () => emptyCell,
    projectItem: (row) => row.fragment.location?.name,
    lineItem: () => emptyCell,
    w: "140px",
  });

  const allocationColumn = column<SpecsAndSelectionsRow>({
    header: () => "Allocation",
    totals: (row) => {
      const [allocated, total] = row.children.reduce(
        (acc, c) => [acc[0] + c.allocatedCountWithCost, acc[1] + c.itemCountWithCost],
        [0, 0],
      );
      return `${allocated}/${total}`;
    },
    group: (row) => ({
      content: () => (
        <Tooltip
          title={`Dollars Allocated: $${formatNumberToString(
            centsToDollars(row.allocatedTotal),
          )} / $${formatNumberToString(centsToDollars(Math.abs(row.totalCostInCents)))}`}
          placement="top"
        >
          <span data-testid="groupAllocationProgress">{`${row.allocatedCountWithCost}/${row.itemCountWithCost}`}</span>
        </Tooltip>
      ),
      value: `${row.allocatedCountWithCost}/${row.itemCountWithCost}`,
      sortValue: row.itemCountWithCost > 0 ? row.allocatedCountWithCost / row.itemCountWithCost : 0,
    }),
    projectItem: (row) => {
      const { name: taskName } = row.fragment.task || {};
      const undefinedOption = { id: "", name: emptyCellDash, status: {} as any };
      const editable = mode !== "estimate" && scheduleTasks;
      const os = getFormState(row);
      return {
        content: () =>
          editable ? (
            <Tooltip title={taskName} placement="top" disabled={!taskName}>
              <BoundSelectField
                field={os.taskId}
                readOnly={row.fragment.project.enableProductConfigPlan}
                options={[undefinedOption, ...scheduleTasks]}
                fieldDecoration={(tsk) => <StatusIndicator status={tsk.status} />}
                getOptionMenuLabel={(tsk) => (
                  <div css={Css.dg.gtc("auto auto").xs.aic.$}>
                    <StatusIndicator status={tsk.status} />
                    <span css={Css.ml3.$}>{tsk.name}</span>
                  </div>
                )}
              />
            </Tooltip>
          ) : (
            <span css={Css.truncate.$} data-testid="taskName">
              <Tooltip title={taskName} placement="top" disabled={!taskName}>
                {taskName || emptyCellDash}
              </Tooltip>
            </span>
          ),
        value: taskName ?? "",
      };
    },
    lineItem: () => emptyCell,
    w: "200px",
  });

  const columns = [
    collapseColumn<SpecsAndSelectionsRow>({ totals: emptyCell, sticky: "left" }),
    ...maybeAddIdColumn(idColumn()),
    getProjectItemColumn({ createProjectItemUrl, showSelections: false }),
    commentColumn(),
    allocationColumn,
    locationColumn,
    costTypeColumn,
    ...maybeBidItemColumn(mode, readOnly),
    quantityColumn(readOnly, getFormState),
    unitColumn(readOnly, unitsOfMeasure, getFormState),
    unitCostColumn,
    totalCostColumn,
    markupPercentColumn,
    markupAmountColumn,
    totalPriceColumn,
  ];

  if (!disableSelectable) {
    columns.splice(
      1,
      0,
      selectColumn<SpecsAndSelectionsRow>({ totals: emptyCell, lineItem: emptyCell, sticky: "left" }),
    );
  }

  return columns;
}
