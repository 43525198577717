import {
  Css,
  FilterDefs,
  Filters,
  GridDataRow,
  GridRowLookup,
  GridTableApi,
  Switch,
  useGroupBy,
  useSuperDrawer,
} from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { matchPath, useHistory, useParams, useRouteMatch } from "react-router-dom";
import { createProjectSelectionDetailsUrl, createProjectSelectionsUrl } from "src/RouteUrls";
import { SearchBox } from "src/components/SearchBox";
import {
  Named,
  ProjectItemInput,
  SpecsAndSelections_ProjectItemFragment,
  SpecsAndSelections_ScheduleTaskFragment,
  SpecsAndSelections_UnitOfMeasureFragment,
  Stage,
} from "src/generated/graphql-types";
import { useQueryStringPersistedToggle } from "src/hooks";
import { SelectionsTable } from "src/routes/projects/components/SelectionsTable";
import { SpecsTable } from "src/routes/projects/components/SpecsTable";
import { SpecsAndSelectionsRow } from "src/routes/projects/models/ProjectItemStore";
import { SelectionsSuperDrawer } from "src/routes/projects/selections/SelectionsSuperDrawer";
import { ProjectStageParams, projectPaths } from "src/routes/routesDef";
import { getStageFromSlug } from "src/utils";
import { StringParam, useQueryParams } from "use-query-params";
import { useProjectItemsStore } from "../context/ProjectItemsContext";
import { ObservableProjectItem, ProjectItemFormInput } from "../models/ObservableProjectItem";
import { SpecsAndSelectionsFilter } from "./SpecsAndSelectionsFilterModal";
import { isSelection, isUnallocatedCost } from "./utils";

// estimate == in use on the estimate page
// S&S == in use on the Specs and Selections page
// S&S-PartialReadOnly == canEditProjectItemsDirectly but still allow setting cost, if canEditCost is true
//
// Ideally S&S-PartialReadOnly should just be removed for directly checking canEditScope
export type SpecsAndSelectionsMode = "estimate" | "S&S" | "S&S-PartialReadOnly";
export type SpecsAndSelectionsTask = Pick<SpecsAndSelections_ScheduleTaskFragment, "id" | "name" | "status">;

export type SpecsAndSelectionsTableProps = {
  locations?: Named[];
  /** This can be optional if you don't want to link a line item to a sub-page. */
  createProjectItemUrl?(projectItemId: string): string;
  disableSelectable?: boolean;
  mode?: SpecsAndSelectionsMode;
  onSave?: (input: ProjectItemInput) => Promise<boolean>;
  projectItems: SpecsAndSelections_ProjectItemFragment[];
  scheduleTasks?: SpecsAndSelectionsTask[];
  filterDefs: FilterDefs<SpecsAndSelectionsFilter>;
  uiFilter: SpecsAndSelectionsFilter;
  setFilter: (filter: SpecsAndSelectionsFilter) => void;
  /**
   * Optionally give a Stage when it cannot be determined via the route.
   * Notably used for /specs-and-selections and /pre-con-services routes.
   */
  stage?: Stage;
  unitsOfMeasure?: SpecsAndSelections_UnitOfMeasureFragment[];
  bulkActionsMenu?: ReactNode;
  api?: GridTableApi<SpecsAndSelectionsRow>;
};

/**
 * Renders a table showing specs and selections for the project and stage
 * specified in the URL params.
 *
 * Note: This table assumes the base URL structure of /:projectId with a given
 * stage prop or omitted stage prop when the URL has a structure of
 * /:projectId/?/:stageSlug
 */
export function SpecsAndSelectionsTable(props: SpecsAndSelectionsTableProps) {
  const {
    locations,
    createProjectItemUrl,
    disableSelectable = false,
    mode = "S&S",
    projectItems,
    scheduleTasks,
    filterDefs,
    uiFilter,
    setFilter,
    stage: _stage,
    unitsOfMeasure,
    bulkActionsMenu,
    api,
    onSave,
  } = props;
  const { projectId, stageSlug } = useParams<ProjectStageParams>();
  const stage = _stage ?? getStageFromSlug(stageSlug);
  const { push } = useHistory();
  // TODO: Review the usage of this queryParams
  const [{ projectItemId }] = useQueryParams({ projectItemId: StringParam });
  const groupBy = useGroupBy({
    costCode: "Cost Code",
    tradeCategory: "Trade Category",
  });
  const [textFilter, setTextFilter] = useState<string>();
  const [showPlanSource, togglePlanSource] = useQueryStringPersistedToggle("showPlanSource");
  const { initialize, store: piStore } = useProjectItemsStore();
  const { openInDrawer, isDrawerOpen, closeDrawer } = useSuperDrawer();

  const autoSave: (state: ObjectState<ProjectItemFormInput>) => Promise<void> = useCallback(
    async (formState) => {
      if (onSave) {
        // Handle fields which need to be placed under the `homeownerSelection` field.
        // NOTE: We do not want to include the `homeownerSelection` key, when these fields are not set
        // If that key is set inadvertently, the mutation may attempt to create `HomeownerSelection` entities
        // for specifications, which will cause it to fail. We also want to do changes to the selection
        // independently of changes to the project item.
        // (never save `unitCostInCents`, we save `totalCostInCents` instead)
        const { isSelectionPublished, id, unitCostInCents, markupAmount, markupPercent, ...projectItemInput } =
          formState.changedValue;
        let success;
        if ([isSelectionPublished].some((v) => v !== undefined)) {
          success = await onSave({ id, homeownerSelection: { isPublished: isSelectionPublished } });
        } else {
          success = await onSave({ id, ...projectItemInput });
        }
        if (!success) {
          formState.revertChanges();
        } else {
          // Commit to reset 'originals' so the next diff is correct; should upstream this into form-state
          formState.commitChanges();
        }
      }
    },
    [onSave],
  );

  const showSelections = uiFilter.view === "selections";
  const showUnallocatedCosts = uiFilter.unallocatedCosts;

  const projectItemPathMatch = useRouteMatch<{ projectItemId: string }>([
    projectPaths.preConServicesDetail,
    projectPaths.specsAndSelectionsDetail,
  ]);

  const selectionsTableId = `selections-${projectId}-${stage}`;
  const specsTableId = `specs-${projectId}-${stage}`;

  useEffect(() => {
    initialize(
      projectItems
        .filter((pi) => !showSelections || isSelection(pi))
        .filter((pi) => !showUnallocatedCosts || isUnallocatedCost(pi)),
      groupBy.value,
    );
  }, [projectItems, showSelections, initialize, groupBy.value, showUnallocatedCosts]);

  // Scroll the page to the row where the projectItem is at
  useEffect(() => {
    if (projectItemId) {
      const el = document.getElementById(`lineItem_${projectItemId}`);
      el?.scrollIntoView({ block: "center" });
    }
  }, [projectItemId]);

  // ref to track when the url path changes when clicking prev/next buttons in the SuperDrawer
  // TODO: create a hook combining useRef and useEffect to tell if value changed
  const prevPathProjectItemRef = useRef<string | undefined>();
  const prevPathProjectItem = prevPathProjectItemRef.current;
  const pathProjectItemId = projectItemPathMatch?.params?.projectItemId;

  useEffect(() => {
    prevPathProjectItemRef.current = pathProjectItemId;
  });

  const rowLookup = useRef<GridRowLookup<Row>>();

  const openRow = useCallback(
    (projectItemId: string, row?: GridDataRow<Row>) => {
      if (rowLookup.current) {
        const list = rowLookup.current.currentList();
        const lineItemRows = list.filter((l) => l.kind === "projectItem");
        const piRow = row ?? lineItemRows.find((r) => r.id === projectItemId);

        if (!piRow) return;
        const { prev, next } = findPrevNext(lineItemRows, piRow);
        /**
         * Update URL with the prev/next projectItem id. We do not need to
         * explicity call openRow since the useEffect below watches for URL
         * changes and triggers a SuperDrawer update.
         */
        const handlePrevNextClick = (newRow: GridDataRow<Row>) => {
          const url = `${createProjectSelectionDetailsUrl(projectId, stage, newRow.id)}${window.location.search}`;
          push(url);
        };
        const observablePIRow = piRow.data as ObservableProjectItem;
        openInDrawer({
          content: <SelectionsSuperDrawer projectId={projectId} stage={stage} projectItem={observablePIRow} />,
          onClose: () => {
            // Only redirect back to Selections page if we are still on the Project Item's Detail URL.
            // It is possible we could be redirecting outside of the page which caused the drawer to close. If so, then do not redirect back to Selections page.
            if (
              matchPath(window.location.pathname, {
                path: [projectPaths.preConServicesDetail, projectPaths.specsAndSelectionsDetail],
              })
            ) {
              // When closing the SuperDrawer, remove /:projectItemId and retain query parameters
              push(`${createProjectSelectionsUrl(projectId, stage)}${window.location.search}`);
            }
          },
          onPrevClick: prev && (() => handlePrevNextClick(prev)),
          onNextClick: next && (() => handlePrevNextClick(next)),
        });
      }
    },
    [openInDrawer, projectId, stage, push],
  );

  // Attempt to open the SuperDrawer based on the queryParam projectItemId
  useEffect(
    () => {
      /*
      Only open the SuperDrawer when:
       - /specs-and-selections/:projectItemId is in the route path
       - /pre-con-services/:projectItemId is in the route path
       - The SuperDrawer provider is not already open or the SuperDrawer project item has changed (using prev/next buttons)
    */
      if (pathProjectItemId && (!isDrawerOpen || pathProjectItemId !== prevPathProjectItem)) {
        openRow(pathProjectItemId);
      } else if (isDrawerOpen && !pathProjectItemId && pathProjectItemId !== prevPathProjectItem) {
        closeDrawer();
      }
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectItemPathMatch, isDrawerOpen, openRow, pathProjectItemId, prevPathProjectItem],
  );

  const { specsTable, selectionsTable } = useMemo(
    () => ({
      specsTable: (
        <SpecsTable
          {...{
            piStore,
            disableSelectable,
            showPlanSource,
            mode,
            textFilter,
            unitsOfMeasure,
            createProjectItemUrl,
            persistCollapse: specsTableId,
            rowLookup,
            autoSave,
            api,
          }}
          scheduleTasks={scheduleTasks || []}
        />
      ),
      selectionsTable: (
        <SelectionsTable
          {...{
            piStore,
            disableSelectable,
            showPlanSource,
            textFilter,
            createProjectItemUrl,
            unitsOfMeasure,
            mode,
            locations: locations || [],
            persistCollapse: selectionsTableId,
            rowLookup,
            autoSave,
            api,
          }}
        />
      ),
    }),
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      piStore,
      disableSelectable,
      showPlanSource,
      textFilter,
      unitsOfMeasure,
      createProjectItemUrl,
      locations,
      specsTableId,
      selectionsTableId,
      mode,
      autoSave,
      api,
    ],
  );

  // Using an offset to determine height of container. This will be based on the possible content above it.
  // If construction, then the offset includes the Tab height + "Add Design Package" action height. Otherwise it is just the tab height
  const heightCalcOffset = stage === Stage.Construction ? 108 : 46;

  return (
    <div css={Css.h(`calc(100% - ${heightCalcOffset}px)`).$}>
      <div css={Css.df.gap3.pb1.$}>
        <Filters groupBy={groupBy} filter={uiFilter} filterDefs={filterDefs} onChange={setFilter} />
        <Switch label="Show Plan Source" selected={showPlanSource} onChange={togglePlanSource} />
        <div css={Css.df.aic.gap1.mla.$}>
          <SearchBox onSearch={setTextFilter} />
          {bulkActionsMenu}
        </div>
      </div>
      {showSelections ? selectionsTable : specsTable}
    </div>
  );
}

type Row = GridDataRow<SpecsAndSelectionsRow>;
// re-writing grid table lookup to skip specs
// we can use the grid table lookup if we change to showing specs in superdrawer as well
function findPrevNext(
  rows: GridDataRow<Row>[],
  row: GridDataRow<Row>,
): { prev: GridDataRow<Row>; next: GridDataRow<Row> } {
  let key: "prev" | "next" = "prev";
  const result: any = {};

  for (let i = 0; i < rows.length; i++) {
    const each = rows[i];
    // Flip from prev to next when we find it
    if (each.kind === row.kind && each.id === row.id) {
      key = "next";
    } else {
      if (key === "prev") {
        // prev always overwrites what was there before
        result[key] = each;
      } else {
        // next only writes first seen
        result[key] ?? (result[key] = each);
      }
    }
  }
  return result;
}
