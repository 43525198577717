import { Css, Icon, Palette, Tag, useTestIds } from "@homebound/beam";
import { Link } from "react-router-dom";
import { ReadyPlanDetailFragment } from "src/generated/graphql-types";
import { createPlanDetailsUrl, createPlanPackageUrl } from "src/RouteUrls";
import { readyPlanStatusToTagType } from "src/utils";

type ReadyPlanCardProp = {
  readyPlan: ReadyPlanDetailFragment;
};
export function ReadyPlanCard({ readyPlan }: ReadyPlanCardProp) {
  const tid = useTestIds({}, "readyPlanCard");
  const { name, id, development, status, displayCode, activeOptionsCount } = readyPlan;
  return (
    <Link
      {...tid}
      css={Css.bgWhite.wPx(200).br8.bshBasic.p2.df.fdc.jcsb.gap3.aifs.onHover.bshHover.$}
      to={development ? createPlanDetailsUrl(development.id, id) : createPlanPackageUrl(id, "latest")}
    >
      <Tag text={status.name} type={readyPlanStatusToTagType[status.code]} {...tid.status} />
      <div>
        <h3 css={Css.smBd.mbPx(4).wbbw.gray900.$} {...tid.name}>
          {name}
        </h3>
        <div css={Css.df.gap1.$}>
          <div css={Css.df.aic.gapPx(4).$}>
            <Icon icon="house" inc={1.5} color={Palette.Gray500} />
            <span css={Css.tiny.gray700.$} {...tid.displayCode}>
              {displayCode ? displayCode : "N/A"}
            </span>
          </div>

          <div css={Css.df.aic.gapPx(4).$}>
            <Icon icon="customize" inc={1.5} color={Palette.Gray500} />
            <span css={Css.tiny.gray700.$} {...tid.activeOptionsCount}>
              {activeOptionsCount} options
            </span>
          </div>
        </div>
      </div>
    </Link>
  );
}
