import {
  BoundMultiSelectField,
  BoundTextAreaField,
  BoundTextField,
  Css,
  FormLines,
  ToggleChipGroup,
} from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { UppyFile } from "@uppy/core";
import { LotType, PotentialOperationDetailsFragment } from "src/generated/graphql-types";
import { useMarketFilter } from "src/hooks";
import { disableBasedOnPotentialOperation } from "src/routes/components/PotentialOperationsUtils";
import { CoverPhotoUploader } from "src/routes/libraries/plan-package/stepper/components/CoverPhotoUploader";
import { PlanPackageDetailsForm } from "../utils";
import { CreatePlanPackageCode } from "./CreatePlanPackageCode";
import { PlanPackageDetailsCard } from "./PlanPackageDetailsCard";

export type PlanOverviewCardProps = {
  formState: ObjectState<PlanPackageDetailsForm>;
  lotTypes: LotType[] | undefined | null;
  coverPhotoUrl: string | null | undefined;
  saveCoverPhoto: (file: UppyFile | undefined) => void;
  canEdit: PotentialOperationDetailsFragment | undefined;
};

export function PlanOverviewCard(props: PlanOverviewCardProps) {
  const { formState, coverPhotoUrl, saveCoverPhoto, lotTypes, canEdit } = props;
  const { options: markets } = useMarketFilter();

  return (
    <PlanPackageDetailsCard title="Plan Overview" width={731}>
      <div css={Css.bt.bcGray200.pt3.pb1.bn.pt0.$}>
        <FormLines width="full" labelStyle="left" gap={3} labelLeftFieldWidth="65%">
          <BoundTextField
            field={formState.name}
            label="Plan Name*"
            placeholder="i.e. The Everett..."
            compact
            required
            disabled={disableBasedOnPotentialOperation(canEdit)}
          />

          <ToggleChipGroup
            label="Product Types(s)"
            options={Object.values(LotType).map((lotType) => ({
              label: lotType,
              value: lotType,
              disabled: disableBasedOnPotentialOperation(canEdit),
            }))}
            values={lotTypes ?? []}
            onChange={(values) => {
              formState.lotTypes.set(values as LotType[]);
            }}
          />
          <BoundMultiSelectField
            label="Market(s)"
            field={formState.markets}
            labelStyle="left"
            options={markets}
            disabled={disableBasedOnPotentialOperation(canEdit)}
          />
          <BoundTextAreaField
            labelStyle="left"
            field={formState.description}
            label="Sales Description"
            placeholder="i.e. This large single family residence is ideal for those looking for a first-level primary suite and the perfect balance between an open layout and private spaces. ..."
            compact
            disabled={disableBasedOnPotentialOperation(canEdit)}
          />
          <CreatePlanPackageCode
            codeField={formState.code}
            typeField={formState.type}
            disabled={disableBasedOnPotentialOperation(canEdit)}
          />
          <div css={Css.df.$}>
            <div css={Css.w("35%").fs0.$}>Plan Cover Photo</div>
            <CoverPhotoUploader
              coverPhotoUrl={coverPhotoUrl}
              saveCoverPhoto={saveCoverPhoto}
              disabled={disableBasedOnPotentialOperation(canEdit)}
            />
          </div>
        </FormLines>
      </div>
    </PlanPackageDetailsCard>
  );
}
