import { Css, Palette, useTestIds } from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { Card } from "src/components/Card";
import {
  CreateOfferingPlanPackageFragment,
  ReadyPlanAssetType,
  SaveProductOfferingInput,
  usePlanPackageStepQuery,
} from "src/generated/graphql-types";
import { PLAN_FALLBACK_IMG } from "src/routes/libraries/plan-package/takeoffs/utils";
import { queryResult } from "src/utils";
import { ProductOfferingStepActions } from "./ProductOfferingStepActions";

type PlanPackageStepProps = {
  formState: ObjectState<SaveProductOfferingInput>;
};

export function PlanPackageStep({ formState }: PlanPackageStepProps) {
  const result = usePlanPackageStepQuery();
  return queryResult(result, ({ planPackages }) => (
    <PlanPackageStepView planPackages={planPackages.entities} formState={formState} />
  ));
}

type PlanPackageStepViewProps = {
  planPackages: CreateOfferingPlanPackageFragment[];
  formState: ObjectState<SaveProductOfferingInput>;
};

export function PlanPackageStepView({ planPackages, formState }: PlanPackageStepViewProps) {
  function setPlanPackageId(id: string) {
    formState.set({ planPackageId: id, options: [] });
  }

  return (
    <>
      <div css={Css.df.fdc.aic.$}>
        <div css={Css.xl3Sb.$}>Choose your plan</div>
        <div css={Css.base.pt2.pbPx(60).$}>
          Plan packages come with option conflicts and dependencies already set and will be automatically inherited here
        </div>
        {planPackages.length > 0 ? (
          <Observer>
            {() => (
              <div css={Css.dg.gtc("repeat(4, 1fr)").gap2.$}>
                {planPackages.map((p) => (
                  <PlanPackageCard
                    planPackage={p}
                    selected={formState.planPackageId.value === p.id}
                    onClick={() => setPlanPackageId(p.id)}
                    key={p.id}
                  />
                ))}
              </div>
            )}
          </Observer>
        ) : (
          <div>No Active plan packages found</div>
        )}
      </div>
      <ProductOfferingStepActions />
    </>
  );
}

type PlanPackageCardProps = {
  planPackage: CreateOfferingPlanPackageFragment;
  selected: boolean;
  onClick: () => void;
};

function PlanPackageCard({ planPackage, selected, onClick }: PlanPackageCardProps) {
  const testId = useTestIds({}, "planPackageCard");
  const sellableSqftString = `${planPackage.minProgramData?.sellableSqft} - ${planPackage.maxProgramData?.sellableSqft} sq ft`;
  const bedString = `${planPackage.minProgramData?.bedrooms} - ${planPackage.maxProgramData?.bedrooms} beds`;
  const bathString = `${planPackage.minProgramData?.fullBaths} - ${planPackage.maxProgramData?.fullBaths} baths`;
  const widthString = `${planPackage.minProgramData?.widthInFeet} - ${planPackage.maxProgramData?.widthInFeet} ft`;
  const depthString = `${planPackage.minProgramData?.depthInFeet} - ${planPackage.maxProgramData?.depthInFeet} ft`;
  const elevationCount = planPackage.options?.filter((o) => o.active && o.type.isElevation).length || 0;
  const coverPhotoUrl =
    planPackage.assets?.find((asset) => asset?.type.code === ReadyPlanAssetType.CoverPhoto)?.asset.previewUrl ??
    PLAN_FALLBACK_IMG;

  return (
    <Card
      key={planPackage.id}
      onClick={onClick}
      xss={Css.if(selected).outline(`2px ${Palette.Blue500} solid`).$}
      {...testId}
    >
      <div css={Css.p1.df.fdc.gap2.$}>
        <img src={coverPhotoUrl} alt="Plan package cover photo" css={Css.hPx(184).wPx(270).ma.$} />

        <div css={Css.df.fdc.gap1.$}>
          <h3 css={Css.xlSb.mbPx(4).wbbw.$}>{planPackage.name}</h3>
          <div css={Css.smSb.$}>{planPackage.code}</div>
          {/* TODO: add market tags once plan package has implemented it */}
        </div>
        <div css={Css.dg.gtc("repeat(2, 1fr)").$}>
          <div>{sellableSqftString}</div>
          <div>{elevationCount} Elevations</div>
          <div>{bedString}</div>
          <div>{widthString}</div>
          <div>{bathString}</div>
          <div>{depthString}</div>
        </div>
      </div>
    </Card>
  );
}
