import { Css } from "@homebound/beam";
import { GeneralTlivChangesFragment } from "src/generated/graphql-types";
import { AggregateMemberVersionChangeType } from "src/routes/libraries/design-catalog/design-package-configurator/components/DesignUpdatesAvailableButton";
import { renderAccordion } from "src/routes/libraries/versioning/VersionHistoryUtils";
import { count } from "src/utils";

export type TlivChangesProps = {
  tliVersions: GeneralTlivChangesFragment[];
};

// General takeoff line item changes - includes added, removed, and updated takeoff line items
export function GeneralTlivChanges({ tliVersions }: TlivChangesProps) {
  const groupedTlivs = tliVersions.groupBy((tliv) => tliv.changeType);
  const newTlivs = groupedTlivs[AggregateMemberVersionChangeType.ADDED] ?? [];
  const removedTlivs = groupedTlivs[AggregateMemberVersionChangeType.REMOVED] ?? [];
  const updatedTlivs = groupedTlivs[AggregateMemberVersionChangeType.UPDATED] ?? [];

  if (newTlivs.isEmpty && removedTlivs.isEmpty && updatedTlivs.isEmpty) return null;

  return (
    <>
      {renderTlivAccordion("Added", newTlivs)}
      {renderTlivAccordion("Removed", removedTlivs)}
      {renderTlivAccordion("Updated", updatedTlivs)}
    </>
  );
}

function renderTlivAccordion(title: string, tlivs: GeneralTlivChangesFragment[]) {
  if (tlivs.isEmpty) return null;
  return renderAccordion(
    `${title} ${count(tlivs, "takeoff line-item")}`,
    <ul css={Css.lg.m0.$}>
      {tlivs
        .groupByObject((tliv) => tliv.location.effectiveLocation)
        .map(([loc, tlivs]) => (
          <li key={loc?.id} css={Css.baseMd.$}>
            {loc?.name} ({tlivs.length})
          </li>
        ))}
    </ul>,
  );
}
