import { Autocomplete, PresentationFieldProps } from "@homebound/beam";
import { useEffect, useState } from "react";
import { ProjectAutocomplete_ProjectFragment, useProjectAutocompleteQuery } from "src/generated/graphql-types";
import { useDebounce } from "src/hooks";

export type ProjectAutocompleteFieldProps = PresentationFieldProps & {
  label?: string;
  onSelect: (selected: ProjectAutocomplete_ProjectFragment) => void;
  disabled?: boolean;
  autofillValue?: string;
  required?: boolean;
};

export function ProjectAutocompleteField(props: ProjectAutocompleteFieldProps) {
  const { onSelect, disabled, label = "Project", autofillValue, ...others } = props;
  const [search, setSearch] = useState<string>(autofillValue ?? "");
  const debouncedSearch = useDebounce(search);
  const { data: searchData, previousData: previousSearchData } = useProjectAutocompleteQuery({
    variables: { filter: { search: debouncedSearch }, page: { limit: 100 } },
    nextFetchPolicy: "cache-first",
  });
  const [prevAutofillValue, setPrevAutofillValue] = useState<string | undefined>(autofillValue);

  useEffect(() => {
    // Update the search value to the autofill value if it's changed
    if (!autofillValue && prevAutofillValue) {
      setSearch("");
      setPrevAutofillValue(autofillValue);
      return;
    }
    // If the autofill value is not set, don't update the search value
    if (!autofillValue) return;
    // If the search value is not the autofill value, update it
    if (search !== autofillValue && autofillValue !== prevAutofillValue) {
      setSearch(autofillValue);
      setPrevAutofillValue(autofillValue);
    }
  }, [autofillValue, prevAutofillValue, search]);

  return (
    <Autocomplete<ProjectAutocomplete_ProjectFragment>
      {...others}
      label={label}
      disabled={disabled}
      getOptionValue={(o) => o.id}
      getOptionLabel={(o) => o.name}
      onSelect={onSelect}
      onInputChange={(t) => {
        setSearch(t ?? "");
        // We need to reset the onSelect to be able to clean the auto complete entirely
        if (!t) onSelect(undefined as unknown as ProjectAutocomplete_ProjectFragment);
      }}
      value={search}
      options={searchData?.projectsPage.entities ?? previousSearchData?.projectsPage.entities ?? []}
    />
  );
}
