import { GridTable } from "@homebound/beam";
import { usePaymentScheduleTabQuery } from "src/generated/graphql-types";
import {
  createPaymentScheduleColumns,
  createPaymentScheduleRows,
} from "src/routes/pdfs/CommitmentOrChangeOrderPdf/components/PaymentScheduleTable";
import { queryResult } from "src/utils";

type PaymentScheduleTabProps = {
  commitmentId: string;
};

export function PaymentScheduleTab({ commitmentId }: PaymentScheduleTabProps) {
  const query = usePaymentScheduleTabQuery({ variables: { commitmentId } });
  return queryResult(query, ({ commitment: c }) => {
    return (
      <GridTable
        columns={createPaymentScheduleColumns(c.committedInCents, true)}
        rows={createPaymentScheduleRows(c.draws, c.depositInBasisPoints)}
      />
    );
  });
}
