import {
  AutoSaveIndicator,
  Css,
  FilterDefs,
  Filters,
  multiFilter,
  Palette,
  ScrollableContent,
  SelectField,
  toggleFilter,
  usePersistedFilter,
  useSessionStorage,
} from "@homebound/beam";
import { useFormState } from "@homebound/form-state";
import { useMemo, useState } from "react";
import { useHistory } from "react-router";
import { SearchBox } from "src/components";
import {
  AddOptionsGlobalOptionTypeFragment,
  PlanPackagePageMetadataQuery,
  ReadyPlanOptionsFilter,
  useAddOptionsMetadataQuery,
  useSavePlanPackageDetailsMutation,
} from "src/generated/graphql-types";
import { disableBasedOnPotentialOperation } from "src/routes/components/PotentialOperationsUtils";
import { createPlanPackageDetailsOptionDataUrl } from "src/RouteUrls";
import {
  OptionDetailsTable,
  PlanOptionGroupBy,
} from "../../../developments/plan-and-options/components/OptionDetailsTable";
import { addRpoConfig, mapReadyPlanToFormState } from "../../../developments/plan-and-options/components/utils";
import { savePlanPackageOptionsProgramData } from "../stepper/utils";

export function OptionDataTab({ metadata }: { metadata: PlanPackagePageMetadataQuery }) {
  const { data: metadataProgramData } = useAddOptionsMetadataQuery();
  const planPackage = metadata.planPackage;
  const disabled = disableBasedOnPotentialOperation(planPackage.canEdit);
  const [save] = useSavePlanPackageDetailsMutation();
  const history = useHistory();

  const formState = useFormState({
    config: addRpoConfig,
    init: {
      input: planPackage,
      map: (planPackage) => mapReadyPlanToFormState(planPackage),
    },
    autoSave: async (formState) => {
      const response = await savePlanPackageOptionsProgramData(formState, planPackage ?? { id: "" }, save);
      if (
        planPackage.version.id &&
        response?.data &&
        planPackage.version.id !== response.data.savePlanPackage.planPackage.version.id
      ) {
        const pp = response.data.savePlanPackage.planPackage;
        history.replace(createPlanPackageDetailsOptionDataUrl(pp.id, pp.version.id));
      }
    },
  });
  const optionsTypes: AddOptionsGlobalOptionTypeFragment[] = useMemo(() => {
    // NOTE: We are relying on the fact that the server will sort the GlobalOption types by order
    return metadata.globalOptionTypes;
  }, [metadata.globalOptionTypes]);

  const { globalOptionTypes = [], locations = [] } = metadata;
  const [searchFilter, setSearchFilter] = useState<string | undefined>();

  const filterDefs: FilterDefs<ReadyPlanOptionsFilter> = useMemo(() => {
    const type = multiFilter({
      label: "Option Type",
      options: globalOptionTypes.filter((got) => !got.isElevation),
      getOptionLabel: ({ name }) => name,
      getOptionValue: ({ id }) => id,
    });

    const location = multiFilter({
      label: "Location",
      options: [{ id: null, name: "None" } as any, ...locations],
      getOptionLabel: ({ name }) => name,
      getOptionValue: ({ id }) => id,
    });

    const active = toggleFilter({ label: "Show Archived" });

    return { type, location, active };
  }, [globalOptionTypes, locations]);

  const { filter, setFilter } = usePersistedFilter<ReadyPlanOptionsFilter>({
    storageKey: "tableFilter",
    filterDefs,
  });
  const [planOptionGroupBy, setPlanOptionGroupBy] = useSessionStorage("planOptionGroupBy", "optionType");
  return (
    <>
      <div css={Css.df.jcsb.mb2.$}>
        <AutoSaveIndicator />
        <div css={Css.df.aic.jcfe.gap1.$}>
          <div css={Css.h100.$}>
            <SelectField
              compact
              sizeToContent
              label="Group by"
              labelStyle="inline"
              options={[
                { id: "optionType", name: "Option Type" },
                { id: "location", name: "Location" },
                { id: "none", name: "None" },
              ]}
              value={planOptionGroupBy}
              onSelect={(gb) => gb && setPlanOptionGroupBy(gb)}
            />
          </div>
          <Filters filter={filter} filterDefs={filterDefs} onChange={setFilter} />
          <SearchBox onSearch={setSearchFilter} />
        </div>
      </div>
      <ScrollableContent virtualized bgColor={Palette.Gray100}>
        <div css={Css.h100.w("calc(100% - 24px)").$}>
          <OptionDetailsTable
            formState={formState}
            searchFilter={searchFilter}
            filter={filter}
            optionsTypes={optionsTypes}
            metadata={metadataProgramData}
            groupBy={planOptionGroupBy as PlanOptionGroupBy}
            showActions={true}
            disabled={disabled}
          />
        </div>
      </ScrollableContent>
    </>
  );
}
