import {
  ButtonMenu,
  Css,
  FilterDefs,
  Filters,
  GridColumn,
  GridDataRow,
  GridTable,
  IconButton,
  ModalBody,
  ModalHeader,
  Pagination,
  ScrollableContent,
  SelectToggle,
  SimpleHeaderAndData,
  Tag,
  column,
  multiFilter,
  selectColumn,
  simpleDataRows,
  useComputed,
  useGridTableApi,
  useModal,
  usePersistedFilter,
} from "@homebound/beam";
import { useState } from "react";
import { PdfViewer, SearchBox } from "src/components";
import { lienWaiverStatusTagMapper } from "src/components/detailPane/bill/utils";
import {
  BillPage_LienWaiverFragment,
  DocumentEditorDetailFragment,
  DocumentType,
  FinancesPageSharedQuery,
  LienWaiverFilter,
  LienWaiverOrder,
  LienWaiversPageLienWaiverFragment,
  Order,
  useDocumentsAssetQuery,
  useLienWaiversPageQuery,
} from "src/generated/graphql-types";
import useZodQueryString from "src/hooks/useZodQueryString";
import { formatList, pageSchema, queryResult } from "src/utils";
import { downloadFilesToZip } from "src/utils/downloader";
import { parseOrder, toOrder } from "src/utils/ordering";
import { openNewTab } from "src/utils/window";
import { UploadLienWaiverModal } from "../bills/components/UploadLienWaiverModal";
import { PandaDocConfirmationModal } from "../development-commitments/components/PandaDocConfirmationModal";
import { TableActions } from "../layout/TableActions";
import { CreateLienWaiverModal } from "./CreateLienWaiverModal";

export type LienWaiversPageFilter = LienWaiverFilter;

type Row = SimpleHeaderAndData<LienWaiversPageLienWaiverFragment>;

type LienWaiversPageProps = {
  shared: FinancesPageSharedQuery;
};

export function LienWaiversPage({ shared }: LienWaiversPageProps) {
  const { markets, projects, tradePartners } = shared;
  const [order, setOrder] = useState<LienWaiverOrder>({ id: Order.Desc });
  const [pageSettings, setPageSettings] = useZodQueryString(pageSchema);
  const [search, setSearch] = useState("");
  const { openModal, closeModal } = useModal();

  const filterDefs: FilterDefs<LienWaiversPageFilter> = {
    market: multiFilter({
      label: "Market",
      options: markets,
      getOptionValue: (o) => o.id,
      getOptionLabel: (o) => o.name,
    }),

    project: multiFilter({
      label: "Project",
      options: projects,
      getOptionValue: (o) => o.id,
      getOptionLabel: (o) => o.name,
    }),

    tradePartner: multiFilter({
      label: "Trade Partner",
      options: tradePartners,
      getOptionValue: (o) => o.id,
      getOptionLabel: (o) => o.name,
    }),
  };

  const { setFilter, filter } = usePersistedFilter<LienWaiversPageFilter>({
    storageKey: "financesLienWaiverFilter",
    filterDefs,
  });

  const query = useLienWaiversPageQuery({ variables: { filter: { ...filter, search }, order, page: pageSettings } });

  const columns: GridColumn<Row>[] = getColumns();

  const tableApi = useGridTableApi<Row>();
  const selectedRows = useComputed(() => tableApi.getSelectedRows("data").map((row) => row.data), [tableApi]);
  const documentIds = selectedRows.filter(({ document }) => document).map(({ document }) => document!.id);
  const documentAssetQuery = useDocumentsAssetQuery({ variables: { filter: { id: documentIds } } });
  const lienWaiversForDownload = documentAssetQuery.data?.documents;

  return queryResult(query, (data) => (
    <div>
      <TableActions>
        <Filters<LienWaiversPageFilter> filter={filter} onChange={setFilter} filterDefs={filterDefs} />
        <div css={Css.df.gap3.mr2.$}>
          <SearchBox onSearch={setSearch} />
          <ButtonMenu
            data-testid="lien-waiver-actions"
            items={[
              {
                label: "Create Final Lien Waiver",
                onClick: () =>
                  openModal({
                    content: (
                      <CreateLienWaiverModal
                        tradePartners={tradePartners}
                        projects={projects}
                        closeModal={closeModal}
                      />
                    ),
                  }),
              },
              {
                label: "Download .zip File",
                disabled: documentIds.isEmpty,
                onClick: () => {
                  void downloadFilesToZip(
                    "Lien_Waivers.zip",
                    lienWaiversForDownload!.map(({ name, asset }) => ({
                      filename: asset.fileName ?? name,
                      url: asset.downloadUrl,
                    })),
                  );
                },
              },
            ]}
            trigger={{ icon: "verticalDots" }}
          />
        </div>
      </TableActions>
      <ScrollableContent>
        <GridTable
          columns={columns}
          rows={createRows(data.lienWaiversPage.entities)}
          sorting={{
            on: "server",
            onSort: (key, direction) => setOrder(toOrder(key, direction)),
            value: parseOrder(order),
          }}
          api={tableApi}
          style={{ rowHeight: "fixed", allWhite: true, bordered: true }}
          fallbackMessage="No lien waivers found that matched given filters."
        />
        <Pagination page={[pageSettings, setPageSettings]} totalCount={data.lienWaiversPage.pageInfo.totalCount} />
      </ScrollableContent>
    </div>
  ));
}

function createRows(data: LienWaiversPageLienWaiverFragment[]): GridDataRow<Row>[] {
  return simpleDataRows(data);
}

function getColumns(): GridColumn<Row>[] {
  return [
    selectColumn<Row>({
      data: (data, { row }) => ({ content: () => <SelectToggle disabled={!data.document} id={row.id} /> }),
    }),
    column<Row>({
      header: "Project",
      data: ({ project }) => project.name,
      serverSideSortKey: "project",
      w: 1.5,
    }),
    column<Row>({
      header: "Trade Partner",
      data: ({ claimant }) => (claimant.__typename === "TradePartner" ? claimant.name : "-"),
      w: 1.5,
    }),
    column<Row>({
      header: "Bill Number",
      data: ({ parent }) => (parent.__typename === "Bill" ? parent.tradePartnerNumber : "-"),
      w: 0.7,
    }),
    column<Row>({ header: "Type", data: ({ type }) => type.name, w: 0.5 }),
    column<Row>({ header: "Condition", data: ({ condition }) => condition.name, w: 0.5 }),
    column<Row>({
      header: "Status",
      data: (row) => {
        const [billType, text] = lienWaiverStatusTagMapper(row.status.code);
        return <Tag type={billType} text={text} />;
      },
      w: 0.5,
    }),
    column<Row>({
      header: "",
      data: (row) => ({
        content: (
          <>
            <LienWaiverDocumentGridCell lw={row} />
            <LienWaiverResendGridCell lw={row} />
            <LienWaiverUploadGridCell lw={row} />
          </>
        ),
      }),
      w: 0.6,
    }),
  ];
}

type LienWaiverGridCellProps = {
  lw: LienWaiversPageLienWaiverFragment | BillPage_LienWaiverFragment;
};

export function LienWaiverDocumentGridCell({ lw }: LienWaiverGridCellProps) {
  const { openModal } = useModal();
  const { document } = lw;
  const isDisabled = !document && !lw.pandaDoc?.externalPandaDocUrl;

  return (
    <IconButton
      data-testid="lien-waiver-doc"
      icon="document"
      tooltip={isDisabled ? "Not uploaded" : "View Lien Waiver"}
      onClick={() =>
        document
          ? openModal({ content: <LienWaiverDocumentModal document={document} />, size: "xxl" })
          : openNewTab(lw.pandaDoc?.externalPandaDocUrl!)
      }
      disabled={isDisabled}
    />
  );
}

export function LienWaiverResendGridCell({ lw }: LienWaiverGridCellProps) {
  const { openModal } = useModal();

  return (
    <IconButton
      data-testid="lien-waiver-resend"
      icon="email"
      tooltip="Resend Lien Waiver via PandaDoc"
      onClick={async () => {
        openModal({
          content: <PandaDocConfirmationModal ownerId={lw.id} documentName="lien waiver" isReUpload />,
        });
      }}
      disabled={
        !lw.canCreatePandaDoc.allowed
          ? formatList(lw.canCreatePandaDoc.disabledReasons.map((r) => r.message))
          : undefined
      }
    />
  );
}

export function LienWaiverUploadGridCell({ lw }: LienWaiverGridCellProps) {
  const { openModal } = useModal();

  return (
    <IconButton
      data-testid="lien-waiver-upload"
      icon="cloudUpload"
      tooltip="Upload Lien Waiver from computer"
      onClick={() => {
        openModal({
          content: (
            <UploadLienWaiverModal ownerId={lw.id} parentId={lw.project.id} documentType={DocumentType.LienWaiver} />
          ),
        });
      }}
    />
  );
}

function LienWaiverDocumentModal({ document }: { document: DocumentEditorDetailFragment }) {
  return (
    <div>
      <ModalHeader>Lien Waiver Document</ModalHeader>
      <ModalBody>
        <div css={Css.bgWhite.bshBasic.oa.br8.mb3.$}>
          <PdfViewer hasHeader assets={[document?.asset]} title={""} />
        </div>
      </ModalBody>
    </div>
  );
}
