import { TakeoffsItemsTableActions } from "src/routes/libraries/plan-package/takeoffs/components/TakeoffsItemsTableActions";
import { PlanPackageTakeoffTable } from "src/routes/libraries/plan-package/takeoffs/components/TakeoffsTable/PlanPackageTakeoffTable";

export function TakeoffsManagerItemsTab() {
  return (
    <>
      <TakeoffsItemsTableActions />
      <PlanPackageTakeoffTable />
    </>
  );
}
