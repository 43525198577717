import { Css, emptyCell, Palette, Tooltip } from "@homebound/beam";
import { Icon, IconKey } from "src/components/Icon";
import { TaskDeadlineType, TaskScheduleDetailsFragment, TaskStatus } from "src/generated/graphql-types";

type TaskIconCellProps = {
  data: Pick<TaskScheduleDetailsFragment, "isCriticalPath" | "deadlineType" | "status" | "globalTask">;
};
type IconAttrs = {
  icon: IconKey;
  testId: string;
  color: Palette;
  backgroundColor: Palette;
  tooltip: string;
  sizeOverride?: number;
};
type TaskScenarios = "delayedCriticalPath" | "delayed" | "criticalPath" | "globalMilestone";

const DEADLINE_TYPE_CONFIG: Record<TaskDeadlineType, IconAttrs> = {
  [TaskDeadlineType.Cutoff]: {
    icon: "scissors",
    testId: "cutoffTaskIcon",
    color: Palette.Gray900,
    backgroundColor: Palette.Yellow600,
    tooltip: "Cutoff",
  },
  [TaskDeadlineType.KeyMilestone]: {
    icon: "diamond",
    testId: "keyMilestoneTaskIcon",
    color: Palette.Gray900,
    backgroundColor: Palette.Blue100,
    tooltip: "Key Milestone",
  },
};

const ICON_CONFIG: Record<TaskDeadlineType | TaskScenarios, IconAttrs> = {
  delayedCriticalPath: {
    icon: "criticalPath",
    testId: "delayedCriticalPathIcon",
    color: Palette.Gray900,
    backgroundColor: Palette.Red50,
    tooltip: "Critical Path - Delayed",
  },
  delayed: {
    icon: "status",
    testId: "delayedIcon",
    color: Palette.Red600,
    backgroundColor: Palette.Red50,
    tooltip: "Delayed",
    sizeOverride: 1.5,
  },
  criticalPath: {
    icon: "criticalPath",
    testId: "criticalPathIcon",
    color: Palette.Gray900,
    backgroundColor: Palette.White,
    tooltip: "Critical Path",
  },
  globalMilestone: {
    icon: "diamond",
    testId: "globalMilestoneTaskIcon",
    color: Palette.Gray900,
    backgroundColor: Palette.Blue100,
    tooltip: "Milestone",
  },
  ...DEADLINE_TYPE_CONFIG,
};

export function TaskIconCell(data: any, { row }: { row: TaskIconCellProps }) {
  const iconAttrs = getIconAttrs(row);
  if (!iconAttrs) return emptyCell;
  const { icon, testId, color, backgroundColor, tooltip, sizeOverride } = iconAttrs;

  const parentPaddingX = 12;
  const parentPaddingY = 12;

  return (
    // Additional height/width added to override the parent row and cell padding -> overflow is clipped cleanly by `overflowHidden` on parent row
    <div
      css={
        Css.add("backgroundColor", backgroundColor)
          .h(`calc(100% + ${parentPaddingY * 2 - 1}px)`)
          .w(`calc(100% + ${parentPaddingX * 2}px)`)
          .mtPx(-1)
          .mrPx(parentPaddingX * -1)
          .mlPx(parentPaddingX * -1).df.aic.jcc.$
      }
      data-testid={testId}
    >
      <Tooltip title={tooltip} placement="top">
        <Icon icon={icon} color={color} inc={sizeOverride} />
      </Tooltip>
    </div>
  );
}

function getIconAttrs({ data: task }: TaskIconCellProps) {
  if (task.globalTask && task.globalTask.isMilestone) return ICON_CONFIG.globalMilestone;
  if (task.isCriticalPath && task.status === TaskStatus.Delayed) return ICON_CONFIG.delayedCriticalPath;
  if (task.status === TaskStatus.Delayed) return ICON_CONFIG.delayed;
  if (task.isCriticalPath) return ICON_CONFIG.criticalPath;

  return null;
}
