import { Css, Tooltip, useTestIds } from "@homebound/beam";
import differenceBy from "lodash/differenceBy";
import { Icon } from "src/components";
import { useCheckForChipStealingQuery } from "src/generated/graphql-types";
import { PRODUCT_FALLBACK_IMG_URL } from "src/routes/libraries/product-catalog/components/product-images-viewer/ProductImageViewer";
import dedent from "ts-dedent";
import { OptionChips } from "../../designCatalogAtoms";

type ChipStealingPreviewProps = {
  /** A record of { plans: [rpoIds], upgrades: [rpoIds], 'rpog:1': [rpoIds], 'rpog:2': [rpoIds], ... } */
  selectedOptions: Record<string, undefined | string[]>;
  placeholderTliId: string;
  productTliId: string | undefined;
};

export function ChipStealingPreview({
  selectedOptions,
  placeholderTliId,
  productTliId: productItivId,
}: ChipStealingPreviewProps) {
  const tid = useTestIds({});
  const rpoIds = Object.values(selectedOptions).flat().compact();
  const query = useCheckForChipStealingQuery({
    variables: { rpoIds, placeholderTliId, maybeEditingTliId: productItivId },
    skip: rpoIds.isEmpty,
  });

  const conflicts = query.data?.checkForChipStealing ?? query.previousData?.checkForChipStealing;

  if (rpoIds.isEmpty || !conflicts || conflicts.isEmpty) return null;

  return (
    <>
      <div css={Css.df.fdc.base.gap2.maxwPx(732).$}>
        <span>The combination of tags you've selected will remove this product from an existing package(s).</span>
        <span>Are you sure you want to make this change?</span>
        <span>Confirming will result in the following configuration:</span>
      </div>
      <div css={Css.w100.maxwPx(732).p2.bgGray200.br8.df.fdr.gap2.oxa.$}>
        {conflicts.map(({ takeoffLineItem: tli, deleted, wouldLoseOptions }) => (
          <div key={tli.id} {...tid.tliCard} css={Css.bgWhite.br8.p1.df.fdc.gap1.h100.wPx(200).hPx(300).fs0.$}>
            {deleted && (
              <Tooltip
                title={dedent`Multiple selections with the same options cannot exist, and will be merged together.
                              Your current selection of options will consume all the options that make this product
                              selection unique, leaving it void of any options. As a result, it will be merged into
                              the current selection and removed.`}
              >
                <div css={Css.bgRed200.tac.smSb.br4.p1.$}>Will Merge Into Current</div>
              </Tooltip>
            )}
            <div css={Css.fg1.bgGray50.bcGray500.df.aic.jcsa.gray400.mb1.relative.br4.if(!tli.bidItem).ba.bsDashed.$}>
              {tli.bidItem ? (
                <img
                  alt="product-headline"
                  src={tli.bidItem.parentMaterialVariant?.featuredImage?.asset?.previewUrl ?? PRODUCT_FALLBACK_IMG_URL}
                  css={Css.br4.objectCover.h100.w100.absolute.p1.$}
                />
              ) : (
                <Icon icon="plus" pxSize={64} />
              )}
            </div>
            <div css={Css.df.fdc.gap1.oh.$}>
              <div css={Css.xs.$}>{tli.name}</div>
              {tli.bidItem?.parentMaterialVariant?.displayName && (
                <div css={Css.smMd.truncate.$}>{tli.bidItem?.parentMaterialVariant.displayName}</div>
              )}
            </div>
            {/* A card with only 2 options, losing both options, would be deleted. But we fetch "Effective" Options which
                would include more than just the 2 being deleted, so it's weird to see a "Will Merge Into Current" card
                that still has options left over (because we only filter out the 2 options). So for consistency, if the
                card is being deleted, don't show any "leftover" chips. */}
            {!deleted && (
              <div css={Css.df.fdr.aic.gap1.w100.oys.$}>
                {differenceBy(tli.options, wouldLoseOptions, (rpo) => rpo.id)
                  .groupByObject((rpo) => rpo.optionGroup)
                  .map(([rpog, rpos]) => (
                    <OptionChips key={rpog.id} rpos={rpos} />
                  ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
}
