import { Button, Css, useTestIds } from "@homebound/beam";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Price } from "src/components";
import {
  DynamicSchedulesSidePaneWithConstraints_PlanTaskFragment,
  ProjectFeature,
  TaskDetailsPage_PlanTaskFragment,
} from "src/generated/graphql-types";
import { useTaskBillContext } from "src/routes/projects/schedule-v2/contexts/TaskBillModalContext";
import { isDefined } from "src/utils";

type BillsProps = {
  task: TaskDetailsPage_PlanTaskFragment | DynamicSchedulesSidePaneWithConstraints_PlanTaskFragment;
  inSidePane?: boolean;
};

type UnbilledByTradeBill = {
  tradePartner: {
    __typename?: "TradePartner" | undefined;
    enableClickToPay: boolean;
    id: string;
    name: string;
  };
  unbilledTaskItems: number;
};

export function Bills({ task, inSidePane = false }: BillsProps) {
  const tid = useTestIds({}, "bills");
  const bills = task.billLineItems.flatMap((bli) => bli.bill).uniqueByKey("id");
  const enableClickToPay = task.schedule.parent.features?.includes(ProjectFeature.ClickToPay);

  const unbilledByTrade: UnbilledByTradeBill[] = useMemo(() => {
    if (!enableClickToPay) return [];

    const groupedByTrade = task.protoBills
      .flatMap((pb) => pb.lineItems)
      .filter((li) => isDefined(li.commitmentLineItem.owner.tradePartner))
      // Because the sidebar query is using the "no-cache" option to avoid apollo cache collisions
      // with draft mode, we can't use the more succinct `groupByObject` here which relies on a stable
      // object identity that the cache would normally provide for the owner.tradePartner object.
      .groupBy((li) => li.commitmentLineItem.owner.tradePartner!.id);

    return Object.values(groupedByTrade)
      .map((lineItems) => {
        return {
          tradePartner: lineItems.first!.commitmentLineItem.owner.tradePartner!,
          unbilledTaskItems: lineItems.sum((li) => li.amountInCents),
        };
      })
      .filter((tp) => tp.unbilledTaskItems > 0);
  }, [enableClickToPay, task.protoBills]);

  return (
    <div css={Css.if(!inSidePane).pb1.$}>
      <div css={Css.if(!inSidePane).my2.else.mb2.$}>
        <span css={Css.df.aic.jcsb.baseSb.gray800.$}>Bills</span>
      </div>
      {bills.map((b) => (
        <div css={Css.df.fdc.w100.mt1.$} key={b.id}>
          <div css={Css.dg.gtc("113px 80px 1fr 1fr").w100.cg1.rg1.smSb.aic.$}>
            <Link target="_blank" to={b.blueprintUrl.path} {...tid.billNumber}>
              #{b.tradePartnerNumber}
            </Link>
            <span css={Css.smMd.$} {...tid.tradePartner}>
              {b.tradePartner.name}
            </span>
            <span css={Css.maxwPx(120).tac.$}>
              <span css={Css.smMd.$}>{b.status.name}</span>
            </span>
            <div css={Css.smMd.tar.$} {...tid.billTotal}>
              <Price valueInCents={b.billedInCents} />
            </div>
          </div>
        </div>
      ))}
      {!unbilledByTrade.isEmpty && <UnbilledByTrade taskId={task.id} unbilledByTrade={unbilledByTrade} />}
      {bills.isEmpty && unbilledByTrade.isEmpty && (
        <div {...tid.noBills} css={Css.br8.bsDashed.bcGray200.gray700.bw("3px").py2.df.jcc.$}>
          There are no bills associated to this task
        </div>
      )}
    </div>
  );
}

function UnbilledByTrade({ unbilledByTrade, taskId }: { unbilledByTrade: UnbilledByTradeBill[]; taskId: string }) {
  const tid = useTestIds({}, "unbilledByTrade");
  const { openTaskBillModal } = useTaskBillContext();

  if (unbilledByTrade.isEmpty) return null;

  return (
    <div css={Css.mt2.$} {...tid}>
      {unbilledByTrade.map(({ tradePartner, unbilledTaskItems }) => (
        <div key={tradePartner.id} css={Css.df.gap1.jcsb.aic.$}>
          <div css={Css.truncate.fb3.$}>{tradePartner.name}</div>
          <div css={Css.smMd.$}>
            Unbilled: <Price valueInCents={unbilledTaskItems} />
          </div>
          <Button
            label="Pay Trade"
            variant="text"
            size="sm"
            disabled={tradePartner.enableClickToPay ? false : "This trade is excluded from Click to Pay"}
            onClick={() => openTaskBillModal({ taskId: taskId, tradePartnerId: tradePartner.id })}
          />
        </div>
      ))}
    </div>
  );
}
