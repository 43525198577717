import { Accordion, Button, Checkbox, Css } from "@homebound/beam";
import { useMemo } from "react";
import { useSetState } from "react-use";
import { TliPlaceholderRequirementsQuery } from "src/generated/graphql-types";

type ProductSearchFiltersProps = {
  brandFilter: string[];
  setBrandFilter: (v: string[]) => void;
  mavFilters: Record<string, string[]>;
  setMavFilters: (
    patch:
      | Partial<Record<string, string[]>>
      | ((prevState: Record<string, string[]>) => Partial<Record<string, string[]>>),
  ) => void;
  placeholderTli?: TliPlaceholderRequirementsQuery["takeoffLineItem"];
};

export function ProductSearchFilters(props: ProductSearchFiltersProps) {
  const { placeholderTli, brandFilter, setBrandFilter, mavFilters, setMavFilters } = props;
  const [showAllFilters, setShowAllFilters] = useSetState<Record<string, boolean>>({});
  const requirementMavs = placeholderTli?.materialVariant?.materialAttributeValues ?? [];

  const brands = useMemo(() => (placeholderTli?.item.materialBrands ?? []).sortByKey("name"), [placeholderTli]);

  return (
    <div css={Css.bgWhite.wPx(340).mtPx(-24).mlPx(-24).pb1.oya.$}>
      {brands.nonEmpty && (
        <Accordion title="Brand" bottomBorder topBorder={false} omitPadding defaultExpanded>
          <div css={Css.df.fdc.gap1.p2.bt.bcGray300.$}>
            {(showAllFilters.brand ? brands : brands?.slice(0, 5))?.map((brand) => (
              <Checkbox
                key={brand.id}
                label={brand.name}
                selected={brandFilter.includes(brand.id)}
                onChange={(selected) =>
                  setBrandFilter(
                    selected
                      ? [...(brandFilter ?? []), brand.id]
                      : (brandFilter ?? []).filter((brandId) => brandId !== brand.id),
                  )
                }
              />
            ))}
            {!showAllFilters.brand && brands.length > 5 && (
              <div css={Css.df.jcc.$}>
                <Button
                  icon="plus"
                  label="Show More"
                  variant="tertiary"
                  onClick={() => setShowAllFilters({ brand: true })}
                />
              </div>
            )}
          </div>
        </Accordion>
      )}
      {placeholderTli?.item.materialAttributeDimensions
        // Filter IN any MADs which are NOT already present on the Placeholder TLi's MV. Only allow supplemental MAVs.
        .filter((mad) => !requirementMavs.map((mav) => mav.dimension.id).includes(mad.id))
        .filter((mad) => mad.values?.nonEmpty)
        .map((mad) => (
          <Accordion key={mad.id} title={mad.name} bottomBorder topBorder={false} omitPadding defaultExpanded>
            <div css={Css.df.fdc.gap1.p2.bt.bcGray300.$}>
              {(showAllFilters[mad.id] ? mad.values : mad.values?.slice(0, 5))?.map((mav) => (
                <Checkbox
                  // NOTE: This field might need to be updated to handle range values
                  //    SC-55230
                  key={mav.id}
                  label={mav.textValue ?? ""}
                  selected={mavFilters[mad.id]?.includes(mav.id)}
                  onChange={(selected) =>
                    setMavFilters((prev) => ({
                      [mad.id]: selected
                        ? [...(prev[mad.id] ?? []), mav.id]
                        : (prev[mad.id] ?? []).filter((mavId) => mavId !== mav.id),
                    }))
                  }
                />
              ))}
              {!showAllFilters[mad.id] && mad.values && mad.values.length > 5 && (
                <div css={Css.df.jcc.$}>
                  <Button
                    icon="plus"
                    label="Show More"
                    variant="tertiary"
                    onClick={() => setShowAllFilters({ [mad.id]: true })}
                  />
                </div>
              )}
            </div>
          </Accordion>
        ))}
    </div>
  );
}
