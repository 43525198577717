import { Css, useBreakpoint } from "@homebound/beam";
import { format } from "date-fns";
import { useMemo } from "react";
import {
  TradePartnerTaskStatus,
  useActionItemsDelayFlagsQuery,
  useActionItemsSchedulingQuery,
  useActionItemsStaleTasksQuery,
  useCurrentInternalUserQuery,
} from "src/generated/graphql-types";
import { nonClosedProjects } from "src/utils/projects";
import { filterScheduleFlagsByNonClosedStatus, getFirstNameFromInternalUser } from "../utils";

export function HeaderOverview() {
  const date = new Date();
  const { sm } = useBreakpoint();
  const { data: currentInternalUserData } = useCurrentInternalUserQuery({ fetchPolicy: "cache-first" });
  const internalUserName = currentInternalUserData?.currentInternalUser?.name!;
  const { data: delayFlags } = useActionItemsDelayFlagsQuery({
    variables: {
      internalUser: currentInternalUserData?.currentInternalUser?.id,
      scheduleParent: [],
      scheduleIsActive: true,
    },
  });
  const { data: staleTasks } = useActionItemsStaleTasksQuery({
    variables: {
      internalUser: currentInternalUserData?.currentInternalUser?.id,
      scheduleIsActive: true,
      stale: true,
      projectStatus: nonClosedProjects,
    },
  });
  const { data: schedulingTasks } = useActionItemsSchedulingQuery({
    variables: {
      internalUser: currentInternalUserData?.currentInternalUser?.id,
      scheduleIsActive: true,
      projectStatus: nonClosedProjects,
    },
  });

  const filteredScheduleFlags = useMemo(() => {
    return delayFlags?.scheduleFlags.nonEmpty ? filterScheduleFlagsByNonClosedStatus(delayFlags?.scheduleFlags) : [];
  }, [delayFlags?.scheduleFlags]);

  const actionItemsCount = useMemo(
    () =>
      [
        filteredScheduleFlags.length ?? 0,
        staleTasks?.scheduleTasks?.length ?? 0,
        schedulingTasks?.scheduleTasks?.filter(
          (task) =>
            task.tradePartner !== null &&
            [TradePartnerTaskStatus.NeedsConfirmation, TradePartnerTaskStatus.NotSent].includes(
              task.tradePartnerStatus.code,
            ),
        ).length ?? 0,
      ].reduce((sum, count) => sum + count, 0),
    [filteredScheduleFlags, staleTasks, schedulingTasks],
  );

  const { firstName } = getFirstNameFromInternalUser(internalUserName);

  return (
    <div css={Css.df.gap2.$}>
      {!sm ? (
        <>
          <div css={Css.df.fdc.pr3.br.bw2.bcGray200.$}>
            <span css={Css.base.gray600.$}>{format(date, "EEE")}</span>
            <span css={Css.xl2Bd.df.jcc.$}>{format(date, "dd")}</span>
          </div>
          <div css={Css.df.fdc.$}>
            <span css={Css.base.gray600.$}>{firstName}, you have</span>
            <span css={Css.xl2Sb.$}>{actionItemsCount} action items today.</span>
          </div>
        </>
      ) : (
        <div css={Css.base.$}>
          {firstName}, you have <strong>{actionItemsCount} action items</strong> today.
        </div>
      )}
    </div>
  );
}
