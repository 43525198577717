import { Button, Css, multiFilter, useModal, usePersistedFilter } from "@homebound/beam";
import { useMemo } from "react";
import { useParams } from "react-router";
import { createDesignPackageUrl, createDesignPackagesUrl } from "src/RouteUrls";
import { useLocationsTreeFilter } from "src/components/autoPopulateFilters/useLocationsTreeFilter";
import {
  FinishSchedule_DesignPackageFragment,
  NamedFragment,
  useFinishScheduleDesignPackageMetadataQuery,
  useFinishScheduleDesignPackageQuery,
} from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { DesignCatalogParams } from "src/routes/routesDef";
import { queryResult } from "src/utils";
import { DesignPackageLocationBadge } from "../designCatalogAtoms";
import { FinishScheduleTable } from "./FinishScheduleTable";
import { ExportFinishScheduleModal } from "./components/ExportFinishScheduleModal";

export function FinishSchedulePage() {
  const { designPackageId, designPackageVersionId } = useParams<DesignCatalogParams>();
  const query = useFinishScheduleDesignPackageQuery({
    variables: { id: designPackageId, versionId: designPackageVersionId },
  });
  return queryResult(query, ({ designPackage }) => <FinishScheduleView designPackage={designPackage} />);
}

function FinishScheduleView({ designPackage }: { designPackage: FinishSchedule_DesignPackageFragment }) {
  const { openModal } = useModal();
  const breadCrumb = [{ href: createDesignPackagesUrl(), label: "Design Packages" }];
  if (designPackage.code) breadCrumb.push({ href: "", label: designPackage.code });

  const { costCodes, itemLocations } =
    useFinishScheduleDesignPackageMetadataQuery({
      variables: { id: designPackage.id, dpRpavId: designPackage.version.id },
    }).data?.designPackage ?? {};

  const { filter, setFilter, filterDefs } = useFinishScheduleFilters(
    designPackage,
    costCodes ?? [],
    designPackage.version.id,
  );

  return (
    <div>
      <PageHeader
        xss={Css.mb0.$}
        title={designPackage.name}
        breadcrumb={breadCrumb}
        left={<DesignPackageLocationBadge designPackage={designPackage} />}
        right={
          <>
            <div css={Css.df.gap3.$}>
              <Button
                variant="text"
                label="Design Package"
                onClick={createDesignPackageUrl(designPackage.id, designPackage.version.id)}
                icon="linkExternal"
              />
              <Button
                label="Export"
                onClick={() =>
                  openModal({
                    content: <ExportFinishScheduleModal exportFilter={filter} designPackage={designPackage} />,
                  })
                }
              />
            </div>
          </>
        }
      />
      <FinishScheduleTable
        filter={filter}
        setFilter={setFilter}
        filterDefs={filterDefs}
        costCodes={costCodes}
        locations={itemLocations ?? []}
        versionId={designPackage.version.id}
      />
    </div>
  );
}

export type FinishScheduleFilters = {
  costCodes: string[];
  locationInPath: string[];
  plans: string[];
} & Record<string, string[]>;

function useFinishScheduleFilters(
  designPackage: FinishSchedule_DesignPackageFragment,
  costCodes: NamedFragment[],
  dpRpavId: string | undefined,
) {
  const { planPackages, options } = designPackage;

  const locationsTreeFilter = useLocationsTreeFilter({ rpavId: dpRpavId });

  const filterDefs = useMemo(() => {
    const upgradeOptions = options.filter((o) => o.optionGroup.forDesignUpgrade);
    const designPackageOptionTuples = options
      .filter((rpo) => rpo.optionGroup.forDesignPackages)
      .groupByObject((rpo) => rpo.optionGroup.name);
    const ppRpavs = planPackages.map((pp) => {
      return { id: pp.version.id, name: pp.name };
    });

    return {
      costCodes: multiFilter({
        label: "Cost Code",
        options: costCodes?.sortByKey("name") ?? [],
        getOptionLabel: ({ name }) => name,
        getOptionValue: ({ id }) => id,
        sizeToContent: true,
      }),
      locationInPath: locationsTreeFilter,
      // Will filter down to all the slots that apply to a given plan package
      plans: multiFilter({
        label: "Plans",
        options: ppRpavs?.sortByKey("name") ?? [],
        getOptionLabel: ({ name }) => name,
        getOptionValue: ({ id }) => id,
        sizeToContent: true,
      }),
      ...designPackageOptionTuples.mapToObject(([rpogName, rpos]) => [
        rpogName, // name serves as key to Record<string, string[]>
        multiFilter({
          label: rpogName,
          options: rpos,
          getOptionLabel: ({ name }) => name,
          getOptionValue: ({ id }) => id,
          sizeToContent: true,
        }),
      ]),
      ...(upgradeOptions.nonEmpty && {
        upgradeOptions: multiFilter({
          label: "Upgrades",
          options: upgradeOptions,
          getOptionLabel: ({ name }) => name,
          getOptionValue: ({ id }) => id,
          sizeToContent: true,
        }),
      }),
    };
  }, [locationsTreeFilter, options, planPackages, costCodes]);

  const { filter, setFilter } = usePersistedFilter({
    filterDefs,
    storageKey: `finish-schedule-filter-${designPackage?.id}`,
  });

  return { filter, setFilter, filterDefs };
}
