import { Css, GridColumn, GridDataRow, GridTable, ModalProps, Tag, simpleHeader, useModal } from "@homebound/beam";
import { lienWaiverConditionStatusTagMapper } from "src/components/detailPane/bill/utils";
import { BillPage_LienWaiverFragment } from "src/generated/graphql-types";
import {
  LienWaiverDocumentGridCell,
  LienWaiverResendGridCell,
  LienWaiverUploadGridCell,
} from "src/routes/lien-waivers/LienWaiversPage";

type ColumnHeaderRow = {
  kind: "header";
  id: string;
};
type LienWaiverRow = {
  kind: "lienWaiver";
  id: string;
  data: BillPage_LienWaiverFragment;
};
type Row = ColumnHeaderRow | LienWaiverRow;

const createColumns = (isLienWaiverReceiver: boolean, openModal: (props: ModalProps) => void) => {
  const conditionColumn: GridColumn<Row> = {
    header: () => ({
      content: <div css={Css.$}>Condition</div>,
      name: "Condition",
    }),
    lienWaiver: (row) => {
      const [lienWaiverStatus, text] = lienWaiverConditionStatusTagMapper({
        status: row?.status?.code,
        isLienWaiverReceiver: isLienWaiverReceiver,
        condition: row?.condition?.name,
      });
      return {
        content: (
          <div css={Css.$}>
            <Tag type={lienWaiverStatus} text={text} />
          </div>
        ),
      };
    },
  };

  const typeColumn: GridColumn<Row> = {
    header: () => ({
      content: <div css={Css.$}>Type</div>,
      name: "Type",
    }),
    lienWaiver: (row) => ({
      content: <div css={Css.$}>{row.type.name}</div>,
    }),
  };

  const documentColumn: GridColumn<Row> = {
    header: () => ({
      content: <div css={Css.$}>Document</div>,
      name: "Document",
    }),
    lienWaiver: (row) => ({ content: <LienWaiverDocumentGridCell lw={row} /> }),
  };

  const resendColumn: GridColumn<Row> = {
    header: () => ({
      content: <div css={Css.$}>Resend</div>,
      name: "Resend",
    }),
    lienWaiver: (row) => ({ content: <LienWaiverResendGridCell lw={row} /> }),
  };

  const uploadColumn: GridColumn<Row> = {
    header: () => ({
      content: <div css={Css.$}>Upload</div>,
      name: "Upload",
    }),
    lienWaiver: (row) => ({ content: <LienWaiverUploadGridCell lw={row} /> }),
  };

  return [conditionColumn, typeColumn, documentColumn, resendColumn, uploadColumn];
};

const createRows = (data: BillPage_LienWaiverFragment[]) => {
  const rows: GridDataRow<Row>[] = [];
  rows.push(simpleHeader);
  data.forEach((l) => {
    rows.push({
      kind: "lienWaiver",
      id: l.id,
      data: l,
    });
  });

  return rows;
};

export const BillLienWaiversDataTable = ({
  isLienWaiverReceiver,
  lienWaivers,
}: {
  isLienWaiverReceiver: boolean;
  lienWaivers: BillPage_LienWaiverFragment[];
}) => {
  const { openModal } = useModal();
  const columns = createColumns(isLienWaiverReceiver, openModal);
  const rows: GridDataRow<Row>[] = createRows(lienWaivers);

  return (
    <div css={Css.px1.$}>
      <GridTable columns={columns} rows={rows} />
    </div>
  );
};
