import { ReactNode } from "react";
import { useParams } from "react-router";
import { createPlanPackageUrl, createPlanPackagesUrl } from "src/RouteUrls";
import { usePlanPackageEditorHeaderQuery } from "src/generated/graphql-types";
import { PlanEditorHeader } from "src/routes/components/PlanConfiguration/PlanEditorHeader";
import { PlanPackageDetailsParams } from "src/routes/routesDef";

type PlanPackageEditorHeaderProps = {
  title: ReactNode;
  subtitle?: ReactNode;
  tooltip?: ReactNode;
  right?: ReactNode;
};

export function PlanPackageEditorHeader({ title, subtitle, tooltip, right }: PlanPackageEditorHeaderProps) {
  const { planPackageId, planPackageVersionId } = useParams<PlanPackageDetailsParams>();
  const { data } = usePlanPackageEditorHeaderQuery({
    variables: { id: planPackageId },
    skip: !planPackageId,
    fetchPolicy: "cache-first",
  });
  const planName = data?.planPackage.name;

  return (
    <PlanEditorHeader
      title={title}
      subtitle={subtitle}
      tooltip={tooltip}
      right={right}
      backUrl={planPackageId ? createPlanPackageUrl(planPackageId, planPackageVersionId) : createPlanPackagesUrl()}
      breadcrumbs={[
        { href: createPlanPackagesUrl(), text: "Plans" },
        { text: planName ? `Editing '${planName}'` : "Create New Plan Package" },
      ]}
    />
  );
}
