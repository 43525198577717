import {
  Button,
  Chip,
  Css,
  GridColumn,
  GridDataRow,
  GridTable,
  IconButton,
  Palette,
  RowStyles,
  column,
} from "@homebound/beam";
import { createDesignPackageUrl, createDesignPackagesUrl } from "src/RouteUrls";
import { formatDate } from "src/components";
import { DesignPackageTableItemFragment, useDesignCatalogsTableQuery } from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { isDefined, queryResult } from "src/utils";
import { DesignPackageLocationBadge, designUpgradeTagColor, planPackageTagColor } from "./designCatalogAtoms";
import { useNewDesignCatalogWizard } from "./new-design-catalog-wizard/useNewDesignCatalogWizard";

export function DesignCatalogsPage() {
  const openNewDesignCatalogWizard = useNewDesignCatalogWizard();
  const designCatalogsQuery = useDesignCatalogsTableQuery({ variables: { filter: {} } });
  return (
    <div css={Css.h100.w100.$}>
      <PageHeader
        title="Design Packages"
        breadcrumb={[{ href: createDesignPackagesUrl(), label: "Design Packages" }]}
        right={<Button label="Create New" onClick={openNewDesignCatalogWizard} />}
      />
      <div>
        {queryResult(designCatalogsQuery, (data) => (
          <DesignCatalogsTable designPackages={data.designPackages.entities} />
        ))}
      </div>
    </div>
  );
}

type DesignCatalogsPageProps = { designPackages: DesignPackageTableItemFragment[] };

function DesignCatalogsTable({ designPackages }: DesignCatalogsPageProps) {
  return (
    <div css={Css.py3.$}>
      <GridTable rows={createRows(designPackages)} columns={columns} rowStyles={createRowStyles()} />
    </div>
  );
}

type Row = { kind: "data"; id: string; data: DesignPackageTableItemFragment };

function createRowStyles(): RowStyles<Row> {
  return { data: { rowLink: ({ id, data }) => createDesignPackageUrl(id, data.version.id) } };
}

function createRows(items: DesignPackageTableItemFragment[]): GridDataRow<Row>[] {
  return items
    .sortBy((dp) => dp.updatedAt.getTime())
    .reverse()
    .map((dp) => ({ kind: "data", id: dp.id, data: dp }));
}

const columns: GridColumn<Row>[] = [
  column<Row>({
    data: (designPackage) => <DesignPackageLocationBadge designPackage={designPackage} />,
    w: "100px",
    align: "right",
  }),
  column<Row>({
    data: (designPackage) => (
      <div css={Css.df.gap1.aic.hPx(50).$}>
        <div css={Css.baseBd.$}>{designPackage.name}</div>
        <div>{designPackage.code}</div>
        {designPackage.availableUpdates.nonEmpty && (
          <IconButton
            inc={1.5}
            bgColor={Palette.Yellow200}
            icon="arrowFromBottom"
            tooltip={designPackage.availableUpdates
              .map((u) => `${u.planVersion.readyPlan.name} - v${u.planVersion.version}`)
              .join(", ")}
            onClick={createDesignPackageUrl(designPackage.id, designPackage.version.id, true)}
          />
        )}
      </div>
    ),
    w: 2.0,
    align: "left",
  }),
  column<Row>({
    data: (designPackage) => {
      const baseRpos = designPackage.options.filter((rpo) => rpo.globalOption.group.forDesignPackages);
      const upgradeRpos = designPackage.options.filter((rpo) => rpo.globalOption.group.forDesignUpgrade);
      const planRpos = designPackage.options.filter((rpo) => rpo.globalOption.isPlanSpecific);

      return (
        <div css={Css.df.fdr.aic.gapPx(4).w25.$}>
          {Object.entries(baseRpos.groupBy((rpo) => rpo.globalOption.group.id)).map(([gogId, rpos]) => {
            const bgColor = rpos.first!.globalOption.group.designPackageTagColor;
            return (
              <Chip
                key={gogId}
                title={`${rpos.length} ${rpos.first!.globalOption.group.name} options`}
                text={String(rpos.length)}
                xss={Css.if(isDefined(bgColor)).bgColor(bgColor!).white.$}
              />
            );
          })}
          {upgradeRpos.nonEmpty && (
            <Chip
              text={upgradeRpos.length.toString()}
              title={`${upgradeRpos.length} upgrades`}
              xss={Css.bgColor(designUpgradeTagColor).white.$}
            />
          )}
          {planRpos.nonEmpty && (
            <Chip
              text={planRpos.length.toString()}
              title={`${planRpos.length} plan options`}
              xss={Css.bgColor(planPackageTagColor).white.$}
            />
          )}
        </div>
      );
    },
  }),
  column<Row>({
    data: (designPackage) => <div>Updated on {formatDate(designPackage.updatedAt)}</div>,
    align: "right",
  }),
];
