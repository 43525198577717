import { Autocomplete, PresentationFieldProps } from "@homebound/beam";
import { useEffect, useState } from "react";
import {
  TradePartnerAutocomplete_TradePartnerFragment,
  useTradePartnerAutocompleteQuery,
} from "src/generated/graphql-types";

export type TradePartnerAutocompleteFieldProps = PresentationFieldProps & {
  label?: string;
  onSelect: (selected: TradePartnerAutocomplete_TradePartnerFragment) => void;
  disabled?: boolean;
  autofillValue?: string;
  required?: boolean;
};

export function TradePartnerAutocompleteField(props: TradePartnerAutocompleteFieldProps) {
  const { onSelect, disabled, label = "Trade Partner", autofillValue, ...others } = props;
  const [search, setSearch] = useState<string>(autofillValue ?? "");
  const { data: searchData, previousData: previousSearchData } = useTradePartnerAutocompleteQuery({
    variables: { filter: { search }, page: { limit: 100 } },
    skip: !search,
    nextFetchPolicy: "cache-first",
  });
  const [prevAutofillValue, setPrevAutofillValue] = useState<string | undefined>(autofillValue);

  useEffect(() => {
    // Update the search value to the autofill value if it's changed
    if (!autofillValue && prevAutofillValue) {
      setSearch("");
      setPrevAutofillValue(autofillValue);
      return;
    }
    // If the autofill value is not set, don't update the search value
    if (!autofillValue) return;
    // If the search value is not the autofill value, update it
    if (search !== autofillValue && autofillValue !== prevAutofillValue) {
      setSearch(autofillValue);
      setPrevAutofillValue(autofillValue);
    }
  }, [autofillValue, prevAutofillValue, search]);

  return (
    <Autocomplete<TradePartnerAutocomplete_TradePartnerFragment>
      {...others}
      label={label}
      disabled={disabled}
      getOptionValue={(o) => o.id}
      getOptionLabel={(o) => o.name}
      onSelect={onSelect}
      onInputChange={(t) => {
        setSearch(t ?? "");
        // We need to reset the onSelect to be able to clean the auto complete entirely
        if (!t) onSelect(undefined as unknown as TradePartnerAutocomplete_TradePartnerFragment);
      }}
      value={search}
      options={searchData?.tradePartnersPage.entities ?? previousSearchData?.tradePartnersPage.entities ?? []}
    />
  );
}
