import {
  Button,
  CollapseToggle,
  Css,
  FilterDefs,
  Filters,
  GridColumn,
  GridDataRow,
  GridTable,
  GridTableApi,
  ModalBody,
  ModalFooter,
  ModalHeader,
  actionColumn,
  column,
  emptyCell,
  multiFilter,
  numericColumn,
  selectColumn,
  useComputed,
  useModal,
  useTestIds,
} from "@homebound/beam";
import { useMemo, useState } from "react";
import { SearchBox, priceCell } from "src/components";
import {
  BillEditor_ProjectItemFragment,
  BillType,
  ProjectAutocomplete_ProjectFragment,
  useBillEditor_ProjectItemsQuery,
} from "src/generated/graphql-types";
import { TableActions } from "src/routes/layout/TableActions";
import { costTypeToNameMapper } from "src/utils";
import { BillReviewFormLineItem } from "../BillEditor";

type Filter = {
  view?: "selections";
  locationId?: string[];
};
type HeaderRow = Record<string, any>;
export type ReviewLineItemModalRow =
  | { kind: "header"; data: HeaderRow; id: string }
  | { kind: "group"; data: BillEditor_ProjectItemFragment[]; id: string }
  | { kind: "lineItem"; data: BillEditor_ProjectItemFragment; id: string };

function createFilterDefs(
  projectItems: BillEditor_ProjectItemFragment[] | BillReviewFormLineItem[] | undefined,
): FilterDefs<Filter> {
  const allLocations = projectItems?.map(({ location }) => location).filter((l) => !!l);
  const locations = [...new Map(allLocations?.map((location) => [location!.id, location])).values()];
  return {
    locationId: multiFilter({
      options: locations,
      getOptionValue: (location) => location!.id,
      getOptionLabel: (location) => location!.name,
    }),
  };
}

function createColumns(): GridColumn<ReviewLineItemModalRow>[] {
  return [
    actionColumn<ReviewLineItemModalRow>({
      header: (data, { row }) => {
        return <CollapseToggle row={row} />;
      },
      group: (data, { row }) => <CollapseToggle row={row} />,
      lineItem: emptyCell,
      w: "32px",
    }),
    selectColumn<ReviewLineItemModalRow>({ w: "32px" }),
    column<ReviewLineItemModalRow>({
      id: "group",
      header: "Cost Code",
      group: (data) => data[0]?.item?.costCode?.displayName,
      lineItem: (data) => data?.displayName,
      w: 2,
    }),
    column<ReviewLineItemModalRow>({
      header: "Location",
      group: emptyCell,
      lineItem: (data) => data.location.name,
    }),
    column<ReviewLineItemModalRow>({
      header: "Cost Type",
      group: emptyCell,
      lineItem: (data) => data.costType && costTypeToNameMapper[data.costType],
    }),
    column<ReviewLineItemModalRow>({
      header: "Trade Partner",
      group: emptyCell,
      lineItem: (data) => data.currentTradePartner?.name,
    }),
    numericColumn<ReviewLineItemModalRow>({
      header: "Revised Int. Budget",
      group: emptyCell,
      lineItem: (data) => priceCell({ valueInCents: data.revisedApprovedBudgetInCents }),
    }),
    numericColumn<ReviewLineItemModalRow>({
      header: "Available Budget",
      group: emptyCell,
      lineItem: (data) => priceCell({ valueInCents: data.uncommittedBudgetAmountInCents }),
    }),
  ];
}

function getProjectsItems(
  projectItems: BillEditor_ProjectItemFragment[] | undefined,
): [string, BillEditor_ProjectItemFragment[]][] {
  const items = projectItems?.groupBy((pi) => pi.item.costCode?.displayName);

  return Object.entries(items || {});
}

function createRows(projectItems: BillEditor_ProjectItemFragment[] | undefined): GridDataRow<ReviewLineItemModalRow>[] {
  const groupedProjects = getProjectsItems(projectItems);

  return [
    { kind: "header", id: "header", data: projectItems || [] },
    ...groupedProjects.map(([gr, pi]) => ({
      kind: "group" as const,
      id: gr,
      data: pi,
      children: pi.map((i: BillEditor_ProjectItemFragment) => ({
        kind: "lineItem" as const,
        id: i.id,
        data: i,
      })),
    })),
  ];
}
export type BillReviewLineItemsModalProps = {
  tableApi: GridTableApi<ReviewLineItemModalRow>;
  project: ProjectAutocomplete_ProjectFragment;
  onAdd: (projectItems: BillEditor_ProjectItemFragment[]) => void;
};

export function BillReviewLineItemsModal({ tableApi, project, onAdd }: BillReviewLineItemsModalProps) {
  const { closeModal } = useModal();
  const [searchFilter, setSearchFilter] = useState<string | undefined>();
  const [filter, setFilter] = useState<Filter>({});

  const { data } = useBillEditor_ProjectItemsQuery({
    variables: {
      filter: {
        projectStage: project?.stages.map((ps) => ps.id),
        excludeFromPurchaseOrders: false,
        excludeBillTypes: [BillType.Overhead, BillType.RepairAndMaintenance, BillType.Warranty],
      },
    },
    skip: !project,
    fetchPolicy: "cache-first",
  });

  const filterDefs = useMemo(() => createFilterDefs(data?.projectItems), [data?.projectItems]);
  const columns = createColumns();
  const rows = useMemo(() => createRows(data?.projectItems), [data?.projectItems]);

  const testIds = useTestIds({}, "editBillAddProjectItems");
  const selectedProjectItems = useComputed(() => {
    return tableApi.getSelectedRowIds("lineItem");
  }, [tableApi]);

  return (
    <>
      <ModalHeader>Select Items</ModalHeader>
      <ModalBody>
        <div {...testIds}>
          <div css={Css.df.mb2.$}>
            <h2 css={Css.baseMd.$}>Line Items</h2>
          </div>
          <TableActions>
            <Filters filter={filter} filterDefs={filterDefs} onChange={setFilter} />
            <SearchBox onSearch={setSearchFilter} />
          </TableActions>
          <GridTable
            columns={columns}
            rows={rows}
            style={{ grouped: true }}
            filter={searchFilter}
            stickyHeader
            fallbackMessage="No Line Items found for the selected projects."
            sorting={{ on: "client", initial: ["group", "DESC"] }}
            api={tableApi}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button label="Cancel" variant="tertiary" onClick={() => closeModal()} />
        <Button
          label="Done"
          variant="primary"
          disabled={!selectedProjectItems?.length}
          onClick={() => {
            const itemIds = selectedProjectItems || [];
            const selectedItems = data?.projectItems.filter((pi) => itemIds.includes(pi.id)) ?? [];
            if (selectedItems.length) {
              onAdd(selectedItems);
            }
            closeModal();
          }}
        />
      </ModalFooter>
    </>
  );
}
