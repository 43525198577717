import {
  Button,
  ButtonMenu,
  Css,
  MenuItem,
  TabContent,
  Tabs,
  TabWithContent,
  Tag,
  TagType,
  useModal,
} from "@homebound/beam";
import { useEffect, useState } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { HeaderBar } from "src/components";
import { EstimateStatus, useEstimatePageQuery, useSaveEstimateMutation } from "src/generated/graphql-types";
import { useStripStage } from "src/hooks/useStripStage";
import { PageHeader } from "src/routes/layout/PageHeader";
import { AcceptChangeEventModal } from "src/routes/projects/change-events/components/AcceptChangeEventModal";
import { CreateContractModal } from "src/routes/projects/estimates/CreateContractModal";
import { EstimateAcceptedModal } from "src/routes/projects/estimates/EstimateAcceptedModal";
import { EstimateHistoryTab } from "src/routes/projects/estimates/EstimateHistoryTab";
import { EstimateLineItemsTab } from "src/routes/projects/estimates/EstimateLineItemsTab";
import { EstimateOverviewTab } from "src/routes/projects/estimates/EstimateOverviewTab";
import { ProjectParams } from "src/routes/routesDef";
import {
  createChangeOrderExhibitUrl,
  createEstimateExportUrl,
  createEstimatesUrl,
  createHomeownerContractChangeOrderUrl,
} from "src/RouteUrls";
import { assertNever, pushInNextLoop } from "src/utils";
import { hasData, renderLoadingOrError } from "src/utils/queryResult";
import { openNewTab } from "src/utils/window";
import { StringParam, useQueryParams } from "use-query-params";
import { createContractDisabledReason } from "./utils";

export function EstimatePage() {
  useStripStage();
  const history = useHistory();
  const { projectId, estimateId } = useParams<ProjectParams & { estimateId: string }>();
  const [{ tab: tabParam }] = useQueryParams({ tab: StringParam });
  const [tab, setTab] = useState<string>("lineItems");
  useEffect(() => setTab(tabParam || "lineItems"), [tabParam]);
  const { openModal } = useModal();

  // We could push this down into the details tab, but we also need to know the title
  // for any of the three tabs, so just go ahead and do it here.
  const estimateQuery = useEstimatePageQuery({ variables: { estimateId } });
  const [saveEstimate] = useSaveEstimateMutation();

  if (!hasData(estimateQuery)) {
    return renderLoadingOrError(estimateQuery);
  }

  // Watch for project/estimate mismatch
  const estimate = estimateQuery.data.projectEstimate;
  if (projectId !== estimate.projectStage.project.id) {
    return <Redirect to={createEstimatesUrl(projectId)} />;
  }

  const { projectStage, totalPriceInCents, totalCostInCents } = estimate;

  const openCreateContractModal = () =>
    openModal({
      content: (
        <CreateContractModal
          estimateId={estimate.id}
          projectId={projectId}
          projectStageId={projectStage.id}
          estimatedCost={totalCostInCents}
          homeownerPrice={totalPriceInCents}
          isChangeOrder={isChangeOrderEstimate}
          stage={estimate.projectStage.stage.code}
        />
      ),
    });

  const tabs: TabWithContent[] = [
    {
      value: "lineItems",
      name: "Line Items",
      render: () => <EstimateLineItemsTab {...{ estimate }} />,
    },
    {
      value: "overview",
      name: "Overview",
      render: () => <EstimateOverviewTab {...{ createChangeorder: openCreateContractModal, estimate }} />,
    },
    { value: "history", name: "History", render: () => <EstimateHistoryTab estimateId={estimate.id} /> },
  ];

  let menuItems: MenuItem[] = [
    {
      label: "Estimate",
      onClick: () => openNewTab(createEstimateExportUrl(estimate.id)),
    },
  ];

  if (estimate.changeEvent) {
    menuItems = [
      {
        label: "Estimate",
        onClick: () => openNewTab(createEstimateExportUrl(estimate.id)),
      },
      {
        label: "Exhibit CO",
        onClick: () => openNewTab(createChangeOrderExhibitUrl(estimate.id)),
      },
    ];
  }

  const isChangeOrderEstimate = estimate.changeEvent?.id !== undefined;

  return (
    <>
      <PageHeader
        title={estimate.title}
        breadcrumb={{
          label: "All Estimates",
          href: createEstimatesUrl(projectId),
        }}
        left={
          <span css={Css.ttc.$}>
            <Tag type={tagTypeMapper(estimate.status.code)} text={estimate.status.name} />
          </span>
        }
        right={
          <>
            <ButtonMenu items={menuItems} placement="right" trigger={{ label: "Export" }} />
            {(estimate.status.code === EstimateStatus.Draft && (
              <Button
                label="Mark as Accepted"
                onClick={async () => {
                  const { id, title, changeEvent } = estimate;
                  if (!!changeEvent) {
                    openModal({
                      content: <AcceptChangeEventModal changeEventId={changeEvent.id} />,
                    });
                  } else {
                    await saveEstimate({
                      variables: {
                        input: {
                          id,
                          title,
                          status: EstimateStatus.Accepted,
                        },
                      },
                    });
                    openModal({
                      content: (
                        <EstimateAcceptedModal
                          disabledReason={createContractDisabledReason(estimate)}
                          isChangeOrderEstimate={isChangeOrderEstimate}
                          onClickCreate={openCreateContractModal}
                        />
                      ),
                    });
                  }
                }}
              />
            )) ||
              (estimate.status.code === EstimateStatus.Accepted &&
                (estimate.homeownerContractChangeOrder ? (
                  <Button
                    label="View Change Order"
                    onClick={() =>
                      pushInNextLoop(
                        history,
                        createHomeownerContractChangeOrderUrl(projectId, estimate.homeownerContractChangeOrder!.id),
                      )
                    }
                    variant="secondary"
                  />
                ) : (
                  <Button
                    disabled={createContractDisabledReason(estimate)}
                    label={isChangeOrderEstimate ? "Complete Change Order" : "Create Contract"}
                    onClick={openCreateContractModal}
                  />
                )))}
          </>
        }
      />
      <HeaderBar left={<Tabs tabs={tabs} selected={tab} onChange={setTab} />} />
      <TabContent tabs={tabs} selected={tab} contentXss={Css.pt3.$} />
    </>
  );
}

function tagTypeMapper(status: EstimateStatus): TagType {
  switch (status) {
    case EstimateStatus.Draft:
      return "info";
    case EstimateStatus.Rejected:
      return "caution";
    case EstimateStatus.Accepted:
      return "success";
    default:
      return assertNever(status);
  }
}
