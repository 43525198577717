import { Button, Chip, Css, HbLoadingSpinner, IconButton, useModal } from "@homebound/beam";
import { createDesignPackageFinishScheduleUrl, createDesignPackagesUrl } from "src/RouteUrls";
import { ReadyPlanVersionHeader } from "src/components/ReadyPlanVersionHeader";
import { PageHeader } from "src/routes/layout/PageHeader";
import { fail } from "src/utils";
import { PublishAggregateVersionModal } from "../../plan-package/details/components/PublishAggregateVersionModal";
import { DesignPackageLocationBadge } from "../designCatalogAtoms";
import { useNewDesignCatalogWizard } from "../new-design-catalog-wizard/useNewDesignCatalogWizard";
import { useDesignPackageConfiguratorContext } from "./DesignPackageConfiguratorContext";
import { DesignPackageButtonMenu } from "./components/DesignPackageButtonMenu";
import { DesignPackageFilters } from "./components/DesignPackageFilters";
import { DesignPackageItemSlotEditor } from "./components/DesignPackageItemSlotEditor";
import { DesignPackageSlotsLeftNav } from "./components/DesignPackageSlotsLeftNav";
import { DesignUpdatesAvailableButton } from "./components/DesignUpdatesAvailableButton";

export function DesignPackageConfigurator() {
  const { openModal } = useModal();
  const { designPackage } = useDesignPackageConfiguratorContext();
  const openNewDesignCatalogWizard = useNewDesignCatalogWizard(designPackage.id);

  return (
    <div css={{ ...h100ExclHdr, ...Css.w100.$ }}>
      <PageHeader
        title={designPackage.name}
        breadcrumb={[
          { href: createDesignPackagesUrl(), label: "Design Packages" },
          { href: window.location.pathname, label: designPackage.code ?? "" },
        ]}
        left={
          <>
            <DesignPackageLocationBadge designPackage={designPackage} />
            <IconButton icon="cog" onClick={openNewDesignCatalogWizard} />
          </>
        }
        right={
          <div css={Css.df.gap3.aic.$}>
            <div>
              {designPackage.copyInProgress && (
                <div css={Css.df.fdr.aic.baseBd.red400.pr4.gap2.$}>
                  <div css={Css.maxwPx(64).maxhPx(64).$}>
                    <HbLoadingSpinner iconOnly />
                  </div>
                  Sync in progress...
                </div>
              )}
              <Chip text={designPackage.status.name ?? "loading"} />
            </div>
            <DesignUpdatesAvailableButton />
            <ReadyPlanVersionHeader readyPlan={designPackage} versionId={designPackage.version.id} />
            <Button
              size="md"
              disabled={!designPackage.aggregateDraft}
              label="Publish"
              onClick={() =>
                openModal({
                  content: (
                    <PublishAggregateVersionModal
                      draftAggregateVersion={designPackage.aggregateDraft ?? fail("No draft aggregate version found")}
                      copyText="This update and version notes will be made available to any Product Offering that reference it."
                      readyPlanName={designPackage.name}
                    />
                  ),
                })
              }
            />
            <Button
              variant="text"
              label="Finish Schedule"
              onClick={createDesignPackageFinishScheduleUrl(designPackage.id, designPackage.version.id)}
              icon="linkExternal"
            />
            <DesignPackageButtonMenu designPackage={designPackage} />
          </div>
        }
      />
      <div css={Css.df.fdr.h100.w100.$}>
        <div css={Css.wPx(340).mtPx(-24).mlPx(-24).$}>
          <DesignPackageSlotsLeftNav />
        </div>
        <div css={Css.h100.w100.px2.oya.$}>
          <DesignPackageFilters />
          <DesignPackageItemSlotEditor />
        </div>
      </div>
    </div>
  );
}

export const h100ExclHdr = Css.h(
  `calc(100% - ${
    48 + // navbar
    89 + // PageHeader
    -24 // PageHeader bottom margin
  }px)`,
).$;
