import { Fragment, ReactNode } from "react";
import { formatList } from "src/utils";
import { PotentialOperation2 } from "../../generated/graphql-types";

export function disableBasedOnPotentialOperation(potentialOperation?: PotentialOperation2): boolean | ReactNode {
  if (canPerformOperation(potentialOperation)) {
    return false;
  }

  return (
    <Fragment>
      {potentialOperation!.disabledReasons.map((dr, i) => (
        <div key={i}>{dr.message}</div>
      ))}
    </Fragment>
  );
}

export function disableBasedOnPotentialOperationText(potentialOperation?: PotentialOperation2): boolean | string {
  if (canPerformOperation(potentialOperation)) {
    return false;
  }

  return formatList(potentialOperation?.disabledReasons.map((r) => r.message) ?? []);
}

function canPerformOperation(potentialOperation?: PotentialOperation2): boolean {
  return !potentialOperation || potentialOperation.allowed;
}
