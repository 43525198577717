import { ApolloCache, FetchResult, MutationTuple } from "@apollo/client";
import { GridTableApi, TriggerNoticeProps, useGridTableApi, useSnackbar } from "@homebound/beam";
import { useMemo } from "react";
import {
  CopyItemTemplateItemsInput,
  ItApi_CopyItemTemplateItemsDocument,
  ItApi_CopyItemTemplateItemsMutation,
  ItApi_CopyItemTemplateItemsMutationVariables,
  ItApi_SaveItemTemplateItemVersionsDocument,
  ItApi_SaveItemTemplateItemVersionsMutation,
  ItApi_SaveItemTemplateItemVersionsMutationVariables,
  ItemTemplateAnomaliesContextDocument,
  ItemTemplateDetailFragment,
  ItemTemplateItemVersionOrder,
  SaveItemTemplateItemVersionInput,
  useItApi_CopyItemTemplateItemsMutation,
  useItApi_SaveItemTemplateItemVersionsMutation,
} from "src/generated/graphql-types";
import { HasIdAndName, fail, mergeEntities, sortObjectKeys } from "src/utils";
import { ItivFilters, toServerFilter } from "src/utils/itemTemplateItem";
import { ItemTemplateItemRows } from "../ItemTemplateItemAllTable";

export type ItemTemplateApiOpts = {
  template: ItemTemplateDetailFragment | undefined;
  filter: ItivFilters;
  order: ItemTemplateItemVersionOrder[];
};

type SaveItivResult = Promise<FetchResult<ItApi_SaveItemTemplateItemVersionsMutation>>;

/**
 * This class provides an API for interacting with a single scope template, and ensures that the cache is updated correctly.
 *
 * It is expected that a single instance will be created and supplied to any component which needs to interact with the template.
 * Currently that is achieved by prop drilling, but we would like to move to a context based approach should we ever get context
 * working properly with our modals and super drawers.
 *
 * Why can't we components issue their own mutations willy-nilly? Because we need to ensure that the table is updated correctly, which
 * requires information generally not available to the component issuing the mutation. In particular, in order properly update the cache (which in turn populates the table),
 * we must know the currently applied filter and sort order, so that the proper cache key to update can be identified.
 *
 * It also handles:
 * * Removal of ITIVs from the cache when delete operations are performed.
 * * A convenient way to get the currently selected ITIV ids, even for ITIV's which are not yet loaded.
 *
 * NOTE: The cache does not store any WIP changes to ITIVs, or new ITIV's which have not been committed. That is handled by individual form states in the table.
 */
export class ItemTemplateApi {
  private opts: ItemTemplateApiOpts = {
    template: undefined,
    filter: {},
    order: [],
  };
  private cacheQualifier = "";

  constructor(
    private readonly _tableApi: GridTableApi<ItemTemplateItemRows>,
    private readonly _saveItivs: MutationTuple<
      ItApi_SaveItemTemplateItemVersionsMutation,
      ItApi_SaveItemTemplateItemVersionsMutationVariables
    >[0],
    private readonly _copyFromTemplate: MutationTuple<
      ItApi_CopyItemTemplateItemsMutation,
      ItApi_CopyItemTemplateItemsMutationVariables
    >[0],
    private readonly _triggerNotice?: (props: TriggerNoticeProps) => { close: () => void },
  ) {}

  get templateId() {
    return this.opts.template?.id ?? "it:1";
  }

  get template(): ItemTemplateDetailFragment {
    return this.opts.template ?? fail("ItAPI used before template was set");
  }

  get tableApi() {
    return this._tableApi;
  }

  get selectedItivIds() {
    return this.tableApi
      .getSelectedRows()
      .flatMap((row) => {
        switch (row.kind) {
          case "item":
            return row.data.id;
          case "groupBy":
          case "totals":
            return row.data.itivIdsInGroup;
          default:
            return [];
        }
      })
      .unique()
      .compact();
  }

  get includeRemoved() {
    return this.opts.filter.showChanges?.includeRemoved ?? false;
  }

  get highlightChanges() {
    return this.opts.filter.showChanges?.highlightChanges ?? false;
  }

  /** Exposed for testing purposes */
  get _testCacheQualifier() {
    return this.cacheQualifier;
  }

  updateOpts(partialOpts: Partial<ItemTemplateApiOpts>) {
    this.opts = { ...this.opts, ...partialOpts };
    this.cacheQualifier = JSON.stringify({
      filter: sortObjectKeys(toServerFilter(this.templateId, this.opts.filter)),
      order: this.opts.order.map((order) => sortObjectKeys(order)),
    });
  }

  private addItemsToCache(cache: ApolloCache<any>, newItems: HasIdAndName[]) {
    cache.modify({
      fields: {
        [`itemTemplateItemVersionsPage:${this.cacheQualifier}`]: (
          existingResult: any, // Modifiers<ItemTemplateItemVersionsPageQuery["itemTemplateItemVersionsPage"]>, //, TODO: Fix this type?
        ) => {
          const { items: existingItivs } = existingResult;
          // Then the saved entities should be added to the itivPage cache
          return {
            ...existingResult,
            items: mergeEntities(
              existingItivs,
              newItems.map((itiv) => ({ __ref: cache.identify(itiv) })),
            ),
          };
        },
      },
    });
  }

  private async onSaveRaw(input: SaveItemTemplateItemVersionInput[] | SaveItemTemplateItemVersionInput) {
    const { includeRemoved, highlightChanges } = this;
    const requestInput = Array.isArray(input) ? input : [input];
    const result = await this._saveItivs({
      mutation: ItApi_SaveItemTemplateItemVersionsDocument,
      variables: {
        items: requestInput.map((itiInput) => ({ templateId: this.templateId, ...itiInput })),
        highlightChanges,
      },
      refetchQueries: this.template.isReadyHomeTemplate ? [ItemTemplateAnomaliesContextDocument] : undefined,
      update: (cache, { data }) => {
        const { deleted = [], removed = [], itemTemplateItemVersions = [] } = data?.saveItemTemplateItemVersions ?? {};

        const effectivelyDeletedIds = [
          ...deleted,
          ...(includeRemoved ? [] : removed.map((itiv: { id: string }) => itiv.id)),
        ];
        if (effectivelyDeletedIds.nonEmpty) {
          // Any ITIV which was deleted (or removed if we aren't including removed) should be immediately removed from the cache
          effectivelyDeletedIds.forEach((id) => cache.evict({ id: `ItemTemplateItemVersion:${id}` }));
          // clear the selection so that deleted rows do not appear as hidden selections
          this.tableApi.clearSelections();
        }

        this.addItemsToCache(cache, itemTemplateItemVersions);
      },
    });

    return result;
  }

  async addItiv(item: SaveItemTemplateItemVersionInput | SaveItemTemplateItemVersionInput[]): SaveItivResult {
    return this.onSaveRaw(item);
  }
  async saveItiv(item: SaveItemTemplateItemVersionInput | SaveItemTemplateItemVersionInput[]): SaveItivResult {
    return this.onSaveRaw(item);
  }
  async deleteItiv(item: SaveItemTemplateItemVersionInput | SaveItemTemplateItemVersionInput[]): SaveItivResult {
    return this.onSaveRaw(item);
  }
  async copyFromTemplate(input: CopyItemTemplateItemsInput): Promise<FetchResult<ItApi_CopyItemTemplateItemsMutation>> {
    const result = await this._copyFromTemplate({
      mutation: ItApi_CopyItemTemplateItemsDocument,
      variables: { input, highlightChanges: this.highlightChanges },
      update: (cache, { data }) => {
        this.addItemsToCache(cache, data?.copyItemTemplateItems?.copiedItemVersions ?? []);
      },
    });
    return result;
  }
}

type UseItemTemplateApiProps = Partial<ItemTemplateApiOpts>;

/**
 * Generally this hook should only be used by a parent component and then drilled down to all other components which need it.
 * This is necessary to ensure that all components are using the same instance of the api, and thus the cache will be maintained correctly.
 *
 * NOTE: We would love to have this available as a context instead, but since our modals and super drawers are outside of the react tree, we cannot use context.
 */
export function useItemTemplateApi(opts: UseItemTemplateApiProps = {}) {
  const { triggerNotice } = useSnackbar();
  const [saveItivs] = useItApi_SaveItemTemplateItemVersionsMutation();
  const [copyFromTemplate] = useItApi_CopyItemTemplateItemsMutation();
  const tableApi = useGridTableApi<ItemTemplateItemRows>();
  const api = useMemo(
    () => new ItemTemplateApi(tableApi, saveItivs, copyFromTemplate, triggerNotice),
    [tableApi, saveItivs, copyFromTemplate, triggerNotice],
  );
  api.updateOpts(opts);

  return [api] as const;
}
