import { Css, useSnackbar } from "@homebound/beam";
import { useEffect } from "react";
import { CsvUploader } from "src/components/CsvUploader";
import { useTakeoffsStore } from "../../TakeoffsManagerContext";
import { useTakeoffUploader } from "../useTakeoffUploader";

export function PlanPackageTakeoffTableEmpty() {
  const { triggerNotice } = useSnackbar();
  const addTli = useTakeoffsStore((state) => state.saveTli);
  const { errors, addError, handleOnDrop, handleOnClick, importDataReady } = useTakeoffUploader(addTli, {
    autoImport: true,
  });

  useEffect(() => {
    if (importDataReady && errors.isEmpty) {
      handleOnClick().catch(() => triggerNotice({ message: "Failed to import items", icon: "error" }));
    }
  }, [importDataReady, handleOnClick, triggerNotice, errors.isEmpty]);

  return (
    <div css={Css.p("10%").$}>
      {/* TODO: Handle Formating of Drop Region better */}
      <label css={{ ...Css.gray700.smMd.db.$, "& button": Css.bcBlue600.bw2.bgBlue50.h("300px").$ }}>
        <CsvUploader label="Drag & drop files to import" errors={errors} onError={addError} onDrop={handleOnDrop} />
      </label>
    </div>
  );
}
