import { BoundTextField, Button, Css, useSnackbar } from "@homebound/beam";
import { ObjectConfig, ObjectState, required, useFormState } from "@homebound/form-state";
import omit from "lodash/omit";
import { Observer } from "mobx-react";
import { useHistory, useParams } from "react-router";
import {
  createDesignPackageProductSearchUrl,
  createDesignPackageSlotDetailUrl,
  createDesignPackagesUrl,
} from "src/RouteUrls";
import { SelectMaybeNewField } from "src/components/selectMaybeNew/SelectMaybeNewField";
import {
  DesignPackageAddProductMetadataDocument,
  InputMaybe,
  MaterialType,
  SaveMaterialVariantInput,
  useAddProductTliPlaceholderQuery,
  useDesignPackageAddProductMetadataQuery,
  useSaveMaterialBrandMutation,
  useSaveMaterialVariantMutation,
} from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { MaterialDimensionsForm } from "src/routes/libraries/material-catalog/components/MaterialDimensionsForm";
import { MaterialImageEditor } from "src/routes/libraries/material-catalog/components/MaterialImageEditor";
import { VariantImageAsset } from "src/routes/libraries/material-catalog/components/material-super-drawer/MaterialSuperDrawerContext";
import { fail } from "src/utils";

type DesignPackageAddProductParams = {
  designPackageId: string;
  designPackageVersionId: string;
  placeholderTliId: string;
  productTliId: string;
};

export function AddProductPage() {
  const history = useHistory();
  const { triggerNotice } = useSnackbar();
  const { designPackageId, designPackageVersionId, placeholderTliId, productTliId } =
    useParams<DesignPackageAddProductParams>();
  const [saveMaterialBrand] = useSaveMaterialBrandMutation();
  const [saveMaterialVariant] = useSaveMaterialVariantMutation();

  // Pull in the Placeholder requirements (like `36in Gas Range`) and possible Attribute Filters (like Finish, Installation Type, etc.)
  const query = useAddProductTliPlaceholderQuery({
    variables: { placeholderTliId: placeholderTliId ?? fail("placeholderTliId is required") },
  });

  const placeholderTli = query.data?.takeoffLineItem;
  const itemMads = placeholderTli?.item.materialAttributeDimensions ?? [];

  const { materialBrands } = useDesignPackageAddProductMetadataQuery().data ?? {};
  const breadCrumb = [{ href: createDesignPackagesUrl(), label: "Design Packages" }];

  const formState = useFormState({
    config: formConfig,
    init: { onlyOnce: true, input: { type: MaterialType.Product } },
  });

  const saveBrand = async (newBrand: string | undefined) => {
    if (newBrand) {
      const { data } = await saveMaterialBrand({
        variables: { input: { name: newBrand } },
        refetchQueries: [DesignPackageAddProductMetadataDocument],
      });
      if (data) {
        formState.brandId.set(data.saveMaterialBrand.materialBrand.id);
      }
    }
  };

  const handleSave = async () => {
    const { images, ...input } = formState.value;
    const { data } = await saveMaterialVariant({
      variables: {
        input: {
          ...input,
          itemId: placeholderTli?.item.id,
          images: images?.map((i) => omit(i, "downloadUrl", "attachmentUrl")),
        },
      },
    });

    const savedMaterial = data?.saveMaterialVariant.materialVariant;

    if (savedMaterial) {
      triggerNotice({
        message: `Material: ${savedMaterial.code} created`,
        icon: "success",
      });
    }

    return savedMaterial;
  };

  return (
    <div>
      <PageHeader title="Add New Product" backButton={() => history.goBack()} breadcrumb={breadCrumb} />
      <div css={Css.df.jcc.pb6.$}>
        <div /* Form Container */ css={Css.mwPx(550).$}>
          <section css={Css.bb.bcGray400.py4.$}>
            <div css={Css.xlMd.mb2.$}>Product Details</div>
            <Observer>
              {() => (
                <div css={Css.df.fdc.gap2.$}>
                  <div>
                    Product Type: <span css={Css.smMd.$}>{placeholderTli?.item.name}</span>
                  </div>
                  <BoundTextField label="Name" field={formState.materialName} />
                  <SelectMaybeNewField
                    label="Brand"
                    value={formState.brandId.value}
                    options={materialBrands ?? []}
                    onSelect={(brandId) => formState.brandId.set(brandId)}
                    getOptionValue={(o) => o.id}
                    getOptionLabel={(o) => o.name}
                    onAdd={saveBrand}
                  />
                  <BoundTextField label="SKU / Model Number" field={formState.modelNumber} />
                  <BoundTextField label="Manufacturer URL" field={formState.manufacturerUrl} />
                </div>
              )}
            </Observer>
          </section>
          <section css={Css.py4.df.fdc.gap2.$}>
            <div css={Css.xlMd.$}>Product Attributes</div>
            <MaterialDimensionsForm type={MaterialType.Product} dimensions={itemMads} variant={formState} />
          </section>
        </div>

        <div /* Product Images */ css={Css.mwPx(550).df.fdc.aife.$}>
          <section css={Css.py4.df.fdc.$}>
            <div css={Css.xlMd.pb4.$}>Product Images</div>
            <MaterialImageEditor variant={formState} />
          </section>
        </div>
      </div>
      <Observer>
        {() => (
          <div /* BottomFooter */ css={Css.absolute.bottom0.w100.df.jcfe.aic.p4.bgWhite.bt.bcGray300.gap2.sm.hPx(80).$}>
            <Button
              variant="textSecondary"
              label="Back to Product Search"
              onClick={createDesignPackageProductSearchUrl(designPackageId, designPackageVersionId, placeholderTliId)}
              size="lg"
            />
            <Button
              label="Add & Select Product"
              onClick={async () => {
                const newMaterialVariant = await handleSave();
                // Navigate to the product details page
                history.push(
                  createDesignPackageSlotDetailUrl(
                    designPackageId,
                    designPackageVersionId,
                    placeholderTliId,
                    productTliId,
                    newMaterialVariant?.defaultBidItem.id,
                  ),
                );
              }}
              disabled={!formState.dirty}
              size="lg"
            />
          </div>
        )}
      </Observer>
    </div>
  );
}

export type AddProductDimensionsFormState = ObjectState<SaveMaterialVariantInput>;
export type AddProductFormValue = Omit<SaveMaterialVariantInput, "components"> & {
  images?: InputMaybe<Array<VariantImageAsset>>;
};

const formConfig: ObjectConfig<AddProductFormValue> = {
  id: { type: "value" },
  type: { type: "value" },
  materialName: { type: "value", rules: [required] },
  brandId: { type: "value" },
  modelNumber: { type: "value" },
  manufacturerUrl: { type: "value" },
  mavIds: { type: "value" },
  images: {
    type: "list",
    config: {
      id: { type: "value" },
      sortOrder: { type: "value" },
      attachmentUrl: { type: "value" },
      downloadUrl: { type: "value" },
      asset: {
        type: "object",
        config: {
          id: { type: "value" },
          s3Key: { type: "value" },
          fileName: { type: "value" },
          contentType: { type: "value" },
          sizeInBytes: { type: "value" },
          delete: { type: "value" },
        },
      },
    },
  },
};
