import { Accordion, Css } from "@homebound/beam";
import { pluralize } from "src/utils";

export function renderListItem(count: number, action: string, baseText: string) {
  return (
    count > 0 && (
      <li>
        {/* Removed 2 takeoff items */}
        {action} {count} {pluralize(count, baseText)}
      </li>
    )
  );
}

export function renderAccordion(title: string, children: React.ReactNode) {
  return (
    <Accordion defaultExpanded topBorder={false} title={title} xss={Css.py0.$}>
      {children}
    </Accordion>
  );
}
