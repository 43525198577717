import { ButtonModal, Css, IconKey, StaticField, Typography } from "@homebound/beam";
import { Link } from "react-router-dom";
import { createDevelopmentLotDetailsUrl } from "src/RouteUrls";
import {
  LotConfigurationDetails_GlobalOptionTypeFragment,
  LotConfigurationDetails_ReadyPlanConfigFragment,
  useLotConfigurationDetailsQuery,
} from "src/generated/graphql-types";
import { OptionTypes } from "src/routes/developments/lot-summary/sequence-sheet/components/utils";
import { groupBy } from "src/utils";
import { LotConfigurationSection } from "./LotConfigurationSection";
import { LotConfigurationStatusChip } from "./LotConfigurationStatusChip";

type LotConfigurationDetailsProps = {
  projectId: string;
  /** Layout config button and plan name as columns (default) or as row */
  layout?: "column" | "row";
  size?: Typography;
};

export function LotConfigurationDetailsStaticField({
  projectId,
  layout = "column",
  size = "tinyMd",
}: LotConfigurationDetailsProps) {
  const { data, loading } = useLotConfigurationDetailsQuery({ variables: { projectId } });
  const isRowLayout = layout === "row";

  if (loading || !data) return <StaticField label="Plan" value="Loading..." />;

  if (isRowLayout)
    return (
      <div css={Css.df.fdr.gap1.aic.$}>
        <div css={Css[size].gray700.$}>{data?.project.readyPlanConfig?.readyPlan?.displayName}</div>
        <div css={Css[size].$}>
          {data.project.readyPlanConfig && (
            <ButtonModal
              content={
                <LotConfigurationDetailsDataView
                  readyPlanConfig={data.project.readyPlanConfig}
                  globalOptionTypes={data.globalOptionTypes}
                />
              }
              variant="text"
              hideEndAdornment
              trigger={{ label: "See lot configuration" }}
            />
          )}
        </div>
      </div>
    );

  return (
    <StaticField
      label={
        <div css={Css.df.aic.$}>
          <span css={Css.gray700.$}>Plan</span>
          <span css={Css.xs.ml1.$}>
            {data.project.readyPlanConfig?.options && (
              <ButtonModal
                content={
                  <LotConfigurationDetailsDataView
                    readyPlanConfig={data.project.readyPlanConfig}
                    globalOptionTypes={data.globalOptionTypes}
                  />
                }
                variant="text"
                hideEndAdornment
                trigger={{ label: "See lot configuration" }}
              />
            )}
          </span>
        </div>
      }
      value={
        data.project.readyPlanConfig?.readyPlan?.displayName
          ? data.project.readyPlanConfig?.readyPlan?.displayName
          : "N/A"
      }
    />
  );
}

function LotConfigurationDetailsDataView({
  readyPlanConfig,
  globalOptionTypes,
}: {
  readyPlanConfig: LotConfigurationDetails_ReadyPlanConfigFragment;
  globalOptionTypes: LotConfigurationDetails_GlobalOptionTypeFragment[];
}) {
  const grouped = groupBy(readyPlanConfig.options, ({ readyPlanOption }) => readyPlanOption.globalOption.type.name);
  const validOptionTypes = globalOptionTypes
    .filter((type) => !type.isElevation)
    .filter((type) => !type.isPlanPackage)
    .sortBy(({ order }) => order);
  return (
    <div css={Css.w("470px").$}>
      <div css={Css.df.jcsb.mb3.$}>
        <div css={Css.df.gap1.mb3.$}>
          <h3 css={Css.smBd.$}>Lot configuration</h3>
          <LotConfigurationStatusChip readyPlanConfig={readyPlanConfig} />
        </div>
        {readyPlanConfig.readyPlan?.development && (
          <Link
            to={createDevelopmentLotDetailsUrl(readyPlanConfig.readyPlan.development.id, readyPlanConfig.id)}
            data-testid="link"
            css={Css.tinyMd.addIn(":focus-visible", Css.outline0.$).$}
          >
            View Details
          </Link>
        )}
      </div>
      <div css={Css.df.fdc.gap2.$}>
        {validOptionTypes.map((option) => (
          <div key={option.id} css={Css.bb.bcGray200.pb2.$}>
            <LotConfigurationSection
              icon={ICON_CONFIG[option.name] || "customize"}
              optionType={option.name}
              data={
                option.name === OptionTypes.ExteriorScheme
                  ? [...(grouped[OptionTypes.Elevation] ?? []), ...(grouped[OptionTypes.ExteriorScheme] ?? [])]
                  : grouped[option.name]
              }
              title={
                option.name === OptionTypes.AddOn
                  ? "Add-ons"
                  : option.name === OptionTypes.ExteriorScheme
                    ? "Exterior Options"
                    : `${option.name} Options`
              }
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export const ICON_CONFIG: Record<string, IconKey> = {
  [OptionTypes.ExteriorScheme]: "leaf",
  [OptionTypes.FloorPlan]: "floorPlan",
  [OptionTypes.Interior]: "chair",
  [OptionTypes.AddOn]: "bolt",
};
