import {
  Button,
  Chip,
  CollapseToggle,
  Css,
  GridDataRow,
  GridTable,
  ModalBody,
  ModalFooter,
  ModalHeader,
  SelectToggle,
  TextAreaField,
  actionColumn,
  column,
  emptyCell,
  selectColumn,
  useComputed,
  useGridTableApi,
  useModal,
  useSnackbar,
} from "@homebound/beam";
import { useCallback, useMemo, useState } from "react";
import { Percentage, Price } from "src/components";
import {
  BillSource,
  PlanTaskBillModal_ProtoBillFragment,
  SaveBillInput,
  SaveBillLineItemInput,
  usePlanTaskBillModal_ProtoBillsQuery,
  useTaskBillModal_SaveBillsMutation,
} from "src/generated/graphql-types";
import { isDefined, pluralize, queryResult } from "src/utils";

export type TaskBillModalProps = {
  taskId: string;
  /**
   * Since a task can be tied to multiple allocated items & trades,
   * Payment to specific trade(s) before task completion is allowed.
   * This can be triggered via clicking on one of the task's trade partners in the task detail pane/ passing tradePartnerId
   */
  tradePartnerId?: string | undefined;
  onComplete?: () => Promise<void>;
};

export function PlanTaskBillModal(props: TaskBillModalProps) {
  const { taskId, tradePartnerId, onComplete } = props;
  const query = usePlanTaskBillModal_ProtoBillsQuery({ variables: { planTaskId: taskId } });
  return queryResult(query, (data) => (
    <BillModalView
      protoBills={data.planTask.protoBills}
      tradePartnerId={tradePartnerId}
      onComplete={onComplete}
      taskId={taskId}
    />
  ));
}

type BillModalViewProps = TaskBillModalProps & {
  protoBills: PlanTaskBillModal_ProtoBillFragment[];
};

const ungroupedTradeLineId = "no-group";

function BillModalView(props: BillModalViewProps) {
  const { protoBills, tradePartnerId, onComplete, taskId } = props;

  const tableApi = useGridTableApi<Row>();
  const { closeModal } = useModal();
  const { triggerNotice } = useSnackbar();
  const [saveTaskBills] = useTaskBillModal_SaveBillsMutation();

  const [defermentReason, setDefermentReason] = useState<string | undefined>(undefined);

  const allowPayBeforeTaskCompletion = isDefined(tradePartnerId);
  const title = allowPayBeforeTaskCompletion
    ? "Confirm payment amounts to send to the following trades for completed work:"
    : "Confirm payment amounts to send to the following trades for completed work. Deselected items will be added to an outstanding bill for the trade:";

  const { tradeBills, lineItemsByOwner } = useMemo(() => {
    // If a tradeId has been passed to the modal, show only bills for that trade
    const tradeBills = isDefined(tradePartnerId)
      ? protoBills.filter((pb) => pb.tradePartner.id === tradePartnerId)
      : protoBills;

    const lineItemsByOwner = groupBillLineItems(tradeBills);

    return { tradeBills, lineItemsByOwner };
  }, [protoBills, tradePartnerId]);

  const selectedDrawRows = useComputed(() => tableApi.getSelectedRows("draw"), [tableApi]);
  const selectedTradeLineRows = useComputed(() => tableApi.getSelectedRows("tradeLine"), [tableApi]);
  const allSelectedRows = [...selectedDrawRows, ...selectedTradeLineRows];

  const deselectedLineItems = useMemo(() => {
    const lineItems = tradeBills.flatMap((protoBill) => protoBill.lineItems);
    const selectedDrawIds = selectedDrawRows.map((r) => r.id);
    const selectedDrawLineItems = lineItems.filter(
      (li) => isDefined(li.commitmentDraw) && selectedDrawIds.includes(li.commitmentDraw!.id),
    );
    const drawClisIds = new Set(selectedDrawLineItems.map((li) => li.commitmentLineItem.id));

    const selectedTradeLineIds = selectedTradeLineRows.map((r) => r.id);
    const selectedTradeLineItems = lineItems.filter(
      (li) =>
        !isDefined(li.commitmentDraw) &&
        selectedTradeLineIds.includes(
          li.commitmentLineItem.primaryBidContractLineItem?.prorations?.first?.tradeLineItem.id ?? ungroupedTradeLineId,
        ),
    );
    const selectedTradeCliIds = new Set(selectedTradeLineItems.map((li) => li.commitmentLineItem.id));

    const deselectedLineItems = lineItems.filter(
      (li) => !drawClisIds.has(li.commitmentLineItem.id) && !selectedTradeCliIds.has(li.commitmentLineItem.id),
    );

    return deselectedLineItems;
  }, [selectedDrawRows, tradeBills, selectedTradeLineRows]);

  const rows = useMemo(() => getRows(lineItemsByOwner), [lineItemsByOwner]);
  const columns = useMemo(() => getColumns(allowPayBeforeTaskCompletion), [allowPayBeforeTaskCompletion]);

  const mutationInput = useMemo(
    () => mapToInput(taskId, tradeBills, deselectedLineItems, tradePartnerId, defermentReason),
    [taskId, tradeBills, deselectedLineItems, tradePartnerId, defermentReason],
  );

  const onSave = useCallback(async () => {
    // Save the `C2P bills` based on modal selections
    const saveBillsResult = await saveTaskBills({
      variables: {
        input: { bills: mutationInput },
      },
      onCompleted: async () => {
        onComplete && (await onComplete());
      },
    });

    if (saveBillsResult.data) {
      saveBillsResult.data.saveBills.bills.forEach((b) => {
        const message = b.isDeferred
          ? `Outstanding bill #${b.tradePartnerNumber} for ${b.tradePartner.name} created`
          : `Bill #${b.tradePartnerNumber} sent to ${b.tradePartner.name} for approval`;
        triggerNotice({ message });
      });
      closeModal();
    }
  }, [mutationInput, triggerNotice, closeModal, onComplete, saveTaskBills]);

  const needsDefermentReason =
    deselectedLineItems.nonEmpty &&
    (!isDefined(defermentReason) || defermentReason === "") &&
    "Deferment reason is required for deselected items";

  return (
    <>
      <ModalHeader>Issue {pluralize(tradeBills.length, "bill")}</ModalHeader>
      <ModalBody>
        <div css={Css.gray700.sm.mb2.$} data-testid="title">
          {title}
        </div>
        <GridTable rows={rows} columns={columns} style={{ allWhite: true, bordered: false }} api={tableApi} />
        {deselectedLineItems.nonEmpty && (
          <div>
            <div css={Css.xs.gray700.mt3.mb2.$}>
              Provide reasoning for completing this task and not issuing payment for deselected items (Deselected items
              will be added to an outstanding bill for the trade).
            </div>
            {/* Per design, although this modal can initiate multiple deferred bills at a time, there is only 1
            textfield for a deferment reason. The assumption is if bills are deferred together, one reason can be
            applied to all bills.
            Multiple deferred Bills however is a rare exception case as we don't expect multiple trade allocations to a single task.*/}
            <TextAreaField
              label="Deferment Reason"
              value={defermentReason}
              onChange={(value) => setDefermentReason(value)}
              labelStyle="hidden"
            />
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button label="Cancel" variant="tertiary" onClick={closeModal} />
        {!allowPayBeforeTaskCompletion && (
          <Button
            label="Complete without Payment"
            variant="secondary"
            onClick={onSave}
            disabled={
              (allSelectedRows.nonEmpty &&
                "Enabled when all items are deselected. Individually deselected items will be added to outstanding bills.") ||
              needsDefermentReason ||
              (mutationInput.isEmpty && "All items have been billed")
            }
          />
        )}
        <Button
          label="Release Bill"
          variant="primary"
          onClick={onSave}
          disabled={
            (!allowPayBeforeTaskCompletion && allSelectedRows.isEmpty && "No items selected for immediate release") ||
            needsDefermentReason
          }
        />
      </ModalFooter>
    </>
  );
}

type DrawRow = {
  kind: "draw";
  data: { drawBasisPoints: number; drawDescription?: string | null; lineItemTotal: number };
};
type TradeLineRow = {
  kind: "tradeLine";
  data: { displayName: string; lineItemTotal: number };
};
type OwnerRow = {
  kind: "lineItemOwner";
  data: {
    tradePartnerName: string | undefined;
    accountingNumber: number;
    ownerType: string;
  };
};
type Row = OwnerRow | DrawRow | TradeLineRow;

type TaskBillModal_BillLineItemInput = Pick<
  SaveBillLineItemInput,
  "id" | "taskId" | "commitmentLineItemId" | "amountInCents"
>;
type TaskBillModal_SaveBillInput = Pick<SaveBillInput, "id"> & { lineItems: TaskBillModal_BillLineItemInput[] };

function groupBillLineItems(protoBills: PlanTaskBillModal_ProtoBillFragment[]) {
  // First group the protoBills by Commitment / CommitmentChangeOrder (owner of the line item)
  const lineItemsByOwner = protoBills
    .flatMap((protoBill) => protoBill.lineItems)
    .groupByObject((li) => li.commitmentLineItem.owner);

  // In the unlikely event that line items are not part of a "draw" and also do not have a "tradeLineItem",
  // then bucket them together into a "Misc" section (using this object identity for the `groupByObject` below).
  const ungroupedTradeLineBucket = { id: ungroupedTradeLineId, bidItem: { displayName: "Misc" } } as const;

  return lineItemsByOwner.map(([lineItemOwner, lineItems]) => {
    // Separate out line items associated a "commitmentDraw" then group the non-draw items by trade line
    const [drawLineItems, nonDrawItems] = lineItems.partition((li) => isDefined(li.commitmentDraw));
    const draws = drawLineItems.groupByObject((li) => li.commitmentDraw!);
    const tradeLines = nonDrawItems.groupByObject(
      (li) =>
        li.commitmentLineItem.primaryBidContractLineItem?.prorations?.first?.tradeLineItem ?? ungroupedTradeLineBucket,
    );
    return { lineItemOwner, draws, tradeLines };
  });
}

function getRows(lineItemsByOwner: ReturnType<typeof groupBillLineItems>): GridDataRow<Row>[] {
  return lineItemsByOwner.map(({ lineItemOwner, draws, tradeLines }) => {
    const drawRows = draws.map(([commitmentDraw, drawLineItems]) => ({
      kind: "draw" as const,
      id: commitmentDraw.id,
      initSelected: true,
      data: {
        drawBasisPoints: commitmentDraw.amountInBasisPoints,
        drawDescription: commitmentDraw.description,
        lineItemTotal: drawLineItems.sum((li) => li.amountInCents),
      },
    }));

    const tradeLineRows = tradeLines.map(([tradeLine, lineItems]) => ({
      kind: "tradeLine" as const,
      id: tradeLine.id,
      initSelected: true,
      data: {
        displayName: tradeLine.bidItem?.displayName ?? "Misc",
        lineItemTotal: lineItems.sum((li) => li.amountInCents),
      },
    }));

    return {
      kind: "lineItemOwner",
      id: lineItemOwner.id,
      data: {
        tradePartnerName: lineItemOwner.tradePartner?.name,
        accountingNumber: lineItemOwner.accountingNumber,
        ownerType: lineItemOwner.__typename === "Commitment" ? "PO" : "CO",
      },
      children: [...drawRows, ...tradeLineRows],
    };
  });
}

function getColumns(allowPayBeforeTaskCompletion: boolean) {
  return [
    actionColumn<Row>({
      lineItemOwner: (_, { row }) => <CollapseToggle row={row} />,
      draw: emptyCell,
      tradeLine: emptyCell,
      w: "32px",
    }),
    // Prevent deselecting line items when directly in pay trade view
    ...(!allowPayBeforeTaskCompletion
      ? [
          selectColumn<Row>({
            lineItemOwner: (_data, { row }) => ({
              content: () => <SelectToggle id={row.id} />,
            }),
            draw: (_data, { row }) => ({
              content: () => <SelectToggle id={row.id} />,
            }),
            tradeLine: (_data, { row }) => ({
              content: () => <SelectToggle id={row.id} />,
            }),
            w: "28px",
          }),
        ]
      : []),
    column<Row>({
      lineItemOwner: (row) => ({
        content: () => {
          return (
            <div>
              <div css={Css.xsBd.lh(2).$} data-testid="tradePartner">
                {row.tradePartnerName}
              </div>
              <Chip text={`${row.ownerType}# ${row.accountingNumber}`} data-testid="accountingNumber" />
            </div>
          );
        },
        value: row.accountingNumber,
      }),
      draw: (row) => (
        <div css={Css.xsMd.$} data-testid="drawPercent">
          {row.drawDescription ?? "Draw"} (<Percentage basisPoints percent={row.drawBasisPoints} />)
        </div>
      ),
      tradeLine: (row) => (
        <div css={Css.xsMd.$} data-testid="tradeLineDescription">
          {row.displayName}
        </div>
      ),
      w: 4,
    }),
    column<Row>({
      lineItemOwner: emptyCell,
      draw: (row) => (
        <div css={Css.xsSb.$} data-testid="rowTotal">
          <Price valueInCents={row.lineItemTotal} />
        </div>
      ),
      tradeLine: (row) => (
        <div css={Css.xsSb.$} data-testid="rowTotal">
          <Price valueInCents={row.lineItemTotal} />
        </div>
      ),
      w: 1,
    }),
  ];
}

function mapToInput(
  taskId: string,
  protoBills: PlanTaskBillModal_ProtoBillFragment[],
  deselectedLineItems: PlanTaskBillModal_ProtoBillFragment["lineItems"],
  tradePartnerId: string | undefined,
  internalNote: string | undefined,
): TaskBillModal_SaveBillInput[] {
  const deselectedClis = deselectedLineItems.map((li) => li.commitmentLineItem.id);

  const groupedBillInputsByTrade = protoBills.groupBy((pb) => pb.tradePartner.id);

  const groupedExistingBillsByTrade = protoBills
    .flatMap((p) => p.existingBills)
    // if in direct pay trade view we filter existing bills by tp id
    .filter((b) => !tradePartnerId || b.tradePartner.id === tradePartnerId)
    .groupBy((b) => b.tradePartner.id);

  const saveBillsInput = Object.keys(groupedBillInputsByTrade).reduce((input, key) => {
    const groupedInputBills = groupedBillInputsByTrade[key];
    const groupedExistingBills = groupedExistingBillsByTrade[key] || [];

    // If a trade has 2 bill types open, make sure we add line items to the correct bill type
    // otherwise if no bill found for that trade with a matching type
    // create a new bill for the trade from our input data

    // extract trade partner and project stage id from an existing draft bill or our inputs
    const { tradePartner } = isDefined(groupedExistingBills.first?.id)
      ? groupedExistingBills.first
      : (groupedInputBills.first ?? {});
    const projectStageId =
      groupedExistingBills.first?.projectStage?.id ??
      groupedInputBills.first?.lineItems?.first?.commitmentLineItem.projectItem.projectStage.id;

    // split existing trade bills by deferred or immediate type
    const [existingDeferredBill, existingImmediateBill] = groupedExistingBills.partition((b) => b.isDeferred);
    // if there's a matching bill already existing for the trade, format the line items
    const existingDeferredItems = (existingDeferredBill.first?.lineItems || [])
      .map((li) => ({
        taskId: li.task?.id,
        commitmentLineItemId: li.commitmentLineItem?.id,
        amountInCents: li.amountInCents,
      }))
      .uniqueByKey("commitmentLineItemId");
    const existingImmediateItems = (existingImmediateBill.first?.lineItems || [])
      .map((li) => ({
        taskId: li.task?.id,
        commitmentLineItemId: li.commitmentLineItem?.id,
        amountInCents: li.amountInCents,
      }))
      .uniqueByKey("commitmentLineItemId");

    // format the new line item inputs
    const newLineItems = groupedInputBills.flatMap((b) =>
      b.lineItems.map((li) => ({
        taskId,
        commitmentLineItemId: li.commitmentLineItem.id,
        amountInCents: li.amountInCents,
      })),
    );

    // split the new line item inputs by deferred or immediate type based on items deselected
    // ensure the split occurs only when not in the direct pay trade view
    const [newDeferredItems, newImmediateItems] = newLineItems.partition(
      (bli) => deselectedClis?.includes(bli.commitmentLineItemId) && !isDefined(tradePartnerId),
    );

    const deferredBillInput = [
      ...(existingDeferredBill.first?.tradePartner.id && newDeferredItems.nonEmpty
        ? [
            {
              // If deferred bill already exists update existing bill
              id: existingDeferredBill.first.id,
              lineItems: [...existingDeferredItems, ...newDeferredItems],
              // preserve previous deferred notes & append notes for new items added
              internalNote: [existingDeferredBill.first.internalNote, internalNote].join(",\n"),
            },
          ]
        : // create a new deferred trade bill if deferred bill does not exist
          newDeferredItems.nonEmpty
          ? [
              {
                source: BillSource.ClickToPay,
                isDeferred: true,
                internalNote,
                tradePartnerId: tradePartner?.id,
                projectStageId,
                lineItems: newDeferredItems,
              },
            ]
          : []),
    ];

    const immediateBillInput = [
      ...(existingImmediateBill.first?.tradePartner.id && newImmediateItems.nonEmpty
        ? [
            {
              // If immediate bill already exists update existing bill
              id: existingImmediateBill.first.id,
              lineItems: [...existingImmediateItems, ...newImmediateItems],
            },
          ]
        : // create a new immediate trade bill if immediate bill does not exist
          newImmediateItems.nonEmpty
          ? [
              {
                source: BillSource.ClickToPay,
                isDeferred: false,
                tradePartnerId: tradePartner?.id,
                projectStageId,
                lineItems: newImmediateItems,
              },
            ]
          : []),
    ];

    return input.concat([...deferredBillInput, ...immediateBillInput] satisfies TaskBillModal_SaveBillInput[]);
  }, [] as TaskBillModal_SaveBillInput[]);

  return saveBillsInput;
}
