import { Global } from "@emotion/react";
import { Css } from "@homebound/beam";
import {
  FinishScheduleVisualModePdfQuery,
  FinishScheduleVisualMode_ReadyPlanOptionFragment,
  FinishScheduleVisualMode_TliFragment,
  NamedFragment,
  useFinishScheduleVisualModePdfQuery,
} from "src/generated/graphql-types";
import { PRODUCT_FALLBACK_IMG_URL } from "src/routes/libraries/product-catalog/components/product-images-viewer/ProductImageViewer";
import { fail, queryResult } from "src/utils";
import { ArrayParam, StringParam, useQueryParams } from "use-query-params";
import { generateCombinations, parseFilterArray } from "./FinishSchedulePdf";

export function FinishScheduleVisualModePdf() {
  const [{ designRpavId, planRpavIds, optionIds, costCodeIds, locationInPath }] = useQueryParams({
    // TODO: update backend url?
    designRpavId: StringParam,
    planRpavIds: ArrayParam,
    optionIds: ArrayParam,
    costCodeIds: ArrayParam,
    locationInPath: ArrayParam,
  });

  const parsedOptionIds = parseFilterArray(optionIds);

  const query = useFinishScheduleVisualModePdfQuery({
    variables: {
      versionId: designRpavId ?? fail("Design Package Rpav Id is required"),
      filter: {
        designRpavId: designRpavId ?? fail("Design Package Rpav Id is required"),
        optionIds: parsedOptionIds,
        planRpavIds: parseFilterArray(planRpavIds),
        costCodeIds: parseFilterArray(costCodeIds),
        locationInPath: parseFilterArray(locationInPath),
      },
    },
  });

  return queryResult(query, (data) => <VisualModePdfView data={data} optionIds={parsedOptionIds} />);
}

function VisualModePdfView(props: {
  data: FinishScheduleVisualModePdfQuery;
  optionIds: (string | null)[] | null | undefined;
}) {
  const { data, optionIds } = props;
  const tlis = data.designPackageFinishSchedule.entities;
  // Generate all possible combinations of forDesignPackage GOGs
  const idps = getIndividualDesignPackages(tlis, data.locations, optionIds);
  return (
    <>
      <Global styles={{ "@page": { size: "landscape" } }} />
      {idps.map(({ rpos, locations, order }, index) => (
        <IndividualDesignPackageView
          isFirst={index === 0}
          version={data.readyPlanAggregateVersion.version}
          key={order}
          rpos={rpos}
          locations={locations}
          order={order}
        />
      ))}
    </>
  );
}

function IndividualDesignPackageView(
  props: IndividualDesignPackage & { version: number | null | undefined; isFirst: boolean },
) {
  const { rpos, locations, order, version, isFirst } = props;
  // Spec Level group should always be ordered first ==> [Deluxe, Contemporary, Light]
  const [primaryRpoName, ...otherRpoNames] = rpos.map((rpo) => rpo.name);

  // After troubleshooting, we found that the background color wasn't filling the entire page.
  // The standard size of a PDF page is 8.5x11 inches, which translates to 816px x 1056px.
  // To ensure the background color covers the entire page, we set the margins to 0 and adjusted the height and width to 1056px and 816px, respectively.

  const pageHeight = 816;
  const pageWidth = 1056;

  return (
    <div css={Css.wPx(pageWidth).$} key={order} style={isFirst ? {} : { pageBreakBefore: "always" }}>
      <div /* Cover Page */ css={Css.hPx(pageHeight).df.fdc.p4.add("backgroundColor", "rgba(235, 234, 228, 1)").$}>
        <div css={Css.df.jcsb.$}>
          <img src="/wordmark.svg" alt="Homebound" css={Css.hPx(40).$} />
          {version && isFirst && <span>v{version}</span>}
        </div>
        <div css={Css.hPx(600).df.fdc.jcc.gap2.$}>
          <div css={Css.xl4Md.fontFamily("PT Serif").$}>{otherRpoNames.join(" ") ?? ""}</div>
          <div css={Css.df.gap1.$}>
            <img css={Css.hPx(40).$} src="/images/spec-level-pdf-icon.svg" alt="" />
            <div css={Css.p1.fw(400).color("rgba(198, 151, 117, 1)").fs("26px").$}>
              {/* Spec Level Name */}
              {primaryRpoName}
            </div>
          </div>
        </div>
      </div>
      {/* Loop over each location to create a page, i.e. Kitchen, Whole House, etc. */}
      {locations.map((locationGroup) => {
        return (
          <div key={locationGroup.location?.id}>
            {/* Loop through each group of TLIs within a location, setting groups of 8 to limit the number displayed per page. */}
            {locationGroup.locationTlis.batched(8).map((tlis, i) => (
              <div key={i} style={{ pageBreakBefore: "always" }} css={Css.hPx(pageHeight).df.fdc.p4.$}>
                <div /* Location Header */ css={Css.df.jcsb.$}>
                  <div css={Css.xl4Md.fontFamily("PT Serif").$}>{locationGroup.location?.name}</div>
                  <div css={Css.df.gap1.$}>
                    <img css={Css.hPx(26).$} src="/images/spec-level-pdf-icon.svg" alt="" />
                    <div css={Css.pPx(2).fw(400).color("rgba(198, 151, 117, 1)").fs("20px").$}>
                      {/* Spec Level Name */}
                      {primaryRpoName}
                    </div>
                  </div>
                </div>

                {/* Setting a height here will contain the product cards and prevent them from spilling onto the next page if a card's height exceeds a certain limit. */}
                <div /* List of Product (tli) Cards */ css={Css.df.fww.gap3.pt2.hPx(700).$}>
                  {tlis.map((tli) => (
                    <div key={tli.id} css={Css.wPx(175).$}>
                      <div /* Product Image */ css={Css.hPx(175).w100.ba.bcGray200.bw2.p1.df.aic.jcc.$}>
                        <img
                          src={tli.materialVariant?.featuredImage?.asset?.previewUrl ?? PRODUCT_FALLBACK_IMG_URL}
                          alt={tli.item.name}
                          css={Css.maxw100.maxh100.$}
                        />
                      </div>
                      <div /* Product Details */ css={Css.df.fdc.mt1.$}>
                        <div css={Css.smBd.fontFamily("PT Serif").$}>{tli.item.name}</div>
                        <div css={Css.smMd.fs("12px").$}>{tli.materialVariant?.listing.brand?.name}</div>
                        <div css={Css.fs("12px").$}>{tli.materialVariant?.listing.name}</div>
                        {tli.materialVariant?.materialAttributeValues
                          .filter((value) => value.dimension.useInVisualModePdf)
                          .map((value) => (
                            <div key={value.id} css={Css.fs("12px").$}>
                              {value.textValue}
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
                </div>

                <div /* Footer */ css={Css.df.jcfe.$}>
                  <div>
                    <img src="/wordmark.svg" alt="Homebound" css={Css.hPx(30).$} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
}

type IndividualDesignPackage = {
  rpos: FinishScheduleVisualMode_ReadyPlanOptionFragment[];
  order: number;
  locations: {
    location: NamedFragment | undefined;
    locationTlis: FinishScheduleVisualMode_TliFragment[];
  }[];
};

function getIndividualDesignPackages(
  tlis: FinishScheduleVisualMode_TliFragment[],
  locations: NamedFragment[],
  optionIds: (string | null)[] | null | undefined,
): IndividualDesignPackage[] {
  // Generate all possible combinations of forDesignPackage GOGs
  const rposByGOG = tlis
    .flatMap((tli) =>
      tli.options.filter((rpo) =>
        // only include RPOs that are in the filter optionIds || are forDesignPackages
        optionIds?.nonEmpty ? optionIds.includes(rpo.id) : rpo.optionGroup.forDesignPackages,
      ),
    )
    .uniqueByKey("id")
    .groupByObject((rpo) => rpo.optionGroup)
    .map(([_, rpos]) => rpos);

  const combinations = generateCombinations(rposByGOG);

  // Each combination represents an IDP
  return combinations
    .map((rpos) => ({
      rpos: rpos.sortBy((rpo) => rpo.optionGroup.order), // The combination of RPO that defines this IDP name
      order: rpos.sum((rpo) => rpo.order),
      locations: tlis
        // Filter TLIs looking for options that matches this IDP/RPO combination
        .filter((tli) => rpos.every((rpo) => tli.options.includes(rpo)))
        .groupByObject((tli) => tli.location?.id ?? "none")
        .map(([locationId, locationTlis]) => ({
          location: locations.find((l) => l.id === locationId),
          locationTlis,
        })),
    }))
    .sortByKey("order");
}
