import {
  BoundNumberField,
  BoundSelectField,
  Button,
  collapseColumn,
  column,
  Css,
  emptyCell,
  GridColumn,
  GridDataRow,
  GridTable,
  LoadingSkeleton,
  numericColumn,
  OnRowSelect,
  Palette,
  ScrollableContent,
  selectColumn,
  simpleHeader,
  Switch,
  Tag,
  Tooltip,
  useComputed,
  useGridTableApi,
  useModal,
  useSnackbar,
  useSuperDrawer,
} from "@homebound/beam";
import { format, isBefore, startOfToday } from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { chipCell, emptyCellDash, Icon, linkHeader, priceCell, SearchBox, useNavigationCheck } from "src/components";
import {
  AssignWorkToBidContractInput,
  BuyoutStatus,
  ConfirmTradesStep_BaseItemLikeFragment,
  ConfirmTradesStep_ItemFragment,
  ConfirmTradesStep_ProjectItemFragment,
  ConfirmTradesStep_ProrationFragment,
  ConfirmTradesStepBidItemFragment,
  ConfirmTradesStepLineItemsFragment,
  ConfirmTradesStepProjectFragment,
  HasDisplayName,
  Maybe,
  PotentialOperation2,
  ReadyPlanOption,
  TradePartnerOnboardingStatus,
  useAssignWorkToBidContractMutation,
  useConfirmTrades_ProjectItemsQuery,
  useConfirmTradesStepQuery,
  useUnassignWorkFromBidContractMutation,
} from "src/generated/graphql-types";
import { useQueryStringPersistedToggle } from "src/hooks";
import { ApprovalSuperDrawer } from "src/routes/components/Approval/ApprovalSuperDrawer";
import { CreateChangeEventApproval } from "src/routes/components/Approval/changeEvents/CreateChangeEventApproval";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { disableBasedOnPotentialOperation } from "src/routes/components/PotentialOperationsUtils";
import { TableActions } from "src/routes/layout/TableActions";
import { itiOptionsDisplayValue } from "src/routes/projects/selections/sharedColumns";
import { LotConfigParams, ProjectParams } from "src/routes/routesDef";
import {
  createBidItemDetailsUrl,
  createDevelopmentLotConfigReviewUrl,
  createDevelopmentLotSummaryReleaseUrl,
} from "src/RouteUrls";
import { groupBy, isDefined, isEmpty, pluralize, queryResult } from "src/utils";
import { DateOnly } from "src/utils/dates";
import { ObjectConfig, ObjectState, useFormState } from "src/utils/formState";

export function ConfirmTradesStep() {
  const { projectId } = useParams<ProjectParams>();
  const query = useConfirmTradesStepQuery({ variables: { id: projectId } });
  return queryResult(query, ({ project }) => <ReviewLineItemsTable project={project} />);
}

type ReviewLineItemsTableProps = {
  project: ConfirmTradesStepProjectFragment;
};

function ReviewLineItemsTable(props: ReviewLineItemsTableProps) {
  const { project } = props;
  const tableApi = useGridTableApi<Row>();
  const { developmentId } = useParams<LotConfigParams>();
  const { useRegisterNavigationCheck } = useNavigationCheck();
  const { openInDrawer } = useSuperDrawer();
  const { triggerNotice } = useSnackbar();
  const [assignWorkToBidContract, { loading }] = useAssignWorkToBidContractMutation();
  const [unassignWorkFromBidContract] = useUnassignWorkFromBidContractMutation();
  const { openModal, closeModal } = useModal();
  const history = useHistory();
  const [search, setSearchFilter] = useState<string | undefined>("");
  const { readyPlanConfig, enableProductConfigPlan } = project;
  const [showPlanSource, togglePlanSource] = useQueryStringPersistedToggle("showPlanSource");
  const query = useConfirmTrades_ProjectItemsQuery({
    variables: {
      filter: { projectStage: [project.stage.id], isRemovedScope: false, search },
      page: { offset: 0, limit: 100 },
    },
    fetchPolicy: "cache-and-network",
  });
  const maybeFetchMore = useCallback(async () => {
    if (query.data?.projectItemsPage.pageInfo.hasNextPage) {
      const offset = query.data.projectItemsPage.projectItems.length;
      await query.fetchMore({ variables: { page: { offset, limit: 200 } } });
    }
  }, [query]);
  // Set formState project items from query results
  const pis = useMemo(() => query.data?.projectItemsPage.projectItems ?? [], [query]);
  const formState = useFormState({
    config: formConfig,
    init: {
      input: [pis, readyPlanConfig, enableProductConfigPlan] satisfies MapToFormInput,
      map: (data) => mapToForm(data),
    },
  });
  // Load more rows on the background by default
  useEffect(() => void maybeFetchMore(), [maybeFetchMore, pis]);

  const maybeAssignProratedBids = useCallback(
    (pi: ObjectState<ProjectItems>, bcliId?: string, selectedBcli?: PotentialBclis) => {
      // Get all prorated lines for the selected trade
      // If the trade is being unassigned, get all prorated lines from the current trade
      const proratedBclis = selectedBcli
        ? selectedBcli.prorations.flatMap((p) => p.tradeLineItem.prorations).map((li) => li.lineItem)
        : (pi.value.prorations?.flatMap((p) => p.tradeLineItem.prorations).map((li) => li.lineItem) ?? []);

      if (proratedBclis.isEmpty) {
        pi.set({
          bcliId: bcliId,
          bcliIds: selectedBcli?.bcliIds,
          bidContractRevisionId: selectedBcli?.bidContractRevisionId,
          tradeCost: selectedBcli?.totalCostInCents || 0,
          unitCostInCents:
            isDefined(pi.quantity.value) && pi.quantity.value !== 0 && selectedBcli
              ? selectedBcli.totalCostInCents || 0 / pi.quantity.value
              : undefined,
          prorations: selectedBcli?.prorations ?? [],
        });
      }

      // Find corresponding PIs for each prorated BCLI
      proratedBclis.forEach((bcli) => {
        const proratedLine = formState.pis.rows.find((pi) =>
          pi.value.potentialBclis.some((pBcli) => pBcli.bcliId === bcli.id),
        );
        if (!proratedLine) return;

        const matchingBcli = selectedBcli
          ? proratedLine.value.potentialBclis.find((pBcli) => pBcli.bcliId === bcli.id)
          : undefined;

        // Assign/unassign the bid for each PI
        proratedLine.set({
          bcliId: matchingBcli?.bcliId,
          bcliIds: matchingBcli?.bcliIds,
          bidContractRevisionId: matchingBcli?.bidContractRevisionId,
          tradeCost: matchingBcli?.totalCostInCents || 0,
          unitCostInCents:
            isDefined(pi.quantity.value) && pi.quantity.value !== 0 && matchingBcli
              ? matchingBcli.totalCostInCents || 0 / pi.quantity.value
              : undefined,
          prorations: matchingBcli?.prorations ?? [],
        });
      });
    },
    [formState.pis.rows],
  );
  const columns = useMemo(
    () => createColumns(enableProductConfigPlan, maybeAssignProratedBids),
    [enableProductConfigPlan, maybeAssignProratedBids],
  );
  const rows = useMemo(
    () => createRows(formState.pis.rows, enableProductConfigPlan, showPlanSource),
    [formState.pis.rows, enableProductConfigPlan, showPlanSource],
  );
  const selectedRows = useComputed(() => tableApi.getSelectedRows("lineItem"), [tableApi]);
  const isFormDirty = useComputed(() => formState.dirty, [formState]);
  const disabled = useComputed(
    () => formState.pis.rows.every((os) => !os.bcliId.dirty) && selectedRows.isEmpty,
    [formState, selectedRows],
  );
  useRegisterNavigationCheck(() => !isFormDirty, [isFormDirty]);
  useEffect(() => closeModal(), [closeModal, pis]);

  const unassignWork = async () => {
    const unassignedPis = formState.pis.rows.filter((os) => os.bcliId.dirty && !os.bcliId.value);
    if (unassignedPis.nonEmpty) {
      await unassignWorkFromBidContract({
        variables: { input: { projectItemIds: unassignedPis.map((pi) => pi.id.value) } },
      });
      formState.commitChanges();
    }
  };

  const submitForApproval = async () => {
    // Unassign trades if necesssary
    await unassignWork();

    // Assign trades
    const mappings = formatMappings(formState, selectedRows);
    if (!mappings.nonEmpty) return;
    const { data, errors } = await assignWorkToBidContract({ variables: { input: mappings } });
    if (errors?.nonEmpty) {
      triggerNotice({ icon: "error", message: "Error assigning trades." });
      return;
    }
    const [changeEvent] = data?.assignWorkToBidContract ?? [];
    formState.commitChanges();

    if (changeEvent) {
      openInDrawer({
        content: (
          <ApprovalSuperDrawer approvalIds={[]}>
            <CreateChangeEventApproval subjectId={changeEvent.id} />
          </ApprovalSuperDrawer>
        ),
        onClose: () => {
          history.push(createDevelopmentLotConfigReviewUrl(developmentId, project.id));
        },
      });
    } else {
      triggerNotice({
        icon: "success",
        message: "Skipping approvals because there are no budget changes to review.",
      });
      // if change event was not created, no need to create an approval request
      // just navigate to the review order page after assigning trades
      history.push(createDevelopmentLotConfigReviewUrl(developmentId, project.id));
    }
  };

  // Select/deselect all related rows from a prorated bid
  const handleRowSelect: OnRowSelect<Row> = {
    lineItem: (data, isSelected) => {
      if (data.value.prorations?.nonEmpty) {
        // Go to each trade line item and get all prorated BCLIs
        const proratedBclis = data.value.prorations.flatMap((p) => p.tradeLineItem.prorations).map((li) => li.lineItem);
        // Find each PI that corresponds to the BCLI and select/deselect their correspondent row
        const selectedPis = formState.pis.rows.filter((pi) => proratedBclis.some((b) => b.id === pi.bcliId.value));
        selectedPis.forEach((pi) => tableApi.selectRow(pi.id.value, isSelected));
      }
    },
  };

  return (
    <div>
      <div css={Css.bgGray100.h100.w100.px8.py3.$}>
        <TableActions>
          {enableProductConfigPlan ? (
            <div css={Css.df.fdr.cgPx(4).$}>
              <strong>{pis.first?.itemTemplateItem?.readyPlan?.displayName ?? ""}</strong>
              {query.data?.projectItemsPage.pageInfo.hasNextPage && "Loading..."}
            </div>
          ) : (
            <Switch label="Show Plan Source" selected={showPlanSource} onChange={togglePlanSource} />
          )}
          <div css={Css.df.aic.gap1.$}>
            <SearchBox onSearch={setSearchFilter} updateQueryString={false} />
            <Button
              variant="primary"
              label="Assign Trades"
              disabled={query.loading || query.data?.projectItemsPage.pageInfo.hasNextPage}
              onClick={() => {
                openModal({
                  content: (
                    <AssignDefaultTradesConfirmationModal
                      formState={formState}
                      selectedRows={selectedRows}
                      pisFromQuery={pis}
                    />
                  ),
                });
              }}
            />
          </div>
        </TableActions>
        <div css={Css.bgGray100.h100.w100.$}>
          {query.loading ? (
            <LoadingSkeleton rows={5} columns={6} />
          ) : (
            <ScrollableContent virtualized>
              <div css={Css.bgGray100.hPx(550).$}>
                <GridTable
                  api={tableApi}
                  columns={columns}
                  rows={rows}
                  as="virtual"
                  onRowSelect={handleRowSelect}
                  style={{ allWhite: true, bordered: true, grouped: true, rowHeight: "fixed" }}
                  stickyHeader
                  fallbackMessage="No Line Items found for the Lot Configuration"
                  infiniteScroll={{ onEndReached: maybeFetchMore }}
                />
              </div>
            </ScrollableContent>
          )}
        </div>
        <div
          css={
            Css.df.jcfe.gap1.aic.fixed.z999.bottom0.left0.right0.bgGray100.px4
              .hPx(100)
              .oxa.boxShadow("0px 0px 32px rgba(201, 201, 201, 0.75)").$
          }
        >
          <Button
            size="lg"
            variant="secondary"
            disabled={false}
            label="Exit"
            onClick={() => history.push(createDevelopmentLotSummaryReleaseUrl(developmentId))}
          />

          <Button
            size="lg"
            disabled={loading || query.loading || disabled}
            label={"Submit for approval"}
            onClick={() =>
              openModal({
                content: (
                  <SubmitForApprovalConfirmationModal
                    formState={formState}
                    selectedRows={selectedRows}
                    submitForApproval={submitForApproval}
                  />
                ),
              })
            }
          />
        </div>
      </div>
    </div>
  );
}

function formatMappings(
  formState: ObjectState<FormValue>,
  selectedRows: GridDataRow<LineItemRow>[],
): AssignWorkToBidContractInput[] {
  const selectedProjectItems = new Set(selectedRows.map((row) => row.id));
  const groupedByBCR = formState.pis.rows
    .filter(
      (os) =>
        (os.bcliId.dirty || selectedProjectItems.has(os.id.value)) && os.bcliId.value && os.bidContractRevisionId.value,
    )
    .groupBy((os) => os.bidContractRevisionId.value!);

  return Object.entries(groupedByBCR).map(([bcrId, pis]) => ({
    projectItemIds: pis.map((pi) => pi.id.value),
    bidContractLineItemIds: pis.map((pi) => pi.bcliId.value).compact(),
    // If the PI is not selected in the table, send autoRelease as `undefined` so we don't create draft commitments.
    // This allows the user to use the page to "assign trades" without creating draft commitments.
    autoRelease: pis.every((pi) => selectedProjectItems.has(pi.id.value)) ? false : undefined,
  }));
}

type AssignDefaultTradesConfirmationModalProps = {
  formState: ObjectState<FormValue>;
  selectedRows: GridDataRow<LineItemRow>[];
  pisFromQuery: ConfirmTradesStep_ProjectItemFragment[];
};

function AssignDefaultTradesConfirmationModal({
  formState,
  selectedRows,
  pisFromQuery: pis,
}: AssignDefaultTradesConfirmationModalProps) {
  const { triggerNotice } = useSnackbar();
  return (
    <ConfirmationModal
      title={isEmpty(selectedRows) ? "Assign Default Trades" : `Assign ${selectedRows.length} Default Trades`}
      label="Assign"
      confirmationMessage="Assigns a default Trade Partner to unassigned line-items. Will not update rows that already have a selected contract."
      onConfirmAction={() => {
        const rows = isEmpty(selectedRows)
          ? formState.pis.rows
          : formState.pis.rows.filter((pi) => selectedRows.some((sr) => sr.id === pi.id.value));

        const keyedPis = pis.keyBy((pi) => pi.id);

        const rowsToUpdate = rows.filter(
          (pi) =>
            // Filter OUT rows that already have a bcli set,
            !pi.bcliId.value &&
            // and those that are NOT Bought Out
            pi.buyoutStatus.value === BuyoutStatus.NotBoughtOut &&
            // Also filter out rows that don't have a preferred bcli to assign to
            keyedPis[pi.id.value]?.preferredPotentialBidContractLineItem?.bidContractLineItem,
        );

        rowsToUpdate.forEach((pi) => {
          const potentialBcli = keyedPis[pi.id.value]!.preferredPotentialBidContractLineItem!;
          pi.set({
            bcliId: potentialBcli.bidContractLineItem.id,
            bcliIds: potentialBcli.bidContractLineItems.map((bcli) => bcli.id),
            bidContractRevisionId: potentialBcli.bidContractLineItem.revision.id,
            tradeCost: potentialBcli.totalCostInCents,
            unitCostInCents:
              isDefined(pi.quantity.value) && pi.quantity.value !== 0
                ? potentialBcli.totalCostInCents / pi.quantity.value
                : undefined,
          });
        });

        triggerNotice({ message: `${rowsToUpdate.length} ${pluralize(rowsToUpdate, "item")} updated` });
      }}
    />
  );
}

type SubmitForApprovalConfirmationModalProps = {
  formState: ObjectState<FormValue>;
  selectedRows: GridDataRow<LineItemRow>[];
  submitForApproval: VoidFunction;
};

function SubmitForApprovalConfirmationModal(props: SubmitForApprovalConfirmationModalProps) {
  const { formState, selectedRows, submitForApproval } = props;
  const selectedProjectItems = selectedRows.map((row) => row.id);
  const assignToTrades = formState.pis.rows.filter(
    (os) => os.bcliId.dirty && os.bcliId.value && os.bidContractRevisionId.value,
  );
  const unassignTrades = formState.pis.rows.filter((os) => os.bcliId.dirty && !os.bcliId.value);
  const createDraftCommitments = assignToTrades.filter((pi) => selectedProjectItems.includes(pi.id.value));

  function formatRows(projectItems: ObjectState<ProjectItems>[]) {
    return (
      <div css={Css.pl2.pt1.$}>
        {projectItems.map((pi) => (
          <div key={pi.id.value}>
            - <strong>{pi.displayName.value}</strong>
          </div>
        ))}
      </div>
    );
  }

  return (
    <ConfirmationModal
      confirmationMessage={
        <div>
          <div css={Css.pb1.$}>Do you want to make these changes?</div>
          {unassignTrades.nonEmpty && (
            <div css={Css.pb2.$}>
              <div>Trades will be unassigned for the following Project Items:</div>
              {formatRows(unassignTrades)}
            </div>
          )}
          {assignToTrades.nonEmpty && (
            <div css={Css.pb2.$}>
              <div>New trades will be assigned (pending approval) to the following Project Items:</div>
              {formatRows(assignToTrades)}
            </div>
          )}
          {createDraftCommitments.nonEmpty && (
            <>
              <div>Draft commitments will be generated for the selected Project Items:</div>
              {formatRows(createDraftCommitments)}
            </>
          )}
        </div>
      }
      onConfirmAction={submitForApproval}
      title="Confirm changes"
      label="Submit"
    />
  );
}

function createColumns(
  enableProductConfigPlan: boolean,
  maybeAssignProratedBids: (pi: ObjectState<ProjectItems>, bcliId?: string, selectedBcli?: PotentialBclis) => void,
): GridColumn<Row>[] {
  if (enableProductConfigPlan) {
    return [
      collapseColumn<Row>({ lineItem: emptyCell }),
      selectColumn<Row>({ w: "32px" }),
      column<Row>(
        withEmptyCells({
          id: "itemCode",
          header: "Item Code",
          assigned: (data) => ({ content: <div css={Css.lgBd.$}>{data}</div>, colspan: 2 }),
          division: (data) => ({ content: <div css={Css.smMd.$}>{data}</div>, colspan: 2 }),
          costCode: (data) => ({ content: <div css={Css.xsMd.$}>{data}</div>, colspan: 2 }),
          lineItem: (data) => data.item.value?.fullCode,
          w: 0.7,
        }),
      ),
      column<Row>(
        withEmptyCells({ id: "name", header: "Name", lineItem: (data) => data?.itemTemplateItemName.value, w: 1.3 }),
      ),
      column<Row>(withEmptyCells({ header: "Cost Type", lineItem: (data) => data.costType.value, w: 0.9 })),
      column<Row>(withEmptyCells({ header: "Location", lineItem: (data) => data.location.value, w: 0.9 })),
      column<Row>(
        withEmptyCells({
          header: "Option",
          lineItem: (data) => data.itemTemplateItemOptions.value?.map((rpo) => rpo.displayName).join(", "),
          w: 0.9,
        }),
      ),
      column<Row>(
        withEmptyCells({
          header: "Existing POs",
          lineItem: (data) => chipCell(data.existingCommitments.value.map((po) => ({ text: `${po}`, title: `${po}` }))),
          w: 1,
        }),
      ),
      column<Row>(
        withEmptyCells({
          header: "Proposed Trade",
          lineItem: (data) => {
            const bcliOptions = data.potentialBclis.value;
            const oneBcliPerTp = Object.values(groupBy(bcliOptions, (bcli) => bcli.tradePartner?.id || "")).map(
              (bclis) => {
                const alreadySelected = bclis.find((bcli) => bcli.bcliId === data.bcliId.value);
                const effectiveToday = bclis.find((bcli) => bcli.isEffective);
                return alreadySelected || effectiveToday || bclis[0];
              },
            );

            const disabledBclis = oneBcliPerTp
              .filter(
                (bcli) =>
                  bcli.tradePartner &&
                  bcli.tradePartner.onboardingStatus.code !== TradePartnerOnboardingStatus.Complete,
              )
              .map((bcli) => bcli.bcliId);

            // "ABC Builder:1" and "ABC Builder:2" are distinct bcliOptions, but for UX we want to detect if only Version has actually changed
            const isCurrentTpSameAsPriorTp =
              !data.bcliId.dirty ||
              bcliOptions.find((bcli) => bcli.bcliId === data.bcliId.originalValue)?.tradePartner?.id ===
                bcliOptions.find((bcli) => bcli.bcliId === data.bcliId.value)?.tradePartner?.id;

            const readOnly =
              disableBasedOnPotentialOperation(data.canAssignBidContractLineItem.value) ||
              data?.buyoutStatus?.value !== BuyoutStatus.NotBoughtOut;

            if (isEmpty(oneBcliPerTp))
              return (
                <Tooltip
                  title={`No Trade Partners have a Signed Bid Contract for ${data.displayName.value}.`}
                  placement="top"
                >
                  <div css={Css.tiny.gray600.$}>No Associated Contract</div>
                </Tooltip>
              );
            return (
              <BoundSelectField
                label="Proposed Trade"
                onSelect={(bcliId, selectedBcli) => maybeAssignProratedBids(data, bcliId, selectedBcli)}
                fieldDecoration={() => !isCurrentTpSameAsPriorTp && <div css={Css.wPx(8).hPx(8).br100.bgYellow600.$} />}
                placeholder="Select a Trade Partner"
                options={oneBcliPerTp}
                getOptionLabel={(bcli): any => {
                  const name = bcli.tradePartner
                    ? bcli.tradePartner.name || bcli.tradePartner.id
                    : "Homebound Internal Budget Estimate";
                  return bcli.isPrimary || !bcli.tradePartner ? name : `${name} (Secondary)`;
                }}
                getOptionValue={(bcli) => bcli.bcliId}
                readOnly={readOnly}
                field={data.bcliId}
                data-testid="proposedTradePartner"
                disabledOptions={disabledBclis}
                unsetLabel={emptyCellDash}
              />
            );
          },
          clientSideSort: false,
        }),
      ),
      numericColumn<Row>(
        withEmptyCells({
          header: () => "Total Cost",
          lineItem: (data) =>
            isDefined(data.tradeCost.value) ? (
              <div css={Css.df.cgPx(4).$}>
                <BoundNumberField field={data.tradeCost} type="cents" readOnly />
                {data.prorations.value?.nonEmpty && (
                  <div css={Css.mya.$}>
                    <Tooltip
                      title="This line item is part of a prorated bid and must be released with the rest of the scope."
                      placement="top"
                    >
                      <Icon icon="alertInfo" color={Palette.Yellow600} inc={2} />
                    </Tooltip>
                  </div>
                )}
              </div>
            ) : (
              emptyCellDash
            ),
          clientSideSort: false,
        }),
      ),
      column<Row>(
        withEmptyCells({
          header: "Version",
          lineItem: (data) => {
            const bcliOptions = data.potentialBclis.value;
            const bcli = bcliOptions.find((o) => o.bcliId! === data.bcliId.value);
            if (!bcli) return;
            const expiredLabel = bcli.endDate
              ? ` (${isBefore(bcli.endDate, startOfToday()) ? "Expired" : "Expires"} ${format(bcli.endDate, "M/dd/yyyy")})`
              : "";
            // "002 (Expired 7/13/2022)";
            return <Tag type="info" text={`${bcli.version}${expiredLabel}`} />;
          },
          w: 1.1,
          clientSideSort: false,
        }),
      ),
    ];
  }
  return [
    collapseColumn<Row>({ lineItem: emptyCell }),
    selectColumn<Row>({ w: "32px", planSource: emptyCell }),
    column<Row>(
      withEmptyCells({
        id: "itemCode",
        header: "Item Code",
        plan: (name) => ({ content: name, colspan: 8 }),
        costCode: (name) => ({ content: name, colspan: 8 }),
        option: (data) => ({
          content: data[0].itemTemplateItemOptions.value?.map((rpo) => rpo.displayName).join(", ") || "Base House",
          colspan: 8,
        }),
        lineItem: (data) => data.item.value?.fullCode,
        w: 0.7,
      }),
    ),
    column<Row>(
      withEmptyCells({
        id: "name",
        header: "Item Description",
        lineItem: (data) => data?.itemTemplateItemName.value,
        planSource: (row) => {
          const rpos = [row.elevationIds, row.specOptionIds, row.otherOptionIds]
            .flatMap((ids) => ids.map((id) => row.options.find((rpo) => rpo.id === id)))
            .compact();
          return { content: `${itiOptionsDisplayValue(rpos)} @ ${row.first.displayVersion}`, colspan: 5 };
        },
        w: 1.3,
      }),
    ),
    column<Row>(
      withEmptyCells({
        header: "Bid Item",
        lineItem: (data) =>
          data.bidItem.value
            ? linkHeader(data.bidItem.value?.code ?? "", createBidItemDetailsUrl(data.bidItem.value?.id ?? ""))
            : emptyCellDash,
        w: 0.8,
      }),
    ),
    column<Row>(
      withEmptyCells({
        header: "Bid Item Description",
        lineItem: (data) => data.bidItem.value?.name ?? emptyCellDash,
        w: 1.0,
      }),
    ),
    column<Row>(
      withEmptyCells({
        header: "Product Name",
        lineItem: (data) => data.bidItem.value?.products.first?.name ?? data.bidItem.value?.products.first?.sku ?? "",
        w: 1.0,
      }),
    ),
    column<Row>(
      withEmptyCells({
        header: "Cost Type",
        lineItem: (data) => data.costType.value,
        w: 0.9,
      }),
    ),
    column<Row>(
      withEmptyCells({
        header: "Location",
        lineItem: (data) => data.location.value,
        w: 0.9,
      }),
    ),
    column<Row>(
      withEmptyCells({
        header: "QTY",
        lineItem: (data) => data.quantity.value ?? emptyCellDash,
        planSource: (row) => row.quantity,
        w: 0.3,
      }),
    ),
    column<Row>(
      withEmptyCells({
        header: "Unit",
        lineItem: (data) => data.unitOfMeasure.value ?? emptyCellDash,
        w: 0.6,
      }),
    ),
    column<Row>(
      withEmptyCells({
        header: "Existing POs",
        lineItem: (data) => chipCell(data.existingCommitments.value.map((po) => ({ text: `${po}`, title: `${po}` }))),
        w: 1,
      }),
    ),
    column<Row>(
      withEmptyCells({
        header: "Proposed Trade",
        lineItem: (data) => {
          const bcliOptions = data.potentialBclis.value;
          const oneBcliPerTp = Object.values(groupBy(bcliOptions, (bcli) => bcli.tradePartner?.id || "")).map(
            (bclis) => {
              const alreadySelected = bclis.find((bcli) => bcli.bcliId === data.bcliId.value);
              const effectiveToday = bclis.find((bcli) => bcli.isEffective);
              return alreadySelected || effectiveToday || bclis[0];
            },
          );

          const disabledBclis = oneBcliPerTp
            .filter(
              (bcli) =>
                bcli.tradePartner && bcli.tradePartner.onboardingStatus.code !== TradePartnerOnboardingStatus.Complete,
            )
            .map((bcli) => bcli.bcliId);

          // "ABC Builder:1" and "ABC Builder:2" are distinct bcliOptions, but for UX we want to detect if only Version has actually changed
          const isCurrentTpSameAsPriorTp =
            !data.bcliId.dirty ||
            bcliOptions.find((bcli) => bcli.bcliId === data.bcliId.originalValue)?.tradePartner?.id ===
              bcliOptions.find((bcli) => bcli.bcliId === data.bcliId.value)?.tradePartner?.id;

          const readOnly =
            disableBasedOnPotentialOperation(data.canAssignBidContractLineItem.value) ||
            data?.buyoutStatus?.value !== BuyoutStatus.NotBoughtOut;

          if (isEmpty(oneBcliPerTp))
            return (
              <Tooltip
                title={`No Trade Partners have a Signed Bid Contract for ${data.displayName.value}.`}
                placement="top"
              >
                <div css={Css.tiny.gray600.$}>No Associated Contract</div>
              </Tooltip>
            );
          return (
            <BoundSelectField
              label="Proposed Trade"
              onSelect={(bcliId, selectedBcli) => {
                data.set({
                  bcliId,
                  bcliIds: selectedBcli?.bcliIds,
                  bidContractRevisionId: selectedBcli?.bidContractRevisionId,
                  tradeCost: selectedBcli?.totalCostInCents || 0,
                  unitCostInCents:
                    isDefined(data.quantity.value) && data.quantity.value !== 0 && selectedBcli
                      ? selectedBcli.totalCostInCents || 0 / data.quantity.value
                      : undefined,
                });
              }}
              fieldDecoration={() => !isCurrentTpSameAsPriorTp && <div css={Css.wPx(8).hPx(8).br100.bgYellow600.$} />}
              placeholder="Select a Trade Partner"
              options={oneBcliPerTp}
              getOptionLabel={(bcli): any => {
                const name = bcli.tradePartner
                  ? bcli.tradePartner.name || bcli.tradePartner.id
                  : "Homebound Internal Budget Estimate";
                return bcli.isPrimary || !bcli.tradePartner ? name : `${name} (Secondary)`;
              }}
              getOptionValue={(bcli) => bcli.bcliId}
              readOnly={readOnly}
              field={data.bcliId}
              data-testid="proposedTradePartner"
              disabledOptions={disabledBclis}
              unsetLabel={emptyCellDash}
            />
          );
        },
        clientSideSort: false,
      }),
    ),
    column<Row>(
      withEmptyCells({
        header: "Contract",
        lineItem: (data) => {
          const bcliOptions = data.potentialBclis.value;
          const selectedTradePartnerId = bcliOptions.find((o) => o.bcliId! === data.bcliId.value)?.tradePartner?.id;
          const options = bcliOptions.filter((o) => o.tradePartner?.id === selectedTradePartnerId);

          const readOnly =
            data?.buyoutStatus?.value !== BuyoutStatus.NotBoughtOut ||
            options.length <= 1 ||
            disableBasedOnPotentialOperation(data.canAssignBidContractLineItem.value);

          return (
            <BoundSelectField
              label="Contract Version"
              onSelect={(bcliId, selectedBcli) => {
                data.set({
                  bcliId,
                  bcliIds: selectedBcli?.bcliIds,
                  bidContractRevisionId: selectedBcli?.bidContractRevisionId,
                  tradeCost: selectedBcli?.totalCostInCents || 0,
                  unitCostInCents: selectedBcli?.unitCostInCents || 0,
                });
              }}
              options={options}
              getOptionLabel={(bcli) => {
                const label = bcli.ofIti ? "Plan Based" : "Unit Based";
                if (!bcli.endDate) return `${bcli.version} - ${label}`;
                const expiredBeforeToday = isBefore(bcli.endDate, startOfToday());
                // "002 (Expired 7/13/2022)"
                return `${bcli.version} - ${label} (${expiredBeforeToday ? "Expired" : "Expires"} ${format(
                  bcli.endDate,
                  "M/dd/yyyy",
                )})`;
              }}
              getOptionValue={(bcli) => bcli.bcliId}
              fieldDecoration={() =>
                !readOnly && data.bcliId.dirty && <div css={Css.wPx(8).hPx(8).br100.bgYellow600.$} />
              }
              readOnly={readOnly}
              field={data.bcliId}
              data-testid="contractVersion"
              unsetLabel={emptyCellDash}
            />
          );
        },
        w: 0.6,
        clientSideSort: false,
      }),
    ),
    numericColumn<Row>(
      withEmptyCells({
        header: "Unit Cost",
        lineItem: (data) =>
          isDefined(data.unitCostInCents.value) ? (
            <BoundNumberField field={data.unitCostInCents} type="cents" readOnly />
          ) : (
            emptyCellDash
          ),
        clientSideSort: false,
        w: 0.6,
      }),
    ),
    numericColumn<Row>(
      withEmptyCells({
        header: "Total Cost",
        lineItem: (data) =>
          isDefined(data.tradeCost.value) ? (
            <BoundNumberField field={data.tradeCost} type="cents" readOnly />
          ) : (
            emptyCellDash
          ),
        planSource: (row) => priceCell({ valueInCents: row.totalCostInCents, displayDirection: true }),
        clientSideSort: false,
      }),
    ),
  ];
}

function createRows(
  projectItems: readonly ObjectState<ProjectItems>[],
  enableProductConfigPlan: boolean,
  showPlanSource: boolean,
): GridDataRow<Row>[] {
  // Filter out items that are excluded from purchase orders
  const filteredProjectItems = projectItems.filter(
    (pi) => !(pi.item?.value?.costCode?.excludeFromPurchaseOrders || pi.item.value?.isContingency),
  );
  const groupedProjects = enableProductConfigPlan
    ? groupByCostDivision(filteredProjectItems)
    : groupByCostCode(filteredProjectItems, showPlanSource);

  return [simpleHeader, ...groupedProjects];
}

type HeaderRow = { kind: "header"; data: undefined; id: string };
type PlanRow = { kind: "plan"; data: string; id: string };
type OptionRow = { kind: "option"; data: ObjectState<FormValue>["pis"]["rows"]; id: string };
type AssignedRow = { kind: "assigned"; data: string; id: string };
type DivisionRow = { kind: "division"; data: string; id: string };
type CostCodeRow = { kind: "costCode"; data: string; id: string };
type LineItemRow = { kind: "lineItem"; data: ObjectState<FormValue>["pis"]["rows"][0]; id: string };
type ItiRow = { kind: "planSource"; data: ConfirmTradesStep_BaseItemLikeFragment; id: string };
type Row = HeaderRow | PlanRow | OptionRow | AssignedRow | DivisionRow | CostCodeRow | LineItemRow | ItiRow;

function groupByCostCode(lineItems: readonly ObjectState<ProjectItems>[], showPlanSource: boolean): GridDataRow<Row>[] {
  const groups = lineItems.groupBy((li) => `${li.item.value?.costCode.id}`);

  return groups.toEntries().map(([costCodeId, pis]) => ({
    kind: "costCode" as const,
    id: costCodeId,
    data: pis.first?.item.value?.costCode.displayName ?? "-",
    children: pis.map((pi) => ({
      kind: "lineItem" as const,
      id: pi.value.id,
      data: pi,
      potentialBclis: pi.potentialBclis,
      children: showPlanSource
        ? [pi.itemTemplateItem.value, ...pi.modifyingTemplateItems.value].compact().map((iti, i) => ({
            kind: "planSource" as const,
            id: `${pi.id.value}-${iti.id}-${i}`,
            data: iti,
          }))
        : [],
    })),
  }));
}

function groupByCostDivision(lineItems: readonly ObjectState<ProjectItems>[]): GridDataRow<Row>[] {
  // Group by Assigned/Unassigned -> Cost Division -> Cost Code
  return lineItems
    .partition((li) => !!li.bcliId.value)
    .filter((pis) => pis.nonEmpty)
    .map((pis) => {
      const type = pis.first?.value.bcliId ? "Assigned" : "Unassigned";
      const pisByCostDivision = pis.groupBy((pi) => `${type}${pi.item.value?.costCode.division.id}`);
      return {
        kind: "assigned" as const,
        id: type,
        data: type,
        children: Object.entries(pisByCostDivision).map(createCostDivisionGroupRow),
      };
    });
}

function createCostDivisionGroupRow([divisionGroupId, pis]: [string, ObjectState<ProjectItems>[]]): GridDataRow<Row> {
  const pisByCostCode = pis.groupBy((pi) => `${divisionGroupId}${pi.item.value?.costCode.id}`);
  return {
    kind: "division" as const,
    id: divisionGroupId,
    data: pis.first?.item.value?.costCode.division.name ?? "-",
    children: Object.entries(pisByCostCode).map(([costCodeGroupId, projectItems]) => ({
      kind: "costCode" as const,
      id: costCodeGroupId,
      data: projectItems.first?.item.value?.costCode.displayName ?? "-",
      initCollapsed: true,
      children: projectItems.map((i) => ({
        kind: "lineItem" as const,
        id: i.value.id,
        data: i,
        potentialBclis: i.potentialBclis,
      })),
    })),
  };
}

const formConfig: ObjectConfig<FormValue> = {
  pis: {
    type: "list",
    config: {
      id: { type: "value" },
      buyoutStatus: { type: "value" },
      canAssignBidContractLineItem: { type: "value" },
      existingCommitments: { type: "value" },
      // itemTemplateId: { type: "value" },
      itemTemplateName: { type: "value" },
      itemTemplateReadyPlan: { type: "value" },
      itemTemplateItemOptions: {
        type: "list",
        config: {
          id: { type: "value" },
          displayName: { type: "value" },
        },
      },
      itemTemplateItem: { type: "value" },
      modifyingTemplateItems: {
        type: "list",
        config: {
          id: { type: "value" },
          quantity: { type: "value" },
          otherOptionIds: { type: "value" },
          elevationIds: { type: "value" },
          first: { type: "value" },
          specOptionIds: { type: "value" },
          options: {
            type: "list",
            config: { id: { type: "value" }, name: { type: "value" }, code: { type: "value" } },
          },
          totalCostInCents: { type: "value" },
        },
      },
      itemTemplateItemName: { type: "value" },
      item: { type: "value" },
      bidItem: { type: "value" },
      location: { type: "value" },
      displayName: { type: "value" },
      bcliId: { type: "value" },
      bcliIds: { type: "value" },
      bidContractRevisionId: { type: "value" },
      costType: { type: "value" },
      selection: { type: "value" },
      tradeCost: { type: "value" },
      quantity: { type: "value" },
      unitOfMeasure: { type: "value" },
      unitCostInCents: { type: "value" },
      potentialBclis: {
        type: "list",
        config: {
          ofIti: { type: "value" },
          bcliId: { type: "value" },
          bcliIds: { type: "value" },
          bidContractRevisionId: { type: "value" },
          tradePartner: { type: "value" },
          totalCostInCents: { type: "value" },
          version: { type: "value" },
          isPrimary: { type: "value" },
          endDate: { type: "value" },
          isEffective: { type: "value" },
          unitCostInCents: { type: "value" },
          prorations: {
            type: "list",
            config: {
              id: { type: "value" },
              costInCents: { type: "value" },
              tradeLineItem: { type: "value" },
            },
          },
        },
      },
      prorations: {
        type: "list",
        config: {
          id: { type: "value" },
          costInCents: { type: "value" },
          tradeLineItem: { type: "value" },
        },
      },
    },
  },
};

type MapToFormInput = [
  ConfirmTradesStep_ProjectItemFragment[],
  ConfirmTradesStepProjectFragment["readyPlanConfig"],
  boolean,
];

function mapToForm([pis, readyPlanConfig, enableProductConfigPlan]: MapToFormInput) {
  return {
    pis: pis
      .filter((pi) => pi.isFromReadyHomeTemplate)
      // only filter pis based on the ready plan their template comes from, if there is a ready plan configured for both the project and the pi
      .filter((pi) =>
        pi.itemTemplateItem?.readyPlan && readyPlanConfig?.readyPlan
          ? pi.itemTemplateItem?.readyPlan?.id === readyPlanConfig?.readyPlan?.id
          : true,
      )
      .map((pi) => {
        const selectedTradePotentialBidContractLineItem = pi.potentialBidContractLineItems.find(
          (po) => pi.probableBidContractLineItem?.id === po.bidContractLineItem.id,
        );
        return {
          id: pi.id,
          canAssignBidContractLineItem: pi.canAssignBidContractLineItem,
          existingCommitments: pi.commitmentLineItems.map((cli) => cli.ownerCommitment.accountingNumber),
          buyoutStatus: pi.buyoutStatus,
          // TODO: Do we REALLY need the template ID here? What are we doing with it?
          // itemTemplateId: pi.itemTemplateItem?.template.id,
          itemTemplateName: pi.itemTemplateItem?.parent.displayName,
          itemTemplateReadyPlan: pi.itemTemplateItem?.readyPlan,
          itemTemplateItemName: pi.itemTemplateItem?.name,
          itemTemplateItemOptions: pi.itemTemplateItem?.options,
          item: pi.item,
          location: pi.itemTemplateItem?.location.name,
          bidItem: pi.bidItem,
          displayName: pi.displayName,
          bcliId: pi.probableBidContractLineItem?.id,
          bcliIds: [],
          bidContractRevisionId: pi.probableBidContractLineItem?.revision.id,
          costType: pi.costType,
          selection: pi.homeownerSelection?.selectedOption?.product.name,
          // Ideally we'd have an easier way of knowing this, i.e. from ProjectItem.totalCostInCents,
          // but it's not set until Commitments are signed ... (until the backend changes?)
          tradeCost: selectedTradePotentialBidContractLineItem?.totalCostInCents ?? undefined,
          quantity: pi.quantity,
          unitOfMeasure: pi.itemTemplateItem?.unitOfMeasure.abbreviation,
          unitCostInCents:
            isDefined(pi.quantity) && pi.quantity !== 0 && selectedTradePotentialBidContractLineItem
              ? selectedTradePotentialBidContractLineItem.totalCostInCents / pi.quantity
              : undefined,
          potentialBclis: pi.potentialBidContractLineItems.map((p) => ({
            ofIti: p.bidContractLineItem.ofIti,
            bcliId: p.bidContractLineItem.id,
            bcliIds: p.bidContractLineItems.map((bcli) => bcli.id),
            bidContractRevisionId: p.bidContractLineItem.revision.id,
            tradePartner: p.bidContractLineItem.revision.bidContract.tradePartner,
            totalCostInCents: p.totalCostInCents,
            endDate: p.bidContractLineItem.revision.endDate,
            version: p.bidContractLineItem.revision.version,
            isPrimary: enableProductConfigPlan
              ? // Check if the BCR is awarded as the primary trade for this cost code
                p.bidContractLineItem.revision.primaryRevisionBidPackageAwards.some(
                  (bpa) => bpa.costCode.id === pi.item.costCode.id,
                )
              : p.bidContractLineItem.revision.bidContract.isPrimary,
            isEffective: p.bidContractLineItem.revision.isEffective,
            unitCostInCents: isDefined(pi.quantity) && pi.quantity !== 0 ? p.totalCostInCents / pi.quantity : undefined,
            prorations: p.bidContractLineItem.prorations ?? [],
          })),
          prorations: pi.probableBidContractLineItem?.prorations ?? [],
          itemTemplateItem: pi.itemTemplateItem,
          modifyingTemplateItems: pi.modifyingTemplateItems ?? [],
        };
      }),
  };
}

type ProjectItems = {
  id: string;
  canAssignBidContractLineItem: PotentialOperation2;
  existingCommitments: number[];
  buyoutStatus: BuyoutStatus;
  // itemTemplateId: string | undefined | null;
  itemTemplateName: string | undefined | null;
  itemTemplateReadyPlan: HasDisplayName | undefined | null;
  itemTemplateItemOptions: Maybe<Partial<ReadyPlanOption>[]>;
  itemTemplateItemName: string | undefined | null;
  item: ConfirmTradesStep_ItemFragment | undefined | null;
  location: string | undefined | null;
  bidItem: ConfirmTradesStepBidItemFragment | undefined | null;
  displayName: string | undefined | null;
  bcliId: string | undefined | null;
  bcliIds: string[];
  bidContractRevisionId: string | undefined | null;
  costType: string | undefined | null;
  selection: string | undefined | null;
  tradeCost: number | undefined | null;
  potentialBclis: PotentialBclis[];
  prorations: ConfirmTradesStep_ProrationFragment[] | undefined;
  quantity: number | undefined | null;
  unitOfMeasure: string | undefined | null;
  unitCostInCents: number | undefined | null;
  itemTemplateItem: ConfirmTradesStep_BaseItemLikeFragment | undefined | null;
  modifyingTemplateItems: ConfirmTradesStep_BaseItemLikeFragment[];
};

type FormValue = {
  pis: ProjectItems[];
};

/** The option for one trade partner. */
type PotentialBclis = {
  ofIti: boolean;
  /** The main Add contract line item. */
  bcliId: string;
  /** All the contract line items (Add + any modifies). */
  bcliIds: string[];
  bidContractRevisionId: string;
  tradePartner: ConfirmTradesStepLineItemsFragment["revision"]["bidContract"]["tradePartner"];
  totalCostInCents: number | undefined | null;
  version: string;
  isPrimary: boolean | null | undefined;
  endDate: DateOnly | null | undefined;
  isEffective: boolean;
  unitCostInCents: number | null | undefined;
  prorations: ConfirmTradesStep_ProrationFragment[];
};

function withEmptyCells(config: Partial<GridColumn<Row>>): GridColumn<Row> {
  return {
    header: emptyCell,
    lineItem: emptyCell,
    plan: emptyCell,
    option: emptyCell,
    assigned: emptyCell,
    division: emptyCell,
    costCode: emptyCell,
    planSource: emptyCell,
    ...config,
  };
}
