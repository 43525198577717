import { isSameDay } from "date-fns";
import { Maybe } from "graphql/jsutils/Maybe";
import { ReactNode, createContext, useContext, useRef } from "react";
import {
  DraftPlanScheduleQuery,
  DraftPlanTaskInput,
  InputMaybe,
  SavePlanTaskScheduleFlagInput,
  ScheduleDraftMode_PlanTaskFragment,
  ScheduleFlagReasonType,
  TradePartnerTaskStatus,
} from "src/generated/graphql-types";
import { isDefined } from "src/utils";
import { DateOnly } from "src/utils/dates";
import { StoreApi, createStore, useStore } from "zustand";

const LAST_UPDATED_NOTIFICATION_TIMEOUT = 6_000;

export type DraftTasksWithChanges = {
  draftTask: ScheduleDraftMode_PlanTaskFragment;
  initialValues: {
    initialStartDate: DateOnly | undefined;
    initialEndDate: DateOnly | undefined;
    initialDuration: number | undefined;
  };
};

export type UserAddedScheduleFlagsInput = Pick<
  SavePlanTaskScheduleFlagInput,
  "clientId" | "reasonId" | "durationInDays" | "id"
> & {
  taskId: string;
  taskName: string;
  title: string;
  scheduleFlagReasonType: {
    name: string;
    code: ScheduleFlagReasonType;
  };
};

export type DraftScheduleStoreState = {
  draftTaskChanges: DraftPlanTaskInput[];
  addDraftTaskChanges: (input: DraftPlanTaskInput[]) => void;
  initialTaskValues?: Record<string, ScheduleDraftMode_PlanTaskFragment>;
  maybeSetInitialValues: (input: DraftPlanScheduleQuery) => void;
  getInitialTaskValue: (taskId: string) => ScheduleDraftMode_PlanTaskFragment | undefined;
  reset: () => void;
  resetRequiredTaskScheduleFlag: (requiredFlags: SavePlanTaskScheduleFlagInput[]) => void;
  requiredScheduleFlags: SavePlanTaskScheduleFlagInput[];
  setRequiredScheduleFlags: (input: SavePlanTaskScheduleFlagInput[]) => void;
  userAddedScheduleFlags: UserAddedScheduleFlagsInput[];
  setUserAddedScheduleFlags: (input: UserAddedScheduleFlagsInput[]) => void;
  removeUserAddedScheduleFlags: (clientId: string) => void;
  // A record of validation errors keyed by the ID of whatever ObjectState gave the error
  validationErrors: Record<string, string[]>;
  setValidationErrors: (input: Record<string, string[]>) => void;
  lastUpdatedTaskId: InputMaybe<string>;
  // Uses publish step getters to determine if the publish flow can be skipped
  canSkipPublishFlow: (latestDraftTaskChanges: ScheduleDraftMode_PlanTaskFragment[]) => boolean;
  // Common getters for publishing step
  // Returns array sorted by startDate
  getTasksWithDateOrDurationChanges: (
    latestDraftTaskChanges: ScheduleDraftMode_PlanTaskFragment[],
  ) => DraftTasksWithChanges[];
  getUnconfirmedPinnedTasks: (
    latestDraftTaskChanges: ScheduleDraftMode_PlanTaskFragment[],
  ) => ScheduleDraftMode_PlanTaskFragment[];
  getConfirmedTasksToBeRescheduled: (
    latestDraftTaskChanges: ScheduleDraftMode_PlanTaskFragment[],
  ) => ScheduleDraftMode_PlanTaskFragment[];
};

type ScheduleDraftStore = StoreApi<DraftScheduleStoreState>;
export type MockInitialValues = Pick<
  DraftScheduleStoreState,
  "draftTaskChanges" | "initialTaskValues" | "userAddedScheduleFlags" | "requiredScheduleFlags"
>;
type DraftScheduleStoreProviderProps = {
  children: ReactNode;
  // Override the store initial state for testing purposes
  mockInitialValues?: MockInitialValues;
};

const StoreContext = createContext<ScheduleDraftStore | undefined>(undefined);

export function DraftScheduleStoreProvider({ children, mockInitialValues }: DraftScheduleStoreProviderProps) {
  const storeRef = useRef<ScheduleDraftStore>();

  if (!storeRef.current) {
    storeRef.current = createDraftScheduleStore(mockInitialValues);
  }

  return <StoreContext.Provider value={storeRef.current}>{children}</StoreContext.Provider>;
}

function createDraftScheduleStore(mockInitialValues?: MockInitialValues) {
  const {
    draftTaskChanges: mockDraftTaskChanges,
    initialTaskValues: mockInitialTaskValues,
    requiredScheduleFlags: mockRequiredScheduleFlags,
    userAddedScheduleFlags: mockUserAddedScheduleFlags,
  } = mockInitialValues ?? {};

  return createStore<DraftScheduleStoreState>((set, get) => ({
    /**
     * This queue of "draft" task changes will be persist for the duration of the "draft" session. Each change will push a new value into the queue
     * and the server will evaluate each change in the order provided
     */
    draftTaskChanges: mockDraftTaskChanges ?? [],
    // It's important that new changes are always added to the end of the list as the inputs are processed in order.
    addDraftTaskChanges: (input) => {
      set({
        draftTaskChanges: [...get().draftTaskChanges, ...input],
        lastUpdatedTaskId: input.first?.id ?? input.first?.clientId,
      });

      // Automatically clear the lastUpdatedTaskId after a short delay
      setTimeout(() => {
        set({ lastUpdatedTaskId: null });
      }, LAST_UPDATED_NOTIFICATION_TIMEOUT);
    },

    /** This is a map of the initial values of the tasks, keyed by the task id.
     * The "maybeSet" action will check for existing values first so it is safe to call multiple times.
     */
    initialTaskValues: mockInitialTaskValues ?? undefined,
    maybeSetInitialValues: (queryResult) => {
      if (!get().initialTaskValues) {
        set({
          initialTaskValues: queryResult.draftPlanSchedule.planTasks.keyBy((t) => t.id),
        });
      }
    },
    getInitialTaskValue: (taskId) => get().initialTaskValues?.[taskId],

    // Clear the store state which will force-fetch the latest from the server
    reset: () => set({ draftTaskChanges: [], initialTaskValues: undefined }),
    // Clears out required schedule flags
    resetRequiredTaskScheduleFlag: (requiredFlags) => {
      // Create a set of required flag IDs for easy lookup
      const requiredFlagIds = new Set(requiredFlags.map((flag) => flag.clientId));
      return set({
        draftTaskChanges: [
          ...get().draftTaskChanges.map(({ scheduleFlags, ...others }) => ({
            ...others,
            // Update the draftTaskChanges by filtering out required flags
            scheduleFlags: scheduleFlags?.filter((flag) => !requiredFlagIds.has(flag.clientId)),
          })),
        ],
      });
    },
    requiredScheduleFlags: mockRequiredScheduleFlags ?? [],
    setRequiredScheduleFlags: (input) => set({ requiredScheduleFlags: input }),
    userAddedScheduleFlags: mockUserAddedScheduleFlags ?? [],
    setUserAddedScheduleFlags: (input) => set({ userAddedScheduleFlags: [...get().userAddedScheduleFlags, ...input] }),
    removeUserAddedScheduleFlags: (clientId) =>
      set({
        // Update the user added flags by filtering out deleted flags
        userAddedScheduleFlags: [...get().userAddedScheduleFlags.filter((flag) => flag.clientId !== clientId)],
      }),
    validationErrors: {},
    setValidationErrors: (input) => {
      const osKey = Object.keys(input)[0];
      // Pull out the name of the form state field that errored from the error message
      // ie: "knownDurationInDays: Task duration must be greater than 0 => Task duration must be greater than 0"
      const newErrors = input[osKey].map((error) => error.split(":")[1]);
      const existingErrors = get().validationErrors;
      set({
        validationErrors: {
          ...existingErrors,
          [osKey]: newErrors,
        },
      });
    },
    lastUpdatedTaskId: null,
    canSkipPublishFlow: (latestDraftTaskChanges) => {
      const canSkipDelayFlagStep =
        !get()
          .getTasksWithDateOrDurationChanges(latestDraftTaskChanges)
          .find((planTask) => planTask.draftTask.isCriticalPath) && get().userAddedScheduleFlags.isEmpty;

      const canSkipTradeConfirmStep =
        get().getUnconfirmedPinnedTasks(latestDraftTaskChanges).isEmpty &&
        get().getConfirmedTasksToBeRescheduled(latestDraftTaskChanges).isEmpty;

      return canSkipDelayFlagStep && canSkipTradeConfirmStep;
    },
    getTasksWithDateOrDurationChanges: (latestDraftTaskChanges) => {
      return latestDraftTaskChanges
        .map((draftTask) => getInitialValuesIfChanged(draftTask, get().getInitialTaskValue(draftTask.id)))
        .compact()
        .sortBy((taskChange) => taskChange.draftTask.startDate);
    },
    getUnconfirmedPinnedTasks: (latestDraftTaskChanges) => {
      return getTasksForTradePartnerAvailabilityRequests(
        latestDraftTaskChanges,
        get().draftTaskChanges,
        (draftTask) => draftTask.tradePartnerStatus.code !== TradePartnerTaskStatus.Confirmed,
        (taskInput, draftTask) =>
          (taskInput.isManuallyScheduled || isDefined(taskInput.tradePartnerId)) && taskInput.id === draftTask.id,
      );
    },
    getConfirmedTasksToBeRescheduled: (latestDraftTaskChanges) => {
      return getTasksForTradePartnerAvailabilityRequests(
        latestDraftTaskChanges,
        get().draftTaskChanges,
        (draftTask) => draftTask.tradePartnerStatus.code === TradePartnerTaskStatus.Confirmed,
        (taskInput, draftTask) => {
          // Because the `draftTask.startDate` will reflect the latest "draft" date, we must compare
          // against the "initial" value that is cached before any draft modifications happen
          const maybeInitialStartDate = get().getInitialTaskValue(draftTask.id)?.startDate;
          if (!maybeInitialStartDate || !taskInput.earliestStartDate) return false;

          const dateHasChanged = !isSameDay(maybeInitialStartDate, taskInput.earliestStartDate);
          return taskInput.id === draftTask.id && dateHasChanged;
        },
      );
    },
  }));
}

export function useDraftScheduleStore<T>(selector: (state: DraftScheduleStoreState) => T) {
  const store = useContext(StoreContext);

  if (!store) {
    throw new Error("Missing DraftScheduleStoreProvider");
  }

  return useStore(store, selector);
}

// helper functions

// filter out tasks that need to be sent a trade partner availability request
function getTasksForTradePartnerAvailabilityRequests(
  draftTasks: ScheduleDraftMode_PlanTaskFragment[],
  draftTaskChanges: DraftPlanTaskInput[],
  statusCheck: (draftTask: ScheduleDraftMode_PlanTaskFragment) => Maybe<boolean>,
  draftChangesCheck: (taskInput: DraftPlanTaskInput, draftTask: ScheduleDraftMode_PlanTaskFragment) => Maybe<boolean>,
) {
  return draftTasks.filter(
    (draftTask) =>
      statusCheck(draftTask) && draftTaskChanges.some((taskInput) => draftChangesCheck(taskInput, draftTask)),
  );
}

function getInitialValuesIfChanged(
  draftTask: ScheduleDraftMode_PlanTaskFragment,
  initialTaskValues?: ScheduleDraftMode_PlanTaskFragment,
) {
  if (!initialTaskValues) return undefined;

  const initialStartDate = isSameDay(initialTaskValues.startDate, draftTask.startDate)
    ? undefined
    : initialTaskValues.startDate;
  const initialEndDate = isSameDay(initialTaskValues.endDate, draftTask.endDate)
    ? undefined
    : initialTaskValues.endDate;
  const initialDuration =
    initialTaskValues.durationInDays === draftTask.durationInDays ? undefined : initialTaskValues.durationInDays;

  if (initialStartDate || initialEndDate || initialDuration) {
    return { draftTask, initialValues: { initialStartDate, initialEndDate, initialDuration } };
  }

  // If there are no date or duration changes, return undefined for easy filtering
  return undefined;
}
