import {
  BoundSelectField,
  BoundTextField,
  Button,
  FormLines,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
} from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useHistory } from "react-router";
import {
  ChangeEventBudgetPhase,
  ChangeEventStatus,
  ChangeEventType,
  CreateCeModalQueryResult,
  LotType,
  SaveChangeEventInput,
  useCreateCeModalQuery,
  useCreateCeModalSaveCeMutation,
} from "src/generated/graphql-types";
import { createChangeEventUrl } from "src/RouteUrls";
import { changeEventTypeToNameMapper, queryResult } from "src/utils";
import { filterArchivedUnlessSelected } from "src/utils/changeEventReasons";
import { isContractualStage } from "src/utils/projects";

type CreateChangeEventModalProps = {
  projectId: string;
};

export function CreateChangeEventModal({ projectId }: CreateChangeEventModalProps) {
  const query = useCreateCeModalQuery({ variables: { projectId } });
  return queryResult(query, (data) => <CreateChangeEventModalView data={data} projectId={projectId} />);
}

type CreateChangeEventModalViewProps = {
  projectId: string;
  data: Exclude<CreateCeModalQueryResult["data"], undefined>;
};

function CreateChangeEventModalView({ data, projectId }: CreateChangeEventModalViewProps) {
  const { changeEventReasons: cers, project } = data;
  const { lotType, stages: projectStages } = project;

  const formState = useFormState({
    config: formConfig,
    init: {
      onlyOnce: true,
      input: {
        budgetPhase: ChangeEventBudgetPhase.Revised,
        type: lotType?.code && changeEventDefaultTypeOptionByLotType[lotType.code],
      },
    },
  });
  const { closeModal } = useModal();
  const [saveChangeEvent] = useCreateCeModalSaveCeMutation();
  const history = useHistory();

  const changeEventReasons = filterArchivedUnlessSelected(cers);

  // TODO: Temporarily rename "Prebid" to "Awarded" on FE until BE is in place to remove awarded completely & rename it to prebid
  const budgetPhaseOptions = [
    { code: ChangeEventBudgetPhase.Prebid, name: "Awarded" },
    { code: ChangeEventBudgetPhase.Revised, name: "Revised" },
  ];

  return (
    <>
      <ModalHeader>Create Change Event</ModalHeader>
      <ModalBody>
        <FormLines>
          <BoundTextField field={formState.title} label="Name" />
          <BoundTextField field={formState.internalNote} label="Description" />
          <BoundSelectField
            field={formState.budgetPhase}
            label="Impacted Budget"
            options={budgetPhaseOptions}
            getOptionLabel={(o) => o.name}
            getOptionValue={(o) => o.code}
          />
          <BoundSelectField
            label="Related Contract"
            field={formState.projectStageId}
            options={projectStages.filter((s) => isContractualStage(s.stage.code))}
            getOptionLabel={({ stage }) => stage.name}
            getOptionValue={({ id }) => id}
            disabledOptions={projectStages.filter((ps) => !ps.hasSignedContract).map((ps) => ps.id)}
            helperText="Only signed contracts can be selected."
          />
          <BoundSelectField field={formState.reasonId} label="Reason for Change" options={changeEventReasons} />
          <BoundSelectField
            label="Change Event Type"
            field={formState.type}
            options={Object.entries(ChangeEventType)}
            getOptionValue={([_, type]) => type}
            getOptionLabel={([_, type]) => `${lotType.clientNoun} ${changeEventTypeToNameMapper(type)}`} // e.g. "Homeowner Chargeable"
          />
        </FormLines>
      </ModalBody>
      <ModalFooter>
        <Observer>
          {() => (
            <>
              <Button label="Cancel" onClick={closeModal} variant="secondary" />
              <Button
                disabled={!formState.valid}
                label="Create"
                onClick={async () => {
                  if (formState.canSave()) {
                    const response = await saveChangeEvent({
                      variables: {
                        input: {
                          ...formState.value,
                          status: ChangeEventStatus.Draft,
                        },
                      },
                    });
                    const newCeId = response.data?.saveChangeEvent.changeEvent.id;
                    if (newCeId) {
                      // close global modal then redirect
                      closeModal();
                      history.push({ pathname: createChangeEventUrl(projectId, newCeId) });
                    }
                  }
                }}
              />
            </>
          )}
        </Observer>
      </ModalFooter>
    </>
  );
}

const formConfig: ObjectConfig<SaveChangeEventInput> = {
  internalNote: { type: "value", rules: [required] },
  projectStageId: { type: "value", rules: [required] },
  reasonId: { type: "value", rules: [required] },
  title: { type: "value", rules: [required] },
  budgetPhase: { type: "value", rules: [required] },
  type: { type: "value", rules: [required] },
};

// Used for the default option of the Change Event Type select field
export const changeEventDefaultTypeOptionByLotType: Record<LotType, ChangeEventType> = {
  [LotType.Bool]: ChangeEventType.External,
  [LotType.Hbl]: ChangeEventType.Internal,
  [LotType.Boyl]: ChangeEventType.Internal,
  [LotType.Btr]: ChangeEventType.Internal,
  [LotType.Fee]: ChangeEventType.Internal,
};
