import { TakeoffLineItemFilter } from "src/generated/graphql-types";

/**
 * Massages our just-what-the-user-sees UI filters into "what's submitted to the server".
 *
 * This is useful for knowing what the apollo cache key will be, given it's based on the
 * "what's submitted to the server" values.
 */
export function toServerFilter(readyPlanId: string, uiFilters: TakeoffLineItemFilter): TakeoffLineItemFilter {
  return {
    readyPlan: [readyPlanId],
    ...uiFilters,
  };
}
