import { Css } from "@homebound/beam";
import { useParams } from "react-router";
import { usePlanPackageVersionHistoryQuery, VersionDetailsCard_VersionFragment } from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { GeneralTlivChanges } from "src/routes/libraries/versioning/GeneralTlivChanges";
import { createPlanPackagesUrl, createPlanPackageTakeoffUrl, createPlanPackageUrl } from "src/RouteUrls";
import { fail, queryResult } from "src/utils";
import { VersionDetailsCard } from "../../versioning/VersionDetailsCard";

type PlanPackageVersionHistoryUrlParams = {
  planPackageId: string;
};

export function PlanPackageVersionHistoryPage() {
  const { planPackageId } = useParams<PlanPackageVersionHistoryUrlParams>();
  const query = usePlanPackageVersionHistoryQuery({ variables: { filter: { readyPlanId: [planPackageId] } } });

  return queryResult(query, ({ readyPlanAggregateVersions }) => (
    <PlanPackageVersionHistoryView versions={readyPlanAggregateVersions} />
  ));
}

type PlanPackageVersionHistoryViewProps = {
  versions: VersionDetailsCard_VersionFragment[];
};

function PlanPackageVersionHistoryView(props: PlanPackageVersionHistoryViewProps) {
  const { planPackageId } = useParams<PlanPackageVersionHistoryUrlParams>();
  const { versions } = props;
  // We reverse the array so that the most recent version is shown first.
  const rpavs = [...versions].reverse();
  const latestVersion = rpavs.first ?? fail("Expected at least one version");

  return (
    <>
      <PageHeader
        title="Version History"
        breadcrumb={[
          { href: createPlanPackagesUrl(), label: "Plans" },
          { href: createPlanPackageUrl(planPackageId, latestVersion.id), label: latestVersion.readyPlan.name },
        ]}
      />
      <div css={Css.df.fdc.gap2.$}>
        {rpavs.map((rpav) => (
          <VersionDetailsCard
            key={rpav.id}
            version={rpav}
            changeDetails={<PlanPackageVersionChanges version={rpav} />}
            versionUrl={createPlanPackageTakeoffUrl(rpav.readyPlan.id, rpav.id)}
          />
        ))}
      </div>
    </>
  );
}

type PlanPackageVersionChangesProps = {
  version: VersionDetailsCard_VersionFragment;
};

function PlanPackageVersionChanges({ version }: PlanPackageVersionChangesProps) {
  const { tliVersions } = version;
  return (
    <>
      <GeneralTlivChanges tliVersions={tliVersions} />
    </>
  );
}
