import {
  Avatar,
  BoundMultiSelectField,
  BoundNumberField,
  BoundSelectField,
  BoundTextAreaField,
  BoundTreeSelectField,
  Button,
  Css,
  PresentationProvider,
  StaticField,
  useModal,
  useSuperDrawer,
} from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import pick from "lodash/pick";
import { Observer } from "mobx-react";
import { useCallback, useMemo } from "react";
import {
  BoundAttachments,
  SaveAttachmentModel,
  attachmentConfig,
} from "src/components/boundAttachments/BoundAttachments";
import {
  CreateChangeRequestDrawer,
  getAssetTreeSelectOptions,
  getProjectTreeSelectValues,
  getRequiredChangeSourceAssets,
} from "src/components/change-log/CreateChangeRequestDrawer";
import {
  AssetTreeSelectValuesFragment,
  ChangeLogRightPaneContentDetailsQuery,
  ChangeRequestDependency,
  ChangeRequestDetailsFragment,
  ChangeRequestPriority,
  ChangeRequestSource,
  ChangeRequestStatus,
  ChangeType,
  Development,
  IncrementalCollectionOp,
  InputMaybe,
  ProjectTreeSelectValuesFragment,
  SaveChangeRequestAsset,
  SaveChangeRequestInput,
  useSaveChangeRequestMutation,
} from "src/generated/graphql-types";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { cannotBeEmpty, daysAgo, mapFormInputToIncrementalOp, maybeDeTagId } from "src/utils";
import { isPersonalizationChangeSource } from "../utils";
import { CustomerRequestPersonalizationInputs } from "./CustomerRequestPersonalizationInputs";
import { DependencyModal } from "./DependencyModal";
import { UrgencyField } from "./UrgencyField";

type DetailsTabProps = {
  changeRequest: ChangeRequestDetailsFragment;
  selectValues: {
    enums: ChangeLogRightPaneContentDetailsQuery["enumDetails"];
    projects: ProjectTreeSelectValuesFragment[];
    developments: Pick<Development, "id" | "name">[];
    assets: AssetTreeSelectValuesFragment[];
  };
};

export const READONLY_STATUSES = [
  ChangeRequestStatus.Approved,
  ChangeRequestStatus.Denied,
  ChangeRequestStatus.Completed,
];

export const ALLOWED_SOURCES = [
  ChangeRequestSource.CustomerRequestPersonalization,
  ChangeRequestSource.BoylCustomerRequestPersonalization,
  ChangeRequestSource.InternalSalesFeedback,
  ChangeRequestSource.FieldDirective,
  ChangeRequestSource.InternalDesignFeedback,
];

export function DetailsTab({ changeRequest, selectValues }: DetailsTabProps) {
  const { enums, projects, developments, assets } = selectValues;
  const [saveChangeRequest, { loading: formSaving }] = useSaveChangeRequestMutation();
  const { openModal } = useModal();

  const scopeSelectValues = useMemo(() => getProjectTreeSelectValues(projects, developments), [projects, developments]);

  const assetSelectValues = useMemo(() => getAssetTreeSelectOptions(assets), [assets]);

  const formState = useFormState({
    config: formConfig,
    init: {
      input: changeRequest,
      map: (changeRequest) => ({
        ...changeRequest,
        source: changeRequest.source.code,
        scopes: changeRequest.scopes.map((s) => s.target.id),
        changeTypes: changeRequest.changeTypes,
        assets: changeRequest.changeRequestAssets.map((cra) => cra.id),
        estimateBudgetImpactInCents: changeRequest.estimateBudgetImpactInCents,
        markupBasisPoints: changeRequest.markupBasisPoints,
        estimatedBuyerOutOfPocketInCents: changeRequest.estimatedBuyerOutOfPocketInCents,
        estimatedRetailPriceInCents: changeRequest.estimatedRetailPriceInCents,
        estimateScheduleImpactInDays: changeRequest.estimateScheduleImpactInDays,
        estimateRevenueImpactInCents: changeRequest.estimateRevenueImpactInCents,
        priority: changeRequest.priority.code,
      }),
    },
    readOnly: READONLY_STATUSES.includes(changeRequest.status.code),
    autoSave: async ({ changedValue: { scopes, attachments, assets, ...rest } }) => {
      const updatedScope = mapFormInputToIncrementalOp(scopes, changeRequest.scopes, {
        existingValueToId: (crs) => crs.target.id,
        removedMapper: (removed) => ({ id: removed.id, op: IncrementalCollectionOp.Delete }),
        addedMapper: (added) => ({ targetId: added, op: IncrementalCollectionOp.Include }),
      });
      const updatedAsset = mapFormInputToIncrementalOp(assets, changeRequest.changeRequestAssets, {
        existingValueToId: (cra) => cra.id,
        removedMapper: (removed) => ({ id: removed.id, op: IncrementalCollectionOp.Remove }),
        addedMapper: (added) => ({ id: added, op: IncrementalCollectionOp.Include }),
      });

      await saveChangeRequest({
        variables: {
          input: {
            id: changeRequest.id,
            ...rest,
            ...(updatedAsset?.nonEmpty ? { changeRequestAssets: updatedAsset as SaveChangeRequestAsset[] } : {}),
            ...(updatedScope?.nonEmpty ? { scopes: updatedScope } : {}),
            attachments: attachments?.map(({ asset, ...props }) => ({
              ...props,
              asset: pick(asset, [
                // Dropping downloadUrl, attachmentUrl and createdAt to get the AssetInput shape
                "contentType",
                "fileName",
                "id",
                "s3Key",
                "sizeInBytes",
                "delete",
              ]),
            })),
          },
        },
        refetchQueries: ["ChangeLogRightPaneContentDetails"],
      });
    },
  });

  // Temporally hide other sources until they are released
  const changeRequestSources = useMemo(
    () => filterChangeRequestSources(enums.changeRequestSource),
    [enums.changeRequestSource],
  );

  return (
    <PresentationProvider wrap>
      <Observer>
        {() => (
          <div css={Css.df.fdc.gap2.$}>
            <BoundTextAreaField label="Change Description" field={formState.rationale} />
            <div css={Css.df.fdc.gap1.$}>
              <span css={Css.gray600.sm.$}>Created {daysAgo(changeRequest.createdAt)} by</span>
              <Avatar src={changeRequest.createdBy.avatarUrl} size="sm" showName name={changeRequest.createdBy.name} />
            </div>
            <BoundSelectField
              label="Change Source (Where it came from)"
              field={formState.source}
              options={changeRequestSources}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ code }) => code}
              onSelect={(value) => {
                if (!isPersonalizationChangeSource(value)) {
                  formState.isStandardOffering.set(undefined);
                  formState.salesforceOpportunityUrl.set(undefined);
                  formState.isUnderContract.set(undefined);
                  formState.productSelection.set(undefined);
                }
                formState.source.set(value);
                if (isPersonalizationChangeSource(value)) {
                  // tie specific hardcoded assets to the change type of personalization
                  formState.assets.set([
                    ...(formState.assets.value ?? []),
                    ...getRequiredChangeSourceAssets(assets, formState.source.value),
                  ]);
                }
              }}
              compact
            />
            <BoundTreeSelectField
              label="Lots/ Developments Impacted"
              field={formState.scopes}
              options={scopeSelectValues}
              onSelect={(options) => formState.scopes.set(options.root.values)}
              defaultCollapsed
              compact
              disabled={formSaving}
            />
            <BoundMultiSelectField
              label="Type of Change (What is affected)"
              field={formState.changeTypes}
              options={enums.changeType}
              compact
              getOptionLabel={({ name }) => name}
              getOptionValue={({ code }) => code}
            />
            <BoundTreeSelectField
              label="Individual Assets (What is affected)"
              field={formState.assets}
              options={assetSelectValues}
              disabledOptions={getDisabledAssets(
                getRequiredChangeSourceAssets(assets, formState.source.value),
                formState.assets.value ?? [],
              )}
              defaultCollapsed
              // Use the `leaf` values so if a user selects an asset, store the asset selection (vs storing the parent business function)
              onSelect={(options) => formState.assets.set(options.leaf.values)}
              compact
              placeholder="--"
            />
            <BoundNumberField
              label="Estimated Budget Impact"
              type="cents"
              field={formState.estimateBudgetImpactInCents}
              helperText="This is the cost to Homebound for making the change."
            />
            <BoundNumberField
              label="Markup %"
              type="basisPoints"
              field={formState.markupBasisPoints}
              helperText="This is the expected markup for the buyer."
            />
            <BoundNumberField
              label="Estimated Buyer Out of Pocket"
              type="cents"
              field={formState.estimatedBuyerOutOfPocketInCents}
              helperText="This is the price the buyer will pay and should be reflected in the contract."
            />
            <BoundNumberField
              label="Estimated Retail Price"
              type="cents"
              field={formState.estimatedRetailPriceInCents}
              helperText="This is the value to the buyer if they were to pay full price for the change."
            />
            <BoundNumberField
              label="Estimated Revenue Impact"
              type="cents"
              field={formState.estimateRevenueImpactInCents}
              helperText="This is the revenue the change will generate."
            />
            <BoundNumberField
              label="Estimated Margin Impact %"
              type="basisPoints"
              field={formState.estimateMarginImpactBasisPoints}
              helperText="This is the profit the change will generate."
            />
            <BoundNumberField
              label="Estimated Schedule Impact"
              type="days"
              field={formState.estimateScheduleImpactInDays}
            />
            <UrgencyField field={formState.priority} options={enums.changeRequestPriority} />
            <CustomerRequestPersonalizationInputs formState={formState} />
            <DependencyModal id={changeRequest.id} kind={ChangeRequestDependency.Block} />
            <DependencyModal id={changeRequest.id} kind={ChangeRequestDependency.Dependent} />
            <DependencyModal id={changeRequest.id} kind={ChangeRequestDependency.Link} />
            <BoundAttachments field={formState.attachments} />
            <div css={Css.df.jcsb.$}>
              <StaticField label={<div css={Css.truncate.mr2.$}>Last Updated</div>} labelStyle="left">
                <div css={Css.asb.$}>{daysAgo(changeRequest.updatedAt)}</div>
              </StaticField>
              <StaticField label={<div css={Css.mr2.$}>ID</div>} labelStyle="left">
                <div css={Css.asb.$}>{maybeDeTagId(changeRequest.id)}</div>
              </StaticField>
            </div>
            <div css={Css.df.aic.jcc.gap2.bt.bcGray200.py2.mbPx(-24).mxPx(-24).bgWhite.$}>
              <DuplicateButton changeRequestId={changeRequest.id} />
              {changeRequest.status.code !== ChangeRequestStatus.Denied && (
                <Button
                  label="Reject"
                  variant="danger"
                  icon="error"
                  onClick={() =>
                    openModal({
                      content: (
                        <ConfirmationModal
                          confirmationMessage="Are you sure you want to reject this change request? This will keep the change request, but close it so no further changes can be made. All future To-Dos and Approvals will be stopped, and all existing open ones will be cancelled."
                          label="Yes, Reject"
                          danger
                          onConfirmAction={async () =>
                            await saveChangeRequest({
                              variables: {
                                input: { id: changeRequest.id, status: ChangeRequestStatus.Denied },
                              },
                              refetchQueries: ["ChangeLogRightPaneContentDetails"],
                            })
                          }
                          title="Reject Request"
                        />
                      ),
                    })
                  }
                />
              )}
            </div>
          </div>
        )}
      </Observer>
    </PresentationProvider>
  );
}

function DuplicateButton({ changeRequestId }: { changeRequestId: string }) {
  const { openModal } = useModal();
  const { openInDrawer } = useSuperDrawer();

  const onConfirm = useCallback(
    () => openInDrawer({ content: <CreateChangeRequestDrawer copyViaChangeRequestId={changeRequestId} /> }),
    [openInDrawer, changeRequestId],
  );

  const onDuplicateClick = useCallback(
    () =>
      openModal({
        content: (
          <ConfirmationModal
            confirmationMessage="Are you sure you want to duplicate this change request? This will copy all of the fields to a new request, but start the To Dos and Approvals over from the beginning."
            label="Yes, Duplicate"
            onConfirmAction={onConfirm}
            title="Duplicate Request"
          />
        ),
      }),
    [openModal, onConfirm],
  );

  return <Button label="Duplicate" variant="tertiary" onClick={onDuplicateClick} />;
}

export type EditChangeRequestForm = Pick<
  SaveChangeRequestInput,
  | "rationale"
  | "estimateBudgetImpactInCents"
  | "markupBasisPoints"
  | "estimatedBuyerOutOfPocketInCents"
  | "estimatedRetailPriceInCents"
  | "estimateScheduleImpactInDays"
  | "estimateRevenueImpactInCents"
  | "estimateMarginImpactBasisPoints"
  | "isStandardOffering"
  | "salesforceOpportunityUrl"
  | "isUnderContract"
  | "productSelection"
> & {
  source: InputMaybe<ChangeRequestSource>;
  scopes: InputMaybe<string[]>;
  changeTypes: InputMaybe<ChangeType[]>;
  assets: InputMaybe<string[]>;
  priority: InputMaybe<ChangeRequestPriority>;
  attachments: InputMaybe<SaveAttachmentModel[]>;
};

const formConfig: ObjectConfig<EditChangeRequestForm> = {
  rationale: { type: "value", rules: [required] },
  source: { type: "value", rules: [required] },
  scopes: { type: "value", rules: [required, cannotBeEmpty] },
  changeTypes: { type: "value", rules: [required, cannotBeEmpty] },
  assets: { type: "value" },
  estimateBudgetImpactInCents: { type: "value" },
  markupBasisPoints: { type: "value" },
  estimatedBuyerOutOfPocketInCents: { type: "value" },
  estimatedRetailPriceInCents: { type: "value" },
  estimateScheduleImpactInDays: { type: "value" },
  estimateRevenueImpactInCents: { type: "value" },
  estimateMarginImpactBasisPoints: { type: "value" },
  priority: { type: "value", rules: [required] },
  isStandardOffering: { type: "value" },
  salesforceOpportunityUrl: { type: "value" },
  isUnderContract: { type: "value" },
  attachments: {
    type: "list",
    config: attachmentConfig,
  },
  productSelection: { type: "value" },
};

function getDisabledAssets(allDisabledAssets: string[], selectedAssetIds: string[]) {
  return allDisabledAssets.filter((asset) => selectedAssetIds.includes(asset));
}

export function filterChangeRequestSources(
  sources: ChangeLogRightPaneContentDetailsQuery["enumDetails"]["changeRequestSource"],
) {
  return sources.filter(({ code }) => ALLOWED_SOURCES.includes(code)).sortByKey("name");
}
