import { Css } from "@homebound/beam";
import {
  BidPackageStatus,
  InvoiceStatus,
  ItemTemplateStatus,
  ProjectStatus,
  ReadyPlanStatus,
  ScheduleStatus,
  TaskStatus,
  TradePartnerTaskStatus,
  WarrantyTicketItemStatus,
} from "src/generated/graphql-types";

export type StatusIndicatorProps = {
  status:
    | ScheduleStatus
    | TaskStatus
    | ProjectStatus
    | ItemTemplateStatus
    | WarrantyTicketItemStatus
    | InvoiceStatus
    | ReadyPlanStatus
    | BidPackageStatus
    | TradePartnerTaskStatus;
  type?: "ItemTemplate" | "BidPackage" | "ReadyPlan";
};

export function StatusIndicator(props: StatusIndicatorProps) {
  return <div css={Css.h1.w1.br100.add(getBgColorAndShadow(props)).$} />;
}

const COLORS = {
  draft: Css.bgGray600.boxShadow("0px 1px 5px rgba(141, 141, 141, 0.3)").$,
  active: Css.bgBlue600.boxShadow("0px 1px 5px rgba(2, 143, 199, 0.3)").$,
  completed: Css.bgGreen400.boxShadow("0px 1px 5px rgba(163, 208, 126, 0.3)").$,
  onHold: Css.bgYellow600.boxShadow("0px 1px 5px rgba(246,177,78,0.3)").$,
  delayed: Css.bgRed600.boxShadow("0px 1px 5px rgba(200, 98, 81, 0.3)").$,
  submitted: Css.bgPurple500.boxShadow("0px 1px 5px rgba(200, 98, 81, 0.3)").$,
};

function getBgColorAndShadow({ status, type }: StatusIndicatorProps) {
  if (type === "ItemTemplate" || type === "ReadyPlan") {
    const itemTemplateStatus = {
      [ItemTemplateStatus.Active]: COLORS.completed,
      [ItemTemplateStatus.Archived]: COLORS.delayed,
      [ItemTemplateStatus.Draft]: COLORS.active,
      [ItemTemplateStatus.SystemDraft]: COLORS.draft,
    };
    const result = itemTemplateStatus[status as ItemTemplateStatus];
    if (result) return result;
  } else if (type === "BidPackage") {
    const bidPackageStatus = {
      [BidPackageStatus.Draft]: COLORS.active,
      [BidPackageStatus.Open]: COLORS.onHold,
      [BidPackageStatus.Complete]: COLORS.completed,
      [BidPackageStatus.Closed]: COLORS.delayed,
    };
    const result = bidPackageStatus[status as BidPackageStatus];
    if (result) return result;
  }

  switch (status) {
    case TaskStatus.NotStarted:
    case ScheduleStatus.Draft:
    case ProjectStatus.Closed:
    case WarrantyTicketItemStatus.Accepted:
    case TradePartnerTaskStatus.CompletedJob:
      return COLORS.draft;
    case TaskStatus.InProgress:
    case ScheduleStatus.Active:
    case ProjectStatus.Active:
    case WarrantyTicketItemStatus.Scheduled:
    case WarrantyTicketItemStatus.InProgress:
    case WarrantyTicketItemStatus.MaterialsOrdered:
    case WarrantyTicketItemStatus.TradeContacted:
    case InvoiceStatus.Approved:
      return COLORS.active;
    case TaskStatus.Complete:
    case ScheduleStatus.Completed:
    case ProjectStatus.Completed:
    case ProjectStatus.UnderWarranty:
    case WarrantyTicketItemStatus.Completed:
    case InvoiceStatus.Paid:
    case TradePartnerTaskStatus.Confirmed:
      return COLORS.completed;
    case TaskStatus.OnHold:
    case ProjectStatus.Hold:
    case WarrantyTicketItemStatus.OnHold:
    case InvoiceStatus.ChangesRequested:
    case WarrantyTicketItemStatus.PendingInfoFromHomeowner:
    case TradePartnerTaskStatus.NeedsConfirmation:
    case TradePartnerTaskStatus.NeedsReconfirmation:
      return COLORS.onHold;
    case TaskStatus.Delayed:
    case WarrantyTicketItemStatus.Rejected:
    case InvoiceStatus.Void:
    case TradePartnerTaskStatus.Unavailable:
    case TradePartnerTaskStatus.NotSent:
      return COLORS.delayed;
    case WarrantyTicketItemStatus.Submitted:
    case InvoiceStatus.Requested:
      return COLORS.submitted;
    default:
      return {};
  }
}
