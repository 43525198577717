import {
  Button,
  Css,
  DnDGrid,
  DnDGridItemHandle,
  IconButton,
  NumberField,
  SelectField,
  useComputed,
  useDnDGridItem,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, useFormState } from "@homebound/form-state";
import { useEffect, useRef, useState } from "react";
import { StepLayout } from "src/components/stepper/StepLayout";
import { NextStepButton } from "src/components/stepper/useStepperWizard/NextStepButton";
import {
  PlanPackageLocationStepQuery,
  PlanPackageLocationStepQueryResult,
  SaveReadyPlanLocationInput,
  useBulkSaveRplsMutation,
  usePlanPackageLocationStepQuery,
  useRoomTypeQuery,
} from "src/generated/graphql-types";
import { createPlanPackageUrl } from "src/RouteUrls";
import { fail } from "src/utils";
import { useDebounceCallback } from "usehooks-ts";
import { PlanPackageEditorHeader } from "./components/PlanPackageEditorHeader";

type LocationStepProps = {
  id: string;
  versionId: string;
  setStepDirty: (dirty: boolean) => void;
};

export function LocationStep({ id, versionId, setStepDirty }: LocationStepProps) {
  const { loading, groupedRpls, reorderLevel, markDeleted, newRoomLocation, onSave, hasDupes } = useQueryToGroupedData(
    id,
    versionId,
    setStepDirty,
  );
  const [hoverRt, setHoverRt] = useState<string>();
  return (
    <StepLayout header={<PlanPackageEditorHeader title="Location" />}>
      <div css={Css.py3.mt2.df.ais.jcc.$}>
        {loading && <div>Loading...</div>}
        {!loading && groupedRpls.isEmpty && <div>No locations found</div>}
        <div css={Css.df.fdc.w25.mwPx(400).maxwPx(600).$}>
          {/* Levels */}
          {groupedRpls.map(([level, rpls]) => (
            <div key={level.id} css={Css.mb2.$}>
              <div css={Css.df.jcsb.pb2.$}>
                <div css={Css.baseMd.$}>{level.name}</div>
              </div>
              <div css={Css.df.fdc.pl3.$}>
                <DnDGrid onReorder={(rplIds) => reorderLevel(level.id, rplIds)}>
                  {rpls
                    .filter((rpl) => !rpl.delete.value)
                    .map((rpl) => (
                      <RoomLocationRow
                        key={rpl.id.value}
                        rpl={rpl}
                        markDeleted={() => markDeleted(rpl.id.value)}
                        highlightRoomType={!!(hoverRt && hoverRt === rpl.value.roomType?.id)}
                        onInteract={setHoverRt}
                      />
                    ))}
                </DnDGrid>
                <div css={Css.df.fdrr.py2.$}>
                  <Button label="+ Add Room" variant="text" onClick={() => newRoomLocation(level.id)} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <NextStepButton
        label="Continue"
        onClick={onSave}
        onCloseReturnUrl={createPlanPackageUrl(id, versionId)}
        disabled={hasDupes}
        tooltip={hasDupes ? "Please resolve duplicate room numbers" : undefined}
        exitButton={{
          variant: "secondary",
          label: "Save & Exit",
          onClick: onSave,
          disabled: hasDupes,
          tooltip: hasDupes ? "Please resolve duplicate room numbers" : undefined,
        }}
      />
    </StepLayout>
  );
}

type ReadyPlanLocationRow = Omit<PlanPackageLocationStepQuery["planPackage"]["roomLocations"][number], "location"> & {
  delete?: boolean;
  hasDupes?: boolean;
  location: PlanPackageLocationStepQuery["planPackage"]["roomLocations"][number]["location"] | undefined;
};

type RoomLocationRowProps = {
  rpl: ObjectState<ReadyPlanLocationRow>;
  markDeleted: VoidFunction;
  /** Highlights all instances of this room type on hover or edit to easily identify duplicates or other bedrooms, hallways, bathrooms, closets, etc */
  highlightRoomType: boolean;
  onInteract: (roomTypeId: string | undefined) => void;
};

function RoomLocationRow({ rpl, markDeleted, highlightRoomType, onInteract }: RoomLocationRowProps) {
  const ref = useRef(null);
  const { roomType, location, roomNumber, id } = rpl.value;
  const { dragHandleProps, dragItemProps } = useDnDGridItem({ id, itemRef: ref });
  const roomTypeQuery = useRoomTypeQuery({ fetchPolicy: "cache-first" });
  const isNewRow = !c.isRealId(id);
  const levelNumber = rpl.value.level?.name.match(/\d+$/)?.[0];

  // every keystroke will bounce the row they're editing around the Level Order so debounce it (and skip empty inputs) for a smoother experience
  const debouncedSetRoomNumber = useDebounceCallback((val) => {
    if (val) rpl.set({ roomNumber: val });
  }, 300);

  return (
    <div
      ref={ref}
      {...dragItemProps}
      onMouseEnter={() => onInteract(roomType?.id)}
      onMouseLeave={() => onInteract(undefined)}
      css={{
        ...Css.df.jcsb.aic.mb1.if(highlightRoomType).bgGray200.onHover.bgGray300.$,
        // reveals the DnD handle as well as the Trash/Remove button
        [`&:hover #hidebutton`]: Css.o100.vv.$,
      }}
    >
      <div id="hidebutton" css={Css.o0.vh.$}>
        <DnDGridItemHandle icon="drag" dragHandleProps={dragHandleProps} />
      </div>
      <div css={Css.mr1.$}>{levelNumber}</div>
      <div
        css={Css.wPx(70).fs0.$} /* NumberField is ignoring this CSS in favor of w100, so wrap it to give w100 a max */
      >
        <NumberField // unbound because we need to debounce the input
          label="Room Number"
          labelStyle="hidden"
          value={roomNumber!}
          onFocus={() => onInteract(roomType?.id)}
          onBlur={() => onInteract(undefined)}
          onChange={debouncedSetRoomNumber}
          errorMsg={rpl.hasDupes.value ? "Duplicate room number" : undefined}
          errorInTooltip
          positiveOnly
          // ERR: Conflicts with debounce, which is more important to UX
          // numberFormatOptions={{ minimumIntegerDigits: 2 }} // UX includes Level like: `1-01` and `2-03`, so padStart
          compact
        />
      </div>
      <div css={Css.pl4.df.jcsb.aic.f1.$}>
        <SelectField
          label="Room Type"
          labelStyle="hidden"
          options={roomTypeQuery.data?.roomTypes ?? []}
          value={roomType?.id}
          onSelect={(val) => {
            rpl.set({ roomType: roomTypeQuery.data?.roomTypes.find((rt) => rt.id === val) });
          }}
          compact
          readOnly={!isNewRow}
          unsetLabel={isNewRow ? "Select Room Type" : `${location?.name} (err no roomType)`.trim()}
        />
        <div id="hidebutton" css={Css.o0.vh.df.aic.jcfe.if(isNewRow).o100.vv.$}>
          <IconButton icon="trash" onClick={markDeleted} />
        </div>
      </div>
    </div>
  );
}

/** [Level, RPLs[]] tuples derived from `RPLs.groupByObject((rpl) => rpl.level)` */
type GroupedData = [NonNullable<ReadyPlanLocationRow["level"]>, ObjectState<ReadyPlanLocationRow>[]];
type FormState = { readyPlanLocations: ReadyPlanLocationRow[] };

function useQueryToGroupedData(id: string, versionId: string, setStepDirty: (dirty: boolean) => void) {
  const query = usePlanPackageLocationStepQuery({ variables: { id, versionId } });
  const [mutate] = useBulkSaveRplsMutation();

  const fs = useFormState<FormState, PlanPackageLocationStepQueryResult["data"]>({
    config: formConfig,
    init: {
      query,
      map: (data) => ({ readyPlanLocations: data.planPackage.roomLocations }),
      onlyOnce: true,
    },
  });

  useComputed(() => setStepDirty(fs.dirty), [fs]);

  const groupedRpls = useComputed<GroupedData[]>(() => {
    return fs.readyPlanLocations.rows
      .sortBy((rpl) => rpl.roomNumber.value ?? fail(`Room without a number ${JSON.stringify(rpl.value)}`))
      .groupByObject((rpl) => rpl.value.level ?? fail(`Found a room location without a level ${rpl.id.value}`))
      .sortBy(([level]) => level.name); // sorts Level 1 group above Level 2
  }, [fs]);

  /**
   * Detect duplicates. We can't autofix these because given [OFFC106, HLWY106, KTCH107], the user may REALLY want KTCH107 and
   * we can't go bumping around that number. So the best we can do is flag the conflict and let the user handle it, expecting
   * backend rules will throw if any actual duplicates make it through.
   */
  useEffect(() => {
    groupedRpls.forEach(([level, rpls]) => {
      const [dupes, uniques] = rpls
        .groupByObject((rpl) => rpl.roomNumber.value!)
        .map(([roomNumber, rpls]) => rpls)
        .partition((rpls) => rpls.length > 1);

      dupes.flat().forEach((rpl) => rpl.set({ hasDupes: true }));
      uniques.flat().forEach((rpl) => rpl.set({ hasDupes: false }));
    });
  }, [groupedRpls]);

  return {
    loading: query.loading,
    groupedRpls,
    hasDupes: useComputed(
      // deleted rows are all set to 0 so ignore those "dupes"
      () => fs.value.readyPlanLocations?.some((rpl) => rpl.roomNumber && rpl.hasDupes),
      [fs],
    ),
    reorderLevel: (levelId: string, rplIdsNewOrder: string[]) => {
      const levelRooms = fs.readyPlanLocations.rows
        .filter((rpl) => rpl.level?.value!.id === levelId)
        .sortBy((rpl) => rpl.roomNumber.value!);
      const currentNumbers = levelRooms.map((rpl) => rpl.roomNumber.value);
      rplIdsNewOrder
        .map((rplId) => levelRooms.find((rpl) => rpl.id.value === rplId) ?? fail(`Could not find rpl with id ${rplId}`))
        .forEach((rpl, ix) => rpl.roomNumber.set(currentNumbers[ix]));
    },
    markDeleted: (rplId: string) => {
      const row = fs.readyPlanLocations.rows.find((rpl) => rpl.id.value === rplId);
      if (!row) fail(`Could not find rpl with id ${rplId}`);
      // Set roomNumber to 0 to avoid dupes. actually delete fake/new rows.
      c.isRealId(row.id.value) ? row.set({ delete: true, roomNumber: 0 }) : fs.readyPlanLocations.remove(row.value);
    },
    newRoomLocation: (levelId: string) => {
      fs.readyPlanLocations.add({
        id: c.next(),
        roomNumber:
          fs.readyPlanLocations.value.filter((rpl) => rpl.level?.id === levelId).max((rpl) => rpl.roomNumber!) + 1,
        location: undefined!,
        level: fs.readyPlanLocations.value.find((rpl) => rpl.level?.id === levelId)!.level,
      });
    },
    onSave: async () => {
      // The existing cache/page-data will technically be wrong now (Created entities will get IDs that didn't merge) but since
      // this is a `Save & Exit` and a `Continue,` the page will disappear so we shouldn't have to deal with that. If we
      // do then a full refetch will be necesasry.
      return void mutate({
        variables: {
          inputs: fs.readyPlanLocations.rows
            .filter((rpl) => rpl.dirty)
            .map((rpl) => rpl.value)
            .map<SaveReadyPlanLocationInput>((rpl) => ({
              // create (if fake id) or update (if actual id)
              ...(c.isRealId(rpl.id)
                ? { id: rpl.id, delete: rpl.delete } // update/delete
                : { roomTypeId: rpl.roomType?.id, readyPlanId: id, levelId: rpl.level?.id }), // create
              roomNumber: rpl.roomNumber ?? fail("Room number is required"),
            })),
        },
      });
    },
  };
}

const c = new (class NewIdCounter {
  count = 0;
  next() {
    return String(this.count++);
  }
  /** IDs that came from the backend will be `rpl:123` */
  isRealId = (val: string) => val.includes(":");
})();

const formConfig: ObjectConfig<FormState> = {
  readyPlanLocations: {
    type: "list",
    config: {
      // from the GQL Type
      id: { type: "value" },
      level: { type: "value" },
      location: { type: "value" }, // passthrough type
      roomNumber: { type: "value" },
      roomType: { type: "value" },
      // Adhoc types used locally
      delete: { type: "value" },
      hasDupes: { type: "value" },
    },
  },
};
