import { Dispatch, SetStateAction, createContext, useContext, useRef } from "react";
import {
  ItivOrderField,
  ProductOfferingScopeMeta_ProductOfferingFragment,
  TakeoffLineItemFilter,
} from "src/generated/graphql-types";
import useZodQueryString from "src/hooks/useZodQueryString";
import { isItivOrderField } from "src/routes/libraries/plan-package/takeoffs/utils";
import { z } from "zod";
import { StoreApi, createStore, useStore } from "zustand";

export type ProductOfferingScopeState = {
  productOffering: ProductOfferingScopeMeta_ProductOfferingFragment;
  filter: TakeoffLineItemFilter;
  setFilter: Dispatch<SetStateAction<TakeoffLineItemFilter>>;
  setItemTableSearch: (search: string) => void;
  itemTableGroupBy: ItivOrderField;
  setItemTableGroupBy: (groupBy: ItivOrderField) => void;
  itemTableSortBy: ItivOrderField;
  setItemTableSortBy: (sortBy: ItivOrderField) => void;
  refetchFilters?: () => void;
};

type ProductOfferingStoreProps = {
  productOffering: ProductOfferingScopeMeta_ProductOfferingFragment;
  initialGroupBy?: string;
  initialSortBy?: string;
  initialFilter?: string;
  setQs?: Function;
};

export type ProductOfferingScopeProviderProps = React.PropsWithChildren<{
  productOffering: ProductOfferingScopeMeta_ProductOfferingFragment;
}>;

export const ProductOfferingScopeContext = createContext<StoreApi<ProductOfferingScopeState> | null>(null);
// Since our offering scope page might have large data sets and we don't want to re-render the entire page when a value changes,
// Abstract the store into 2 hooks in order to only get reactive properties (which may cause unnecessary re-renders) when needed

// hook that gets the latest state without needing reactivity
export function useProductOfferingContext() {
  const store = useContext(ProductOfferingScopeContext);
  if (!store) {
    throw new Error("Missing ProductOfferingProvider");
  }
  return store;
}

// hook that gets reactive state properties
export function useProductOfferingStore<T>(selector: (state: ProductOfferingScopeState) => T) {
  const store = useContext(ProductOfferingScopeContext);
  if (!store) {
    throw new Error("Missing ProductOfferingProvider");
  }
  return useStore(store, selector);
}

// We create a store to sync the state and query string values changed based on the filter, groupBy, sortBy and
// side navigation subFilters for the productOfferingScope page
// TODO: SC-53220 side navigation subFilters
export function ProductOfferingContextProvider({ productOffering, children }: ProductOfferingScopeProviderProps) {
  const storeRef = useRef<StoreApi<ProductOfferingScopeState>>();
  // Query string handling to persist the groupBy, sortBy, and filter values
  const [{ groupBy: initialGroupBy, sortBy: initialSortBy, filter: initialFilter }, setQs] =
    useZodQueryString(offeringQuerySchema);

  if (!storeRef.current || storeRef.current.getState().productOffering !== productOffering) {
    storeRef.current = createProductOfferingStore({
      initialGroupBy,
      initialSortBy,
      initialFilter,
      setQs,
      productOffering,
    });
  }
  return (
    <ProductOfferingScopeContext.Provider value={storeRef.current}>{children}</ProductOfferingScopeContext.Provider>
  );
}

export function createProductOfferingStore(props: ProductOfferingStoreProps) {
  const { initialGroupBy, initialSortBy, initialFilter, setQs, productOffering } = props;

  const store = createStore<ProductOfferingScopeState>((set, get) => ({
    // Initial Values
    filter: initialFilter ? JSON.parse(initialFilter) : {},
    itemTableGroupBy: initialGroupBy && isItivOrderField(initialGroupBy) ? initialGroupBy : ItivOrderField.CostCode,
    itemTableSortBy: initialSortBy && isItivOrderField(initialSortBy) ? initialSortBy : ItivOrderField.Item,
    productOffering,

    setFilter: (maybeFunction: Function | TakeoffLineItemFilter) =>
      set((state) => {
        const filterValue = typeof maybeFunction === "function" ? maybeFunction(state.filter) : maybeFunction;
        setQs && setQs({ filter: JSON.stringify(filterValue) });
        return {
          filter: filterValue,
        };
      }),

    setItemTableGroupBy: (groupBy: ItivOrderField) => {
      setQs && setQs({ groupBy, sortBy: get().itemTableSortBy });
      set({ itemTableGroupBy: groupBy });
    },

    setItemTableSortBy: (sortBy: ItivOrderField) => {
      setQs && setQs({ sortBy, groupBy: get().itemTableGroupBy });
      set({ itemTableSortBy: sortBy });
    },

    setItemTableSearch: (search: string) =>
      set((state) => {
        // Remove search and don't set a search value it is empty - avoids unnecessary API requests when going from `undefined` to ""
        const { search: oldSearchValue, ...otherFilters } = state.filter;
        return { filter: { ...otherFilters, ...(search ? { search } : {}) } };
      }),
  }));
  return store;
}

const offeringQuerySchema = z.object({
  groupBy: z.coerce.string().default(ItivOrderField.CostCode),
  sortBy: z.coerce.string().default(ItivOrderField.Item),
  filter: z.coerce.string().default("{}"),
});
