import {
  BoundNumberField,
  BoundSelectField,
  BoundTextAreaField,
  Css,
  Icon,
  IconKey,
  Palette,
  Tooltip,
} from "@homebound/beam";
import { FieldState, ObjectState } from "@homebound/form-state";
import { snakeCase } from "change-case";
import { ReactNode } from "react";
import { InputMaybe } from "src/generated/graphql-types";
import { isDefined } from "src/utils";
import { PlanDetailsTooltip } from "./PlanDetailsTooltip";
import { AddOptionsMetadata, AddProgramDetailsForm, OptionsEnumNames } from "./utils";

type OptionDetailCardProps = {
  title: string;
  icon?: string;
  children: ReactNode;
  width: number;
  alignIcon?: boolean;
  tooltip?: string;
};

export function OptionDetailCard({ title, icon, children, width, alignIcon = false, tooltip }: OptionDetailCardProps) {
  return (
    <div css={Css.wPx(width).p3.bgWhite.br8.bshBasic.$} data-testid={snakeCase(`section_${title.toLowerCase()}`)}>
      <div css={Css.df.aic.$}>
        {icon && (
          <div css={Css.df.aic.jcc.bgGray200.w4.h4.borderRadius("50%").mr2.$}>
            <Icon icon={icon as IconKey} inc={2.5} xss={Css.if(alignIcon).mt2.$} />
          </div>
        )}
        <h4 css={Css.lgSb.mr1.$} data-testid="card_title">
          {title}
        </h4>
        {tooltip && (
          <Tooltip placement="right" title={tooltip}>
            <Icon icon="infoCircle" color={Palette.Gray700} inc={2} />
          </Tooltip>
        )}
      </div>
      <div css={Css.gray700.pt2.$}>{children}</div>
    </div>
  );
}

type PlanDetailsCardProps = {
  title: string;
  icon?: string;
  headerTooltips?: boolean;
  lines: PlanDetailsCardLine[];
  disabled: ReactNode | undefined;
};

export type PlanDetailsCardLine = {
  label: string;
  base: FieldState<InputMaybe<number>> | FieldState<InputMaybe<string>>;
  min?: FieldState<InputMaybe<number>>;
  max?: FieldState<InputMaybe<number>>;
  minMaxComputed?: boolean;
  metadata?: AddOptionsMetadata;
  enumName?: OptionsEnumNames;
  type?: "textArea"; // explicit field to define different line types
};

export function PlanDetailsCard({ title, icon, headerTooltips = false, lines, disabled }: PlanDetailsCardProps) {
  const [enumLines, numberLines] = lines.partition(
    ({ enumName, metadata }) => isDefined(enumName) && isDefined(metadata),
  );

  function Line({
    line: { label, base, min, max, minMaxComputed, metadata, enumName, type },
  }: {
    line: PlanDetailsCardLine;
  }) {
    return (
      <div css={Css.df.gap3.pb2.$}>
        <div css={Css.df.f3.sm.gap1.$}>
          <p data-test-id={label}>{label}</p>
        </div>
        {type === "textArea" && (
          <BoundTextAreaField
            compact
            labelStyle="hidden"
            field={base as FieldState<InputMaybe<string>>}
            disabled={disabled}
          />
        )}
        {enumName && metadata ? (
          <div css={Css.df.wPx(228).$}>
            <BoundSelectField
              required
              compact
              labelStyle="hidden"
              field={base as FieldState<InputMaybe<string>>}
              options={
                metadata.enumDetails[enumName]?.map((enumValue) => ({
                  id: enumValue.code,
                  name: enumValue.name,
                })) ?? []
              }
              disabled={disabled}
            />
          </div>
        ) : (
          min &&
          max && (
            <>
              <div css={Css.df.wPx(60).$}>
                <BoundNumberField
                  compact
                  label="Base"
                  labelStyle="hidden"
                  field={base as FieldState<InputMaybe<number>>}
                  data-testid={`${snakeCase(label)}_base`}
                  disabled={disabled}
                />
              </div>
              <div css={Css.df.wPx(60).$}>
                <BoundNumberField
                  compact
                  label="Min"
                  labelStyle="hidden"
                  field={min}
                  readOnly={minMaxComputed}
                  data-testid={`${snakeCase(label)}_min`}
                />
              </div>
              <div css={Css.df.wPx(60).$}>
                <BoundNumberField
                  compact
                  label="Max"
                  labelStyle="hidden"
                  field={max}
                  readOnly={minMaxComputed}
                  data-testid={`${snakeCase(label)}_max`}
                />
              </div>
            </>
          )
        )}
      </div>
    );
  }

  return (
    <OptionDetailCard title={title} icon={icon} width={600}>
      {enumLines.map((line) => (
        <Line key={line.label} line={line} />
      ))}
      <div css={Css.df.gap3.pb2.$}>
        <div css={Css.df.f3.$}></div>
        <div css={Css.df.wPx(60).gap1.$}>
          <p>Base</p>
          {headerTooltips && <PlanDetailsTooltip title={baseTextTooltip} />}
        </div>
        <div css={Css.df.wPx(60).gap1.$}>
          <p>Min</p> {headerTooltips && <PlanDetailsTooltip title={minTextTooltip} />}
        </div>
        <div css={Css.df.wPx(60).gap1.$}>
          <p>Max</p> {headerTooltips && <PlanDetailsTooltip title={maxTextTooltip} />}
        </div>
      </div>
      {numberLines.map((line) => (
        <Line key={line.label} line={line} />
      ))}
    </OptionDetailCard>
  );
}

export function BasementCard(props: {
  title: string;
  icon?: string;
  formState: ObjectState<AddProgramDetailsForm>;
  metadata: AddOptionsMetadata;
  disabled: ReactNode | undefined;
}) {
  const { title, icon, formState, metadata, disabled } = props;
  return (
    <OptionDetailCard title={title} icon={icon} width={600}>
      <div css={Css.df.gap3.py3.$}>
        <div css={Css.df.f3.sm.gap1.$}>
          <p data-test-id={"Basement"}>Basement</p>
        </div>
        <div css={Css.df.wPx(228).$}>
          <BoundSelectField
            required
            compact
            label="Base Type"
            labelStyle="inline"
            field={formState.programData.basementConfig}
            options={
              metadata?.enumDetails.programDataBasementConfig?.map((enumValue) => ({
                id: enumValue.code,
                name: enumValue.name,
              })) ?? []
            }
            disabled={disabled}
          />
        </div>
      </div>
    </OptionDetailCard>
  );
}

const baseTextTooltip = "The base amount is the main value for this plan without any options";
const minTextTooltip = "The minimum number is the lowest value this field can have with any combination of options";
const maxTextTooltip = "The maximum number is the highest value this field can have with any combination of options";
