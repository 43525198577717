import { Css, dateFilter, FilterDefs, Filters, FullBleed, ScrollableContent, useTestIds } from "@homebound/beam";
import { useMemo } from "react";

import { subWeeks } from "date-fns";
import { PlanPackagePageMetadataQuery } from "src/generated/graphql-types";
import {
  UserEventFilterWithDateFilterValue,
  UserEventsTable,
} from "src/routes/my-blueprint/activity-feed/UserEventsTable";
import { useStorageFilter } from "src/routes/projects/budget/components/useStorageFilter";
import { DateFilterOperation, dateFilterOperations } from "src/utils";
import { DateOnly } from "src/utils/dates";

export function ActivityTab({ metadata }: { metadata: PlanPackagePageMetadataQuery }) {
  const tid = useTestIds({}, "activity");
  const planPackage = metadata.planPackage;
  const optionIds = planPackage.options.map((rpo) => rpo.id);

  const filterDefs: FilterDefs<UserEventFilterWithDateFilterValue> = useMemo(() => {
    return {
      createdAt: dateFilter({
        label: "Created at",
        operations: dateFilterOperations,
        getOperationLabel: ({ label }) => label,
        getOperationValue: ({ value }) => value,
        defaultValue: { op: DateFilterOperation.After, value: new DateOnly(subWeeks(new Date(), 1)) },
      }),
    };
  }, []);

  const { setFilter, filter } = useStorageFilter<UserEventFilterWithDateFilterValue>({
    storageKey: "planActivityTabFilter",
    filterDefs,
  });

  return (
    <>
      <ScrollableContent>
        <FullBleed>
          <div css={Css.bgGray100.$}>
            <div css={Css.p3.bgGray100.mh100.pxPx(60).$}>
              <div css={Css.pb3.$} {...tid.elevation}>
                <h1 css={Css.xlBd.gray900.pb3.$} {...tid.title}>
                  Activity
                </h1>
                <div css={Css.p3.tac.bgWhite.$}>
                  <div css={Css.baseSb.gray900.pb3.bb.bcGray200.mb1.tar.df.jcfe.$} {...tid.filter}>
                    <Filters<UserEventFilterWithDateFilterValue>
                      filter={filter}
                      onChange={setFilter}
                      filterDefs={filterDefs}
                    />
                  </div>
                  {filter.createdAt && (
                    <UserEventsTable
                      currentUser={{ id: metadata.currentUser?.id }}
                      filter={{
                        createdAt: { op: filter.createdAt.op, value: filter.createdAt.value },
                        parent: [
                          planPackage?.id,
                          ...optionIds,
                          ...planPackage?.aggregateVersions.map((rpav) => rpav.id),
                        ].compact(),
                      }}
                      // is `rpav` even a valid UserEventParent?
                      queryFilter={{ parentType: ["rp", "rpo", "rpopdm", "rpav"] }}
                      embedded
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </FullBleed>
      </ScrollableContent>
    </>
  );
}
