import {
  Accordion,
  BoundNumberField,
  BoundSelectField,
  BoundTextAreaField,
  BoundTextField,
  Button,
  Css,
  FormLines,
  px,
  StaticField,
  useModal,
  useSnackbar,
  useSuperDrawer,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, required, useFormState } from "@homebound/form-state";
import { ReactNode } from "react";
import { CommentFeed, emptyCellDash } from "src/components";
import { BidItemBoundSelectField } from "src/components/autoPopulateSelects/BidItemBoundSelectField";
import { UnitsOfMeasureBoundSelectField } from "src/components/autoPopulateSelects/UnitsOfMeasureBoundSelectField";
import {
  CostCode,
  CostDivision,
  CostType,
  Location,
  OverviewTab_ProjectItemFragment,
  OverviewTabQuery,
  OverviewTabScheduleTasksQuery,
  ProjectItem,
  ProjectItemInput,
  ScheduleTask,
  Stage,
  TaskStatus,
  useOverviewTabDeleteProjectItemMutation,
  useOverviewTabQuery,
  useOverviewTabSaveProjectItemMutation,
  useOverviewTabScheduleTasksQuery,
} from "src/generated/graphql-types";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { OverviewTabProductDetailsPanel } from "src/routes/projects/selections/OverviewTabProductDetailsPanel";
import { isDefined, queryResult } from "src/utils";

export type OverviewTabProps = {
  projectId: string;
  projectItemId: string;
  stage: Stage;
};

/** S&S Selection SuperDrawer Overview Tab */
export function OverviewTab(props: OverviewTabProps) {
  const query = useOverviewTabQuery({ variables: { projectItemId: props.projectItemId } });
  return queryResult(query, {
    data: (data) => <OverviewTabForm {...props} {...data} />,
  });
}

export type OverviewTabFormProps = {
  projectId: string;
  stage: Stage;
  projectItem: OverviewTab_ProjectItemFragment;
  locations: OverviewTabQuery["locations"];
};

export function OverviewTabForm(props: OverviewTabFormProps) {
  const { triggerNotice } = useSnackbar();
  const { projectId, stage, projectItem, locations } = props;
  const product = projectItem.homeownerSelection?.selectedOption?.product;

  const { data: schedule } = useOverviewTabScheduleTasksQuery({
    variables: {
      filter: {
        project: [projectId],
        stage: [stage],
        status: [
          TaskStatus.NotStarted,
          TaskStatus.InProgress,
          TaskStatus.Complete,
          TaskStatus.OnHold,
          TaskStatus.Delayed,
        ],
      },
    },
    fetchPolicy: "cache-first",
  });

  const [save] = useOverviewTabSaveProjectItemMutation();
  const [deleteProjectItem] = useOverviewTabDeleteProjectItemMutation();

  const onChange = async (projectItem: ProjectItemInput) => {
    const result = await save({ variables: { projectItem } });
    const userErrors = result.data?.saveProjectItems?.userErrors;
    const errorText = userErrors && userErrors.map((e) => e.message).join(" ");
    if (errorText) {
      triggerNotice({ id: "snackbarMessage", message: errorText, icon: "error" });
    }
  };

  const readOnly = !projectItem.canEditScope.allowed;
  const formState = useFormState({
    config: formConfig,
    init: { input: projectItem, map: mapProjectItemToFormState },
    autoSave: async ({ changedValue }) => {
      const { markupPercent, ...otherFields } = changedValue;
      await onChange({
        ...(isDefined(markupPercent) && { markupBasisPoints: markupPercent * 100 }),
        ...otherFields,
      });
    },
    readOnly,
  });

  return (
    <div css={Css.df.jcsb.gap2.$}>
      <div css={Css.fb(px(480)).$}>
        <OverviewTabProductDetailsPanel formState={formState} product={product} projectItem={projectItem} />
        {projectItem.isSelection ? (
          <Accordion title="Notes" bottomBorder>
            <NotesSection formState={formState} />
          </Accordion>
        ) : (
          <fieldset css={Css.df.fdc.gap2.$} data-testid="notesFieldset">
            <legend css={Css.baseMd.gray900.mb2.$}>Notes</legend>
            <NotesSection formState={formState} />
          </fieldset>
        )}
      </div>
      <div css={Css.fg1.maxw(px(360)).df.gap5.fdc.$}>
        <DeleteProjectItemButton
          projectItem={projectItem}
          deleteProjectItem={async () => {
            await deleteProjectItem({
              variables: { projectItemId: projectItem.id },
              refetchQueries: ["SpecsAndSelectionItems"],
            });
          }}
        />
        <OverviewTabFormFields
          locations={locations}
          projectItem={projectItem}
          scheduleTasks={schedule?.scheduleTasks ?? []}
          formState={formState}
        />
        <Accordion title="Comments" bottomBorder>
          <CommentFeed commentable={projectItem} />
        </Accordion>
      </div>
    </div>
  );
}

type DeleteProjectItemButtonProps = {
  projectItem: OverviewTab_ProjectItemFragment;
  deleteProjectItem: (projectItemId: string) => Promise<void>;
};

function DeleteProjectItemButton({ projectItem, deleteProjectItem }: DeleteProjectItemButtonProps) {
  const { openModal } = useModal();
  const { triggerNotice } = useSnackbar();
  const { closeDrawer } = useSuperDrawer();
  const { hasSignedContract } = projectItem.projectStage;
  return (
    <div css={Css.tar.mb2.$}>
      <Button
        disabled={hasSignedContract}
        tooltip={hasSignedContract && "Project stage has a signed contract"}
        variant="secondary"
        label="Delete item"
        onClick={() =>
          openModal({
            content: (
              <ConfirmationModal
                title={`Delete ${projectItem.displayName}`}
                label="Delete"
                confirmationMessage="Are you sure you want to delete this project item? This action cannot be undone."
                onConfirmAction={async () => {
                  await deleteProjectItem(projectItem.id);
                  triggerNotice({
                    icon: "success",
                    message: `The Project item ${projectItem.id} has been deleted`,
                  });
                  closeDrawer();
                }}
              />
            ),
          })
        }
      />
    </div>
  );
}

type OverviewTabFormFieldsProps = {
  projectItem: OverviewTabQuery["projectItem"];
  locations: OverviewTabQuery["locations"];
  scheduleTasks: OverviewTabScheduleTasksQuery["scheduleTasks"];
  formState: ObjectState<OverviewTabFormValue>;
};

function OverviewTabFormFields({ projectItem, locations, scheduleTasks, formState }: OverviewTabFormFieldsProps) {
  const {
    project: { canEditPrice },
    canEditScope,
    canEditCost,
    canEditName,
    bidItem,
  } = projectItem;
  const undefinedOption = { id: undefined as any, name: emptyCellDash };

  return (
    <Form xss={Css.fg1.maxw(px(360)).df.gap5.fdc.$}>
      <fieldset css={Css.df.fdc.gap2.$}>
        <FormLines labelStyle="left" compact width="full">
          <div css={Css.py0.$}>
            <StaticField label="Cost Code" value={formState.costCode.value} />
            <StaticField label="Cost Type" value={formState.costType.value} />
            {bidItem?.name && <StaticField label="Item Description" value={bidItem?.name} />}
          </div>
          <BidItemBoundSelectField
            value={bidItem}
            field={formState.bidItemId}
            filter={{ projectItemId: projectItem.id }}
          />
          <BoundTextField field={formState.name} label="Project Item Name" readOnly={!canEditName.allowed} />
          {projectItem.unitOfMeasure?.useQuantity === false || projectItem.quantity === 0 ? (
            <StaticField label="Unit Cost" value={"N/A"} />
          ) : (
            <BoundNumberField
              type="cents"
              field={formState.unitCostInCents}
              label="Unit Cost"
              readOnly={!canEditScope.allowed || projectItem.isSelection}
            />
          )}
          <UnitsOfMeasureBoundSelectField field={formState.unitOfMeasureId} label="UoM" />
          {projectItem.unitOfMeasure?.useQuantity === false ? (
            <StaticField label="Quantity" value={"N/A"} />
          ) : (
            <BoundNumberField field={formState.quantity} label="Quantity" />
          )}
          <BoundNumberField
            type="cents"
            field={formState.totalCostInCents}
            label="Total Cost"
            readOnly={!canEditCost.allowed}
          />
          <BoundNumberField
            type="percent"
            field={formState.markupPercent}
            label="Markup %"
            readOnly={!canEditScope.allowed || projectItem.isSelection}
            disabled={!canEditPrice.allowed}
          />
          <BoundNumberField
            type="cents"
            field={formState.totalPriceInCents}
            label="HO Price"
            readOnly={!canEditScope.allowed || projectItem.isSelection}
            disabled={!canEditPrice.allowed}
          />
          <BoundSelectField field={formState.locationId} options={locations} readOnly={!canEditScope.allowed} />
          <BoundSelectField
            label="Schedule Task"
            field={formState.taskId}
            options={[undefinedOption, ...scheduleTasks]}
          />
        </FormLines>
      </fieldset>
    </Form>
  );
}

export type OverviewTabFormValue = {
  id: ProjectItem["id"];
  division: CostDivision["name"];
  costCode: CostCode["name"];
  costType: CostType;
  name: ProjectItem["name"] | null | undefined;
  locationId?: Location["id"] | null | undefined;
  unitCostInCents: number | null | undefined;
  totalCostInCents: number | null | undefined;
  totalPriceInCents: number | null | undefined;
  markupPercent: number | null | undefined;
  quantity: number | null | undefined;
  unitOfMeasureId: string | null | undefined;
  specifications: ProjectItem["specifications"];
  tradePartnerNote: ProjectItem["tradePartnerNote"];
  internalNote: ProjectItem["internalNote"];
  tradeCategoryName: string | null | undefined;
  taskId?: ScheduleTask["id"] | null | undefined;
  bidItemId: string | null | undefined;
};

export const formConfig: ObjectConfig<OverviewTabFormValue> = {
  id: { type: "value" },
  division: { type: "value", rules: [required], readOnly: true },
  costCode: { type: "value", rules: [required], readOnly: true },
  costType: { type: "value", rules: [required], readOnly: true },
  name: { type: "value", rules: [required] },
  locationId: { type: "value" },
  unitCostInCents: { type: "value" },
  totalCostInCents: { type: "value" },
  totalPriceInCents: { type: "value" },
  markupPercent: { type: "value" },
  quantity: { type: "value" },
  unitOfMeasureId: { type: "value" },
  specifications: { type: "value" },
  tradePartnerNote: { type: "value" },
  internalNote: { type: "value" },
  tradeCategoryName: { type: "value" },
  taskId: { type: "value" },
  bidItemId: { type: "value" },
};

function mapProjectItemToFormState(projectItem: OverviewTabFormFieldsProps["projectItem"]): OverviewTabFormValue {
  return {
    id: projectItem.id,
    division: projectItem.item.costCode.division.displayName,
    costCode: projectItem.item.costCode.displayName,
    costType: projectItem.costType,
    name: projectItem.name,
    locationId: projectItem.location?.id,
    unitOfMeasureId: projectItem.unitOfMeasure.id,
    quantity: projectItem.quantity,
    totalCostInCents: projectItem.totalCostInCents,
    totalPriceInCents: projectItem.totalPriceInCents,
    unitCostInCents: projectItem.unitCostInCents,
    markupPercent: (projectItem.markupBasisPoints ?? 0) / 100,
    specifications: projectItem.specifications,
    tradePartnerNote: projectItem.tradePartnerNote,
    internalNote: projectItem.internalNote,
    tradeCategoryName: projectItem.item.tradeCategory?.name,
    taskId: projectItem.task?.id,
    bidItemId: projectItem.bidItem?.id,
  };
}

/** Semantic observable form */
function Form({ children, xss }: { children: ReactNode; xss: Record<string, any> }) {
  return <form css={xss}>{children}</form>;
}

export function NotesSection({ formState }: { formState: ObjectState<OverviewTabFormValue> }) {
  return (
    <>
      <BoundTextAreaField label="Specifications (visible to everyone)" field={formState.specifications} />
      <BoundTextAreaField label="Trade Notes (only visible to trades)" field={formState.tradePartnerNote} />
      <BoundTextAreaField label="Internal Note (only visible to Homebound employees)" field={formState.internalNote} />
    </>
  );
}
