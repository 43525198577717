import { Css, ScrollableParent } from "@homebound/beam";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { NotFound } from "src/routes/NotFound";
import { GlobalOptionsPage } from "src/routes/libraries/global-options/GlobalOptionsPage";
import { PlanPackagePage } from "src/routes/libraries/plan-package/details/PlanPackagePage";
import { ProductOfferingConfigsPage } from "src/routes/libraries/product-offering-configs/ProductOfferingConfigsPage";
import { ScheduleTemplateDetailPage } from "src/routes/libraries/schedules/ScheduleTemplateDetail";
import { ScheduleTemplatesPage } from "src/routes/libraries/schedules/ScheduleTemplatesPage";
import { ProductOfferingScopePage } from "../developments/product-offerings/ProductOfferingScopePage";
import {
  bidItemCreatePath,
  bidItemDetailsPath,
  catalogTaskFolderPath,
  catalogTaskPath,
  catalogTasksPath,
  constraintItemsCatalogPath,
  designCatalogPath,
  designCatalogsPath,
  designPackageCompareModePath,
  designPackageFinishSchedulePath,
  designPackageVersionHistoryPath,
  globalOptionsPath,
  globalProductOfferingConfigsPath,
  globalProductOfferingsPath,
  installTasksPath,
  itemCatalogPath,
  itemTemplatePath,
  itemTemplatesPath,
  librariesPath,
  materialCostPath,
  materialCreatePath,
  materialDetailsPath,
  materialsCatalogPath,
  milestoneCatalogPath,
  milestonesCatalogPath,
  planPackageEditPath,
  planPackagePath,
  planPackageTakeoffsPath,
  planPackageVersionHistoryPath,
  planPackagesPath,
  productCatalogPath,
  productCreatePath,
  productDetailsPath,
  productOfferingConfigEditPath,
  productOfferingConfigPath,
  productOfferingCreatePath,
  productOfferingEditPath,
  productOfferingScopePath,
  scheduleTemplatePath,
  scheduleTemplatesPath,
} from "../routesDef";
import { ItemTemplatePage } from "../settings/itemTemplates/ItemTemplatePage";
import { ItemTemplatesPage } from "../settings/itemTemplates/ItemTemplatesPage";
import { DesignCatalogsPage } from "./design-catalog/DesignCatalogsPage";
import { DesignPackageCompareModePage } from "./design-catalog/compare-mode/DesignPackageCompareMode";
import { DesignPackageConfiguratorLayout } from "./design-catalog/design-package-configurator/DesignPackageConfiguratorLayout";
import { FinishSchedulePage } from "./design-catalog/finish-schedules/FinishSchedulePage";
import { DesignPackageVersionHistoryPage } from "./design-catalog/version-history/DesignPackageVersionHistoryPage";
import { BidItemPage } from "./item-catalog/BidItemPage";
import { ItemCatalogPage } from "./item-catalog/ItemCatalogPage";
import { MaterialCatalogPage } from "./material-catalog/MaterialCatalogPage";
import { MaterialCostPage } from "./material-catalog/MaterialCostPage";
import { MaterialPage } from "./material-catalog/MaterialPage";
import { InstallTasksPage } from "./material-catalog/install-tasks/InstallTasksPage";
import { MilestoneCatalogForm } from "./milestone-catalog/MilestoneCatalogForm";
import { MilestoneCatalogPage } from "./milestone-catalog/MilestoneCatalogPage";
import { PlanPackagesPage } from "./plan-package/list/PlanPackagesPage";
import { CreateOrUpdatePlanPackageWizard } from "./plan-package/stepper";
import { PlanPackageTakeoffPage } from "./plan-package/takeoffs/PlanPackageTakeoffPage";
import { PlanPackageVersionHistoryPage } from "./plan-package/version-history/PlanPackageVersionHistoryPage";
import { ProductCatalogPage } from "./product-catalog/ProductCatalogPage";
import { ProductPage } from "./product-catalog/ProductPage";
import { AddProductOfferingConfigPage } from "./product-offering-configs/AddProductOfferingConfigPage";
import { GlobalProductOfferings } from "./product-offerings/GlobalProductOfferings";
import { AddProductOfferingPage } from "./product-offerings/add-product-offering/AddProductOfferingPage";
import { EditProductOfferingPage } from "./product-offerings/edit-product-offering/EditProductOfferingPage";
import { ConstraintItemCatalogPage } from "./task-catalog/ConstraintItemCatalogPage";
import { TaskCatalogPage } from "./task-catalog/TaskCatalogPage";
import { TaskCatalogForm } from "./task-catalog/components/TaskCatalogForm";

export function LibrariesLayout() {
  const hasOwnLayout = useRouteMatch([planPackageTakeoffsPath, planPackageEditPath, productOfferingScopePath]);

  return hasOwnLayout ? (
    <LibraryRouter />
  ) : (
    <ScrollableParent xss={Css.fg1.px3.bgGray100.$}>
      <LibraryRouter />
    </ScrollableParent>
  );
}

function LibraryRouter() {
  return (
    <Switch>
      <Route path={globalOptionsPath} exact component={GlobalOptionsPage} />
      <Route path={productCatalogPath} exact component={ProductCatalogPage} />
      <Route path={productDetailsPath} component={ProductCatalogPage} />
      <Route path={materialCreatePath} exact component={MaterialPage} />
      <Route path={materialCostPath} component={MaterialCostPage} />
      <Route path={materialDetailsPath} exact component={MaterialCatalogPage} />
      <Route path={installTasksPath} component={InstallTasksPage} />
      <Route path={materialsCatalogPath} component={MaterialCatalogPage} />
      <Route path={productCreatePath} exact component={ProductPage} />
      <Route path={itemCatalogPath} exact component={ItemCatalogPage} />
      <Route path={bidItemCreatePath} exact component={BidItemPage} />
      <Route path={bidItemDetailsPath} exact component={ItemCatalogPage} />
      <Route path={itemTemplatePath} component={ItemTemplatePage} />
      <Route path={scheduleTemplatePath.scheduleTemplate} exact component={ScheduleTemplateDetailPage} />
      <Route path={scheduleTemplatesPath} component={ScheduleTemplatesPage} />
      <Route path={itemTemplatesPath} exact component={ItemTemplatesPage} />
      <Route path={catalogTasksPath} exact component={TaskCatalogPage} />
      <Route path={catalogTaskPath} exact component={TaskCatalogForm} />
      <Route path={constraintItemsCatalogPath} exact component={ConstraintItemCatalogPage} />
      <Route path={designCatalogsPath} exact component={DesignCatalogsPage} />
      <Route path={designPackageVersionHistoryPath} component={DesignPackageVersionHistoryPage} />
      <Route path={designPackageFinishSchedulePath} exact component={FinishSchedulePage} />
      <Route path={designPackageCompareModePath} exact component={DesignPackageCompareModePage} />
      <Route path={designCatalogPath} component={DesignPackageConfiguratorLayout} />
      <Route path={catalogTaskFolderPath} exact component={TaskCatalogPage} />
      <Route path={globalProductOfferingsPath} exact component={GlobalProductOfferings} />
      <Route path={productOfferingCreatePath} exact component={AddProductOfferingPage} />
      <Route path={productOfferingEditPath} exact component={EditProductOfferingPage} />
      <Route path={globalProductOfferingConfigsPath} exact component={ProductOfferingConfigsPage} />
      <Route path={productOfferingConfigPath} exact component={AddProductOfferingConfigPage} />
      <Route path={productOfferingConfigEditPath} exact component={AddProductOfferingConfigPage} />
      <Route path={productOfferingScopePath} exact component={ProductOfferingScopePage} />
      <Route path={planPackageVersionHistoryPath} exact component={PlanPackageVersionHistoryPage} />
      <Route path={planPackageTakeoffsPath} component={PlanPackageTakeoffPage} />
      <Route path={planPackagePath} component={PlanPackagePage} />
      <Route path={planPackagesPath} exact component={PlanPackagesPage} />
      <Route path={planPackageEditPath} exact component={CreateOrUpdatePlanPackageWizard} />
      <Route path={milestonesCatalogPath} exact component={MilestoneCatalogPage} />
      <Route path={milestoneCatalogPath} exact component={MilestoneCatalogForm} />
      <Route path={librariesPath} render={() => <Redirect to={globalOptionsPath} />} />
      {/* Otherwise, not found */}
      <Route component={NotFound} />
    </Switch>
  );
}
