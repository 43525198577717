import { Css } from "@homebound/beam";
import { GeneralTlivChangesFragment } from "src/generated/graphql-types";
import { renderAccordion } from "src/routes/libraries/versioning/VersionHistoryUtils";
import { count } from "src/utils";

type ItemSlotUpdatesProps = {
  tliVersions: GeneralTlivChangesFragment[];
};

export function ProductUpdates(props: ItemSlotUpdatesProps) {
  const { tliVersions } = props;
  const productTlivs = tliVersions.filter((tliv) => !!tliv.placeholder?.id);

  if (productTlivs.isEmpty) return null;

  return (
    <>
      {renderAccordion(
        `Selected ${count(productTlivs, "product")}`,
        <ul css={Css.m0.$}>
          {productTlivs.map((tliv) => (
            <li data-testid="productUpdate" key={tliv.id} css={Css.baseMd.$}>
              {tliv.location.effectiveLocation?.name}: {tliv.displayName}: {tliv.materialVariant?.displayName ?? ""}
            </li>
          ))}
        </ul>,
      )}
    </>
  );
}
