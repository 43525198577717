import { Button, Css, useComputed, useSnackbar, useTestIds } from "@homebound/beam";
import { useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useEffect, useMemo } from "react";
import { useHistory } from "react-router";
import { createPlanPackageEditUrl, createPlanPackageUrl } from "src/RouteUrls";
import { useNavigationCheck } from "src/components";
import { StepLayout } from "src/components/stepper/StepLayout";
import { NextStepButton } from "src/components/stepper/useStepperWizard/NextStepButton";
import { UploaderProvider } from "src/contexts/UploaderContext";
import {
  AssetInput,
  ReadyPlanAssetType,
  SaveReadyPlanOptionInput,
  useGlobalOptionsQuery,
  usePlanPackageDetailsStepQuery,
  useSavePlanPackageDetailsMutation,
} from "src/generated/graphql-types";
import { disableBasedOnPotentialOperation } from "src/routes/components/PotentialOperationsUtils";
import { PlanPackageEditorHeader } from "src/routes/libraries/plan-package/stepper/components/PlanPackageEditorHeader";
import { isNumber, queriesResult } from "src/utils";
import { ElevationForm } from "./components/ElevationForm";
import { PlanPackageDetailsCard } from "./components/PlanPackageDetailsCard";
import { planPackageElevationsConfig } from "./utils";

type ElevationsStepProps = {
  id: string;
  versionId: string;
  setStepDirty: (dirty: boolean) => void;
};

export function ElevationsStep({ id, versionId, setStepDirty }: ElevationsStepProps) {
  const { triggerNotice } = useSnackbar();
  const history = useHistory();
  const query = usePlanPackageDetailsStepQuery({ variables: { id, versionId }, skip: !id });
  const [savePP] = useSavePlanPackageDetailsMutation();
  const disabled = disableBasedOnPotentialOperation(query.data?.planPackage?.canEdit);

  // get global options for elevations
  const globalOptionsQuery = useGlobalOptionsQuery({ variables: { filter: { isElevation: true } } });

  const nameOptions = useMemo(() => {
    return (
      globalOptionsQuery.data?.globalOptions?.map((option) => {
        return {
          value: option?.id,
          label: option?.code + " - " + option?.name,
        };
      }) ?? []
    );
  }, [globalOptionsQuery.data]);

  const formState = useFormState({
    config: planPackageElevationsConfig,
    init: {
      query,
      map: ({ planPackage }) => ({
        readyPlanOptions: sortElevationsByActiveAndBase(
          planPackage?.options
            .filter((option) => option?.type.isElevation)
            .map((option) => ({
              id: option?.id,
              name: option?.name,
              displayName: option?.displayName,
              active: option?.active,
              globalOptionId: option?.globalOption.id,
              assets: option?.assets?.map((assets) => ({
                id: assets.id,
                asset: {
                  id: assets.asset.id,
                  previewUrl: assets.asset.previewUrl,
                },
                parentId: assets.parent?.id,
                type: assets.type.code as ReadyPlanAssetType,
              })),
              optionGroupId: option?.optionGroup?.id,
              readyPlanId: id,
              order: option?.order,
              default: option.order === 1, // true if it's the first elevation in the group
            })) ?? [],
        ),
      }),
    },
  });

  const isDirty = useComputed(() => formState.dirty, [formState.dirty]);
  useEffect(() => {
    setStepDirty(isDirty);
  }, [setStepDirty, isDirty]);
  const { useRegisterNavigationCheck } = useNavigationCheck();
  useRegisterNavigationCheck(() => !formState.dirty, [formState]);

  const { valid } = useComputed(() => ({ valid: formState.valid }), [formState.valid]);

  const tid = useTestIds({}, "elevationsStep");

  const onAddElevation = async () => {
    const newOptionInput = {
      readyPlanId: id,
      active: true,
      name: "",
      assets: [],
      order: (formState.readyPlanOptions.value.last?.order ?? 0) + 1,
    };

    // update local state
    formState.readyPlanOptions.add(newOptionInput);
  };

  const onSave = async () => {
    if (formState.dirty) {
      // save only elevations with dirty state
      const changes = formState.readyPlanOptions.changedValue;
      const response = await savePP({
        variables: {
          input: {
            id,
            // name is derived, cannot be saved
            options: changes.map(({ name, ...elevation }, i) => ({
              ...elevation,
              ...(elevation.assets && {
                assets: elevation.assets.map((asset) => ({
                  ...asset,
                  asset: {
                    ...asset.asset,
                    previewUrl: undefined,
                  },
                })),
              }),
              displayName: undefined,
            })),
          },
        },
      });
      formState.commitChanges();
      triggerNotice({ message: `Your Plan has been updated!` });
      if (response?.data && versionId !== response.data.savePlanPackage.planPackage.version.id) {
        const pp = response.data.savePlanPackage.planPackage;
        history.replace(createPlanPackageEditUrl(pp.id, pp.version.id));
      }
    }
  };

  return (
    <StepLayout
      header={<PlanPackageEditorHeader title="Add Elevations" />}
      body={queriesResult([query, globalOptionsQuery], {
        data: () => {
          return (
            <Observer>
              {() => (
                <UploaderProvider>
                  <div css={Css.py3.$}>
                    <div css={Css.mt2.df.fww.ais.gap2.jcc.$} {...tid.sections}>
                      <PlanPackageDetailsCard title="Elevations" width={731}>
                        {formState.readyPlanOptions.rows?.map((elevation, index) => {
                          return (
                            <ElevationForm
                              disabled={disabled}
                              key={elevation.value.id}
                              elevation={elevation}
                              allElevations={formState.readyPlanOptions.rows}
                              index={index}
                              nameOptions={nameOptions}
                              setElevations={(values) => formState.readyPlanOptions.set(values)}
                            />
                          );
                        })}
                        <Button
                          disabled={disabled}
                          icon="plus"
                          label="Add Elevation"
                          variant="tertiary"
                          onClick={onAddElevation}
                        />
                      </PlanPackageDetailsCard>
                    </div>
                    <NextStepButton
                      label="Continue"
                      disabled={!valid}
                      onClick={onSave}
                      onCloseReturnUrl={createPlanPackageUrl(id, versionId)}
                      exitButton={{
                        variant: "secondary",
                        label: "Save & Exit",
                        onClick: onSave,
                      }}
                    />
                  </div>
                </UploaderProvider>
              )}
            </Observer>
          );
        },
      })}
    />
  );
}

export type DefaultedReadyPlanOption = SaveReadyPlanOptionInput & {
  displayName?: string;
  assets: {
    id?: string | null | undefined;
    asset?: (AssetInput & { previewUrl?: string | null }) | null | undefined;
    parentId?: string | null | undefined;
    type: ReadyPlanAssetType;
  }[];
};

export const sortElevationsByActiveAndBase = (elevations: DefaultedReadyPlanOption[]) => {
  return elevations.sort(
    (a, b) =>
      (a.active && isNumber(a.order) ? a.order : Number.MAX_SAFE_INTEGER) -
      (b.active && isNumber(b.order) ? b.order : Number.MAX_SAFE_INTEGER),
  );
};
