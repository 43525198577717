import {
  BoundCheckboxField,
  BoundNumberField,
  BoundTextField,
  Button,
  Css,
  FormLines,
  useComputed,
  useModal,
  useTestIds,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useCallback, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { createProductOfferingConfigsCompareUrl, createProductOfferingConfigsUrl } from "src/RouteUrls";
import {
  AddProductOfferingConfigMetaQuery,
  AddProductOfferingConfig_ReadyPlanOptionsFragment,
  AddProductOfferingConfigs_DeltasQuery,
  EditProductOfferingConfigQuery,
  RpoDeltaFragment,
  SaveProductOfferingConfigInput,
  useAddProductOfferingConfigMetaQuery,
  useAddProductOfferingConfigs_DeltasLazyQuery,
  useEditProductOfferingConfigQuery,
  useSaveProductOfferingConfigMutation,
} from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { ProductOfferingConfigParams } from "src/routes/routesDef";
import { queriesResult } from "src/utils";
import { StringParam, useQueryParams } from "use-query-params";
import { CreateProjectFromOfferingConfigModal } from "./CreateProjectFromOfferingConfigModal";
import { OptionsByTypeSection } from "./components/OptionsByTypeSection";

export function AddProductOfferingConfigPage() {
  const { productOfferingId, productOfferingConfigId } = useParams<ProductOfferingConfigParams>();
  // Query the offering calculated cost
  // And the offering RPOs delta cost
  // and offering programData (used to calc the total cost/SqFt)
  const metaDataQuery = useAddProductOfferingConfigMetaQuery({
    variables: {
      costInput: {
        readyPlanId: productOfferingId,
        readyPlanOptionIds: [],
        // In this `create` screen we always want to render the RPO's delta cost
        // The "all" flag indicates the delta cost should be queried for all offering RPOs not yet selected (passed to readyPlanOptionIds)
        deltaForReadyPlanOptionIds: ["all"],
      },
      // Query just the offering PD to start, since RPOs have not yet been selected
      programDataInput: { readyPlan: productOfferingId, readyPlanOptions: [] },
    },
    fetchPolicy: "no-cache",
  });
  // If the config already exists query it
  const configQuery = useEditProductOfferingConfigQuery({
    variables: { id: productOfferingConfigId },
    skip: !productOfferingConfigId,
  });

  return queriesResult([metaDataQuery, configQuery] as const, {
    data: (metaData, configData) => (
      <AddProductOfferingConfigPageView productOfferingConfig={configData?.productOfferingConfig} metaData={metaData} />
    ),
  });
}

type AddOfferingConfigPageProps = {
  metaData: AddProductOfferingConfigMetaQuery;
  productOfferingConfig?: EditProductOfferingConfigQuery["productOfferingConfig"];
};

export type ConfigOptionsAndDelta = {
  rpo: AddProductOfferingConfig_ReadyPlanOptionsFragment;
  delta: RpoDeltaFragment | undefined;
};

export function AddProductOfferingConfigPageView(props: AddOfferingConfigPageProps) {
  const { productOfferingId } = useParams<ProductOfferingConfigParams>();
  const [{ devId, configIds }] = useQueryParams({
    devId: StringParam,
    configIds: StringParam,
  });
  const history = useHistory();
  const { openModal } = useModal();
  const { metaData } = props;
  const formState = useFormState({
    config: formConfig,
    init: {
      input: props,
      map: (queryResults) => mapConfigToForm(queryResults),
    },
  });
  const [saveOfferingConfig] = useSaveProductOfferingConfigMutation();
  const [recomputeConfigCost] = useAddProductOfferingConfigs_DeltasLazyQuery({
    fetchPolicy: "cache-and-network",
  });
  const tid = useTestIds({});
  // Store/set the latest delta updates for our list of RPOs
  const [deltaRpos, setDeltas] = useState<ConfigOptionsAndDelta[]>(
    metaData.computeReadyPlanCost.readyPlan.options.map((rpo) => ({
      rpo,
      delta: metaData.computeReadyPlanCost.deltas.find((d) => d.readyPlanOption.id === rpo.id),
    })),
  );

  // The full list of all available config RPOs to select, should stay static.
  // We only update the RPO delta cost if it changes based on the list of selected RPOS (via `recomputeCosts` util).
  // Group these by RPO type
  const groupedRposByType = useMemo(
    () => deltaRpos.sortBy((d) => d.rpo.type.order ?? Number.POSITIVE_INFINITY).groupBy((res) => res.rpo.type.name),
    [deltaRpos],
  );

  const selectedRpos = useComputed(
    () => formState.value.readyPlanOptions.map((os) => os.readyPlanOptionId).compact(),
    [formState],
  );

  /**
   On each RPO select/deselect:
   - Query the calc for the total config cost (base offering & selected RPOs costs),
    RPO delta costs, and combined program data to calc Cost/SqFt.
   - Then uppdate formState with the refetched values 
   **/
  const recomputeCosts = useCallback(
    async (
      rpo: AddProductOfferingConfig_ReadyPlanOptionsFragment,
      isSelected: boolean,
      prevMultiSelectRpoId?: string,
    ) => {
      // Update form with selected RPO
      formState.set(
        mapSelectedRpoToForm(
          metaData.computeReadyPlanCost.readyPlan.options,
          formState,
          rpo,
          isSelected,
          prevMultiSelectRpoId,
        ),
      );
      const selectedRpos = formState.value.readyPlanOptions.map((os) => os.readyPlanOptionId).compact();
      const res = await recomputeConfigCost({
        variables: {
          costInput: {
            readyPlanId: productOfferingId,
            readyPlanOptionIds: selectedRpos,
            // As RPOs are selected/deselected
            // Fetch updated deltas for RPOs not yet selected
            deltaForReadyPlanOptionIds: ["all"],
          },
          programDataInput: {
            readyPlan: productOfferingId,
            readyPlanOptions: selectedRpos,
          },
        },
      });

      if (res.data) {
        // Fetch computed delta/total costs
        setDeltas(
          metaData.computeReadyPlanCost.readyPlan.options.map((rpo) => ({
            rpo,
            delta: res.data?.computeReadyPlanCost.deltas.find((d) => d.readyPlanOption.id === rpo.id),
          })),
        );
        // And update formState costs
        formState.set(mapRecomputedCostToForm(res.data));
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formState.value, selectedRpos, deltaRpos],
  );

  const missingRequiredRpos = useComputed(() => {
    const requiredRposByGroup = metaData.computeReadyPlanCost.readyPlan.options
      .filter((rpo) => rpo.optionGroup.required)
      .groupBy((rpo) => rpo.optionGroup.name);
    const selectedRpos = formState.value.readyPlanOptions.map((rpo) => rpo.readyPlanOptionId).compact();
    const missingRpoGroups = Object.keys(requiredRposByGroup).filter(
      (k) => !requiredRposByGroup[k].some((rpo) => selectedRpos.includes(rpo.id)),
    );
    if (missingRpoGroups.isEmpty) return;
    return `${missingRpoGroups.join(", ")} missing a required option selection`;
  }, [formState]);

  return (
    <Observer>
      {() => (
        <>
          <PageHeader
            xss={Css.bgGray100.bsn.$}
            right={
              <>
                <Button
                  variant="primary"
                  label="Save"
                  size="md"
                  onClick={async () => {
                    await saveOfferingConfig({
                      variables: { input: mapFormToInput(formState) },
                    });
                    if (configIds)
                      // If we've come from the compare page with a set of configs selected
                      // return to the compare page with that set of configIds
                      return history.push(
                        createProductOfferingConfigsCompareUrl(JSON.parse(configIds), productOfferingId, devId),
                      );
                    if (devId) return history.push(createProductOfferingConfigsUrl(devId, productOfferingId));
                  }}
                  disabled={!!missingRequiredRpos && missingRequiredRpos}
                />
                <Button
                  variant="tertiary"
                  label="Create Project"
                  size="md"
                  onClick={() =>
                    openModal({
                      content: (
                        <CreateProjectFromOfferingConfigModal
                          productOfferingConfig={props.productOfferingConfig!}
                          developmentId={devId!}
                        />
                      ),
                    })
                  }
                  disabled={!props.productOfferingConfig || !devId}
                />
              </>
            }
          />
          <div css={Css.df.fdc.aic.$}>
            <div css={Css.df.fdc.aic.my6.$}>
              <div css={Css.xl3Sb.$} {...tid.title}>
                Create Configurations
              </div>
              <div css={Css.base.pt2.$}>
                Select options to configure and see cost updates on. Save a configuration to be pulled into
                underwriting.
              </div>
            </div>
            <div css={Css.w("40%").p4.bgWhite.bshBasic.br12.aic.mb5.$}>
              <FormLines labelStyle="hidden" compact width="full">
                <BoundTextField
                  field={formState.name}
                  aria-label="config name"
                  placeholder="Configuration Name Goes Here"
                />
              </FormLines>
              <div css={Css.df.fdc.jcc.gap3.my2.$}>
                <div css={Css.baseSb.df.jcc.fdr.gap7.aic.$}>
                  <div css={Css.df.fdr.gap1.aic.jcc.$}>
                    <div>Total Cost:</div>
                    <div>
                      <BoundNumberField
                        aria-label="totalConfigCost"
                        label=""
                        labelStyle="hidden"
                        type="cents"
                        field={formState.totalCostInCents}
                        readOnly
                      />
                    </div>
                  </div>
                  <div css={Css.df.fdr.gap1.aic.jcc.$}>
                    <div>Cost/ Sq Ft:</div>
                    <div>
                      <BoundNumberField
                        aria-label="totalCostSqFt"
                        label=""
                        labelStyle="hidden"
                        type="cents"
                        field={formState.totalCostSqFt}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div css={Css.sm.gray700.$}>
                  Costs summaries are pulled from a mixture of cost sources, including some that are not awarded bids
                </div>

                <div css={Css.df.jcc.$}>
                  <BoundCheckboxField field={formState.isBaseConfig} label="Set as base" />
                </div>
              </div>
            </div>
            <OptionsByTypeSection
              groupedRpos={groupedRposByType}
              formState={formState}
              recomputeCosts={recomputeCosts}
            />
          </div>
        </>
      )}
    </Observer>
  );
}

export type ProductOfferingConfigForm = Omit<SaveProductOfferingConfigInput, "readyPlanOptionIds"> & {
  readyPlanOptions: { readyPlanOptionId: string | null | undefined }[];
  totalCostInCents: number | null | undefined;
  totalCostSqFt: number | null | undefined;
};
export const formConfig: ObjectConfig<ProductOfferingConfigForm> = {
  id: { type: "value" },
  productOfferingId: { type: "value", rules: [required] },
  name: { type: "value", rules: [required] },
  readyPlanOptions: {
    type: "list",
    rules: [required],
    config: { readyPlanOptionId: { type: "value" } },
  },
  totalCostInCents: { type: "value" },
  totalCostSqFt: { type: "value" },
  isBaseConfig: { type: "value" },
};

export function mapConfigToForm(queryResults: AddOfferingConfigPageProps): ProductOfferingConfigForm {
  const { productOfferingConfig, metaData } = queryResults;
  const { id, name, isBaseConfig, readyPlanOptions: savedConfigRpos } = productOfferingConfig ?? {};
  const {
    computeReadyPlanCost: { totalCostInCents, readyPlan: productOffering },
    computeProgramData: { sellableSqft },
  } = metaData;
  return {
    id,
    name,
    productOfferingId: productOffering.id,
    readyPlanOptions: (metaData.computeReadyPlanCost.deltas || [])
      // If RPOs have been saved to the config, preselect its checkbox
      .map((deltaRpo) => ({
        readyPlanOptionId: savedConfigRpos?.find((opt) => opt.id === deltaRpo.readyPlanOption.id)?.id,
      })),
    // TBD: On intial render, if the BE config totalCostInCents is defined
    // default to the BE config totalCostInCents
    totalCostInCents,
    totalCostSqFt: sellableSqft ? Math.round(totalCostInCents / Number(sellableSqft)) : 0,
    isBaseConfig,
  };
}

export function mapFormToInput(formState: ObjectState<ProductOfferingConfigForm>): SaveProductOfferingConfigInput {
  const { totalCostInCents, totalCostSqFt, readyPlanOptions, ...rest } = formState.value;
  return {
    ...rest,
    readyPlanOptionIds: readyPlanOptions.map((opt) => opt.readyPlanOptionId).compact(),
  };
}

export function mapRecomputedCostToForm(metaData: AddProductOfferingConfigs_DeltasQuery) {
  const { computeReadyPlanCost, computeProgramData } = metaData;
  const totalCostInCents = computeReadyPlanCost.totalCostInCents;
  const sellableSqft = computeProgramData.sellableSqft;
  return {
    totalCostInCents,
    totalCostSqFt: sellableSqft ? Math.round(totalCostInCents / Number(sellableSqft)) : 0,
  };
}

export function mapSelectedRpoToForm(
  allRpos: AddProductOfferingConfig_ReadyPlanOptionsFragment[],
  formState: ObjectState<ProductOfferingConfigForm>,
  newRpo: AddProductOfferingConfig_ReadyPlanOptionsFragment,
  isSelected: boolean,
  multiOptRpoId?: string,
) {
  // if is selected is false deselect the children of and the children of
  let osRpos = formState.readyPlanOptions.value;
  // Remove any unselected rpos
  // Or multi option group RPOId already selected
  osRpos = osRpos.filter((rpo) => rpo.readyPlanOptionId !== newRpo.id && rpo.readyPlanOptionId !== multiOptRpoId);
  // If prereq children match the prereq childred of the previously/multiOptRpoid
  const newRpoPreqChildren = newRpo.optionPrereqChildren.map((rpo) => rpo.id);
  const multiOptRpoPreqChildren = multiOptRpoId
    ? allRpos.find((rpo) => rpo.id === multiOptRpoId)?.optionPrereqChildren.map((rpo) => rpo.id)
    : [];

  osRpos = [
    // Update formState existing RPOS with newly selected rpo
    ...osRpos,
    ...(isSelected
      ? [
          {
            readyPlanOptionId: newRpo.id,
          },
        ]
      : []),
  ];

  // check if options need be removed
  if (
    // If the new rpo is prereq of something and deselected
    (newRpoPreqChildren.nonEmpty && !isSelected) ||
    // OR the option group prereq children are not the same as the new rpo prereq children
    (multiOptRpoPreqChildren &&
      multiOptRpoPreqChildren.nonEmpty &&
      !multiOptRpoPreqChildren.every((rpo) => newRpoPreqChildren.includes(rpo)))
  ) {
    // remove every rpo that has an unfulfilled prereq
    // Filter all rpos that are selected so we have access to rpo prereqs
    let selectedRpos = allRpos.filter((rpo) => osRpos.some((os) => os.readyPlanOptionId === rpo.id));
    // get a list of rpos to remove, these will have a prerequsite that is not selected
    let rposToRemove = getFormRPOsToRemove(selectedRpos, allRpos);

    // functions for the while loop
    const filterOsRpos = (rpo: { readyPlanOptionId: string | null | undefined }) =>
      rpo.readyPlanOptionId !== rposToRemove.first!.id;

    const filterSelectedRpos = (rpo: AddProductOfferingConfig_ReadyPlanOptionsFragment) =>
      osRpos.some((os) => os.readyPlanOptionId === rpo.id);

    // Iterate as items are removed
    while (rposToRemove.nonEmpty) {
      // remove the rpos from formState
      osRpos = osRpos.filter(filterOsRpos);
      rposToRemove.shift();
      // reload the items to remove now that something has been removed
      selectedRpos = allRpos.filter(filterSelectedRpos);
      rposToRemove = getFormRPOsToRemove(selectedRpos, allRpos);
    }
  }

  return {
    readyPlanOptions: allRpos
      .map((rpo) => {
        const osRpoId = osRpos.find((os) => os.readyPlanOptionId === rpo.id)?.readyPlanOptionId;
        return osRpoId ? { readyPlanOptionId: osRpoId } : null;
      })
      .compact(),
  };
}

function getFormRPOsToRemove(
  selectedRpos: AddProductOfferingConfig_ReadyPlanOptionsFragment[],
  allRpos: AddProductOfferingConfig_ReadyPlanOptionsFragment[],
) {
  return selectedRpos.filter((currentRpo) =>
    // remove an item if
    // the current rpo has a prereq that is not selected
    // and that prereq in not in the same group as a prereq that is selected
    {
      // current rpos does not include a prereq
      if (currentRpo.optionPrerequisites.isEmpty) return false;
      // Do not remove if every prereq is satisfied
      return !currentRpo.optionPrerequisites.every((prereq) => {
        // selected rpos contains the prereq
        const selectedOptContainsPrereq = selectedRpos.map((so) => so.id).includes(prereq.id);
        if (selectedOptContainsPrereq) return true;
        // or selected rpos contain an item in the same group as the prereq
        const selectedOptIsSameGroupAsPrereq = prereq.optionGroup.options.some((optionGroupRpo) => {
          // return true if current option preqs has a selected option of the same option group
          const rpoOfSameOptGroupIsSelected = selectedRpos.map((o) => o.id).includes(optionGroupRpo.id);
          // and that option is a prereq of the current selected rpo
          const rpoOfSameOptGroupIsPreReqOfCurrRpo = currentRpo.optionPrerequisites
            .map((prereq) => prereq.id)
            .includes(optionGroupRpo.id);
          return rpoOfSameOptGroupIsSelected && rpoOfSameOptGroupIsPreReqOfCurrRpo;
        });
        return selectedOptIsSameGroupAsPrereq;
      });
    },
  );
}
