import { TagType } from "@homebound/beam";
import { AggregateVersionStatus, ItemTemplateStatus, ReadyPlanStatus } from "src/generated/graphql-types";
import { assertNever } from "./utils";

export const readyPlanStatusToTagType: Record<ReadyPlanStatus, TagType> = {
  [ReadyPlanStatus.Draft]: "info",
  [ReadyPlanStatus.Active]: "success",
  [ReadyPlanStatus.Archived]: "warning",
};

export function itemTemplateStatusToTagType(
  status: ItemTemplateStatus | ReadyPlanStatus | AggregateVersionStatus,
): TagType {
  switch (status) {
    case ItemTemplateStatus.Archived:
    case ReadyPlanStatus.Archived:
    case AggregateVersionStatus.Archived:
      return "warning";
    case ItemTemplateStatus.SystemDraft:
    case ItemTemplateStatus.Active:
    case ReadyPlanStatus.Active:
    case AggregateVersionStatus.Active:
      return "success";
    case ItemTemplateStatus.Draft:
    case ReadyPlanStatus.Draft:
    case AggregateVersionStatus.Draft:
      return "info";
    default:
      return assertNever(status);
  }
}
