import { Button, Checkbox, Css, HasIdAndName, MultiSelectField, useSnackbar, useTestIds } from "@homebound/beam";
import { Fragment, ReactNode, useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { createDraftScheduleUrl } from "src/RouteUrls";
import { StepActions, useStepperContext } from "src/components/stepper";
import {
  DraftPlanTaskInput,
  Maybe,
  PlanTask,
  ScheduleDraftMode_PlanTaskFragment,
  ScheduleDraftMode_TradePartnerFragment,
  useSaveDraftPlanScheduleMutation,
} from "src/generated/graphql-types";
import { formatMonthDay } from "src/utils/dates";
import { MarketContactMenuLabel, MarketContactWithRoles, getMarketsContacts } from "../utils";
import { useDraftScheduleStore } from "./scheduleDraftStore";

type TradeConfirmPublishStepProps = {
  draftTasks: ScheduleDraftMode_PlanTaskFragment[];
  scheduleParentId: string;
};

export function TradeConfirmPublishStep({ scheduleParentId, draftTasks }: TradeConfirmPublishStepProps) {
  const { triggerNotice } = useSnackbar();
  const { prevStep } = useStepperContext();
  const history = useHistory();

  const draftTaskChanges = useDraftScheduleStore((state) => state.draftTaskChanges);
  const reset = useDraftScheduleStore((state) => state.reset);
  const resetRequiredScheduleFlags = useDraftScheduleStore((state) => state.resetRequiredTaskScheduleFlag);
  const requiredScheduleFlags = useDraftScheduleStore((state) => state.requiredScheduleFlags);
  const userAddedScheduleFlags = useDraftScheduleStore((state) => state.userAddedScheduleFlags);

  const getUnconfirmedPinnedTasks = useDraftScheduleStore((state) => state.getUnconfirmedPinnedTasks);
  const getConfirmedTasksToBeRescheduled = useDraftScheduleStore((state) => state.getConfirmedTasksToBeRescheduled);

  const [saveDraftSchedule] = useSaveDraftPlanScheduleMutation();
  // local state to handle selecting/unselecting trade partner contacts to send emails to
  const [selectedTradePartnerContacts, setSelectedTradePartnersContacts] = useState<Record<string, string[]>>({});

  // tasks that have been manually scheduled but not confirmed by the trade partner will be used to send tradePartnerAvailabilityRequests
  // we also send requests for trade partners who have been updated/or added to a task
  const unConfirmedPinnedTasks = useMemo(
    () => getUnconfirmedPinnedTasks(draftTasks),
    [draftTasks, getUnconfirmedPinnedTasks],
  );

  // tasks that have been confirmed and have a new start date will be used to send tradePartnerAvailabilityRequests
  const confirmedTasksToBeRescheduled = useMemo(
    () => getConfirmedTasksToBeRescheduled(draftTasks),
    [getConfirmedTasksToBeRescheduled, draftTasks],
  );

  // Create a map of trade partners with two lists (tasks and trade partners) to easily pluck out data
  const groupedTasks = useMemo(() => {
    const tasksToBeSent = [...unConfirmedPinnedTasks, ...confirmedTasksToBeRescheduled];
    // first group by the trade partner id
    const groupedById = tasksToBeSent.groupBy((task) => task.tradePartner?.id ?? "");
    // then create two children arrays, one for the trade partner and one for the tasks
    return Object.entries(groupedById).map(([tradePartnerId, tasks]) => ({
      tradePartner: tasks.find((task) => task.tradePartner?.id === tradePartnerId)?.tradePartner,
      tasks: tasks.map((task) => ({
        id: task.id,
        name: task.name,
        startDate: task.startDate,
      })),
    }));
  }, [unConfirmedPinnedTasks, confirmedTasksToBeRescheduled]);

  const tradePartnerAvailabilityRequestInput = useMemo(
    () =>
      groupedTasks.flatMap(({ tasks, tradePartner }) => {
        const contactIds = selectedTradePartnerContacts[tradePartner?.id ?? ""];

        if (contactIds && contactIds.nonEmpty) {
          // Map tasks to create trade partner availability request input shape
          return tasks.map((task) => ({
            taskId: task.id,
            tradePartnerId: tradePartner?.id ?? "",
            tradePartnerContactIds: contactIds,
          }));
        }

        // Return an empty array if no contacts are selected for the trade partner
        return [];
      }),
    [groupedTasks, selectedTradePartnerContacts],
  );

  const draftTaskChangesInput = useMemo(
    () => [
      ...draftTaskChanges,
      ...requiredScheduleFlags,
      // remove unnecessary fields from sending to the backend, merge the user added flags with draft changes
      ...userAddedScheduleFlags.map(({ taskId, taskName, title, scheduleFlagReasonType, ...others }) => ({
        id: taskId,
        scheduleFlags: [{ ...others }],
      })),
    ],
    [draftTaskChanges, requiredScheduleFlags, userAddedScheduleFlags],
  );

  const saveAndPublishSchedule = useCallback(
    async (withTradeUpdates = false) => {
      await saveDraftSchedule({
        variables: {
          input: {
            scheduleParentId,
            draftTaskChanges: draftTaskChangesInput,
            ...(withTradeUpdates && {
              tradePartnerAvailabilityRequests: tradePartnerAvailabilityRequestInput,
            }),
          },
        },
      });
      // Reset store before redirecting
      reset();
      history.push(createDraftScheduleUrl(scheduleParentId));
      triggerNotice({ message: "Draft schedule changes have been saved" });
      if (withTradeUpdates) {
        triggerNotice({ message: "Scheduling emails have been sent to Trade Partners." });
      }
    },
    [
      saveDraftSchedule,
      scheduleParentId,
      draftTaskChangesInput,
      tradePartnerAvailabilityRequestInput,
      reset,
      history,
      triggerNotice,
    ],
  );

  const onBackSelect = useCallback(() => {
    resetRequiredScheduleFlags(requiredScheduleFlags);
    prevStep();
  }, [prevStep, resetRequiredScheduleFlags, requiredScheduleFlags]);

  // Handler function to update trade partner contacts on select
  const handleSelectedTradePartner = useCallback(
    (selected: boolean, tradePartner: ScheduleDraftMode_TradePartnerFragment | null | undefined) => {
      if (!tradePartner) return;

      const tradePartnerContacts = getMarketsContacts(tradePartner);

      setSelectedTradePartnersContacts((prevState) => {
        const newState = { ...prevState };
        if (selected) {
          newState[tradePartner?.id ?? ""] = tradePartnerContacts.map((c) => c.id);
        } else {
          // Remove the trade partner key/value from local state
          delete newState[tradePartner?.id ?? ""];
        }

        return newState;
      });
    },
    [setSelectedTradePartnersContacts],
  );

  function shouldDisableTradePartnerButton() {
    // first rule is if there are no selected trade partners then disable
    if (Object.keys(selectedTradePartnerContacts).isEmpty) return true;

    const hasAllContacts = Object.values(selectedTradePartnerContacts).every((contactIds) => contactIds.nonEmpty);
    // Disable the button if any selected trade partner is missing contacts
    return !hasAllContacts;
  }

  return (
    <div css={Css.maxwPx(1200).ma.tac.mt6.$} data-testid="tradeConfirmPublishStep">
      <h1 css={Css.xl3Sb.$}>Select Trades to Email</h1>
      <p css={Css.base.my2.mb5.$}>
        The following Trade Partners are associated to the tasks that have been updated. Select the ones that you want
        to send emails to. The emails will inform trades of the date that they should be available for their assigned
        task(s).
      </p>
      {groupedTasks.isEmpty ? (
        <TradePartnerAvailabilityRequestEmptyState />
      ) : (
        <div css={Css.dg.gtc("1fr 100px 120px 300px").rg1.cg3.bgWhite.p4.maxwPx(1000).br8.ma.$}>
          <span css={Css.smSb.tal.$}>Trade Partner</span>
          <span css={Css.smSb.tal.$}>Original Date</span>
          <span css={Css.smSb.tal.$}>Updated Date</span>
          <span css={Css.smSb.tal.$}>Contact</span>
          {groupedTasks.map(({ tasks, tradePartner }) => {
            const tradePartnerContacts = getMarketsContacts(tradePartner);
            return (
              <TradePartnerColumnData
                key={tradePartner?.id}
                tasks={tasks}
                tradePartner={tradePartner}
                handleSelectedTradePartner={handleSelectedTradePartner}
                tradePartnerMarketContacts={tradePartnerContacts}
                selectedTradePartnerContacts={selectedTradePartnerContacts}
                contactColumn={
                  <TradePartnerContactColumn
                    tradePartner={tradePartner}
                    selectedTradePartnerContacts={selectedTradePartnerContacts}
                    setSelectedTradePartnersContacts={setSelectedTradePartnersContacts}
                    tradePartnerMarketContacts={tradePartnerContacts}
                  />
                }
              />
            );
          })}
        </div>
      )}
      <StepActions>
        <>
          <Button label="Back" onClick={onBackSelect} variant="secondary" />
          <Button label="Skip and Publish" onClick={() => saveAndPublishSchedule()} variant="tertiary" />
          <Button
            label="Send Emails to Trades"
            onClick={() => saveAndPublishSchedule(true)}
            disabled={shouldDisableTradePartnerButton()}
            size="md"
          />
        </>
      </StepActions>
    </div>
  );
}

function TradePartnerAvailabilityRequestEmptyState() {
  return (
    <div
      css={
        Css.df.jcc.aic.bgGray200.gray700.p4
          .wPx(731)
          .hPx(515)
          .br8.boxShadow("0px 4px 8px 0px rgba(53, 53, 53, 0.08), 0px 2px 16px 0px rgba(53, 53, 53, 0.03)").ma.gap3.smMd
          .$
      }
      data-testid="tradePartnerAvailabilityRequestEmptyState"
    >
      The changes made do not require any Trade <br /> Partner availability updates.
    </div>
  );
}

type TradePartnerColumnDataProps = {
  tasks: Pick<PlanTask, "id" | "name" | "startDate">[];
  tradePartner: ScheduleDraftMode_TradePartnerFragment | null | undefined;
  handleSelectedTradePartner: (
    selected: boolean,
    tradePartner: ScheduleDraftMode_TradePartnerFragment | null | undefined,
    tasks: HasIdAndName[],
  ) => void;
  tradePartnerMarketContacts: MarketContactWithRoles[];
  selectedTradePartnerContacts: Record<string, string[]>;
  contactColumn: ReactNode;
};

function TradePartnerColumnData({
  tasks,
  tradePartner,
  handleSelectedTradePartner,
  tradePartnerMarketContacts,
  selectedTradePartnerContacts,
  contactColumn,
}: TradePartnerColumnDataProps) {
  const tids = useTestIds({}, "tradeConfirmStep");
  const getInitialTaskValue = useDraftScheduleStore((state) => state.getInitialTaskValue);

  return (
    <Fragment key={tradePartner?.id}>
      <div css={Css.df.fdc.gap2.tal.gc(1).mt1.$}>
        <label css={Css.df.aic.gap2.sm.gray900.$}>
          <Checkbox
            label=""
            checkboxOnly
            selected={!!(tradePartner && selectedTradePartnerContacts[tradePartner.id])}
            onChange={(selected) =>
              // handles adding the trade partner availability request for the (grouped) tasks
              handleSelectedTradePartner(selected, tradePartner, tasks)
            }
            disabled={tradePartnerMarketContacts.isEmpty}
          />
          {tradePartner?.name ? (
            <span css={Css.sm.gray900.$} {...tids.tradeName}>
              {tradePartner.name}
            </span>
          ) : (
            "No Trade Partner"
          )}
        </label>
      </div>
      <div css={Css.df.fdc.gap2.gc(4).mt1.$}>{contactColumn}</div>

      {tasks.map((task) => {
        const initialTaskValue = getInitialTaskValue(task.id);
        return (
          <Fragment key={task.id}>
            <div css={Css.sm.gray700.ml4.mb1.tal.truncate.gc(1).$} {...tids.taskName}>
              {task.name}
            </div>
            <div css={Css.gc(2).mb1.tal.$} {...tids.taskInitialDate}>
              {initialTaskValue ? formatMonthDay(initialTaskValue.startDate) : "--"}
            </div>
            <div css={Css.gc(3).mb1.tal.$} {...tids.taskNewDate}>
              {formatMonthDay(task.startDate)}
            </div>
          </Fragment>
        );
      })}
    </Fragment>
  );
}

type TradePartnerContactColumnProps = {
  tradePartner: ScheduleDraftMode_TradePartnerFragment | null | undefined;
  selectedTradePartnerContacts: Record<string, string[]>;
  setSelectedTradePartnersContacts: React.Dispatch<React.SetStateAction<Record<string, string[]>>>;
  tradePartnerMarketContacts: MarketContactWithRoles[];
};

function TradePartnerContactColumn({
  tradePartner,
  setSelectedTradePartnersContacts,
  selectedTradePartnerContacts,
  tradePartnerMarketContacts,
}: TradePartnerContactColumnProps) {
  return (
    <div css={Css.tal.df.fdc.gap2.$}>
      {tradePartnerMarketContacts?.nonEmpty ? (
        <MultiSelectField
          label="tradePartnerContactIds"
          labelStyle="hidden"
          getOptionMenuLabel={MarketContactMenuLabel}
          getOptionLabel={({ name, email }) => `${name}, <${email}>`}
          getOptionValue={({ id }) => id}
          onSelect={(selectedContacts) => {
            setSelectedTradePartnersContacts((prevState) => ({
              ...prevState,
              [tradePartner?.id ?? ""]: selectedContacts,
            }));
          }}
          options={tradePartnerMarketContacts}
          values={tradePartner?.id ? selectedTradePartnerContacts[tradePartner.id] : []}
          compact
        />
      ) : (
        <span>No contact information available for this trade partner</span>
      )}
    </div>
  );
}

// helper function to filter out tasks that need to be sent a trade partner availability request
function getTasksForTradePartnerAvailabilityRequests(
  draftTasks: ScheduleDraftMode_PlanTaskFragment[],
  draftTaskChanges: DraftPlanTaskInput[],
  statusCheck: (draftTask: ScheduleDraftMode_PlanTaskFragment) => Maybe<boolean>,
  draftChangesCheck: (taskInput: DraftPlanTaskInput, draftTask: ScheduleDraftMode_PlanTaskFragment) => Maybe<boolean>,
) {
  return draftTasks.filter(
    (draftTask) =>
      statusCheck(draftTask) && draftTaskChanges.some((taskInput) => draftChangesCheck(taskInput, draftTask)),
  );
}
