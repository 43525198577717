import { BoundTextAreaField, Button, Css, FormLines, StaticField, Tag, useTestIds } from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { capitalCase } from "change-case";
import { Observer } from "mobx-react";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { createBudgetUrl, createChangeEventUrl } from "src/RouteUrls";
import { FormattedDate, Price } from "src/components";
import { Card } from "src/components/Card";
import { billStatusTagMapper } from "src/components/detailPane/bill/utils";
import { BillPage_BillFragment } from "src/generated/graphql-types";
import { isHeadlessBill } from "src/utils/bills";
import { FormInput } from "../BillPage";
import { BillApproval } from "./BillApproval";
import { BillScheduleTasks } from "./BillScheduleTasks";

export const BillSummary = ({
  formState,
  bill,
  showBillFile,
  showBillFileCallback,
}: {
  formState: ObjectState<FormInput>;
  bill: BillPage_BillFragment;
  showBillFile: boolean;
  showBillFileCallback: () => void;
}) => {
  const tids = useTestIds({}, "billSummary");

  const [billType, billStatusText] = useMemo(() => billStatusTagMapper(bill), [bill]);

  const commitments = bill.parents.map((item, index) => (
    <div key={item.id}>
      {index ? ", " : ""}
      {
        <Link to={{ pathname: item.blueprintUrl.path }}>
          {item.id.includes("cco") ? "CO" : "PO"} #{item.accountingNumber}
        </Link>
      }
    </div>
  ));

  const changeEvents = bill.changeEvents.map((event, index) => (
    <div key={event.id}>
      {index ? ", " : ""}
      {<Link to={{ pathname: createChangeEventUrl(bill.projectStage.project.id, event.id) }}>{event.displayName}</Link>}
    </div>
  ));

  const gmailMessage = bill.ocrs.find((ocr) => ocr.gmailAttachment)?.gmailAttachment!.message;

  return (
    <div {...tids}>
      <Card xss={Css.$}>
        <div css={Css.df.fdc.f1.gap4.p2.$}>
          <div css={Css.df.aic.$}>
            <div css={Css.df.fdc.f1.gap1.wminc.$}>
              <div>
                <Tag type={billType} text={billStatusText} {...tids.status} />
              </div>
              <div>
                <Button
                  label={showBillFile ? "Hide File" : "View File"}
                  onClick={showBillFileCallback}
                  variant="secondary"
                  icon={"document"}
                  size="md"
                  disabled={formState.documents.value.isEmpty && "This bill is missing an attached file"}
                />
              </div>
            </div>
            <div css={Css.xl4Sb.$} {...tids.amount}>
              <Price valueInCents={formState.billedInCents.value} />
            </div>
          </div>

          <div css={Css.dg.gap2.ifLg.gtc("repeat(5, auto)").ifSm.gtc("repeat(3, auto)").$}>
            <StaticField label="Type" {...tids.type}>
              {capitalCase(bill.type.code)}
            </StaticField>
            <StaticField label="Source" {...tids.source}>
              {bill.source.name}
            </StaticField>
            <StaticField label="Project" {...tids.project}>
              <Link to={{ pathname: createBudgetUrl(formState.projectStage.project.id.value) }}>
                {formState.projectStage.project.name.value}
              </Link>
            </StaticField>
            <StaticField label="TradePartner" value={formState.tradePartner?.name.value} {...tids.tradePartner} />
            <StaticField label="Bill Date" {...tids.billDate}>
              <FormattedDate date={formState.billDate.value ?? undefined} dateFormatStyle="short" mdash />
            </StaticField>
            <StaticField label="Due Date" {...tids.dueDate}>
              {formState.dueDate.value ? (
                <FormattedDate date={formState.dueDate.value ?? undefined} dateFormatStyle="short" mdash />
              ) : (
                ""
              )}
            </StaticField>
            <StaticField label="Intacct ID" {...tids.intacctId}>
              {formState.intacctId.value ?? <span css={Css.fw3.o50.$}>No ID related</span>}
            </StaticField>
            {!isHeadlessBill(bill) && (
              <div css={Css.gc("span 1").$}>
                <StaticField label="Commitments" {...tids.commitments}>
                  {commitments}
                </StaticField>
              </div>
            )}
            {changeEvents.nonEmpty && (
              <div css={Css.gc("span 1").$}>
                <StaticField label="Change events" {...tids.changeEvents}>
                  {changeEvents}
                </StaticField>
              </div>
            )}
          </div>

          <Observer>
            {() => (
              <FormLines width="full">
                {bill.intacctSyncError && (
                  <StaticField label="Sync Error">
                    <div css={Css.red700.$}> {bill.intacctSyncError} </div>
                  </StaticField>
                )}
                {gmailMessage && (
                  <div css={Css.df.w("49.5%").gap2.ifSm.w100.$}>
                    <StaticField label="Origin" {...tids.origin}>
                      Email from {gmailMessage.sender} with subject '{gmailMessage.subject}'
                    </StaticField>
                  </div>
                )}
                <div css={Css.df.w("49.5%").gap2.ifSm.w100.$}>
                  <BoundTextAreaField
                    {...tids.internalNote}
                    label="Internal Description (optional)"
                    field={formState.internalNote}
                  />
                </div>
              </FormLines>
            )}
          </Observer>

          <div css={Css.df.w100.gap2.$}>
            <BillApproval bill={bill} />
            <BillScheduleTasks bill={bill} />
          </div>
        </div>
      </Card>
    </div>
  );
};
