import {
  BoundNumberField,
  BoundSelectField,
  BoundTextAreaField,
  BoundTextField,
  Button,
  Css,
  FormLines,
  GridDataRow,
  IconButton,
  Palette,
  ResponsiveGrid,
  ResponsiveGridItem,
  SelectField,
  StaticField,
  ToggleChips,
  useComputed,
  useGridTableApi,
  useModal,
  useSnackbar,
  useToast,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, required, useFormState } from "@homebound/form-state";
import { capitalCase } from "change-case";
import { Observer } from "mobx-react";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Link, useParams } from "react-router-dom";
import { addEntityParam, createBillPageUrl, createBillsAndCreditsPageUrl, createChangeEventUrl } from "src/RouteUrls";
import { CommentFeed, DocumentUploaderV2, PdfViewer } from "src/components";
import { BoundBeamDateField } from "src/components/BoundBeamDateField";
import { ProjectAutocompleteField } from "src/components/autoPopulateSelects/ProjectAutocompleteField";
import { TradePartnerAutocompleteField } from "src/components/autoPopulateSelects/TradePartnerAutocompleteField";
import {
  BillEditor_BillFragment,
  BillEditor_BillLineItemFragment,
  BillEditor_CommitmentLikeFragment,
  BillEditor_CommitmentLineItemFragment,
  BillEditor_ProjectItemFragment,
  BillPage_DocumentFragment,
  BillReviewReason,
  BillStatus,
  BillType,
  ChangeEventStatus,
  CommitmentStatus,
  CreateAssetResultFragment,
  DocumentType,
  InputMaybe,
  Maybe,
  ProjectAutocomplete_ProjectFragment,
  SaveBillInput,
  SaveBillLineItemInput,
  SaveCommentInput,
  Stage,
  TradePartnerAutocomplete_TradePartnerFragment,
  useBillEditor_BillQuery,
  useBillEditor_CalculateDueDateQuery,
  useBillEditor_CommitmentLikesQuery,
  useBillEditor_CreateCommitmentMutation,
  useBillEditor_ReviewReasonsDetailsQuery,
  useBillEditor_SaveBillMutation,
  useDeleteBillMutation,
  useSubmitApprovalMutation,
} from "src/generated/graphql-types";
import { useToggle } from "src/hooks";
import { disableBasedOnPotentialOperationText } from "src/routes/components/PotentialOperationsUtils";
import { fail, formatCentsToPrice, isDefined, isNumber, queryResult } from "src/utils";
import { DateOnly, formatWithYear } from "src/utils/dates";
import { BooleanParam, StringParam, useQueryParams } from "use-query-params";
import { ConfirmationModal } from "../components/ConfirmationModal";
import { PageHeader } from "../layout/PageHeader";
import { IdOrAddParams } from "../routesDef";
import { BillPage, FinalBillFormActions } from "./BillPage";
import { AddToBillReviewLogModal } from "./components/AddToBillReviewLogModal";
import { BillChangeEventSetupModal } from "./components/BillChangeEventSetupModal";
import { BillCommitmentsModal, getToggleChips, handleToggle } from "./components/BillCommitmentsModal";
import { BillConfirmationModal } from "./components/BillConfirmationModal";
import { BillFileDiffConfirmationContent } from "./components/BillFileDiffConfirmationContent";
import { BillLineItemsTable, LineItemRow, Row } from "./components/BillLineItemsTable";
import { BillReviewLineItemsModal, ReviewLineItemModalRow } from "./components/BillReviewLineItemsModal";
import { BillReviewLineItemsTable, ReviewLineItemRow } from "./components/BillReviewLineItemsTable";
import { BilledInCentsField } from "./components/BilledInCentsField";
import { useBillOcr } from "./hooks/useBillOcr";
import { getBillableLimit, getDynamicPdfVierwerWidth } from "./utils";

function isDraft(billStatus: BillStatus) {
  return billStatus === BillStatus.Draft;
}

export function BillEditor() {
  const { idOrAdd } = useParams<IdOrAddParams>();

  const [{ commitmentLikeId, edit }] = useQueryParams({
    commitmentLikeId: StringParam,
    edit: BooleanParam,
  });

  const isChangeOrderId = !!(commitmentLikeId && commitmentLikeId.includes("cco:"));
  const isNew = idOrAdd === addEntityParam;
  const billQuery = useBillEditor_BillQuery({ variables: { id: idOrAdd }, skip: isNew });

  const commitmentLikeQuery = useBillEditor_CommitmentLikesQuery({
    variables: { commitmentLikeId: commitmentLikeId ?? "", isChangeOrderId },
    skip: !commitmentLikeId,
  });

  if (commitmentLikeId) {
    return queryResult(commitmentLikeQuery, {
      data: (data) => (
        <BillEditorForm commitmentLike={isChangeOrderId ? data.commitmentChangeOrder : data.commitment} />
      ),
    });
  }

  return isNew ? (
    <BillEditorForm />
  ) : (
    queryResult(billQuery, {
      data: ({ bill }) => {
        // Open bill editor by default for draft bills that are not Click-To-Pay
        return (isDraft(bill.status.code) && bill.canEdit.allowed) || edit ? (
          <BillEditorForm bill={bill} />
        ) : (
          <BillPage billId={bill.id} />
        );
      },
    })
  );
}

type CreateBillEditorProps = {
  commitmentLike?: BillEditor_CommitmentLikeFragment;
  bill?: BillEditor_BillFragment;
};

function BillEditorForm({ commitmentLike, bill }: CreateBillEditorProps) {
  const [saveBill] = useBillEditor_SaveBillMutation();
  const [saveApproval] = useSubmitApprovalMutation();
  const [deleteBill] = useDeleteBillMutation();
  const [createCommitmentFromBill] = useBillEditor_CreateCommitmentMutation();
  const { openModal, closeModal } = useModal();
  const { triggerNotice } = useSnackbar();
  const { showToast } = useToast();
  const history = useHistory();
  const tableApi = useGridTableApi<Row>();
  const reviewLineItemModalTableApi = useGridTableApi<ReviewLineItemModalRow>();
  const reviewLineItemTableApi = useGridTableApi<ReviewLineItemRow>();
  const [selectedCommitments, setSelectedCommitments] = useState<BillEditor_CommitmentLikeFragment[]>(
    bill?.parents || [commitmentLike].compact(),
  );
  const [userHasEditedTotal, setUserHasEditedTotal] = useState<boolean>(false);
  const [amountInCents, setAmountInCents] = useState<Maybe<number>>(() => {
    if (!bill) return undefined;
    return bill.lineItems.sum((li) => li.amountInCents);
  });
  const [editReviewReason, toggleEditReviewReason] = useToggle(false);

  const isEdit = !!bill;
  const isDraft = bill && bill.status.code === BillStatus.Draft;
  const hasRecentChangeEventRequested =
    bill?.changeEvents && bill?.changeEvents.last?.statusDetail.code === ChangeEventStatus.Requested;

  const formState = useFormState({
    config: formConfig,
    init: {
      input: bill
        ? mapBillToForm(bill, hasRecentChangeEventRequested)
        : commitmentLike
          ? mapCommitmentLikeToForm(commitmentLike)
          : undefined,
      onlyOnce: true,
    },
    addRules(state) {
      state.lineItems.rules.push(() => {
        const lineItems = filteredLineItems(state.lineItems.value ?? []);
        // For standard bills, all selected line items should come from signed commitments
        return lineItems.find((li) => li.owner?.status && li.owner.status !== CommitmentStatus.Signed)
          ? "Selected commitments must be signed"
          : undefined;
      });
      state.documents.rules.push(() => {
        const documents = state.documents.value ?? [];
        const assets = state.assets.value ?? [];
        return documents.nonEmpty || assets.nonEmpty ? undefined : "At least one document is required";
      });
    },
    readOnly: hasRecentChangeEventRequested,
  });

  const calculatedDueDateQuery = useBillEditor_CalculateDueDateQuery({ skip: true });
  const reviewReasonsQuery = useBillEditor_ReviewReasonsDetailsQuery({
    skip: !bill?.pendingReview,
    nextFetchPolicy: "cache-first",
  });
  const [isLoadingBillOcr, setIsLoadingBillOcr] = useState(false);
  const [allowOcr, setAllowOcr] = useState(true);
  const loadingBillOcrPlaceholder = isLoadingBillOcr ? "Loading bill details..." : undefined;
  const callBillOcr = useBillOcr({
    formState,
    allowOcr,
    setAllowOcr,
    setSelectedCommitments,
    setIsLoading: setIsLoadingBillOcr,
    setUserHasEditedTotal,
  });
  const [formStateFromOcr, setFormStateFromOcr] = useState<BillFormInput | undefined>(undefined);

  function mapLineItemsToInput(lineItems: BillFormLineItem[], useBillableLimit?: boolean) {
    return lineItems.map((li) => ({
      id: li.bliId ?? undefined,
      commitmentLineItemId: li.commitmentLineItemId,
      projectItemId: li.projectItemId,
      amountInCents: useBillableLimit ? getBillableLimit(li) : li.amountInCents,
    }));
  }

  function mapToInput(formStateValue: BillFormInput, useBillableLimit?: boolean): SaveBillInput {
    const { documents, dueDate, billDate, tradePartner, project, assets, reviewLineItems, ...others } = formStateValue;
    const lineItems = filteredLineItems(formStateValue.lineItems);

    // Commitments and project items are filtered to be from the same project stage
    const projectStageId = selectedCommitments.first
      ? "projectStage" in selectedCommitments.first
        ? selectedCommitments.first.projectStage.id
        : selectedCommitments.first.commitment.projectStage.id
      : lineItems.first?.projectStage?.id;

    return {
      ...others,
      tradePartnerId: tradePartner?.id,
      projectStageId:
        projectStageId ||
        project?.stages.find((ps) => ps.stage.code === Stage.Construction)?.id ||
        fail("A construction project stage is required for this bill"),
      documents: documents?.map(({ id }) => id),
      dueDate: dueDate && new DateOnly(dueDate),
      billDate: billDate && new DateOnly(billDate),
      lineItems: mapLineItemsToInput(lineItems, useBillableLimit),
      assetId: assets?.first?.id,
    };
  }

  const updateTotals = (val: Maybe<number>) => {
    if (!userHasEditedTotal) {
      formState.proposedAmountInCents.set(val);
    }
    setAmountInCents(val);
  };

  const handleLineItemsAmountUpdate = () => {
    const totalAmountInCents = filteredLineItems(formState.lineItems.value || []).sum((li) => li.amountInCents ?? 0);
    updateTotals(totalAmountInCents);
  };

  const filteredLineItems = (lineItems: BillFormLineItem[]) =>
    lineItems?.filter((li) => {
      if (!isNumber(li.amountInCents)) return false;
      // If the bill type is not standard, we should not filter by selected rows
      if (formState.type.value !== BillType.Standard) return true;
      const rowId = li.commitmentLineItemId ?? li.id;
      return bill?.id
        ? // If it is in edit mode you should evaluate commitmentLineItemId
          li.commitmentLineItemId && tableApi.getSelectedRowIds().includes(li.commitmentLineItemId)
        : // Otherwise it's a new bill line item
          rowId && tableApi.getSelectedRowIds().includes(rowId);
    }) ?? [];

  // Map selected commitments to remove or add line items
  useEffect(() => {
    const lineItems = formState.lineItems.value ?? [];
    const removelineItems = isHeadless(formState.type.value)
      ? []
      : lineItems.filter((bli) => !selectedCommitments.map((c) => c.id).includes(bli.owner!.id));
    removelineItems.forEach((bli) => {
      // Remove selected rows from the API to prevent them from being marked as hidden rows
      tableApi.selectRow(bli.commitmentLineItemId!, false);
      formState.lineItems.remove(bli);
    });
    // Skip line items that we've already added, so they don't lose their current state
    const existingCommitments = lineItems.map((bli) => bli.owner?.id).unique();

    const potentialBillLineItems = selectedCommitments
      .filter((c) => !existingCommitments.includes(c.id))
      .flatMap((c) => c.lineItems)
      .map((cli) => {
        const changeOrdersWithSameProjectItem = cli.ownerCommitment.changeOrders
          .flatMap((co) => co.lineItems)
          .filter((li) => li.projectItem.id === cli.projectItem.id);

        return {
          id: undefined, // This is a potential new line item so it doesn't have an id
          amountInCents: undefined,
          displayName: cli.projectItem.displayName,
          commitmentLineItemId: cli.id,
          costChangeInCents: cli.costChangeInCents,
          pendingBilledInCents: cli.pendingBilledInCents,
          pendingUnbilledInCents: cli.pendingUnbilledInCents,
          billableLimitInCents: cli.billableLimitInCents,
          owner: cli.owner,
          changeOrdersWithSameProjectItem: changeOrdersWithSameProjectItem,
          revisedApprovedBudgetInCents: cli.projectItem.revisedApprovedBudgetInCents,
          unbilledCommittedInCents: cli.projectItem.unbilledCommittedInCents,
          bills: cli.projectItem.bills,
          task: cli.projectItem.task,
          projectItemId: cli.projectItem.id,
        };
      });

    potentialBillLineItems.map((bli) => formState.lineItems.add(bli));
  }, [selectedCommitments, formState.lineItems, tableApi, formState.type.value]);

  // Clear selected rows when the create bill type changes
  useEffect(() => {
    if (formState.type.value !== BillType.Standard) {
      tableApi.clearSelections();
    }
  }, [formState.type.value, tableApi]);

  // If user has manually set total to a draft bill before editing line items, we should not automatically update total anymore
  useEffect(() => {
    if (isEdit && isDraft && amountInCents !== formState.proposedAmountInCents.value) {
      setUserHasEditedTotal(true);
    }
  }, [isEdit, isDraft, amountInCents, formState.proposedAmountInCents.value]);

  // If this is a draft auto parsed bill, then set the selected commitments from its ocr results
  useEffect(() => {
    if (
      isEdit &&
      isDraft &&
      bill &&
      bill.reviewReason === BillReviewReason.AutoParsed &&
      bill.ocrs.some((ocr) => ocr.commitmentLikes.nonEmpty) &&
      ![BillType.Warranty, BillType.RepairAndMaintenance].includes(bill.type.code)
    ) {
      const commitmentLikes = [bill.parents, bill.ocrs.flatMap((ocr) => ocr.commitmentLikes)]
        .flat()
        .compact()
        .uniqueByKey("id");
      setSelectedCommitments(commitmentLikes);
    }
  }, [isEdit, isDraft, bill]);

  const handleSave = async (pendingReview?: boolean, comment?: SaveCommentInput) => {
    const { data } = await saveBill({
      variables: {
        input: {
          ...(mapToInput({
            ...formState.value,
            pendingReview: pendingReview ?? formState.value.pendingReview,
          }) satisfies SaveBillInput),
          comment,
        },
      },
    });
    formState.commitChanges();
    triggerNotice({
      message: `Bill successfully ${bill ? `edited` : pendingReview ? `added to the Bill Review log` : `created`}`,
    });
    if (!bill) {
      history.push(createBillPageUrl({ idOrAdd: data?.saveBill.bill?.id }));
    }
  };

  const onDelete = useCallback(async () => {
    await deleteBill({ variables: { input: { id: bill?.id ?? fail("No deletable bill found") } } });
    showToast({ message: `Bill #${bill?.tradePartnerNumber} successfully deleted`, type: "success" });
    history.push(createBillsAndCreditsPageUrl());
  }, [bill?.id, bill?.tradePartnerNumber, deleteBill, history, showToast]);

  const onCancel = useCallback(
    async (comment?: SaveCommentInput) => {
      await saveBill({ variables: { input: { id: bill?.id, status: BillStatus.Cancelled, comment } } });
      showToast({ message: `Bill #${bill?.tradePartnerNumber} has been successfully cancelled`, type: "success" });
    },
    [bill?.id, bill?.tradePartnerNumber, saveBill, showToast],
  );

  // bills shouldn't ever be reversed from the bill editor, so we don't need to implement this
  const onReverse = useCallback(async () => {}, []);

  const showFormStateErrors = (errorMessages: string[]) => {
    if (errorMessages.nonEmpty) {
      return (
        <>
          {errorMessages.map((err) => (
            <div key={err}>{err}</div>
          ))}
        </>
      );
    }
    return null;
  };

  function reviewBudgetImpact() {
    return formState.reviewLineItems.value?.nonEmpty
      ? formState.reviewLineItems.value.sum((li) => li.budgetImpactInCents ?? 0)
      : 0;
  }

  const validateTotalsError = useComputed(() => {
    let totalAmountInCents: number;
    if (isRepairOrWarranty(formState.type.value)) {
      totalAmountInCents =
        formState.billedInCents.value ?? formState.lineItems.rows.sum((li) => li.amountInCents.value ?? 0);
    } else {
      const reviewLineItemsBillAmount =
        formState.reviewLineItems.value?.sum((li) => Number(li.billAmountInCents ?? 0)) || 0;
      const selectedLineItems =
        formState.type.value === BillType.Standard
          ? tableApi.getSelectedRows().filter((li) => li.kind === "lineItem")
          : tableApi.getVisibleRows().filter((li) => li.kind === "lineItem");
      const selectedLineItemsAmount = selectedLineItems.sum(
        (row: GridDataRow<Row>) => (row as LineItemRow).data.amountInCents.value ?? 0,
      );
      totalAmountInCents =
        bill?.pendingReview && formState.reviewLineItems.value.nonEmpty
          ? selectedLineItemsAmount + reviewLineItemsBillAmount
          : selectedLineItemsAmount;
    }
    const proposedAmountInCents = Number(formState.proposedAmountInCents.value ?? 0);
    const error = totalAmountInCents !== proposedAmountInCents;

    return error ? "The line items do not match the total" : false;
  }, [formState, amountInCents, tableApi]);

  const isChangeEventNeeded = useComputed(() => {
    if (isRepairOrWarranty(formState.type.value)) return false;
    const overBillableLimit = formState.lineItems?.value?.some(
      (li) => (li.amountInCents ?? 0) > getBillableLimit(li, formState.type.value!),
    );
    return reviewBudgetImpact() > 0 || overBillableLimit;
  }, [bill]);

  const resetFormState = () => {
    const { documents, assets } = formState;
    // Create deep copies of documents and assets
    const documentsCopy = JSON.parse(JSON.stringify(documents?.value ?? []));
    const assetsCopy = JSON.parse(JSON.stringify(assets?.value ?? []));
    // Reset all form state values to default
    formState.revertChanges();
    setSelectedCommitments([]);
    updateTotals(null);
    // Set the deep copies back to the form state to keep the documents and assets tracking
    formState.documents.set(documentsCopy);
    formState.assets.set(assetsCopy);
  };

  const handleAddBillReviewLineItems = (items: BillEditor_ProjectItemFragment[]) => {
    formState.reviewLineItems.set(
      items.map(({ bills, ...rest }) => ({
        ...rest,
        quantity: 1,
        billAmountInCents: 0,
        budgetImpactInCents: 0,
      })),
    );
  };

  const handleRemoveBillReviewLineItem = (id: string) => {
    formState.reviewLineItems.set(formState.reviewLineItems.value?.filter((r) => r.id !== id));
    reviewLineItemModalTableApi.selectRow(id, false);
  };

  const isReviewFlowEnabled = useComputed(
    () =>
      formState.type.value === BillType.Standard &&
      bill?.reviewReasonDetail &&
      bill?.reviewReasonDetail?.code !== BillReviewReason.InsufficientBudget,
    [formState],
  );

  const isStandardBill = formState.type.value === BillType.Standard;
  const isEssentialFieldsFilled = formState.type.value && formState.project.value;
  const isLineItemsTableVisible = isEssentialFieldsFilled && selectedCommitments.nonEmpty;
  const canUpdateReviewReason =
    (formState.type.value !== BillType.Overhead || formState.reviewReason.value === BillReviewReason.AutoParsed) &&
    bill?.changeEvents.isEmpty;

  const ocrDataDiff = useComputed(() => {
    const { tradePartnerNumber, tradePartner, proposedAmountInCents } = formState.value;
    return [
      {
        label: "Invoice Number",
        fromOcr: formStateFromOcr?.tradePartnerNumber,
        current: tradePartnerNumber,
      },
      {
        label: "Trade Partner",
        fromOcr: formStateFromOcr?.tradePartner?.name,
        current: tradePartner?.name,
      },
      {
        label: "Total",
        fromOcr: formStateFromOcr?.proposedAmountInCents,
        current: proposedAmountInCents,
        isPrice: true,
      },
    ].filter(({ fromOcr, current }) => fromOcr && fromOcr !== current);
  }, [formState, formStateFromOcr]);

  return (
    <Observer>
      {() => (
        <div>
          <PageHeader
            title="Create Bill"
            breadcrumb={{ href: createBillsAndCreditsPageUrl(), label: "Bills & Credits" }}
            right={
              <div css={Css.df.cg1.$}>
                {!bill ? (
                  <>
                    <Button
                      variant="secondary"
                      label="Cancel"
                      onClick={() =>
                        openModal({
                          content: (
                            <ConfirmationModal
                              confirmationMessage="Are you sure you want to discard these changes?"
                              onConfirmAction={() => history.push(createBillsAndCreditsPageUrl())}
                              title="Discard Changes"
                              label="Confirm"
                            />
                          ),
                        })
                      }
                    />
                    <Button
                      variant="secondary"
                      label="Add to Bill Review Log"
                      disabled={!formState.valid ? showFormStateErrors(formState.errors) : false}
                      onClick={() =>
                        openModal({
                          content: (
                            <AddToBillReviewLogModal
                              handleSave={handleSave}
                              formState={formState}
                              selectedLineItems={filteredLineItems(formState.lineItems.value)}
                            />
                          ),
                        })
                      }
                    />
                  </>
                ) : (
                  <>
                    {/* Non-draft bills should be canceled/reversed/deleted from the view page */}
                    {isDraft &&
                      (bill?.canCancel.allowed || bill?.canDelete.allowed ? (
                        <FinalBillFormActions {...{ openModal, bill, onDelete, onCancel, onReverse }} />
                      ) : (
                        // always show the delete button
                        <Button
                          variant="danger"
                          label="Delete"
                          disabled={disableBasedOnPotentialOperationText(bill.canDelete)}
                          onClick={() => {}}
                        />
                      ))}
                    <Button
                      variant="secondary"
                      label="Save changes"
                      disabled={!formState.dirty}
                      onClick={() =>
                        formState.type.value === BillType.Standard &&
                        (filteredLineItems(formState.lineItems.value).isEmpty ||
                          hasUpdatedLineItems(bill.lineItems, filteredLineItems(formState.lineItems.value)))
                          ? openModal({
                              content: (
                                <ConfirmationModal
                                  confirmationMessage={
                                    <div>
                                      {filteredLineItems(formState.lineItems.value).isEmpty ? (
                                        <div>
                                          <div>This bill will be saved without line items. </div>
                                          <div css={Css.mt1.$}>
                                            If you want to include line items, you must select and input the amount for
                                            each.
                                          </div>
                                        </div>
                                      ) : (
                                        <div css={Css.mb2.$}>
                                          This bill will be saved with the following line items:
                                        </div>
                                      )}
                                      {filteredLineItems(formState.lineItems.value).map((li) => (
                                        <li key={li.id}>
                                          <span css={Css.smBd.$}>{li.displayName}</span>:
                                          {` ${formatCentsToPrice(li.amountInCents!)}`}
                                        </li>
                                      ))}
                                    </div>
                                  }
                                  onConfirmAction={() => handleSave()}
                                  title="Save changes"
                                  label="Confirm"
                                />
                              ),
                            })
                          : handleSave()
                      }
                    />
                  </>
                )}
                {!isEdit ? (
                  <Button
                    variant="primary"
                    label="Create"
                    disabled={
                      !formState.valid
                        ? showFormStateErrors(formState.errors)
                        : isRepairOrWarranty(formState.type.value)
                          ? !formState.billedInCents.value
                            ? "Bill amount is required"
                            : formState.billedInCents.value !== formState.proposedAmountInCents.value
                          : filteredLineItems(formState.lineItems.value).isEmpty
                            ? "Bill amount is required for the selected line items"
                            : validateTotalsError
                    }
                    onClick={() => {
                      const confirmationContent = formState.billedInCents.value ? (
                        <div>
                          A {capitalCase(formState.type.value!)} bill for{" "}
                          {formatCentsToPrice(formState.billedInCents.value)} will be created and submitted for
                          approval.
                        </div>
                      ) : (
                        <div>
                          <div css={Css.mb2.$}> A Bill will be created with the following line items:</div>
                          {filteredLineItems(formState.lineItems.value).map((li) => (
                            <li key={li.id}>
                              <span css={Css.smBd.$}>{li.displayName}</span>:
                              {` ${formatCentsToPrice(li.amountInCents!)}`}
                            </li>
                          ))}
                        </div>
                      );
                      const confirmationButton = { label: "Confirm", onClick: () => handleSave() };
                      const ocrDataDiffContent = (
                        <div css={Css.df.fdc.$}>
                          <div css={Css.mb2.$}>
                            Data extracted from the bill file is different from the form, please review it and confirm:
                          </div>
                          <BillFileDiffConfirmationContent differences={ocrDataDiff} />
                        </div>
                      );
                      openModal({
                        content: (
                          <BillConfirmationModal
                            title="Create Bill"
                            contents={
                              ocrDataDiff.nonEmpty ? [ocrDataDiffContent, confirmationContent] : [confirmationContent]
                            }
                            submitButtons={ocrDataDiff ? [{ label: "Next" }, confirmationButton] : [confirmationButton]}
                            onConfirmAction={() => handleSave()}
                          />
                        ),
                      });
                    }}
                  />
                ) : isDraft ? (
                  <>
                    {bill.pendingReview && !hasRecentChangeEventRequested ? (
                      <Button
                        variant="primary"
                        label="Submit for Approval"
                        disabled={
                          !formState.valid ||
                          (formState.reviewLineItems.value?.isEmpty && formState.lineItems.value?.isEmpty) ||
                          validateTotalsError
                        }
                        onClick={() => {
                          openModal({
                            content: isChangeEventNeeded ? (
                              <BillChangeEventSetupModal
                                projectId={formState.project.value.id}
                                formState={formState}
                                tableApi={tableApi}
                                handleSave={async () => {
                                  // Save bill data and line items without budget impact
                                  await saveBill({
                                    variables: {
                                      input: mapToInput(
                                        { ...formState.value },
                                        formState.type.value === BillType.Standard,
                                      ),
                                    },
                                  });
                                  formState.commitChanges();
                                }}
                              />
                            ) : (
                              <ConfirmationModal
                                confirmationMessage="Do you want to submit this Bill for an approval?"
                                onConfirmAction={async () => {
                                  if (
                                    bill.pendingReview &&
                                    formState.reviewLineItems.value.nonEmpty &&
                                    bill.type.code === BillType.Standard
                                  ) {
                                    await createCommitmentFromBill({
                                      variables: {
                                        input: {
                                          billId: formState.id.value!,
                                          changeEvent: null,
                                          lineItems: formState.reviewLineItems.value.map((li) => ({
                                            projectItemId: li.id,
                                            costChangeInCents: li.billAmountInCents,
                                            quantity: li.quantity,
                                          })),
                                        },
                                      },
                                    });
                                    triggerNotice({
                                      message: "Bill submitted for approval and commitment was created",
                                      action: {
                                        label: "Go to Bill",
                                        onClick: createBillPageUrl({ idOrAdd: bill.id }),
                                        variant: "tertiary",
                                      },
                                    });
                                  } else {
                                    await saveBill({
                                      variables: {
                                        input: { ...mapToInput({ ...formState.value }), pendingReview: false },
                                      },
                                    });
                                    triggerNotice({
                                      message: "Bill submitted for approval",
                                      action: {
                                        label: "Go to Bill",
                                        onClick: createBillPageUrl({ idOrAdd: bill.id }),
                                        variant: "tertiary",
                                      },
                                    });
                                  }
                                  closeModal();
                                  history.push(createBillsAndCreditsPageUrl());
                                }}
                                title="Submit for Approval"
                                label="Confirm"
                              />
                            ),
                            size: "xl",
                          });
                        }}
                      />
                    ) : hasRecentChangeEventRequested ? null : (
                      <Button
                        variant="primary"
                        label="Submit for Approval"
                        disabled={
                          formState.dirty || !formState.valid || !bill.canSubmitForApproval.allowed
                            ? showFormStateErrors(bill.canSubmitForApproval.disabledReasons.map((r) => r.message))
                            : validateTotalsError
                        }
                        onClick={() => {
                          openModal({
                            content: (
                              <ConfirmationModal
                                confirmationMessage="Do you want to submit this Bill for an approval?"
                                onConfirmAction={async () => {
                                  if (bill.pendingReview) {
                                    await saveBill({ variables: { input: { id: bill.id, pendingReview: false } } });
                                  } else {
                                    await saveApproval({ variables: { input: { subjectId: bill.id } } });
                                  }
                                  triggerNotice({
                                    message: "Bill submitted for approval",
                                    action: {
                                      label: "Go to Bill",
                                      onClick: createBillPageUrl({ idOrAdd: bill.id }),
                                      variant: "tertiary",
                                    },
                                  });
                                  closeModal();
                                  history.push(createBillsAndCreditsPageUrl());
                                }}
                                title="Submit for Approval"
                                label="Confirm"
                              />
                            ),
                          });
                        }}
                      />
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
            }
          />
          {bill?.reviewReasonDetail && (
            <>
              {editReviewReason ? (
                <div css={Css.df.jcc.$}>
                  <div>
                    <BoundSelectField
                      field={formState.reviewReason}
                      options={(reviewReasonsQuery?.data?.enumDetails.billReviewReason || [])
                        // "Auto-Parsed" is reserved for bills generated from the parsing service
                        // "Insufficient budget" should only be used for overhead bills
                        .filter(
                          (val) =>
                            val.code !== BillReviewReason.AutoParsed &&
                            ((formState.type.value === BillType.Overhead &&
                              val.code === BillReviewReason.InsufficientBudget) ||
                              (formState.type.value !== BillType.Overhead &&
                                val.code !== BillReviewReason.InsufficientBudget)),
                        )
                        .map((val) => ({ id: val.code, name: val.name }))}
                      placeholder="Select a reason"
                    />
                  </div>
                </div>
              ) : (
                <div css={Css.df.jcc.aic.w100.$} data-testid="review-reason-banner">
                  <div css={Css.df.fdc.aic.jic.p2.bgYellow200.gray900.br12.wfc.gapPx(2).$}>
                    <div>
                      <span>
                        This bill is under review. <strong>Reason: </strong>
                      </span>
                      <span>{bill.reviewReasonDetail.name}</span>
                    </div>
                    {hasRecentChangeEventRequested && bill.changeEvents.last && (
                      <Link
                        to={createChangeEventUrl(bill.project.id, bill.changeEvents.last?.id)}
                        data-testid="review-reason-banner-change-event-link"
                      >
                        Pending Change Event: CE #{bill.changeEvents.last.identifier}
                      </Link>
                    )}
                    {bill.reviewers.nonEmpty && (
                      <div css={Css.df.jcc.$}>
                        <span css={Css.fwb.$}> Reviewers:&nbsp;</span>
                        <span>{bill.reviewers.map((reviewer) => reviewer.name).sentenceJoin()}</span>
                      </div>
                    )}
                  </div>
                  {canUpdateReviewReason && (
                    <div css={Css.pl1.$}>
                      <IconButton color={Palette.Gray600} onClick={toggleEditReviewReason} icon="pencil" />
                    </div>
                  )}
                </div>
              )}
            </>
          )}
          <ResponsiveGrid columns={20} minColumnWidth={40} gap={30}>
            <ResponsiveGridItem colSpan={1} />
            <ResponsiveGridItem colSpan={18}>
              <div css={Css.df.$}>
                <div css={Css.w50.pr2.$}>
                  <div css={Css.gray700.$}>Now please add all information related to the bill document.</div>
                  <div css={Css.mt3.mb3.$}>
                    <FormLines width="full" labelSuffix={{ required: "*" }}>
                      <div>
                        <div css={Css.df.cg2.$}>
                          <div css={Css.w50.pr1.$}>
                            <ProjectAutocompleteField
                              required
                              disabled={selectedCommitments.nonEmpty || isEdit || !!loadingBillOcrPlaceholder}
                              onSelect={(val) => formState.project.set(val)}
                              autofillValue={formState.project.value?.name}
                            />
                          </div>
                          <div css={Css.w50.pr1.$}>
                            <BoundNumberField
                              required
                              disabled={isEdit && !isDraft}
                              label="Total"
                              placeholder={loadingBillOcrPlaceholder}
                              field={formState.proposedAmountInCents}
                              onChange={(val) => {
                                formState.proposedAmountInCents.set(val);
                                setUserHasEditedTotal(true);
                              }}
                            />
                          </div>
                        </div>
                        {formState.project.value && (
                          <div css={Css.cg2.bgGray200.p1.mt2.br8.$}>
                            <div css={Css.df.jcsb.mx2.my1.$}>
                              <StaticField label="Project Status" value={formState.project.value?.status?.name} />
                              <StaticField
                                label="Start Date"
                                value={
                                  formState.project.value?.startDate
                                    ? formatWithYear(
                                        typeof formState.project.value?.startDate === "string"
                                          ? new DateOnly(new Date(formState.project.value?.startDate))
                                          : formState.project.value?.startDate,
                                      )
                                    : undefined
                                }
                              />
                              <StaticField
                                label="Vertical Complete Date"
                                value={
                                  formState.project.value?.verticalCompleteDate
                                    ? formatWithYear(
                                        typeof formState.project.value?.verticalCompleteDate === "string"
                                          ? new DateOnly(new Date(formState.project.value?.verticalCompleteDate))
                                          : formState.project.value?.verticalCompleteDate,
                                      )
                                    : undefined
                                }
                              />
                              <StaticField
                                label="Warranty Start Date"
                                value={
                                  formState.project.value?.warrantyStartDate
                                    ? formatWithYear(
                                        typeof formState.project.value?.warrantyStartDate === "string"
                                          ? new DateOnly(new Date(formState.project.value?.warrantyStartDate))
                                          : formState.project.value?.warrantyStartDate,
                                      )
                                    : undefined
                                }
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      <div css={Css.df.cg2.$}>
                        <SelectField
                          label="Bill Type"
                          required
                          placeholder={loadingBillOcrPlaceholder}
                          value={formState.type.value}
                          disabled={!formState.project.value || (isEdit && !isDraft) || hasRecentChangeEventRequested}
                          onSelect={(val) => {
                            formState.type.set(val);
                            // Clear out added commitments/PIs if the bill type changes
                            setSelectedCommitments([]);
                            formState.lineItems.set([]);
                            formState.billedInCents.set(null);
                            formState.reviewLineItems.set([]);
                          }}
                          options={[
                            BillType.Standard,
                            BillType.Overhead,
                            BillType.RepairAndMaintenance,
                            BillType.Warranty,
                          ].map((type) => ({
                            id: type,
                            name: capitalCase(type),
                          }))}
                        />
                        <TradePartnerAutocompleteField
                          required
                          disabled={
                            !formState.type.value || selectedCommitments.nonEmpty || hasRecentChangeEventRequested
                          }
                          onSelect={async (val) => {
                            formState.tradePartner.set(val);
                          }}
                          autofillValue={formState.tradePartner.value?.name}
                        />
                      </div>
                      <div css={Css.df.cg2.$}>
                        <div css={Css.w50.$}>
                          <BoundTextField
                            label="Invoice Number"
                            field={formState.tradePartnerNumber}
                            placeholder={loadingBillOcrPlaceholder}
                          />
                        </div>
                        <div css={Css.w50.$}>
                          <div css={Css.gray700.ptPx(4).$}>Commitments</div>
                          {selectedCommitments.nonEmpty && (
                            <ToggleChips
                              values={getToggleChips(selectedCommitments)}
                              getLabel={({ accountingNumber }) => `#${accountingNumber}`}
                              onRemove={(item) => handleToggle(item, setSelectedCommitments)}
                              xss={Css.my1.$}
                            />
                          )}
                          <div css={Css.pyPx(9).mlPx(2).$}>
                            <Button
                              label="+ Add Commitment"
                              disabled={
                                !formState.project.value ||
                                !formState.tradePartner.value ||
                                formState.type.value !== BillType.Standard ||
                                hasRecentChangeEventRequested
                              }
                              onClick={() => {
                                openModal({
                                  content: (
                                    <BillCommitmentsModal
                                      selectedProject={formState.project.value}
                                      selectedTradePartner={formState.tradePartner.value}
                                      selectedCommitments={selectedCommitments}
                                      setSelectedCommitments={setSelectedCommitments}
                                    />
                                  ),
                                  size: "xl",
                                });
                              }}
                              size="sm"
                              variant="text"
                            />
                          </div>
                        </div>
                      </div>
                      <div css={Css.df.cg2.$}>
                        <BoundBeamDateField
                          label="Bill Date"
                          required
                          field={formState.billDate}
                          placeholder={loadingBillOcrPlaceholder}
                          onChange={async (value) => {
                            if (!value) return;
                            const billDate = new DateOnly(value);
                            formState.billDate.set(billDate);
                            // Calculate due date for new bills
                            if (!bill) {
                              const { data } = await calculatedDueDateQuery.refetch({
                                tradePartnerId: formState.tradePartner.value?.id,
                                billDate,
                              });
                              formState.dueDate.set(new DateOnly(data.tradePartner.billDueDateFor));
                            }
                          }}
                        />
                        <BoundBeamDateField
                          label="Due Date"
                          required
                          field={formState.dueDate}
                          placeholder={loadingBillOcrPlaceholder}
                        />
                      </div>
                      <BoundTextAreaField label="Internal Description (optional)" field={formState.internalNote} />
                    </FormLines>
                  </div>
                  <div
                    css={
                      Css.bsDashed.gray300.bw1.if(formState.lineItems?.value && formState.lineItems?.value.nonEmpty).mb3
                        .else.my4.$
                    }
                  />
                  {isReviewFlowEnabled && !hasRecentChangeEventRequested ? (
                    <>
                      {selectedCommitments.nonEmpty && (
                        <BillLineItemsTable
                          tableApi={tableApi}
                          formState={formState}
                          isEdit={isEdit}
                          onUpdateLineItemAmount={handleLineItemsAmountUpdate}
                        />
                      )}
                      <div css={Css.if(formState.reviewLineItems.value.nonEmpty).pb1.$}>
                        <div css={Css.df.jcsb.aic.if(formState.lineItems.value.nonEmpty).pt3.$}>
                          <div css={Css.df.fdc.$}>
                            <div css={Css.smBd.gray600.$}>Additional Line Items</div>
                            {formState.reviewLineItems.value.isEmpty && (
                              <div css={Css.gray400.$}> Add lines first and later you can add bill amount to them.</div>
                            )}
                          </div>
                          <Button
                            variant="secondary"
                            icon="plus"
                            label="Add Line Item"
                            disabled={hasRecentChangeEventRequested}
                            onClick={() => {
                              openModal({
                                content: (
                                  <BillReviewLineItemsModal
                                    tableApi={reviewLineItemModalTableApi}
                                    project={formState.project.value}
                                    onAdd={handleAddBillReviewLineItems}
                                  />
                                ),
                                size: "xxl",
                              });
                            }}
                          />
                        </div>
                      </div>
                      {formState.reviewLineItems.value.nonEmpty && (
                        <div css={Css.pb1.$}>
                          <BillReviewLineItemsTable
                            tableApi={reviewLineItemTableApi}
                            formState={formState}
                            onRemoveLineItem={handleRemoveBillReviewLineItem}
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {!isLineItemsTableVisible ? (
                        <>
                          {isStandardBill || formState.type.value === undefined ? (
                            <>
                              <div css={Css.red600.mb2.$}>
                                To select or add line items, first select the bill type and project.
                              </div>
                              <div css={Css.df.jcsb.aic.$}>
                                <div css={Css.df.fdc.$}>
                                  <div css={Css.smBd.gray600.$}>Line Items</div>
                                  <div css={Css.gray400.$}>
                                    {" "}
                                    Available options will be loaded after commitments are selected.{" "}
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : null}
                        </>
                      ) : (
                        <BillLineItemsTable
                          tableApi={tableApi}
                          formState={formState}
                          isEdit={isEdit}
                          onUpdateLineItemAmount={handleLineItemsAmountUpdate}
                          hasRecentChangeEventRequested={bill?.pendingReview && hasRecentChangeEventRequested}
                        />
                      )}
                    </>
                  )}
                  {isEssentialFieldsFilled && (
                    <>
                      {isRepairOrWarranty(formState.type.value) ? (
                        <BilledInCentsField formState={formState} onChange={updateTotals} />
                      ) : !isStandardBill ? (
                        <>
                          <BillLineItemsTable
                            tableApi={tableApi}
                            formState={formState}
                            isEdit={isEdit}
                            onUpdateLineItemAmount={handleLineItemsAmountUpdate}
                          />
                        </>
                      ) : null}
                    </>
                  )}
                </div>
                <div css={Css.w50.$}>
                  <div css={Css.gray700.mt5.$}>Add bill attachments here</div>
                  {formState.documents.value?.nonEmpty ? (
                    <div css={Css.bgWhite.bshBasic.br8.mhPx(400).oa.$}>
                      <PdfViewer
                        hasHeader
                        assets={formState.documents.value.map(({ asset }) => asset)}
                        handlePdfDelete={(assetId) => {
                          formState.documents.remove(
                            formState.documents.value.findIndex((d) => d.asset.id === assetId),
                          );
                          setIsLoadingBillOcr(false);
                          resetFormState();
                          setAllowOcr(true);
                        }}
                        pdfPageWidth={getDynamicPdfVierwerWidth()}
                      />
                    </div>
                  ) : formState.assets?.value?.nonEmpty ? (
                    <div css={Css.bgWhite.bshBasic.br8.mhPx(400).oa.$}>
                      <PdfViewer
                        hasHeader
                        assets={formState.assets.value as CreateAssetResultFragment[]}
                        handlePdfDelete={(assetId) => {
                          formState.assets.remove(formState.assets.value.findIndex((d) => d.id === assetId));
                          setIsLoadingBillOcr(false);
                          resetFormState();
                          setAllowOcr(true);
                        }}
                        pdfPageWidth={getDynamicPdfVierwerWidth()}
                      />
                    </div>
                  ) : (
                    <DocumentUploaderV2
                      xss={Css.mt1.bgBlue50.bcBlue700.h100.maxh("90vh").$}
                      documentType={DocumentType.Bill}
                      message="Drag & drop file"
                      multiple={true}
                      file={undefined}
                      onUploadStart={() => {
                        setIsLoadingBillOcr(true);
                      }}
                      onFinish={async (file, asset) => {
                        const isFileAsset = typeof file === "string";
                        const hasDocumentAsset =
                          !isFileAsset && formState.documents.value?.some(({ asset }) => asset.id === file?.asset.id);
                        const hasAsset = formState.assets.value?.nonEmpty;

                        if (!isFileAsset && file && !hasDocumentAsset) {
                          formState.documents.add(file);
                        }
                        if (isFileAsset && asset && !hasAsset) {
                          formState.assets.add(asset);
                        }
                        if (!file) {
                          formState.documents.set([]);
                          formState.assets.set([]);
                        }
                        await callBillOcr();
                        setFormStateFromOcr(JSON.parse(JSON.stringify(formState.value)));
                      }}
                      projectId={formState.project.value?.id}
                      error={formState.documents.touched ? formState.documents.errors.join(" ") : undefined}
                    />
                  )}
                  {bill && (
                    <div css={Css.mt3.ml2.$}>
                      <CommentFeed commentable={bill} />
                    </div>
                  )}
                </div>
              </div>
            </ResponsiveGridItem>
            <ResponsiveGridItem colSpan={1} />
          </ResponsiveGrid>
        </div>
      )}
    </Observer>
  );
}

export type BillFormLineItemOtherBills = {
  id: string;
  name: string;
  dueDate?: DateOnly | null | undefined;
  billedInCents: number;
  lineItems: {
    id: string;
    amountInCents: number;
    projectItem: {
      id: string;
      displayName: string;
    };
  }[];
  paidInCents: number;
  status: {
    __typename?: "BillStatusDetail";
    code: BillStatus;
  };
  type: {
    __typename?: "BillTypeDetail";
    code: BillType;
  };
};

export type BillFormLineItem = Omit<SaveBillLineItemInput, "costCode"> &
  Partial<
    Pick<
      BillEditor_CommitmentLineItemFragment,
      | "pendingBilledInCents"
      | "pendingUnbilledInCents"
      | "billableLimitInCents"
      | "costChangeInCents"
      | "owner"
      | "ownerCommitment"
    >
  > &
  Partial<
    Pick<
      BillEditor_ProjectItemFragment,
      | "uncommittedBudgetAmountInCents"
      | "revisedApprovedBudgetInCents"
      | "unbilledCommittedInCents"
      | "projectStage"
      | "task"
    >
  > & {
    displayName: string;
    bliId?: string;
    isPendingOrBilled?: boolean;
    bills?: BillFormLineItemOtherBills[];
    changeOrdersWithSameProjectItem?: {
      id: string;
      costChangeInCents?: number | null | undefined;
      projectItem: {
        id: string;
      };
    }[];
  };

export type BillReviewFormLineItem = Omit<BillEditor_ProjectItemFragment, "bills" | "isSelection" | "item"> & {
  quantity?: number | null;
  billAmountInCents?: number | null;
  budgetImpactInCents?: number | null;
};

export type BillFormInput = Omit<
  SaveBillInput,
  "tradePartnerId" | "balanceInCents" | "documents" | "quickbooksId" | "lineItems" | "dueDate" | "billDate"
> & {
  documents: BillPage_DocumentFragment[];
  lineItems: BillFormLineItem[];
  reviewLineItems: BillReviewFormLineItem[];
  project: ProjectAutocomplete_ProjectFragment;
  dueDate?: DateOnly | null;
  billDate?: DateOnly | null;
  tradePartner?: TradePartnerAutocomplete_TradePartnerFragment;
  otherBills?: BillFormLineItemOtherBills[];
  assets: Partial<CreateAssetResultFragment>[];
};

export type CreateBillFormState = ObjectState<BillFormInput>;

export const formConfig: ObjectConfig<BillFormInput> = {
  id: { type: "value" },
  project: { type: "value", rules: [required] },
  type: { type: "value", rules: [required] },
  tradePartner: { type: "value", rules: [required] },
  tradePartnerNumber: { type: "value", rules: [required] },
  billDate: { type: "value", rules: [required] },
  dueDate: { type: "value", rules: [required] },
  internalNote: { type: "value" },
  projectStageId: { type: "value" },
  proposedAmountInCents: {
    type: "value",
    rules: [({ value }) => (isDefined(value) ? undefined : "Total is required")],
  },
  assets: {
    type: "list",
    config: {
      id: { type: "value" },
      attachmentUrl: { type: "value" },
      contentType: { type: "value" },
      createdAt: { type: "value" },
      downloadUrl: { type: "value" },
      version: { type: "value" },
    },
  },
  documents: {
    type: "list",
    config: {
      id: { type: "value" },
      name: { type: "value" },
      asset: {
        type: "object",
        config: {
          id: { type: "value" },
          attachmentUrl: { type: "value" },
          contentType: { type: "value" },
          createdAt: { type: "value" },
          downloadUrl: { type: "value" },
          version: { type: "value" },
        },
      },
    },
  },
  isTradePartnerCredit: { type: "value" },
  billedInCents: { type: "value" },
  reviewReason: { type: "value" },
  reviewLineItems: {
    type: "list",
    config: {
      id: { type: "value" },
      displayName: { type: "value" },
      quantity: { type: "value" },
      billAmountInCents: { type: "value" },
      budgetImpactInCents: { type: "value" },
      task: { type: "value" },
      location: { type: "value" },
      uncommittedBudgetAmountInCents: { type: "value" },
      revisedApprovedBudgetInCents: { type: "value" },
      unbilledCommittedInCents: { type: "value" },
      projectStage: { type: "value" },
      costType: { type: "value" },
    },
  },
  lineItems: {
    type: "list",
    config: {
      id: { type: "value" },
      bliId: { type: "value", readOnly: true },
      displayName: { type: "value" },
      costChangeInCents: { type: "value" },
      commitmentLineItemId: { type: "value" },
      projectItemId: { type: "value" },
      amountInCents: { type: "value" },
      pendingBilledInCents: { type: "value" },
      pendingUnbilledInCents: { type: "value" },
      billableLimitInCents: { type: "value" },
      changeOrdersWithSameProjectItem: {
        type: "list",
        config: {
          id: { type: "value" },
          costChangeInCents: { type: "value" },
          projectItem: {
            type: "object",
            config: { id: { type: "value" } },
          },
        },
      },
      isPendingOrBilled: { type: "value" },
      uncommittedBudgetAmountInCents: { type: "value" },
      revisedApprovedBudgetInCents: { type: "value" },
      unbilledCommittedInCents: { type: "value" },
      task: { type: "value" },
      owner: {
        type: "object",
        config: { id: { type: "value" }, accountingNumber: { type: "value" }, status: { type: "value" } },
      },
      bills: {
        type: "list",
        config: {
          id: { type: "value" },
          name: { type: "value" },
          dueDate: { type: "value" },
          billedInCents: { type: "value" },
          lineItems: {
            type: "list",
            config: {
              id: { type: "value" },
              amountInCents: { type: "value" },
              projectItem: {
                type: "object",
                config: {
                  id: { type: "value" },
                  displayName: { type: "value" },
                },
              },
            },
          },
          paidInCents: { type: "value" },
          status: { type: "value" },
          type: { type: "value" },
        },
      },
    },
  },
};

export function mapBillToForm(bill: BillEditor_BillFragment, hasRecentChangeEventRequested?: boolean): BillFormInput {
  const { lineItems, type, status, projectStage, changeEvents, ...others } = bill;

  const changeEventLineItems =
    hasRecentChangeEventRequested && changeEvents.nonEmpty && changeEvents.last?.lineItems.nonEmpty
      ? changeEvents.last?.lineItems?.map((li) => ({
          id: li.id,
          displayName: li.projectItem.displayName,
          quantity: li.quantity,
          billAmountInCents: li.totalPriceInCents,
          budgetImpactInCents: li.totalCostInCents - li.projectItem.revisedApprovedBudgetInCents,
          uncommittedBudgetAmountInCents: li.projectItem.uncommittedBudgetAmountInCents,
          revisedApprovedBudgetInCents: li.projectItem.revisedApprovedBudgetInCents,
          unbilledCommittedInCents: li.projectItem.unbilledCommittedInCents,
          costType: li.projectItem.costType,
          location: li.projectItem.location,
          projectStage: li.projectItem.projectStage,
        }))
      : [];

  return {
    ...others,
    type: type.code,
    assets: [],
    reviewLineItems: changeEventLineItems,
    projectStageId: projectStage.id,
    lineItems: isHeadless(type.code)
      ? lineItems.map((li) => ({
          id: li.id,
          amountInCents: li.amountInCents,
          projectItem: li.projectItem,
          bliId: li.id,
          displayName: li.projectItem.displayName,
          billableLimitInCents: 0,
          pendingBilledInCents: 0,
          isPendingOrBilled: bill.isPendingOrBilled,
          costChangeInCents: 0,
          projectItemId: li.projectItem.id,
          revisedApprovedBudgetInCents: li.projectItem.revisedApprovedBudgetInCents,
          uncommittedBudgetAmountInCents: li.projectItem.uncommittedBudgetAmountInCents,
        }))
      : lineItems.map((bli) => {
          const changeOrdersWithSameProjectItem = bli.commitmentLineItem?.ownerCommitment.changeOrders
            .flatMap((co) => co.lineItems)
            .filter((li) => li.projectItem.id === bli.projectItem.id);
          return {
            ...bli,
            bliId: bli.id,
            displayName: bli.projectItem.displayName,
            pendingBilledInCents: bli.commitmentLineItem?.pendingBilledInCents,
            pendingUnbilledInCents: bli.commitmentLineItem?.pendingUnbilledInCents,
            billableLimitInCents: bli.commitmentLineItem?.billableLimitInCents,
            isPendingOrBilled: bill.isPendingOrBilled,
            commitmentLineItemId: bli.commitmentLineItem?.id,
            costChangeInCents: bli.commitmentLineItem?.costChangeInCents,
            owner: bli.commitmentLineItem?.owner,
            ownerCommitment: bli.commitmentLineItem?.ownerCommitment,
            projectItemId: bli.projectItem.id,
            revisedApprovedBudgetInCents: bli.projectItem.revisedApprovedBudgetInCents,
            unbilledCommittedInCents: bli.projectItem.unbilledCommittedInCents,
            changeOrdersWithSameProjectItem,
            bills: bli.projectItem.bills,
            task: bli.projectItem.task,
          };
        }),
  };
}

function mapCommitmentLikeToForm(commitmentLike: BillEditor_CommitmentLikeFragment): BillFormInput {
  return {
    type: BillType.Standard,
    assets: [],
    project:
      "commitment" in commitmentLike
        ? commitmentLike.commitment.projectStage.project
        : commitmentLike.projectStage.project,
    tradePartner:
      ("commitment" in commitmentLike ? commitmentLike.commitment.tradePartner : commitmentLike.tradePartner) ||
      undefined,
    lineItems: [],
    reviewLineItems: [],
    documents: [],
  };
}

function isHeadless(billType: InputMaybe<BillType>) {
  return billType && [BillType.Overhead, BillType.RepairAndMaintenance, BillType.Warranty].includes(billType);
}

function isRepairOrWarranty(billType: InputMaybe<BillType>) {
  return billType === BillType.RepairAndMaintenance || billType === BillType.Warranty;
}

function hasUpdatedLineItems(
  billLineItems: BillEditor_BillLineItemFragment[],
  formStateLineItems: BillFormLineItem[],
): boolean {
  return formStateLineItems.some((fsli) => {
    const matchinglineItem = billLineItems.find((bli) => bli.id === fsli.id);
    return !matchinglineItem || matchinglineItem.amountInCents !== fsli.amountInCents;
  });
}
