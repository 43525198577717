import { currentAuthToken } from "@homebound/auth-components";
import { Button, ModalBody, ModalFooter, ModalHeader, useModal, useSnackbar, useToast } from "@homebound/beam";
import { useCallback, useState } from "react";
import { jsonToCSV } from "react-papaparse";
import { CsvUploader } from "src/components/CsvUploader";
import { ItemsSelectField } from "src/components/autoPopulateSelects/ItemsSelectField";
import { baseDownloadUrl } from "src/context";
import { useToggle } from "src/hooks";
import { BulkUploadCsvResponse } from "../item-catalog/components/BulkUploadBidItemModal";
import { useMaterialCatalog } from "./useMaterialCatalog";

export function UploadMaterialListingsCsvModal() {
  const [itemId, setItemId] = useState<string>();
  const { upload, setCsvContent, csvContent, errors, addError, clearErrors } = useUploadCsv();
  const { closeModal } = useModal();

  return (
    <>
      <ModalHeader>Create Material Listings</ModalHeader>
      <ModalBody>
        <ItemsSelectField label="Item" filter={{ version: [2] }} value={itemId} onSelect={(id) => setItemId(id)} />
        <CsvUploader
          errors={errors}
          onError={(reason) => addError(reason)}
          onDrop={(content) => {
            clearErrors();
            setCsvContent(
              jsonToCSV(
                content.map(({ data }) => data),
                { newline: "\n" },
              ),
            );
          }}
        />
      </ModalBody>
      <ModalFooter>
        <Button label="Cancel" variant="tertiary" onClick={() => closeModal()} />
        <Button
          label="Upload"
          variant={errors.nonEmpty ? "danger" : "primary"}
          disabled={!csvContent || !itemId || errors.nonEmpty}
          onClick={() => upload(itemId!)}
        />
      </ModalFooter>
    </>
  );
}

function useUploadCsv() {
  const [csvContent, setCsvContent] = useState<string>();
  const [loading, toggleLoading] = useToggle(false);
  const { refetch } = useMaterialCatalog();
  const { triggerNotice } = useSnackbar();
  const { closeModal } = useModal();
  const { showToast } = useToast();

  const [errors, setErrors] = useState<string[]>([]);
  const addError = (msg: string) => setErrors((errs) => errs.concat(msg));
  const clearErrors = () => setErrors([]);

  const upload = useCallback(
    async (itemId: string) => {
      if (loading || !itemId) return;
      if (!csvContent) return triggerNotice({ message: "Can't upload CSV without any line items" });
      toggleLoading();

      const response = await fetch(`${baseDownloadUrl()}/csv?type=saveMaterialListings&itemId=${itemId}`, {
        method: "POST",
        headers: { "content-type": "text/csv", Authorization: `Bearer ${await currentAuthToken()}` },
        body: csvContent,
      });
      toggleLoading(); // the rest happens fast enough to turn it off early so we don't have to keep toggling it off

      if (response.status !== 200) {
        const { message } = await response.json();
        if (message) showToast({ message, type: "error" });
        return;
      }

      const result = (await response.json()) as BulkUploadCsvResponse;
      const { warnings, errors } = result;

      if (errors.nonEmpty) {
        errors.sortByKey("row").forEach((e) => addError(`Row ${e.row}: ${e.message}`));
        return;
      }

      // not aware of any Warnings states on the upload yet. Not ready to handle `warnings`

      refetch();
      triggerNotice({ message: "Listings successfully created" });
      closeModal();
    },
    [closeModal, csvContent, loading, refetch, showToast, toggleLoading, triggerNotice],
  );
  return { upload, csvContent, setCsvContent, errors, addError, clearErrors };
}
