import { ObjectState } from "@homebound/form-state";
import { BillType } from "src/generated/graphql-types";
import { BillFormLineItem } from "./BillEditor";
import { FormLineItem } from "./BillPage";

export function getDynamicPdfVierwerWidth(browserWidth?: number) {
  const { innerHeight } = window;
  return innerHeight > 900 ? 820 : innerHeight > 800 ? 760 : 640;
}

export function getBillableLimit(li: BillFormLineItem | FormLineItem, type: BillType = BillType.Standard) {
  if (type === BillType.Overhead) return li.uncommittedBudgetAmountInCents ?? 0;
  const { changeOrdersWithSameProjectItem, pendingUnbilledInCents, unbilledCommittedInCents } = li;
  const negativeValuesFromChangeOrders =
    changeOrdersWithSameProjectItem
      ?.filter((cocli) => (cocli.costChangeInCents ?? 0) < 0)
      .sum((cocli) => cocli.costChangeInCents ?? 0) ?? 0;
  const commitmentLimit = pendingUnbilledInCents ?? 0;
  const projectItemLimit = unbilledCommittedInCents ?? 0;

  return projectItemLimit > commitmentLimit && commitmentLimit > 0
    ? [commitmentLimit + negativeValuesFromChangeOrders, projectItemLimit].min()
    : [commitmentLimit, projectItemLimit].min();
}
export function calcLineItemValues(li: ObjectState<FormLineItem> | ObjectState<BillFormLineItem>, isCredit?: boolean) {
  const {
    pendingBilledInCents: { value: pendingBilledInCents = 0 },
    costChangeInCents: { value: costChangeInCents = 0 },
    isPendingOrBilled: { value: isPendingOrBilled = true },
  } = li;

  const sign = isCredit ? -1 : 1;
  const amountInCents = isPendingOrBilled ? Number(li.amountInCents.value) || 0 : 0;
  const amountInCentsOriginal = isPendingOrBilled ? Number(li.amountInCents.originalValue) || 0 : 0;
  // We do not want to include this bill amount in the "billedInCents" column, so extract this amount out.
  const otherBilledInCents = Number(pendingBilledInCents) - amountInCentsOriginal * sign;
  return {
    otherBilledInCents,
    thisBilledInCents: amountInCents,
    unBilledInCents: Number(costChangeInCents) - (otherBilledInCents + amountInCents * sign),
  };
}
