import { Global } from "@emotion/react";
import { Css, GridColumn, GridDataRow, GridTable, column } from "@homebound/beam";
import { format } from "date-fns";
import startCase from "lodash/startCase";
import { Fragment, useMemo } from "react";
import { emptyCellDash } from "src/components";
import {
  DesignPackageLocation,
  FinishSchedulePdfQuery,
  FinishSchedule_MaterialAttributeValueFragment,
  FinishSchedule_TliFragment,
  useFinishScheduleDesignPackageTlisQuery,
  useFinishSchedulePdfQuery,
} from "src/generated/graphql-types";
import {
  StackedAttributes,
  fixedDimensionsConfiguration,
  getDynamicAttributes,
  maybeAddUnitSuffix,
} from "src/routes/libraries/design-catalog/finish-schedules/FinishScheduleTable";
import {
  PRODUCT_EMPTY_STATE_IMG_URL,
  PRODUCT_FALLBACK_IMG_URL,
} from "src/routes/libraries/product-catalog/components/product-images-viewer/ProductImageViewer";
import { fail, queryResult, sanitizeHtml } from "src/utils";
import { ArrayParam, DateParam, StringParam, useQueryParams } from "use-query-params";

export function FinishSchedulePdf() {
  const [{ designPackageId, designRpavId, planRpavIds, optionIds, costCodeIds, locationInPath, exportDate, exportBy }] =
    useQueryParams({
      designPackageId: StringParam,
      designRpavId: StringParam,
      planRpavIds: ArrayParam,
      optionIds: ArrayParam,
      costCodeIds: ArrayParam,
      locationInPath: ArrayParam,
      exportDate: DateParam,
      exportBy: StringParam,
    });

  const query = useFinishSchedulePdfQuery({
    variables: { id: designPackageId ?? fail("Design Package Id is required"), versionId: designRpavId },
  });

  const parsedPlanRpavIds = parseFilterArray(planRpavIds);
  const parsedOptionIds = parseFilterArray(optionIds);
  const parsedCostCodeIds = parseFilterArray(costCodeIds);
  const parsedLocationInPath = parseFilterArray(locationInPath);

  const { data: tlisData } = useFinishScheduleDesignPackageTlisQuery({
    variables: {
      filter: {
        designRpavId: designRpavId ?? fail("Design Package Rpav Id is required"),
        planRpavIds: parsedPlanRpavIds,
        optionIds: parsedOptionIds,
        costCodeIds: parsedCostCodeIds,
        locationInPath: parsedLocationInPath,
      },
    },
  });

  return queryResult(query, (data) => (
    <FinishSchedulePdfView
      data={data}
      exportDate={exportDate}
      exportBy={exportBy}
      tlis={tlisData?.designPackageFinishSchedule.entities ?? []}
      costCodeIds={parsedCostCodeIds ?? []}
      locationIds={parsedLocationInPath ?? []}
      planRpavIds={parsedPlanRpavIds ?? []}
      optionIds={parsedOptionIds ?? []}
    />
  ));
}

type FinishSchedulePdfViewProps = {
  data: FinishSchedulePdfQuery;
  tlis: FinishSchedule_TliFragment[];
  costCodeIds: string[];
  locationIds: string[];
  planRpavIds: string[];
  optionIds: string[];
  exportDate: Date | null | undefined;
  exportBy: string | null | undefined;
};

export function FinishSchedulePdfView(props: FinishSchedulePdfViewProps) {
  const { data, tlis, costCodeIds, locationIds, planRpavIds, optionIds, exportDate, exportBy } = props;
  const { designPackage, costCodes, locations } = data;

  const isInterior = designPackage.location.code === DesignPackageLocation.Interior;

  const filteredCostCodes = costCodes.filter((l) => costCodeIds?.includes(l.id) ?? false);

  const filteredLocations = locations.filter((l) => locationIds?.includes(l.id) ?? false);

  const filteredPlans = designPackage.planPackages.filter((p) =>
    p.aggregateVersions.some((rpav) => planRpavIds?.includes(rpav.id)),
  );

  // Generate all possible combinations of forDesignPackages GOGs
  // i.e. [[Base, Deluxe, Premium], [Contemporary, Farmhouse]]
  const rposByGOG = tlis
    .flatMap((tli) =>
      tli.options
        .filter((rpo) => (isInterior ? rpo.optionGroup.forDesignInterior : rpo.optionGroup.forDesignExterior))
        // Filter rpos by the options selected in the filter
        .filter((rpo) => (optionIds?.nonEmpty ? optionIds.includes(rpo.id) : true)),
    )
    .unique()
    .groupByObject((rop) => rop.optionGroup.group)
    .map(([_, rpos]) => rpos);

  const combinations = generateCombinations(rposByGOG);

  // Each combination represents an IDP
  const idps = combinations
    .map((rpos) => {
      // Filter Tlis looking for options that matches this IDP
      const filteredTlis = tlis.filter((tli) => rpos.every((rpo) => tli.options.includes(rpo)));
      const costCodeGroups = filteredTlis.groupBy((tli) => tli.item.costCode?.id ?? "none");

      return {
        rpos: rpos.filter((rpo) => rpo.optionGroup.forDesignPackages), // The combination of RPO that defines this IDP name
        order: rpos.sum((rpo) => rpo.order),
        groups: Object.entries(costCodeGroups).map(([costCodeId, costCodeTlis]) => {
          const locationGroups = costCodeTlis.groupBy((tli) => tli.location.id);
          return {
            costCode: costCodes.find((c) => c.id === costCodeId),
            rooms: Object.entries(locationGroups).map(([locationId, locationTlis]) => ({
              location: locations.find((l) => l.id === locationId) ?? fail("impossible no location found"),
              baseTlis: locationTlis.filter((tli) => tli.options.every((rpo) => rpo.optionGroup.forDesignPackages)),
              // Find all the TLIs that are associated to a plan option
              plans: locationTlis
                .flatMap((tli) => tli.options)
                .uniqueByKey("id")
                .filter((rpo) => rpo.optionGroup.isPlanPackage)
                .map(
                  (planOption) =>
                    [
                      planOption, // We use this to display the option name
                      locationTlis.filter((tli) => tli.options.some((rpo) => rpo.id === planOption.id)),
                    ] as const,
                ),
              // Find all the TLIs that are associated to a an upgrade option and are not associated to any plan option
              upgrades: locationTlis
                .flatMap((tli) => tli.options)
                .uniqueByKey("id")
                .filter((rpo) => rpo.optionGroup.forDesignUpgrade)
                .map(
                  (upgradeOption) =>
                    [
                      upgradeOption, // We use this to display the option name
                      locationTlis
                        .filter((tli) => tli.options.some((rpo) => rpo.id === upgradeOption.id))
                        .filter((tli) => tli.options.every((rpo) => !rpo.optionGroup.isPlanPackage)),
                    ] as const,
                )
                .filter(([_, tlis]) => tlis.nonEmpty),
            })),
          };
        }),
      };
    })
    .sortByKey("order");

  return (
    <Fragment>
      <Global styles={{ "@page": { size: "letter landscape" } }} />
      <div css={Css.df.jcsb.$}>
        <div css={Css.df.fdc.gap1.$}>
          <div css={Css.xlSb.$}>{designPackage.name}</div>
          <div css={Css.df.gap1.sm.aic.$}>
            <span>
              Cost Codes:{" "}
              <span css={Css.smBd.$}>
                {filteredCostCodes.nonEmpty ? filteredCostCodes.map((c) => c.name).join(", ") : "All"}
              </span>
            </span>
            <span>
              Rooms:{" "}
              <span css={Css.smBd.$}>
                {filteredLocations.nonEmpty ? filteredLocations.map((c) => c.name).join(", ") : "All"}
              </span>
            </span>
            <span>
              Plans:{" "}
              <span css={Css.smBd.$}>
                {filteredPlans.nonEmpty ? filteredPlans.map((c) => c.name).join(", ") : "All"}
              </span>
            </span>
            <span>
              Location: <span css={Css.smBd.$}>{designPackage.location.name}</span>
            </span>
          </div>
        </div>
        <div css={Css.df.fdc.gap1.$}>
          <span>Last Updated: {format(designPackage.updatedAt, "MM/dd/yyyy")}</span>
          <span>Exported: {format(exportDate ?? new Date(), "MM/dd/yyyy")}</span>
          <span>Exported by: {exportBy}</span>
          <span>Version: {designPackage.version.version}</span>
        </div>
      </div>
      <div css={Css.df.fdc.gap2.mt1.$}>
        {idps.map(({ rpos, groups }, i) => (
          <div key={rpos.map((rpo) => rpo.id).join()} css={Css.pb3.if(i < idps.length - 1).bb.bcGray400.$}>
            <span css={Css.baseMd.gray700.$}>{rpos.map((rpo) => rpo.name).join(" - ")}</span>
            {groups.map(({ costCode, rooms }) => (
              <div key={`${costCode?.id}-${rpos.map((rpo) => rpo.id).join()}`} css={Css.df.fdc.gap1.$}>
                <span css={Css.lgSb.$}>{costCode?.name ?? "Without Cost Code"}</span>
                {rooms.map(({ location, baseTlis, plans, upgrades }) => (
                  <div key={location.id}>
                    <span css={Css.baseMd.$}>{location.name}</span>
                    <FinishScheduleTable tlis={baseTlis} />
                    {upgrades.map(([upgradeOption, upgradeTlis]) => (
                      <div key={upgradeOption.id}>
                        <span css={Css.baseMd.$}>
                          {location.name} - {upgradeOption.name}
                        </span>
                        <FinishScheduleTable tlis={upgradeTlis} />
                      </div>
                    ))}
                    {plans.map(([planOption, planTlis]) => (
                      <div key={planOption.id}>
                        <span css={Css.baseMd.$}>
                          {location.name} - {planOption.name}
                        </span>
                        <FinishScheduleTable tlis={planTlis} />
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            ))}
          </div>
        ))}
      </div>
    </Fragment>
  );
}

function FinishScheduleTable({ tlis }: { tlis: FinishSchedule_TliFragment[] }) {
  const rows = useMemo(() => createRows(tlis ?? []), [tlis]);
  const columns = useMemo(() => createColumns(), []);

  return (
    <GridTable
      style={{ rowHover: false, bordered: true }}
      rowStyles={{ data: { cellCss: Css.bcGray200.bt.br.$ } }}
      columns={columns}
      rows={rows}
      as="table"
    />
  );
}

type FinishScheduleRow = {
  id: string;
  kind: "data";
  data: FinishSchedule_TliFragment;
  dynamicAttributes: FinishSchedule_MaterialAttributeValueFragment[][];
};

function createRows(data: FinishSchedule_TliFragment[]): GridDataRow<FinishScheduleRow>[] {
  // Only show Product TLis (which all point to their placeholder)
  return data
    .filter((tli) => !!tli.placeholder)
    .map((tli) => ({ kind: "data", id: tli.id, data: tli, dynamicAttributes: getDynamicAttributes(tli) }));
}

function createColumns(): GridColumn<FinishScheduleRow>[] {
  return [
    column<FinishScheduleRow>({
      data: (tli) => (
        <div css={Css.w100.h100.mwPx(75).df.aic.jcc.$}>
          <img
            css={Css.hPx(75).wPx(75).$}
            src={
              !tli.isDisabledBidItem && !tli.bidItem
                ? PRODUCT_EMPTY_STATE_IMG_URL
                : tli.materialVariant?.featuredImage?.asset?.previewUrl || PRODUCT_FALLBACK_IMG_URL
            }
            alt="product"
          />
        </div>
      ),
      w: "100px",
      mw: "100px",
    }),
    column<FinishScheduleRow>({
      data: (tli) => (
        <div css={Css.df.fdc.gap2.$}>
          <div>
            <div data-testid="productType" css={Css.smMd.df.fww.$}>
              {startCase(tli.slot.name || tli.item.name)}
            </div>
            <div css={Css.gray600.$}>PRODUCT TYPE</div>
          </div>
          <div data-testid="productCode">{tli.materialVariant?.code}</div>
          {tli.materialVariant?.listing.manufacturerUrl && (
            <a
              target="_blank"
              href={tli.materialVariant?.listing.manufacturerUrl}
              css={Css.blue600.tdu.$}
              rel="noreferrer"
            >
              Manufacturer Link
            </a>
          )}
        </div>
      ),
      w: "250px",
    }),
    column<FinishScheduleRow>({
      data: (tli) => (
        <StackedAttributes
          topAttribute={{
            value: tli.materialVariant?.listing.name ?? emptyCellDash,
            label: "PRODUCT NAME",
          }}
          bottomAttribute={{
            value: tli.materialVariant?.listing.brand?.name ?? emptyCellDash,
            label: "BRAND",
          }}
        />
      ),
      w: "250px",
    }),
    column<FinishScheduleRow>({
      data: (tli) => (
        <div css={Css.h100.gap2.$}>
          <div>
            <div data-testid="skuModel" css={Css.smMd.$}>
              {tli.materialVariant?.modelNumber ?? emptyCellDash}
            </div>
            <div css={Css.gray600.$}>SKU/MODEL #</div>
          </div>
        </div>
      ),
      w: "150px",
    }),
    // 3 "fixed attribute" columns.
    ...Object.entries(fixedDimensionsConfiguration).map(([_, [topDimName, bottomDimName]]) =>
      column<FinishScheduleRow>({
        data: (tli) => {
          const topMav = tli.materialVariant?.materialAttributeValues.find(
            (mav) => mav.dimension.name.toLowerCase() === topDimName?.toLowerCase(),
          );
          const bottomMav = tli.materialVariant?.materialAttributeValues.find(
            (mav) => mav.dimension.name.toLowerCase() === bottomDimName?.toLowerCase(),
          );
          return (
            <StackedAttributes
              topAttribute={{
                value: topMav?.textValue ?? emptyCellDash,
                label: maybeAddUnitSuffix(topMav) ?? topDimName,
              }}
              bottomAttribute={{
                value: bottomMav?.textValue ?? emptyCellDash,
                label: maybeAddUnitSuffix(bottomMav) ?? bottomDimName,
              }}
            />
          );
        },
        w: "120px",
      }),
    ),
    // We try to render up to 4 attribute fields in addition to the 6 fixed attributes
    // 'row.dynamicAttributes' is a 2D array of FinishSchedule_MaterialAttributeValueFragment[][]
    // i.e. [[attrValue1, attrValue2], [attrValue3, attrValue4]]
    column<FinishScheduleRow>({
      data: (_, { row }) => {
        const columnOne = row.dynamicAttributes.first ?? [];
        const [topMav, bottomMav] = columnOne;
        return (
          <StackedAttributes
            topAttribute={{ value: topMav?.textValue ?? emptyCellDash, label: maybeAddUnitSuffix(topMav) ?? "" }}
            bottomAttribute={{
              value: bottomMav?.textValue ?? emptyCellDash,
              label: maybeAddUnitSuffix(bottomMav) ?? "",
            }}
          />
        );
      },
      w: "120px",
    }),
    column<FinishScheduleRow>({
      data: (_, { row }) => {
        const columnTwo = row.dynamicAttributes.last ?? [];
        const [topMav, bottomMav] = columnTwo;
        return (
          <StackedAttributes
            topAttribute={{ value: topMav?.textValue ?? emptyCellDash, label: maybeAddUnitSuffix(topMav) ?? "" }}
            bottomAttribute={{
              value: bottomMav?.textValue ?? emptyCellDash,
              label: maybeAddUnitSuffix(bottomMav) ?? "",
            }}
          />
        );
      },
      w: "120px",
    }),
    column<FinishScheduleRow>({
      data: (tli) => (
        <div /* Design Notes */ dangerouslySetInnerHTML={{ __html: sanitizeHtml(tli.specifications ?? "") }} />
      ),
      w: "210px",
    }),
  ];
}

/* Function to generate all combinations from any number of groups
 Turns [[Base, Deluxe, Premium], [Contemporary, Farmhouse]] 
 Into [[Base, Contemporary], [Base, Farmhouse], [Deluxe, Comtemporary], [Deluxe, Farmhouse], [Premium, Contemporary], [Premium, Farmhouse]]
*/
export function generateCombinations<T>(groups: T[][]): T[][] {
  // Reduce the groups to a single array of combinations
  return groups.reduce((acc, group) => {
    if (acc.length === 0) {
      return group.map((item) => [item]);
    }
    const newCombinations: T[][] = [];
    for (const combination of acc) {
      for (const item of group) {
        newCombinations.push([...combination, item]);
      }
    }
    return newCombinations;
  }, [] as T[][]);
}

export function parseFilterArray(filter: (string | null)[] | null | undefined) {
  // If the filter is not empty, split the string by comma and map the values to an array of strings, i.e. ["1,2,3"] => ["1", "2", "3"]
  return filter?.nonEmpty ? filter.first!.split(",").map((id) => id) : undefined;
}
