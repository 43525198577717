import { ObjectConfig, ObjectState, required } from "@homebound/form-state";
import {
  AddOptionsMetadataQuery,
  AddReadyPlanOptionFragment,
  GlobalOption,
  IncrementalCollectionOp,
  InputMaybe,
  Location,
  Maybe,
  ProgramDataForLotSequenceFragment,
  ReadyPlanOptionGroupFragment,
  SavePlanPackageInput,
  SaveProductTypeInput,
  SaveReadyPlanAssetInput,
  SaveReadyPlanOptionInput,
  SaveReadyPlanOptionProgramDataModificationInput,
  SaveReadyPlanProgramDataInput,
  Scalars,
  useSavePlanPackageDetailsMutation,
} from "src/generated/graphql-types";
import { ProgramDataKey } from "src/routes/developments/lot-summary/sequence-sheet/components/utils";
import { HasIdAndName } from "src/utils";
import { DefaultedReadyPlanOption } from "../stepper/2.ElevationsStep";

export const productTypeConfig: ObjectConfig<SaveProductTypeInput> = {
  id: { type: "value" },
  name: { type: "value" },
};

export type FileData = { previewUrl?: string | null; fileName?: string | null | undefined };

type LocalFilesForm = {
  assets: (SaveReadyPlanAssetInput & { asset: FileData })[];
};

export type PlanPackageDetailsForm = Omit<SavePlanPackageInput, "code"> &
  LocalFilesForm & {
    code: Maybe<Scalars["Int"]>;
  };

export const planPackageDetailsConfig: ObjectConfig<PlanPackageDetailsForm> = {
  id: { type: "value" },
  name: { type: "value", rules: [required] },
  description: { type: "value" },
  code: { type: "value", rules: [required] },
  type: { type: "value", rules: [required] },
  lotTypes: { type: "value" },
  assets: {
    type: "list",
    config: {
      id: { type: "value" },
      asset: { type: "value" },
      parentId: { type: "value" },
      type: { type: "value" },
      active: { type: "value" },
    },
  },
  markets: { type: "value" },
};

export const saveReadyPlanOptionConfig: ObjectConfig<DefaultedReadyPlanOption> = {
  id: { type: "value" },
  readyPlanId: { type: "value", rules: [required] },
  globalOptionId: { type: "value", rules: [required] },
  active: { type: "value", rules: [required] },
  displayName: { type: "value" },
  order: { type: "value" },
  assets: {
    type: "list",
    config: {
      id: { type: "value" },
      asset: { type: "value" },
      parentId: { type: "value" },
      type: { type: "value" },
    },
  },
};

export const planPackageElevationsConfig: ObjectConfig<{
  readyPlanOptions: DefaultedReadyPlanOption[];
}> = {
  readyPlanOptions: { type: "list", config: saveReadyPlanOptionConfig, update: "incremental" },
};

//  //////////////////////////////////////////////////////////////////////////

type ChildOptions = Maybe<SaveReadyPlanOptionInput> & { globalOptionName?: string };

export type ProgramDataModifications = Maybe<
  Omit<SaveReadyPlanOptionProgramDataModificationInput, "modifiedOptionId" | "matchesOptionId">
> & {
  globalOptionCode?: string;
  modifiedOption: { id: string };
  matchesOption?: {
    id: string;
    globalOption: {
      id: string;
      code: string;
    };
  };
  // Add derived fields that are not in the inputs, so the UI can render them
  programData?: Omit<ProgramDataForLotSequenceFragment, ProgramDataKey | "__typename" | "updatedAt">;
};

// Extending the `SaveReadyPlanOptionInput` to help with rendering the UI
export type AddReadyPlanOption = Omit<SaveReadyPlanOptionInput, "childOptions"> & {
  fragment?: AddReadyPlanOptionFragment;
  optionTypeId: Maybe<string>;
  globalTypeName?: Maybe<string>;
  globalOptionCode?: Maybe<string>;
  globalOptionDescription?: Maybe<string>;
  isElevation?: Maybe<boolean>;
  globalOptionLocation?: Maybe<Pick<Location, "id" | "name">>;
  childOptions?: ChildOptions[];
  programDataModifiedBy?: ProgramDataModifications[];
  // Add derived fields that are not in the inputs, so the UI can render them
  programData?: Omit<ProgramDataForLotSequenceFragment, ProgramDataKey | "__typename" | "updatedAt">;
  optionGroup?: ReadyPlanOptionGroupFragment;
};

export type AddReadyPlanOptionsForm = {
  readyPlanId: string;
  readyPlanOptions: AddReadyPlanOption[];
};

export const addRpoConfig: ObjectConfig<AddReadyPlanOptionsForm> = {
  readyPlanId: { type: "value" },
  readyPlanOptions: {
    type: "list",
    config: {
      fragment: { type: "value" },
      id: { type: "value" },
      name: { type: "value" },
      active: { type: "value", rules: [required] },
      globalOptionId: { type: "value", rules: [required] },
      readyPlanId: { type: "value", rules: [required] },
      locationId: { type: "value" },
      optionTypeId: { type: "value" },
      globalTypeName: { type: "value" },
      globalOptionCode: { type: "value" },
      globalOptionDescription: { type: "value" },
      globalOptionLocation: { type: "value" },
      isElevation: { type: "value" },
      programDatas: {
        type: "list",
        config: {
          id: { type: "value" },
          op: { type: "value" },
          readyPlanOptionIds: { type: "value" },
          programData: { type: "object", config: completePDConfig() },
        },
      },
      programData: {
        type: "object",
        config: completePDConfig(),
      },
      programDataModifiedBy: {
        type: "list",
        config: {
          id: { type: "value" },
          globalOptionCode: { type: "value" },
          modifiedOption: { type: "value" },
          matchesOption: { type: "value" },
          programData: {
            type: "object",
            config: completePDConfig(),
          },
        },
      },
      childOptions: {
        type: "list",
        config: {
          id: { type: "value" },
          active: { type: "value", rules: [required] },
          globalOptionId: { type: "value", rules: [required] },
          globalOptionName: { type: "value" },
        },
      },
      optionGroup: { type: "value" },
    },
  },
};

// Extending the `SaveReadyPlanOptionInput` to help with rendering the UI
export type ConfigureReadyPlanOption = Omit<SaveReadyPlanOptionInput, "childOptions"> & {
  optionTypeId: Maybe<string>;
  globalTypeName?: Maybe<string>;
  globalOptionCode?: Maybe<string>;
  location?: Maybe<Pick<Location, "id" | "name">>;
  optionGroup?: Omit<ReadyPlanOptionGroupFragment, "required">;
  optionPrerequisiteIds: InputMaybe<string[]>;
  optionConflictIds: InputMaybe<string[]>;
  optionConflictChildIds: InputMaybe<string[]>;
  order: InputMaybe<number>;
};

export type ConfigureReadyPlanOptionsForm = {
  readyPlanOptions: ConfigureReadyPlanOption[];
};

export const configureRpoConfig: ObjectConfig<ConfigureReadyPlanOptionsForm> = {
  readyPlanOptions: {
    type: "list",
    config: {
      id: { type: "value" },
      name: { type: "value" },
      active: { type: "value", rules: [required] },
      readyPlanId: { type: "value", rules: [required] },
      optionTypeId: { type: "value" },
      globalTypeName: { type: "value" },
      globalOptionCode: { type: "value" },
      location: { type: "value" },
      optionGroup: {
        type: "object",
        config: {
          id: { type: "value" },
          name: { type: "value" },
          isMultiOptionGroup: { type: "value" },
          isSingleOptionGroup: { type: "value" },
          order: { type: "value" },
        },
      },
      optionPrerequisiteIds: { type: "value" },
      optionConflictIds: { type: "value" },
      optionConflictChildIds: { type: "value" },
      optionPrereqChildIds: { type: "value" },
      order: { type: "value" },
    },
  },
};

export function getExistingAndActiveOptions(
  globalSchemes: Pick<GlobalOption, "id" | "code" | "name">[],
  currentOptions: { globalOption: Pick<GlobalOption, "id" | "name"> }[],
): HasIdAndName[] {
  // Modify activeGlobalSchemes to include the code of the global option in the name
  const globalSchemesWithCode = globalSchemes.map(({ id, code, name }) => ({ id, name: `${code} - ${name}` }));
  return currentOptions
    .map((rpo) => rpo.globalOption as HasIdAndName)
    .concat(globalSchemesWithCode)
    .uniqueByKey("id")
    .sortByKey("name");
}

export function completePDConfig() {
  return {
    ...readyPlanProgramDataConfigConfig(),
    sellableSqft: { type: "value" },
    permittableSqft: { type: "value" },
    grossBuildableSqft: { type: "value" },
    netBuildableSqft: { type: "value" },
    sellableAboveGroundSqft: { type: "value" },
    sellableBelowGroundSqft: { type: "value" },
    grossBelowGroundSqft: { type: "value" },
    unfinishedBelowGroundSqft: { type: "value" },
    imperviousSqft: { type: "value" },
  };
}

export type OptionsEnumNames = Exclude<keyof AddOptionsMetadataQuery["enumDetails"], "__typename">;

function readyPlanProgramDataConfigConfig() {
  return {
    stories: { type: "value" },
    widthInFeet: { type: "value" },
    minLotSizeInSqft: { type: "value" },
    minLotDepthInFeet: { type: "value" },
    minLotWidthInFeet: { type: "value" },
    buildingHeightInFeet: { type: "value" },
    depthInFeet: { type: "value" },
    bedrooms: { type: "value" },
    closetsInPrimarySuite: { type: "value" },
    fullBaths: { type: "value" },
    halfBaths: { type: "value" },
    garageAttached: { type: "value" },
    garageDetached: { type: "value" },
    garagePort: { type: "value" },
    garageConfiguration: { type: "value" },
    basementConfig: { type: "value" },
    firstFloorBedrooms: { type: "value" },
    diningRoom: { type: "value" },
    casualDining: { type: "value" },
    mediaRooms: { type: "value" },
    loftGameFlexRooms: { type: "value" },
    offices: { type: "value" },
    workspaces: { type: "value" },
    primaryBedroom: { type: "value" },
  };
}

export function preProcessUpdatedOptions(formState: ObjectState<ConfigureReadyPlanOptionsForm>) {
  return (
    formState.readyPlanOptions.rows
      .filter((rpo) => rpo.dirty && Object.getOwnPropertyNames(rpo.changedValue).length > 1)
      .map((rpo) => ({ ...rpo.changedValue, optionGroup: undefined }))
      // ignore the childIds as they are only used for UI
      .map((rpo) => {
        const { optionPrereqChildIds, optionConflictChildIds, ...rest } = rpo;
        return rest;
      })
      // since we're ignoring the optionGroup here, rpos may be dirty but technically not changed
      // so let's make sure there is something other than the id and group here
      .filter(({ id, optionGroup, ...rest }) => Object.keys(rest).length)
  );
}

export async function savePlanPackageOptionsProgramData(
  formState: ObjectState<AddReadyPlanOptionsForm>,
  planPackage: { id: string },
  saveMutation: ReturnType<typeof useSavePlanPackageDetailsMutation>[0],
) {
  // for every rpo, we need to gather the program data changes
  const saveReadyPlanPayload: SavePlanPackageInput = {
    id: planPackage.id,
    programDatas: formState.readyPlanOptions.rows
      .flatMap((rpo) => {
        const programDataChanges = rpo.programData.changedValue;
        // identify its id
        const readyPlanProgramDataId = rpo.programDatas?.value.find(
          (rppd) => rppd.readyPlanOptionIds?.length === 1,
        )?.id;

        const modifiersChanges = rpo.programDataModifiedBy.changedValue
          .filter((rppd) => !!rppd.programData || rppd.id?.startsWith("temp_"))
          .map((modifierChange) => {
            let id = modifierChange?.id?.split("_")?.[1];
            let readyPlanOptionIds: string[] | undefined;

            if (modifierChange?.id?.startsWith("temp_")) {
              id = undefined;
              const fullModifyRecord = rpo.programDataModifiedBy.value.find((pdm) => pdm.id === modifierChange.id);
              readyPlanOptionIds = [rpo.id.value, fullModifyRecord?.matchesOption?.id].compact();
            }

            return {
              id,
              op: IncrementalCollectionOp.Include,
              ...(readyPlanOptionIds && { readyPlanOptionIds }),
              ...(Object.keys(modifierChange.programData ?? {}).nonEmpty && {
                programData: modifierChange.programData,
              }),
            };
          });

        // turn it into a ready plan program data record
        return [
          {
            id: readyPlanProgramDataId,
            readyPlanOptionIds: [rpo.id.value],
            op: IncrementalCollectionOp.Include,
            ...(Object.keys(programDataChanges ?? {}).nonEmpty && { programData: programDataChanges }),
          },
          ...modifiersChanges,
        ] as SaveReadyPlanProgramDataInput[];
      })
      .filter(
        (saveRppdInput) => Object.keys(saveRppdInput.programData ?? {}).nonEmpty || saveRppdInput.readyPlanOptionIds,
      ),
  };

  // save it
  if (saveReadyPlanPayload.programDatas?.nonEmpty) return saveMutation({ variables: { input: saveReadyPlanPayload } });
}
